import React, { useState, useEffect } from "react";
import cx from "classnames";

import Keyword from "./keyword";

import {
  Col,
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  Collapse
} from "reactstrap";

const KeywordList = React.memo(({row, ind, saveEditingSeed, deleteKeyword, makeitSeedKeyword, moveTo, archiveSeed, isListOpen, keywordTouched}) => {


  const [keywordList, setKeywordList] = useState(row.keywordList);
	const [editingMode, setEditingMode] = useState("");
  const [editingSeed, setEditingSeed] = useState("");

  
  useEffect(() => {
    setKeywordList(row.keywordList);
  }, [row])
  
  const [selected, setSelected] = useState({});
  const select = (ind, idx, keyword, isNew) => {
  	//let [keyword] = {ind, idx};
    // console.log(ind, idx, keyword, isNew, keywordList);
  	let newSelection = {...selected};
  	if (newSelection[keyword]) {
  		delete newSelection[keyword];
  	} else {
  		newSelection[keyword] = {ind, idx};
  	}
  	setSelected({...newSelection});
    if ( isNew === 1 ) {
      keywordTouched(keyword);
      let keywordListNew = [...keywordList];
      keywordListNew[ind].isNew=0;
      setKeywordList(keywordListNew);
    }
  }

  const handleClick = (e, data) => {
  	e.preventDefault();
    console.log(e, data);
  	let keywordSelected = {};
  	let {action, keyword, ind, idx} = data;
  	if ( selected[keyword] ) {
      keywordSelected = {...selected};
    } else {
      keywordSelected = {
        [keyword]: {ind, idx}
      };
    }

    if ( action === "Seed" ) {
    	makeitSeedKeyword(keywordSelected);
    }
    if ( action === "Delete" ) {
    	deleteKeyword(keywordSelected);
    }

    if ( action === "Move" ) {
    	moveTo(keywordSelected);
    }
  }

  const [isCollapse, setIsCollapse] = useState(isListOpen);
  const [icon, setIcon] = useState("fa fa-chevron-down");
  const toggle = () => {
    setIsCollapse(!isCollapse);
    let faicon = isCollapse
        ? "fa fa-chevron-right"
        : "fa fa-chevron-down";
    setIcon(faicon);
  };


  const animate = (isListOpen) => {
  	setIsCollapse(isListOpen);
    let faicon = !isListOpen
        ? "fa fa-chevron-right"
        : "fa fa-chevron-down";
    setIcon(faicon);
  }

  useEffect(() => {
  	animate(isListOpen);
  }, [isListOpen])


 	return (<Col className={cx("p-0", ind%5<4 && "pr-1")}>
    {editingMode === row.seed ? 
      <InputGroup size="sm">
        <Input value={editingSeed} onChange={(e) => setEditingSeed(e.target.value)}/>
        <InputGroupAddon addonType="append">
          <Button className="btn-icon" color="primary" onClick={() => saveEditingSeed(row.seed, editingSeed)}><i className="fas fa-save"></i></Button>
        </InputGroupAddon>
      </InputGroup>
      : <h4 className="list-group-item p-1 bg-primary text-white" onClick={toggle}>
          {row.seed !== "UNCATEGORIZED" && <Button size="sm" className="btn-icon p-0" color="primary" onClick={() => archiveSeed(row.seed, row.archived)}>
          	{row.archived ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>}</Button>}
          <i className={icon} /> {row.seed}
          {row.seed !== "UNCATEGORIZED" && <Button className="bg-transparent float-right p-0" 
            onClick={() => {setEditingMode(row.seed); setEditingSeed(row.seed); }}>
            <i className="fas fa-pen"></i>
          </Button>}
        </h4>}
    <Collapse isOpen={isCollapse}>
	    <ul className="list-group" style={{maxHeight: "500px", overflow: "auto" }}>
	      {keywordList.map((keywordRow, idx) => (
	        <Keyword key={keywordRow.keywordID} keywordRow={keywordRow} 
	        	handleClick={handleClick} 
	        	select={select}
	        	isSelected={selected[keywordRow.keyword]} 
	        	ind={idx} idx={keywordRow.keywordID} 
	        />
	      ))}
	    </ul>
    </Collapse>
  </Col>);
});

export default KeywordList;