import React, { useState, useEffect } from "react";
import moment from "moment";
import cx from "classnames";
import { API } from 'aws-amplify';

import { Link } from "react-router-dom";

import md5 from 'md5';
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css';
import DataTable from 'react-data-table-component';
import memoize from 'memoize-one';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,
  Col,
  Input,
  UncontrolledTooltip,
  ListGroup,
  ListGroupItem,
  ButtonGroup,
  Label
} from "reactstrap";


// import Spinner from "../../../components/Spinner";
import FbRuleModal from "../../../components/FbRuleModal.js";
import FbRuleAccountsModal from "../../../components/FbRuleAccountsModal.js";

import CONFIG from "../../reports/config.js"


const TIMEs = {
  "": "as soon as revenue are out",
  "d0": "SUNDAY - only run if today is Sunday",
  "d1": "MONDAY - only run if today is Monday",
  "d2": "TUESDAY - only run if today is Tuesday",
  "d3": "WEDNESDAY - only run if today is Wednesday",
  "d4": "THURSDAY - only run if today is Thursday",
  "d5": "FRIDAY - only run if today is Friday",
  "d6": "SATURDAY - only run if today is Saturday",
  "d-0": "SKIP SUNDAY - only run if today is not a Sunday",
  "d-1": "SKIP MONDAY - only run if today is not a Monday",
  "d-2": "SKIP TUESDAY - only run if today is not a Tuesday",
  "d-3": "SKIP WEDNESDAY - only run if today is not a Wednesday",
  "d-4": "SKIP THURSDAY - only run if today is not a Thursday",
  "d-5": "SKIP FRIDAY - only run if today is not a Friday",
  "d-6": "SKIP SATURDAY - only run if today is not a Saturday",
  "0": "This hourly rule run at 00:00 UTC ( only run at 00:00 UTC )",
  "1": "This hourly rule run at 01:00 UTC ( only run at 01:00 UTC )",
  "2": "This hourly rule run at 02:00 UTC ( only run at 02:00 UTC )",
  "3": "This hourly rule run at 03:00 UTC ( only run at 03:00 UTC )",
  "4": "This hourly rule run at 04:00 UTC ( only run at 04:00 UTC )",
  "5": "This hourly rule run at 05:00 UTC ( only run at 05:00 UTC )",
  "6": "This hourly rule run at 06:00 UTC ( only run at 06:00 UTC )",
  "7": "This hourly rule run at 07:00 UTC ( only run at 07:00 UTC )",
  "8": "This hourly rule run at 08:00 UTC ( only run at 08:00 UTC )",
  "9": "This hourly rule run at 09:00 UTC ( only run at 09:00 UTC )",
  "10": "This hourly rule run at 10:00 UTC ( only run at 10:00 UTC )",
  "11": "This hourly rule run at 11:00 UTC ( only run at 11:00 UTC )",
  "12": "This hourly rule run at 12:00 UTC ( only run at 12:00 UTC )",
  "13": "This hourly rule run at 13:00 UTC ( only run at 13:00 UTC )",
  "14": "This hourly rule run at 14:00 UTC ( only run at 14:00 UTC )",
  "15": "This hourly rule run at 15:00 UTC ( only run at 15:00 UTC )",
  "16": "This hourly rule run at 16:00 UTC ( only run at 16:00 UTC )",
  "17": "This hourly rule run at 17:00 UTC ( only run at 17:00 UTC )",
  "18": "This hourly rule run at 18:00 UTC ( only run at 18:00 UTC )",
  "19": "This hourly rule run at 19:00 UTC ( only run at 19:00 UTC )",
  "20": "This hourly rule run at 20:00 UTC ( only run at 20:00 UTC )",
  "21": "This hourly rule run at 21:00 UTC ( only run at 21:00 UTC )",
  "22": "This hourly rule run at 22:00 UTC ( only run at 22:00 UTC )",
  "23": "This hourly rule run at 23:00 UTC ( only run at 23:00 UTC )",
  "0_revenue": "This hourly rule run as soon as the 00:00 (UTC) revenue are available and it will continue to run till midnight",
  "1_revenue": "This hourly rule run as soon as the 01:00 (UTC) revenue are available and it will continue to run till midnight",
  "2_revenue": "This hourly rule run as soon as the 02:00 (UTC) revenue are available and it will continue to run till midnight",
  "3_revenue": "This hourly rule run as soon as the 03:00 (UTC) revenue are available and it will continue to run till midnight",
  "4_revenue": "This hourly rule run as soon as the 04:00 (UTC) revenue are available and it will continue to run till midnight",
  "5_revenue": "This hourly rule run as soon as the 05:00 (UTC) revenue are available and it will continue to run till midnight",
  "6_revenue": "This hourly rule run as soon as the 06:00 (UTC) revenue are available and it will continue to run till midnight",
  "7_revenue": "This hourly rule run as soon as the 07:00 (UTC) revenue are available and it will continue to run till midnight",
  "8_revenue": "This hourly rule run as soon as the 08:00 (UTC) revenue are available and it will continue to run till midnight",
  "9_revenue": "This hourly rule run as soon as the 09:00 (UTC) revenue are available and it will continue to run till midnight",
  "10_revenue": "This hourly rule run as soon as the 10:00 (UTC) revenue are available and it will continue to run till midnight",
  "11_revenue": "This hourly rule run as soon as the 11:00 (UTC) revenue are available and it will continue to run till midnight",
  "12_revenue": "This hourly rule run as soon as the 12:00 (UTC) revenue are available and it will continue to run till midnight",
  "13_revenue": "This hourly rule run as soon as the 13:00 (UTC) revenue are available and it will continue to run till midnight",
  "14_revenue": "This hourly rule run as soon as the 14:00 (UTC) revenue are available and it will continue to run till midnight",
  "15_revenue": "This hourly rule run as soon as the 15:00 (UTC) revenue are available and it will continue to run till midnight",
  "16_revenue": "This hourly rule run as soon as the 16:00 (UTC) revenue are available and it will continue to run till midnight",
  "17_revenue": "This hourly rule run as soon as the 17:00 (UTC) revenue are available and it will continue to run till midnight",
  "18_revenue": "This hourly rule run as soon as the 18:00 (UTC) revenue are available and it will continue to run till midnight",
  "19_revenue": "This hourly rule run as soon as the 19:00 (UTC) revenue are available and it will continue to run till midnight",
  "20_revenue": "This hourly rule run as soon as the 20:00 (UTC) revenue are available and it will continue to run till midnight",
  "21_revenue": "This hourly rule run as soon as the 21:00 (UTC) revenue are available and it will continue to run till midnight",
  "22_revenue": "This hourly rule run as soon as the 22:00 (UTC) revenue are available and it will continue to run till midnight",
  "23_revenue": "This hourly rule run as soon as the 23:00 (UTC) revenue are available and it will continue to run till midnight",
};

const ruleConditionPeriod = {
  "today":"Today",
  "yesterday":"Yesterday",
  "last2days": "Last two days: condition must be true every single day",
  "last3days":"Last three days: condition must be true every single day",
  "last7days":"Last 7 days: condition must be true every single day",
  "lifetime":"Lifetime: condition must be true every single day",
  "aggr2days": "Sum of last two days: condition must be true after aggregating",
  "aggr3days": "Sum of last three days: condition must be true after aggregating",
  "aggr7days": "Sum of last 7 days: condition must be true after aggregating"
};


const columns = memoize((toggleEditRuleModal,setLastExecutionOutput, toggle, saveNotification, setEditingRule, setSelectedSkipDates, toggleSkipUntillModal, deleteRule, fbAccountsMapping) => [
  {selector: (rule) => fbAccountsMapping[rule.fbAccount] ? fbAccountsMapping[rule.fbAccount].accountName : rule.fbAccount, name: "FB Account", sortable: true, compact:true, wrap:true},
  {selector: "name", name: "Rule Name", sortable: true, wrap:true, cell: (rule) => {
    let id = md5(rule.name + "" + rule.fbAccount);
    return (
    <div style={{cursor: "pointer"}} onClick={() => {setEditingRule(rule); toggleEditRuleModal();}}>
      <span href="#" id={`rule-${id}`}>{rule.name}</span>
      <UncontrolledTooltip placement="right" target={`rule-${id}`}>
        <ListGroup flush>
          {JSON.parse(rule.conditions).map((c, iddx) => 
            <ListGroupItem color="warning" key={`rule-${id}-cond-${iddx}`}>{c.dimension} {c.op} {c.value}</ListGroupItem>
          )}
          {/*<ListGroupItem color="warning">click to edit</ListGroupItem>*/}
        </ListGroup>
      </UncontrolledTooltip></div>
  )}},
  {selector: (rule) => {
    if ( rule.action.startsWith("BID") ) {
      let [action, value, col="bid"] = rule.action.split("_");
      if ( !value.endsWith("%") ) {
        value = value + "%";
      }
      return action + " " + value + " of " + col;
    } else if ( rule.action.startsWith("BUDGET") ) {
      let [action, value] = rule.action.split("_");
      return action + " " + value;
    } else if ( rule.action.startsWith("PAUSE_RESTART_") ) {
      let [pause, restart, when, updateCol, updateVal] = rule.action.split("_");
      let label = "PAUSE AND RESTART: " + when;
      if ( updateCol ) {
        label += " UPDATE " + updateCol + ":" + updateVal;
      } 
      return label;
    } else {
      return rule.action;
    }
  }, name: "Action", sortable: true, wrap: true},
  {selector: "applyTo", width:"70px", name: "Apply to:", compact:true, cell: (rule) => rule.applyTo.startsWith("adset") ? rule.applyTo : `${rule.applyTo} campaigns`},
  {selector: (rule) => ruleConditionPeriod[rule.time_range] && ruleConditionPeriod[rule.time_range].split(":")[0], name: "Time Range", compact:true},
  {name: "Schedule", width:"70px", cell: (rule) => {
    let id = md5(rule.name + "" + rule.fbAccount);
    return <div>
      <span href="#" id={`rule-schedule-${id}`}>{rule.schedule}</span>
      <UncontrolledTooltip placement="right" target={`rule-schedule-${id}`}>{TIMEs[rule.time]}</UncontrolledTooltip>
    </div>
  }},
  {selector: "last_execution_time", name: "Last Executed", sortable: true, ignoreRowClick:true, center:true, compact:true, cell: (rule) => (
    <span style={{cursor: "pointer"}} onClick={() => {
        setLastExecutionOutput(JSON.parse(rule.last_execution_output || "[]")); 
        toggle();
      }}>{rule.last_execution_time && rule.last_execution_time.slice(0,-6)}</span>)},
  {selector: "notification", name: "Notification", ignoreRowClick:true, width:"160px", cell: (rule) => (
      <div><small>OFF</small><Label className="custom-toggle custom-toggle-danger" style={{margin:0}}>
        <Input type="checkbox" defaultChecked={rule.notification} onClick={(e) => {
          let notification = e.target.checked ? 1 : 0; 
          saveNotification(notification, rule); 
        }} />
        <span className="custom-toggle-slider rounded-circle" data-label-off="OFF" data-label-on="ON"></span>
      </Label><small>ON</small></div>
  )},
  { compact:true, ignoreRowClick:true, cell: (rule) => (
    <ButtonGroup size="sm">
      <Button onClick={e => {
        setEditingRule(rule);
        if ( rule.skip_dates && rule.skip_dates !== "" ) {
          setSelectedSkipDates(JSON.parse(rule.skip_dates));
        } else {
          setSelectedSkipDates([]);
        }
        toggleSkipUntillModal()
      }} color="primary">SKIP DATEs</Button>
      <Button onClick={e => deleteRule(rule)} color="danger">DELETE</Button>
    </ButtonGroup>
  )}
]);


const FbRules = (props) => {


  const [isLoading, setIsLoading] = useState(false);
  const [customRules, setCustomRules] = useState([]);
  const [fbAccounts, setFbAccounts] = useState([]);
  const [fbAccountsMapping, setFbAccountsMapping] = useState({});
  // RULES!
  const [fbRuleModal, setRuleModal] = useState(false);
  const toggleNewRuleModal = () => {
    setRuleModal(!fbRuleModal);
  }

  const saveRule = async ( rule ) => {
    setIsLoading(true);
    let response = await API.post('customAPI', '/fbrules', {
      body: {...rule}
    });
    // console.log(rule);
    response = await API.get('customAPI', '/fbrules');
    setCustomRules(response.rules);
    setIsLoading(false);
  }


  const deleteRule = async ( rule ) => {
    setIsLoading(true);
    let response = await API.del('customAPI', '/fbrules', {
      body: {...rule}
    });
    // const newCustomRules = customRules.filter((r) => !(r.name === rule.name && r.fbAccount === rule.fbAccount && r.schedule === rule.schedule && r.action === rule.action))
    // setCustomRules(newCustomRules);
    response = await API.get('customAPI', '/fbrules');
    setCustomRules(response.rules);
    setIsLoading(false);
  }



  const [editingRule, setEditingRule] = useState({});
  const [skipUntillModal, setSkipUntillModal] = useState(false);
  const toggleSkipUntillModal = () => {
    // if modal is open ... reset rule;
    if ( skipUntillModal ) {
      setEditingRule({});
    }
    setSkipUntillModal(!skipUntillModal);
  }
 
  const [startDate, setStartDate] = useState(new Date());
  const [selectedSkipDates, setSelectedSkipDates] = useState([]);
  const toggleSkipDate = (date) => {
    date = moment(date).format("YYYY-MM-DD");

    let index = selectedSkipDates.indexOf(date);
    let newSelectedDates = [...selectedSkipDates];
    if ( index === -1 ) {
      newSelectedDates.push(date);
    } else {
      newSelectedDates.splice(index, 1);
    }
    setSelectedSkipDates(newSelectedDates);
  }

  const skipRule = async ( rule, skip_untill ) => {
    setIsLoading(true);

    let response = await API.put('customAPI', '/fbrules', {
      body: {...editingRule, skipDates: selectedSkipDates }
    });
    // const newCustomRules = customRules.filter((r) => !(r.name === rule.name && r.fbAccount === rule.fbAccount && r.schedule === rule.schedule && r.action === rule.action))
    // setCustomRules(newCustomRules);
    response = await API.get('customAPI', '/fbrules');
    toggleSkipUntillModal();
    setCustomRules(response.rules);
    setIsLoading(false);
  }


  const [fbByUtmCampaign, setFbByUtmCampaign] = useState({});
  const [fbByDomain, setFbByDomain] = useState({});
  const [fbByBuyingStrategy, setFbByBuyingStrategy] = useState({});
  const [fbAccountsByActivity, setFbByActivitiy] = useState({});
  const [filterByActivity, setFilterByActivity] = useState(false);
  const [filterByBuyingStrategy, setFilterByBuyingStrategy] = useState(false);
  const [filterByDomain, setFilterByDomain] = useState(false);
  const [filterByUTMCampaign, setFilterByUtmCampaign] = useState(false);

  const loadSettings = async () => {
    setIsLoading(true);
  
    let response = await API.get('customAPI', '/facebookAccountMapper/businessManager');
    const businessManagerMap = response.businessManagerList.reduce((acc, row) => {
      acc[row.id] = row;
      return acc;
    }, {})

    let {mapping} = await API.get('customAPI', '/facebookAccountMapper/mapping', {
      queryStringParameters: {
        groupby: "accountID",
        ignore_bulkupload: true
      }
    });
    setFbAccountsMapping(mapping);

    // setFbAccountsMapping(Object.keys(mapping).reduce((acc,account_id) => {
    //   acc[mapping[account_id].accountName] = mapping[account_id];
    //   return acc;
    // }, {}));

    const fbAccounts = Object.keys(mapping).map((account_id) => {
      return {id: account_id, name: mapping[account_id].accountName, activity: mapping[account_id].activity, 
        bm: businessManagerMap[mapping[account_id].businessManagerID].name, buying_strategy: mapping[account_id].buying_strategy,
        utm_campaign: mapping[account_id].utm_campaign, domain: mapping[account_id].domain, raw: {...mapping[account_id]}};
    });
    setFbAccounts(fbAccounts);

    const fbByUtmCampaign = fbAccounts.reduce((acc, row) => {
      if ( row.utm_campaign ) {
        acc[row.utm_campaign] = acc[row.utm_campaign] || [];
        acc[row.utm_campaign].push(row);
      }
      return acc;
    }, {});
    setFbByUtmCampaign(fbByUtmCampaign);

    const fbByDomain = fbAccounts.reduce((acc, row) => {
      if ( row.domain ) {
        acc[row.domain] = acc[row.domain] || [];
        acc[row.domain].push(row);
      }
      return acc;
    }, {});
    setFbByDomain(fbByDomain);

    const fbByBuyingStrategy = fbAccounts.reduce((acc, row) => {
      if ( row.buying_strategy ) {
        acc[row.buying_strategy] = acc[row.buying_strategy] || [];
        acc[row.buying_strategy].push(row);
      }
      return acc;
    }, {});
    setFbByBuyingStrategy(fbByBuyingStrategy)

    const fbAccountsByActivity = fbAccounts.reduce((acc, row) => {
      if ( row.activity ) {
        acc[row.activity] = acc[row.activity] || [];
        acc[row.activity].push(row);
      }
      return acc;
    }, {});
    setFbByActivitiy(fbAccountsByActivity);



    // setFbAccounts(response.fbAccounts);
    // setFbAccountsMapping(response.fbAccounts.reduce((acc, fb) => { acc[fb.id] = fb.name; return acc;}, {}))

    const {rules} = await API.get('customAPI', '/fbrules');
    setCustomRules(rules);
    const ruleByName = rules.reduce((acc,r) => {
      acc[r.name] = acc[r.name] || {...r};
      acc[r.name].fbAccounts = acc[r.name].fbAccounts || [];
      acc[r.name].fbAccounts.push(r.fbAccount);
      return acc;
    }, {});
    // setCustomRules(Object.keys(ruleByName).map((rulename) => ruleByName));
    setRuleByName(ruleByName);
    setRuleNames(Object.keys(ruleByName).sort());
    setIsLoading(false);
  }
  const [ruleNames, setRuleNames] = useState([]);
  const [ruleByName, setRuleByName] = useState({});


  useEffect(() => {
    loadSettings();
  }, []);


  const [ruleNameFilter, setRuleNameFilter] = useState("");
  const [modal, setModal] = useState(false);
  const [last_execution_output, setLastExecutionOutput] = useState([]);
  const toggle = () => setModal(!modal);

  const saveNotification = async (notification, rule) => {
    setIsLoading(true);
    console.log(rule)
    await API.put('customAPI', '/fbrules', {
      body: {
        op: "notification",
        notification,
        fbrule_name: rule.name,
        fbrule_account: rule.fbAccount
      }
    });
    let response = await API.get('customAPI', '/fbrules');
    setCustomRules(response.rules);
    setIsLoading(false);
  }

  const saveFbRuleAccounts = async (rule, addedAccounts) => {
    setIsLoading(true);
    await API.put('customAPI', '/fbrules', {
      body: {
        op: "addFBAccounts",
        fbrule: rule,
        fbrule_accounts: addedAccounts
      }
    });
    loadSettings();
    toggleEditRuleModal();
    setIsLoading(false);
  }

  const [editRuleModal, setEditRuleModal] = useState(false);
  const toggleEditRuleModal = () => {
    setEditRuleModal(!editRuleModal);
  };

  const [accountNameFilter, setAccountNameFilter] = useState(false);

  return (
    <Container className="mt-2" fluid>
      <Row>
        <Col>
          <Card className="shadow">
            <CardHeader className={cx("border-0")}>
              <Row className="align-items-center">
                <Col xs="6">
                  <h3 className="mb-0">Facebook rules dashboard</h3>
                </Col>
                <Col className="text-left" xs="3">
                  <Link to='/admin/keyword-tools/automation/fb-rules-logs' className="btn btn-icon btn-info" 
                    style={{paddingTop:0, paddingLeft: 5, paddingBottom:0, paddingRight:5}}>
                    GO TO rule logs
                  </Link>
                </Col>
                <Col className="text-right" xs="3">
                  <button className="btn btn-icon btn-warning" type="button" 
                    style={{paddingTop:0, paddingLeft: 5, paddingBottom:0, paddingRight:5}}
                    onClick={e => { e.preventDefault(); setRuleModal(true); }}>
                    <i className="fas fa-plus"></i> Create rule
                  </button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <Label for="fbAccount">Activity: </Label>
                    <Input bsSize="sm" type="select" name="ruleActivity" id="ruleActivity" onChange={(e) => setFilterByActivity(e.target.value)} value={filterByActivity}>
                      <option key="empty-activity" value="">----</option>
                      {Object.keys(fbAccountsByActivity).map((activity,i) => <option key={`activity-${i}`} value={activity}>{activity}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="fbAccount">Buying Strategy: </Label>
                    <Input bsSize="sm" type="select" name="buying_strategy" id="buying_strategy" onChange={(e) => setFilterByBuyingStrategy(e.target.value)} value={filterByBuyingStrategy}>
                      <option key="empty-buying_strategy" value="">----</option>
                      {Object.keys(fbByBuyingStrategy).map((buying_strategy,i) => <option key={`buying_strategy-${i}`} value={buying_strategy}>{buying_strategy}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="fbAccount">Domain: </Label>
                    <Input bsSize="sm" type="select" name="domain" id="domain" onChange={(e) => setFilterByDomain(e.target.value)} value={filterByDomain}>
                      <option key="empty-domain" value="">----</option>
                      {Object.keys(fbByDomain).map((domain,i) => <option key={`domain-${i}`} value={domain}>{domain}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="fbAccount">UTM Campaign: </Label>
                    <Input bsSize="sm" type="select" name="utmCampaign" id="utmCampaign" onChange={(e) => setFilterByUtmCampaign(e.target.value)} value={filterByUTMCampaign}>
                      <option key="empty-utmCampaign" value="">----</option>
                      {Object.keys(fbByUtmCampaign).map((utmCampaign,i) => <option key={`utmCampaign-${i}`} value={utmCampaign}>{utmCampaign}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="fbAccount">Rule Name: </Label>
                    <Input type="select" name="ruleNameFilter" bsSize="sm" value={ruleNameFilter} onChange={(e) => setRuleNameFilter(e.target.value)}>
                      <option key="empty-page" value="">----</option>
                      {ruleNames.map((name) => <option key={name} value={name}>{name}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="fbAccount">FB Account: </Label>
                    <Input type="select" name="accountNameFilter" bsSize="sm" value={accountNameFilter} onChange={(e) => setAccountNameFilter(e.target.value)}>
                      <option key="empty-page" value="">----</option>
                      {fbAccounts.map((fb) => <option key={fb.name} value={fb.id}>{fb.name}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
                  <DataTable
                    responsive={true}
                    columns={columns(toggleEditRuleModal,setLastExecutionOutput, toggle, saveNotification, setEditingRule, setSelectedSkipDates, toggleSkipUntillModal, deleteRule, fbAccountsMapping)}
                    data={customRules.filter((r) => ruleNameFilter && ruleNameFilter.length ? r.name === ruleNameFilter : true)
                      .filter((r) => accountNameFilter && accountNameFilter.length ? r.fbAccount == accountNameFilter : true)
                      .filter((r) => filterByActivity && filterByActivity.length ? r.activity === filterByActivity : true)
                      .filter((r) => filterByDomain && filterByDomain.length && fbAccountsMapping[r.fbAccount] ? fbAccountsMapping[r.fbAccount].domain === filterByDomain : true)
                      .filter((r) => filterByBuyingStrategy && filterByBuyingStrategy.length && fbAccountsMapping[r.fbAccount] ? fbAccountsMapping[r.fbAccount].buying_strategy === filterByBuyingStrategy : true)
                      .filter((r) => filterByUTMCampaign && filterByUTMCampaign.length && fbAccountsMapping[r.fbAccount] ? fbAccountsMapping[r.fbAccount].utm_campaign === filterByUTMCampaign : true)}
                    progressPending={isLoading}
                    persistTableHead={true}
                    noHeader={true}
                    striped={true}
                    highlightOnHover
                    dense={true}
                    pagination={true}
                    paginationPerPage={50}
                    paginationRowsPerPageOptions={[10,25,50,100,150,500,1000]}
                  />
            </CardBody>
          </Card>
        </Col>
      </Row>


     <FbRuleAccountsModal isLoading={isLoading} saveFbRuleAccounts={saveFbRuleAccounts} editingRule={editingRule} editRuleModal={editRuleModal} toggleEditRuleModal={toggleEditRuleModal} ruleByName={ruleByName} fbAccounts={fbAccounts} />
      
      <Modal isOpen={skipUntillModal} toggle={toggleSkipUntillModal} size="lg">
        <ModalHeader toggle={toggleSkipUntillModal}>Skip rule  ({editingRule.activity}) - {editingRule.fbAccount}:</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <FormGroup>
                <label className="form-control-label" htmlFor="input-username">
                  Select Dates:
                </label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => {
                    setStartDate(date); 
                    toggleSkipDate(date);
                  }}
                  startDate={startDate}
                  inline
                />
              </FormGroup>
            </Col>
            <Col>
              <ListGroup flush>
                {selectedSkipDates.map((date, idx) => <ListGroupItem onClick={() => toggleSkipDate(date)}key={idx}>{date}</ListGroupItem>)}
              </ListGroup>
              <hr/>
              <h6>To remove a date from list, simply click on it!</h6>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleSkipUntillModal}>CLOSE</Button>
          <Button color="primary" onClick={skipRule} disabled={isLoading}>SAVE</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Last Execution Output</ModalHeader>
        <ModalBody>
          <ListGroup flush>
            {last_execution_output.map((line, iddx) =>
              <ListGroupItem color="info" key={`rule-execution-time-${iddx}`}>{line}</ListGroupItem>
            )}
          </ListGroup>
        </ModalBody>
      </Modal>
      <FbRuleModal fbRuleModal={fbRuleModal} toggleNewRuleModal={toggleNewRuleModal} CONFIG={CONFIG} 
          fbAccounts={fbAccounts} saveRule={saveRule} times={TIMEs} />
    </Container>
  );
}


export default FbRules;
