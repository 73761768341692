import React, { useState, useEffect } from "react";
import { API } from 'aws-amplify';

import { Field, Form, Formik, FieldArray } from "formik";
import { ReactstrapSelect, ReactstrapInput } from "reactstrap-formik";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  ListGroup,
  ListGroupItem,
  UncontrolledTooltip
} from "reactstrap";


import Spinner from "../../../components/Spinner";
import FlagListGroup from "../../../components/flagListGroup.js";


const OutbrainAutomation = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState({});
  const [marketList, setMarketList] = useState([]);

  // const [BLACKLIST, setBlacklist] = useState([]);
  // const [WHITELIST, setWhitelist] = useState([]);
  
  // const [OFFERS, setOffers] = useState([]);

  const loadSettings = async () => {
    setIsLoading(true);
    let response = await API.get('customAPI', '/keyword-tools/outbrainAutomation');
    setSettings(response.landingPages);
    setIsLoading(false);
  }


  const toggleMarket = (newMarket) => {
    let index = marketList.indexOf(newMarket);
    if (index === -1) {
      setMarketList([...marketList, newMarket])
    } else {
      marketList.splice(index, 1);
      setMarketList([...marketList]);
    }
  }

  useEffect(() => {
    loadSettings();
  }, []);

  const [downloadUrl,setDownloadurl] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false)
  const [logs, setLogs] = useState([]);
  const makeMoneyBaby = async (values, { setSubmitting }) => {
    console.log(values, marketList);
    try {
      const {logs=[], downloadUrl=false} = await API.post('customAPI', '/keyword-tools/outbrainAutomation', {
        body: {
          marketList,
          ...values
        }
      });
      setLogs(logs);
      setDownloadurl(downloadUrl);
      setDownloadModal(true);
    } catch ( e ) {
      alert(e.message);
    }
    setSubmitting(false);
  }

  const [focusOn, setFocusOn] = useState("");

  return (
    <Container className="mt-2" fluid>
      <Row>
        <Col>
          <Card className="bg-secondary shadow">
            <CardHeader className="border-0 bg-green">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">OUTBRAIN UPLOAD</h3>
                </Col>
                <Col xs="3">
                  
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Spinner isLoading={isLoading}>
              <Formik
                initialValues={{ 
                  marketerID: "00978768942e72fb9e1951fdd1969d79d6",
                	domain: '', 
                	utm_source: '', 
                	utm_campaign: '', 
                  daily_budget: 30, 
                  cpc: 0.8, 
                  targetCPA: 1,
                  "site_id": '', 
                  "domainUrl": "", 
                  device: '', 
                  optimizationType: "",
                  number_of_creatives: 2}}
                onSubmit={makeMoneyBaby}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  setValues
                  /* and other goodies */
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row form>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="Domain"
                            name="domain"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "domain",
                              id: "domain",
                              options: Object.keys(settings),
                              defaultOption: "-- Domain --"
                            }}
                          />
                          {errors.domain && touched.domain && errors.domain}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="UTM Source"
                            name="utm_source"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "utm_source",
                              id: "utm_source",
                              options: Object.keys(settings[values.domain] || {}),
                              defaultOption: "-- UTM Source --"
                            }}
                            onChange={(e) => {
                              const {site_id=false, domainUrl=false, device=""} = settings[values.domain] && settings[values.domain][e.target.value];
                              console.log(settings[values.domain][e.target.value]);
                              setFieldValue("site_id", site_id);
                              setFieldValue("domainUrl", domainUrl);
                              setFieldValue("device", device);
                              handleChange(e)
                            }}
                          />
                          {errors.utm_source && touched.utm_source && errors.utm_source}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="UTM Campaign"
                            name="utm_campaign"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "utm_campaign",
                              id: "utm_campaign",
                              options: UTM_CAMPAIGN,
                              defaultOption: "-- UTM Campaign --"
                            }}
                          />
                          {errors.utm_campaign && touched.utm_campaign && errors.utm_campaign}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="DEVICE"
                            name="device"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "device",
                              id: "device",
                              options: DEVICEs,
                              defaultOption: "-- DEVICE --"
                            }}
                          />
                          {errors.device && touched.device && errors.device}
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr className="my-4" />
                    {/* Description */}
                    <h6 className="heading-small text-muted mb-4">CAMPAIGN SETUP</h6>
                    <Row form>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="Marketer ID"
                            name="marketerID"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "marketerID",
                              id: "marketerID",
                              options: MARKETERS,
                              defaultOption: "-- Sub Account --"
                            }}
                          />
                          {errors.marketerID && touched.marketerID && errors.marketerID}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="Campaign Daily Budget"
                            name="daily_budget"
                            type="number"
                            component={ReactstrapInput}
                          />
                          {errors.daily_budget && touched.daily_budget && errors.daily_budget}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="CPC"
                            name="cpc"
                            type="number"
                            component={ReactstrapInput}
                          />
                          {errors.cpc && touched.cpc && errors.cpc}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="Max number of creative per image"
                            name="number_of_creatives"
                            type="number"
                            component={ReactstrapInput}
                          />
                          {errors.number_of_creatives && touched.number_of_creatives && errors.number_of_creatives}
                        </FormGroup>
                      </Col>
                      <Col md={6}>
                        <FormGroup>
                          <Field
                            label="CONVERSION BID STRATEGY"
                            name="optimizationType"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "optimizationType",
                              id: "optimizationType",
                              options: optimizationTypes,
                              defaultOption: "-- OPTIMISATION --"
                            }}
                          />
                          {errors.optimizationType && touched.optimizationType && errors.optimizationType}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            disabled={values.optimizationType !== "TARGET_CPA_FULLY_AUTOMATED"}
                            label="Target CPA"
                            name="targetCPA"
                            type="number"
                            component={ReactstrapInput}
                          />
                          {errors.targetCPA && touched.targetCPA && errors.targetCPA}
                        </FormGroup>
                      </Col>
                      
                    </Row>
                    <hr className="my-4" />
                    {/* Description */}
                    <h6 className="heading-small text-muted mb-4">KEYWORD LIST</h6>
                    <FormGroup>
                      <Label>MARKET SELECTION:</Label>
                      <FlagListGroup fill={true} markets={MARKETS} selectedMarkets={marketList} onClick={toggleMarket} />
                    </FormGroup>
                    {marketList.map((market) => {
                      const adsiteNameByMarketerID = {
                        "009d1aaab37db7bd0cbb883ec8f5ea17e4": "{Keyword} | Gesponserte Links",
                        "00abd1c7ee2f43871bce242340fce59447": "{Keyword} | Enlaces Publicitarios",
                        "009cac093e1a741376a19965e7ae9e24bf": "{Keyword} | Liens de recherche",
                        "007054ce555d66205ff1cddd6c1a422dde": "{Keyword} | Sponsored Links",
                        "0063b8614036c3ffb83add86c8c569c958": "{Keyword} | Ricerca annunci",
                        "008559365c5960957181f5bf1782289ea2": "{Keyword} | Enlaces Publicitarios",
                        "002208b87ad5d7e87aa24fe5e7d8aa9091": "{Keyword} | Zoek Advertenties",
                        "004579fcd534c4e7f2d70721e1c717df5f": "{Keyword} | Sponsored Links"
                      }

                      if ( !values[`${market}_adsitename`] && adsiteNameByMarketerID[values.marketerID] ) {
                        setFieldValue(`${market}_adsitename`, adsiteNameByMarketerID[values.marketerID]);
                      }

                      return (<div key={market}>
                        <Row>
                          <Col md={6}>
                            <h3>
                              AD TEMPLATE <span className="text-primary" style={{ textTransform: "uppercase" }}>{market}</span> - 
                              use {`{Keyword}`} has a placeholder.
                            </h3>
                            <Field
                              className="mt"
                              name={`${market}_adsitename`}
                              placeholder="Ad site name ( max 60 characters )"
                              type="text"
                              component={ReactstrapInput}
                            />
                            <hr className="my-1" />
                            <ListGroup className="list-group-horizontal" style={{cursor: "pointer"}}>
                              <ListGroupItem className="p-1"><b>Macro:</b></ListGroupItem>
                              {[
                                {name: "country", value:"$country$"},
                                {name: "region", value:"$region$"},
                                {name: "city", value:"$city$"},
                                {name: "dayofweek", value:"$dayofweek$"}].map((opt, idn) => (
                              <ListGroupItem key={`macro-${idn}`} className="p-1" onClick={(e) => {
                                e.preventDefault();
                                const [fieldName, idx=false] = focusOn.split(".");
                                let currValue = values[fieldName];
                                if ( !!idx ) {
                                  currValue = values[fieldName][idx];
                                }
                                if ( !currValue ) {
                                  return;
                                }
                                setFieldValue(focusOn, currValue + " " + opt.value);
                                document.getElementById(focusOn).focus();
                              }} href="#" id={opt.name}>{opt.name}
                                {opt.name === "dayofweek" &&
                                <UncontrolledTooltip placement="top" target={opt.name}>
                                  <p>day of week starts w current day, adding and + sign and number will display that day. IE dayofweek + 2 would be Saturday since today is Thursday and 2 days from now is Saturday.</p>
                                </UncontrolledTooltip>}
                              </ListGroupItem>))}
                            </ListGroup>
                            <FieldArray
                               name={`${market}_adtitle`}
                               render={arrayHelpers => (
                                 <div>
                                 {[...Array(values.number_of_creatives)].map((x, index) =>
                                    <Field
                                      key={`Title ${index+1}`}
                                      label={`Title ${index+1}`}
                                      placeholder="Title ( max 150 characters )"
                                      name={`${market}_adtitle.${index}`}
                                      type="textarea"
                                      component={ReactstrapInput}
                                      onFocus={() => setFocusOn(`${market}_adtitle.${index}`)}
                                      id={`${market}_adtitle.${index}`}
                                    />
                                  )}
                                 </div>
                               )}
                             />
                            <hr className="my-1" />
                            <Field
                              label="Call to action ( optional )"
                              name={`${market}_adcta`}
                              component={ReactstrapSelect}
                              inputprops={{
                                name: `${market}_adcta`,
                                id: `${market}_adcta`,
                                options: CTA,
                                defaultOption: "NONE"
                              }}
                            />
                          </Col>
                          <Col xs={6} md={6} key={`keyword-for-${market}`}>
                            <Field
                              label={`Keyword List FOR ${market.toUpperCase()}`}
                              placeholder="List of keyword, one per row!"
                              name={`${market}_keywordList`}
                              type="textarea"
                              rows="20"
                              component={ReactstrapInput}
                            />
                          </Col>
                        </Row>
                        <hr className="my-4" />
                      </div>)
                    })}
                    <Row className="mt-4">
                      <Col className="text-center">
                        <Button color="primary" type="submit" disabled={isSubmitting}>MAKE MONEY BABE!!!</Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
              </Spinner>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={downloadModal} toggle={() => setDownloadModal(false)} size="lg">
        <ModalHeader toggle={() => setDownloadModal(false)}></ModalHeader>
        <ModalBody>
          <Row className="text-center">
            <Col>
              {logs.map((log) => {
                let message = log.message;
                if ( log.id_campaign ) {
                  message = <a rel="noopener noreferrer" href={`https://my.outbrain.com/amplify/site/marketers/00978768942e72fb9e1951fdd1969d79d6/reports/campaigns?campaignId=${log.id_campaign}`} target="_blank">
                    {log.message}</a> 
                }
                return <p className={log.warning ? "text-warning" : "text-success"}>{message}</p>
              })}
            </Col>
          </Row>
          {downloadUrl && <Row className="text-center mt-4">
            <Col>
              <h6 className="mt-2 mb-2">Click DOWNLOAD ZIP to get your bulk zip!</h6>
              <a rel="noopener noreferrer" href={downloadUrl} target="_blank" className="btn btn-success">DOWNLOAD ZIP</a>
            </Col>
          </Row>}
        </ModalBody>
      </Modal>
    </Container>
  );
}

const MARKETERS = [
{
    id: '00e44a09ea5f06e8116178deceddf53721',
    name: 'MC - EA ROW'
  },
  {
    id: '009ab62993245e76bf0b38a4f7aaebdf44',
    name: 'Martim Chessum - Domains'
  },
  {
    id: '00ab24deb7086188c4ae864fd79915d027',
    name: 'MC - EA Europe'
  },
  {
    id: '00978768942e72fb9e1951fdd1969d79d6',
    name: 'Martim Chessum Bookmarks'
  },
  {
    id: '00c0e7e1d1515bcd170239787158562d96',
    name: 'MC - Tonic Worldwide'
  },
  {
    id: '00423eadf78d1d7cb70e488f978f8a8af9',
    name: 'MC - G'
  },
  {
    id: '00995e0b3cdfc2a6153503532a3c636422',
    name: 'Conversion Minds_EUR'
  },
  {
    id: '00106a29c787d27699f9f4655498d09f13',
    name: 'Conversion Minds_USD'
  },
  {
    id: '00aa9c6e8027b39f4ba4d435a02c7f2e5c',
    name: 'Conversion Minds_USD_SA_PartnersProgram_FR'
  },
  {
    id: '009d1aaab37db7bd0cbb883ec8f5ea17e4',
    name: "Conversion Minds - DACH"
  },
  {
    id: '009cac093e1a741376a19965e7ae9e24bf',
    name: "Conversion Minds - FR"
  },
  {
    id: '002208b87ad5d7e87aa24fe5e7d8aa9091',
    name: 'Conversion Minds - NL'
  },
  {
    id: '00abd1c7ee2f43871bce242340fce59447',
    name: 'Conversion Minds - ES'
  },
  {
    id: '007054ce555d66205ff1cddd6c1a422dde',
    name: 'Conversion Minds - IN'
  },
  {
    id: '0063b8614036c3ffb83add86c8c569c958',
    name: 'Conversion Minds - IT'
  },
  {
    id: '008559365c5960957181f5bf1782289ea2',
    name: 'Conversion Minds - MX'
  }, 
  {
    id: '004579fcd534c4e7f2d70721e1c717df5f',
    name: 'Conversion Minds - UK'
  }
].sort((a, b) => a.name > b.name)





const optimizationTypes = [
  // {id: "TRAFFIC", name:  "Campaign optimized towards maximizing traffic (clicks)"},
  {id: "CPC", name: "Semi Automatic"},
  {id: "MAX_CONVERSION_FULLY_AUTOMATED", name: "Fully Automatic"},
  {id: "TARGET_CPA_FULLY_AUTOMATED", name: "Target CPA"}
]


const DEVICEs = [
  { id: "mobile", name: "mobile" },
  { id: "tablet", name: "tablet" },
  { id: "desktop", name: "desktop" },
]

const MARKETS = [
    { value: "at", flag: "at", label: "Austria"},
    { value: "br", flag: "br", label: "Brazil"},
    { value: "ch", flag: "ch", label: "Switzerland"},
    { value: "de", flag: "de", label: "Germany"},
    { value: "dk", flag: "dk", label: "Danmark"},
    { value: "es", flag: "es", label: "Spain"},
    { value: "fi", flag: "fi", label: "Finland"},
    { value: "fr", flag: "fr", label: "France"},
    { value: "it", flag: "it", label: "Italy"},
    { value: "in", flag: "in", label: "India" },
    { value: "mx", flag: "mx", label: "Mexico"},
    { value: "nl", flag: "nl", label: "Netherlands"},
    { value: "no", flag: "no", label: "Norway", lang: "NO" },
    { value: "se", flag: "se", label: "Sweden"},
    { value: "uk", flag: "gb", label: "United Kingdom"},
    { value: "us", flag: "us", label: "United States"}
  ];

  const UTM_CAMPAIGN = [
    { id: "automotive", name: "automotive" },
    { id: "education", name: "education" },
    { id: "fashion", name: "fashion" },
    { id: "finance", name: "finance" },
    { id: "flight", name: "flight" },
    { id: "gambling", name: "gambling" },
    { id: "health", name: "health" },
    { id: "household", name: "household" },
    { id: "services", name: "services" },
    { id: "shopping", name: "shopping" },
    { id: "tech", name: "tech" },
    { id: "travel", name: "travel" }
    // { value: "business", label: "business" },
    // { value: "internet", label: "internet" },
    // { value: "health", label: "health" },
    // { value: "astrology", label: "astrology" }
  ];




  const CTA = [
	{id: "NONE", name: "NONE"},
	{id: "APPLY_NOW", name: "APPLY_NOW"},
	{id: "BOOK_NOW", name: "BOOK_NOW"},
	{id: "CONTACT_US", name: "CONTACT_US"},
	{id: "DOWNLOAD", name: "DOWNLOAD"},
	{id: "LEARN_MORE", name: "LEARN_MORE"},
	{id: "GET_QUOTE", name: "GET_QUOTE"},
	{id: "SHOP_NOW", name: "SHOP_NOW"},
	{id: "SIGN_UP", name: "SIGN_UP"},
	{id: "WATCH_MORE", name: "WATCH_MORE"},
	{id: "LISTEN_NOW", name: "LISTEN_NOW"},
	{id: "SUBSCRIBE", name: "SUBSCRIBE"},
	{id: "RSVP", name: "RSVP"},
	{id: "REGISTER_NOW", name: "REGISTER_NOW"},
	{id: "INSTALL_APP", name: "INSTALL_APP"},
	{id: "READ_MORE", name: "READ_MORE"},
	{id: "PLAY_GAME", name: "PLAY_GAME"},
	{id: "DONATE_NOW", name: "DONATE_NOW"}
];


export default OutbrainAutomation;
