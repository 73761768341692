import React, {useState} from "react";
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, InputGroup,
  InputGroupAddon} from "reactstrap";

// import Spinner from "./Spinner";

const CreateRedirectModal = (props) => {
  const { isLoading, createRedirect, toggleCreateRedirect, 
          redirect, setRedirect, handleRedirectChange, saveRedirect} = props;


  const [redirUrl, setRedirUrl] = useState("");
  const [redirPerc, setRedirectPercentage] = useState(100);


  const handleTrafficTypeDevice = (device) => {
    let exists = redirect.traffic_type.indexOf(device);
    let trafficType = [...redirect.traffic_type];
    if ( exists === -1 ) {
      handleRedirectChange({
        target: {
          name: "traffic_type",
          value: [...trafficType, device]
        }
      });
    } else {
      trafficType.splice(exists, 1)
      handleRedirectChange({
        target: {
          name: "traffic_type",
          value: trafficType
        }
      });
    }
  }

  console.log(redirect);

  return (
		<Modal isOpen={createRedirect} toggle={toggleCreateRedirect} size="lg">
      <ModalHeader toggle={toggleCreateRedirect}>Create Redirect Modal</ModalHeader>
      <ModalBody className="pt-0">
    	 	<Form>
          {/*<FormGroup>
            <Label for="tbaccount">TYPE: </Label>
            <ButtonGroup size="lg" className="btn-block">
              <Button className="w-100" color="primary" type="button" name="via-csv" disabled={redirect.type==="shuffle"} 
                onClick={() => setRedirect({...redirect, ...{type: "shuffle"}})}>SHUFFLE</Button>
              <Button className="w-100" color="info" type="button" name="via-api" disabled={redirect.type==="redirect"} 
                onClick={() => setRedirect({...redirect, ...{type: "redirect"}})}>REDIRECT</Button>
              <Button className="w-100" color="warning" type="button" name="via-api" disabled={redirect.type==="cloaking"} 
                onClick={() => setRedirect({...redirect, ...{type: "cloaking"}})}>CLOAKING</Button>
            </ButtonGroup>
          </FormGroup>*/}
         	<FormGroup>
            <Label for="input-redirectname">
              Redirect Name:
            </Label>
            <Input
              className="form-control"
              id="input-redirectname"
              name="name"
              type="text"
              placeholder="Redirect name"
              value={redirect.name}
              onChange={handleRedirectChange}
            />
          </FormGroup>
         	<FormGroup>
            <Label>Referer: </Label>
            <FormGroup check>
              <Label check>
                <Input type="radio"  name="referer" value="hide" 
                  checked={redirect.referer==="hide" ? true : false} onChange={handleRedirectChange} />
                Hide ( Pass deliver.cmindtrack.net, js redirect! )
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="radio"  name="referer" value="show" 
                  checked={redirect.referer==="show" ? true : false} onChange={handleRedirectChange} />
                 Show ( Pass traffic source )
              </Label>
            </FormGroup>
          </FormGroup>
          <FormGroup>
            <Label>Block if referer: </Label>
            <FormGroup check>
              <Label check>
                <Input type="radio"  name="block_if_referer" value="block_if_referer" 
                  checked={redirect.block_if_referer==="block_if_referer" ? true : false} onChange={handleRedirectChange} />
                YES ( It will redirect to backfill url if ref is not empty! ) 
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                 <Input type="radio"  name="block_if_referer" value="donot_block_if_referer" 
                  checked={redirect.block_if_referer==="donot_block_if_referer" ? true : false} onChange={handleRedirectChange} />
                 NO
              </Label>
            </FormGroup>
          </FormGroup>

          <FormGroup>
            <Label>Block if NO referer: </Label>
            <FormGroup check>
              <Label check>
                <Input type="radio"  name="block_if_no_referer" value="block_if_no_referer" 
                  checked={redirect.block_if_no_referer==="block_if_no_referer" ? true : false} onChange={handleRedirectChange} />
                YES ( It will redirect to backfill url if ref is empty! ) 
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                 <Input type="radio"  name="block_if_no_referer" value="donot_block_if_no_referer" 
                  checked={redirect.block_if_no_referer==="donot_block_if_no_referer" ? true : false} onChange={handleRedirectChange} />
                 NO
              </Label>
            </FormGroup>
          </FormGroup>

          <FormGroup>
            <Label className="d-block">Traffic Type: </Label>
            <FormGroup check inline>
              <Input type="checkbox" name="desktop" id="desktop" checked={redirect.traffic_type.indexOf("desktop") !== -1} onChange={(e) => handleTrafficTypeDevice("desktop")} />
              <Label for="desktop" check>Desktop</Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" name="tablet" id="tablet" checked={redirect.traffic_type.indexOf("tablet") !== -1} onChange={(e) => handleTrafficTypeDevice("tablet")} />
              <Label for="tablet" check>Tablet</Label>
            </FormGroup>
            <FormGroup check inline>
              <Input type="checkbox" name="mobile" id="mobile" checked={redirect.traffic_type.indexOf("mobile") !== -1} onChange={(e) => handleTrafficTypeDevice("mobile")} />
              <Label for="mobile" check>Mobile</Label>
            </FormGroup>
          </FormGroup>

          <FormGroup>
            <Label for="input-frequencycap">
              Frequency Cap:
            </Label>
            <Row form>
              <Col sm={6}>
                <Input
                  className="form-control"
                  id="input-frequencycap-count"
                  name="frequencycap-count"
                  type="number"
                  placeholder="Numero max di redirect (#count)"
                  value={redirect.frequencycap.count}
                  onChange={(e) => {
                    setRedirect({...redirect, ...{frequencycap: {
                      count: e.target.value,
                      ttl: redirect.frequencycap.ttl
                    }}})
                  }}
                />
              </Col>
              <Col sm={6}>
                <Input
                  className="form-control"
                  id="input-frequencycap-ttl"
                  name="frequencycap-ttl"
                  type="number"
                  placeholder="Timer ( sec 3600 = 1h )"
                  value={redirect.frequencycap.ttl}
                  onChange={(e) => {
                    setRedirect({...redirect, ...{frequencycap: {
                      count: redirect.frequencycap.count,
                      ttl: e.target.value
                    }}})
                  }}
                />
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Label for="input-list_ip" className="d-block">
              IP Targeting:
            </Label>
            <FormGroup check inline>
              <Label check>
                <Input type="radio"  name="list_ip_targeting" value="include" 
                  checked={redirect.list_ip_targeting==="include" ? true : false} onChange={handleRedirectChange} />
                INCLUDE
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                 <Input type="radio"  name="list_ip_targeting" value="exclude" 
                  checked={redirect.list_ip_targeting==="exclude" ? true : false} onChange={handleRedirectChange} />
                 EXCLUDE
              </Label>
            </FormGroup>
            <Input
              className="form-control"
              id="input-list_ip"
              name="list_ip"
              type="text"
              placeholder="List class of ip targeting: ip/32, ip/16, ip/23!"
              value={redirect.list_ip ? redirect.list_ip.join(",") : ""}
              onChange={(e) => {
                setRedirect({
                  ...redirect,
                  "list_ip": e.target.value.split(",")
                })
              }}
            />
          </FormGroup>
          
          <FormGroup>
            <Label for="input-list_os" className="d-block">
              ASN Targeting:
            </Label>
            <FormGroup check inline>
              <Label check>
                <Input type="radio"  name="list_asn_targeting" value="include" 
                  checked={redirect.list_asn_targeting==="include" ? true : false} onChange={handleRedirectChange} />
                INCLUDE
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                 <Input type="radio"  name="list_asn_targeting" value="exclude" 
                  checked={redirect.list_asn_targeting==="exclude" ? true : false} onChange={handleRedirectChange} />
                 EXCLUDE
              </Label>
            </FormGroup>
            <Input
              className="form-control"
              id="input-list_asn"
              name="list_asn"
              type="text"
              placeholder="List operating systems ex Mac Os, Windows ..."
              value={redirect.list_asn ? redirect.list_asn.join(",") : ""}
              onChange={(e) => {
                setRedirect({
                  ...redirect,
                  "list_asn": e.target.value.split(",")
                })
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label for="input-list_market" className="d-block">
              Market Targeting:
            </Label>
            <FormGroup check inline>
              <Label check>
                <Input type="radio"  name="list_market_targeting" value="include" 
                  checked={redirect.list_market_targeting==="include" ? true : false} onChange={handleRedirectChange} />
                INCLUDE
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                 <Input type="radio"  name="list_market_targeting" value="exclude" 
                  checked={redirect.list_market_targeting==="exclude" ? true : false} onChange={handleRedirectChange} />
                 EXCLUDE
              </Label>
            </FormGroup>
            <Input
              className="form-control"
              id="input-list_market"
              name="list_market"
              type="text"
              placeholder="List country code (i.e http://dev.maxmind.com/geoip/legacy/codes/iso3166/)"
              value={redirect.list_market ? redirect.list_market.join(",") : ""}
              onChange={(e) => {
                setRedirect({
                  ...redirect,
                  "list_market": e.target.value.split(",")
                })
              }}
            />
          </FormGroup>


          <FormGroup>
            <Label for="input-list_os" className="d-block">
              OS Targeting:
            </Label>
            <FormGroup check inline>
              <Label check>
                <Input type="radio"  name="list_os_targeting" value="include" 
                  checked={redirect.list_os_targeting==="include" ? true : false} onChange={handleRedirectChange} />
                INCLUDE
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                 <Input type="radio"  name="list_os_targeting" value="exclude" 
                  checked={redirect.list_os_targeting==="exclude" ? true : false} onChange={handleRedirectChange} />
                 EXCLUDE
              </Label>
            </FormGroup>
            <Input
              className="form-control"
              id="input-list_os"
              name="list_os"
              type="text"
              placeholder="List operating systems ex Mac Os, Windows ..."
              value={redirect.list_os ? redirect.list_os.join(",") : ""}
              onChange={(e) => {
                setRedirect({
                  ...redirect,
                  "list_os": e.target.value.split(",")
                })
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label for="input-list_browser" className="d-block">
              Browser Targeting:
            </Label>
            <FormGroup check inline>
              <Label check>
                <Input type="radio"  name="list_browser_targeting" value="include" 
                  checked={redirect.list_browser_targeting==="include" ? true : false} onChange={handleRedirectChange} />
                INCLUDE
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                 <Input type="radio"  name="list_browser_targeting" value="exclude" 
                  checked={redirect.list_browser_targeting==="exclude" ? true : false} onChange={handleRedirectChange} />
                 EXCLUDE
              </Label>
            </FormGroup>
            <Input
              className="form-control"
              id="input-list_browser"
              name="list_browser"
              type="text"
              placeholder="List broswer by name ..."
              value={redirect.list_browser ? redirect.list_browser.join(",") : ""}
              onChange={(e) => {
                setRedirect({
                  ...redirect,
                  "list_browser": e.target.value.split(",")
                })
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label for="input-keywordshuffle">
              Shuffle Keyword ( use ##KEYWORD## in url ):
            </Label>
            <Input
              className="form-control"
              id="input-keywordshuffle"
              name="keywordshuffle"
              type="textarea"
              placeholder="keywordshuffle"
              value={redirect.keywordshuffle ? redirect.keywordshuffle.join(",") : ""}
              onChange={(e) => {
                setRedirect({
                  ...redirect,
                  "keywordshuffle": e.target.value.split(/,|\n/)
                })
              }}
              rows={10}
            />
          </FormGroup>

          <FormGroup>
            <Label for="input-kshuffle">
              Shuffle K ( use ##K## in url ): 
            </Label>
            <Input
              className="form-control"
              id="input-kshuffle"
              name="kshuffle"
              type="textarea"
              placeholder="kshuffle"
              value={redirect.kshuffle ? redirect.kshuffle.join(",") : ""}
              onChange={(e) => {
                setRedirect({
                  ...redirect,
                  "kshuffle": e.target.value.split(/,|\n/)
                })
              }}
              rows={10}
            />
          </FormGroup>


          <FormGroup>
            <Label for="input-backfillurl">
              Backfill URL:
            </Label>
            <Input
              className="form-control"
              id="input-backfillurl"
              name="backfill_url"
              type="url"
              placeholder="Backfill URL"
              value={redirect.backfill_url}
              onChange={handleRedirectChange}
            />
          </FormGroup>

          {/**<FormGroup>
            <Label>Cloack landing page: </Label>
            <FormGroup check>
              <Label check>
                <Input type="radio"  name="cloak" value="cloak" 
                  checked={redirect.cloak ==="cloak" ? true : false} onChange={handleRedirectChange} />
                YES
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                 <Input type="radio"  name="cloak" value="donot_cloak" 
                  checked={redirect.cloak ==="donot_cloak" ? true : false} onChange={handleRedirectChange} />
                 NO
              </Label>
            </FormGroup>
          </FormGroup>*/}

          <Row form>
            <Col sm={8}>
              <FormGroup>
                <Label for="input-backfillurl">
                  Redirect URL:
                </Label>
                <Input type="url" value={redirUrl} onChange={(e) => setRedirUrl(e.target.value)} />
              </FormGroup>
            </Col>
            <Col sm={4}>
              <FormGroup>
                <Label for="input-backfillurl">
                  Perc:
                </Label>
                <InputGroup>
                  <Input type="number" value={redirPerc} onChange={(e) => setRedirectPercentage(e.target.value)} />
                  <InputGroupAddon addonType="append">
                    <Button color="secondary" onClick={(e) => {
                      handleRedirectChange({
                        target: {
                          name: "list_redirect_url",
                          value: [...redirect.list_redirect_url, {
                            url: redirUrl,
                            percentage: redirPerc
                          }],
                        },
                      });
                      setRedirectPercentage(0);
                      setRedirUrl("");
                    }}><i className="fa fa-plus" /></Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row form>
            <Col>
              {redirect.list_redirect_url.map((redirectInput, idx) => {
                return <InputGroup key={idx}>
                  <Input value={redirectInput.url + ", " + redirectInput.percentage} readOnly={true} />
                  <InputGroupAddon addonType="append">
                    <Button color="secondary" onClick={(e) => {
                      let tmp = [...redirect.list_redirect_url];
                      tmp.splice(idx, 1);
                      handleRedirectChange({
                        target: {
                          name: "list_redirect_url",
                          value: tmp
                        }
                      });
                    }}><i className="fa fa-minus" /></Button>
                  </InputGroupAddon>
                </InputGroup>
              })}
            </Col>
          </Row>

      	</Form>
      </ModalBody>
      <ModalFooter>
	      <Button color="secondary" onClick={toggleCreateRedirect}>CLOSE</Button>
	      <Button color="primary" disabled={isLoading} onClick={saveRedirect}>SAVE</Button>
	    </ModalFooter>
    </Modal>
  )
}

export default CreateRedirectModal;

