import React, { useState } from "react";
import { Button, Card, CardHeader, CardBody, Modal, ModalBody, ModalFooter, Row, Col } from "reactstrap";

import Spinner from "../../../components/Spinner";


import SearchedImagesCard from "./selectedImages/searchedImagesCard";
import DropzoneImagesCard from "./selectedImages/dropzoneImagesCard";

const ImageGallery = (props) => {

  const [modal, setModal] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState("");
  const { images, imagePexelsTotal, imagePixabayTotal, pixabayImages, 
          flickrImages, imageFlickrTotal, imageUnsplashTotal, 
          unsplashImages, selectedImages, dropzoneFiles, 
          toggleImageFromSelected, addLocalImageToSelected, isLoading, 
          imagePaginationClick, page, showUploadSection, 
          ccImages, imageCCTotal, depositPhotosImages, imageDepositPhotosTotal, depositPhotosMonthlyCount=0 } = props;

  const toggle = (imageUrl) => {
    setModalImageUrl(imageUrl);
    setModal(!modal);
  };

  return (
    <>
      {(selectedImages.length === 0 && dropzoneFiles.length === 0)
        ? null
        : <Row className="align-items-center mb-3">
          <Col>
            <Card className="shadow">
              <CardHeader>
                <Row>
                  <Col xs={{ size: "auto" }} className="ml-auto">
                    <Button color="primary" disabled={dropzoneFiles.length === 0 && selectedImages.length === 0} onClick={() => showUploadSection()}>
                      GO TO UPLOAD
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {selectedImages.length > 0 &&
                  <SearchedImagesCard {...{ selectedImages, toggleImageFromSelected, toggle }} />
                }
                {dropzoneFiles.length > 0 &&
                  <DropzoneImagesCard {...{ dropzoneFiles, addLocalImageToSelected, toggle }} />
                }
              </CardBody>
            </Card>
          </Col>
        </Row>
      }

      {page > 0 &&
        <Row>
          <div className="col mt-2">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="justify-content-between align-items-center">
                  <Col xs={{ size: "auto" }} className={"align-items-center"}>
                    <div style={{ display: "inline-flex", alignItems: "center" }}>
                      <h3 className="mb-0">PEXELS</h3>
                      {imagePexelsTotal > 0 &&
                        <div style={{ fontSize: "0.8rem", paddingLeft: "0.5rem" }}>(images matching your criteria: {imagePexelsTotal})</div>
                      }
                    </div>
                  </Col>
                  {imagePexelsTotal > 0 &&
                    <Col xs={{ size: "auto" }}>
                      <span style={{ marginRight: "0.8rem" }}><small>Page: {page} of {Math.ceil(imagePexelsTotal / 20)}</small></span>
                      <Button outline color="primary"
                        disabled={page <= 1}
                        onClick={() => imagePaginationClick("prev")}>
                        PREV PAGE
                      </Button>
                      <Button outline color="primary" onClick={() => imagePaginationClick("next")}>
                        NEXT PAGE
                      </Button>
                    </Col>
                  }
                </Row>
              </CardHeader>
              <CardBody className="pt-0">
                <Spinner loading={isLoading}>
                  <Row>
                    {imagePexelsTotal > 0
                      ? images.map((img, idx) => {
                        let active = "";
                        if (selectedImages.indexOf(img.src.landscape) !== -1) {
                          active = "img-tool-active";
                        }
                        if ( img.width < 500 || img.height < 500 ) {
                          active = "img-tool-notgood";
                        }
                        return (
                          <Col key={`img-${idx}`} xs={{ size: "auto" }}>
                            <div className="p-1 d-block mb-4 h-100" style={{ position: "relative" }}>
                              {!active && <i className="ni ni-image img-tool-preview-icon text-white" onClick={() => toggle(img.src.original + "?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200")} />}
                              <img className={active} src={img.src.tiny} alt="img-gallery-preview-img" width="100px" height="auto" max-height="100px" onClick={() => active !== "img-tool-notgood" && toggleImageFromSelected(img.src.landscape)} />
                            </div>
                          </Col>
                        );
                      })
                      : <NoImageResults />
                    }
                  </Row>
                </Spinner>
              </CardBody>
            </Card>
          </div>
        </Row>
      }

      {page > 0 &&
        <Row>
          <div className="col mt-2">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="justify-content-between align-items-center">
                  <Col xs={{ size: "auto" }} className={"align-items-center"}>
                    <div style={{ display: "inline-flex", alignItems: "center" }}>
                      <h3 className="mb-0">PIXABAY</h3>
                      {imagePixabayTotal > 0 &&
                        <div style={{ fontSize: "0.8rem", paddingLeft: "0.5rem" }}>(images matching your criteria: {imagePixabayTotal})</div>
                      }
                    </div>
                  </Col>
                  {imagePixabayTotal > 0 &&
                    <Col xs={{ size: "auto" }}>
                      <span style={{ marginRight: "0.8rem" }}><small>Page: {page} of {Math.ceil(imagePixabayTotal / 20)}</small></span>
                      <Button outline color="primary"
                        disabled={page <= 1}
                        onClick={() => imagePaginationClick("prev")}>
                        PREV PAGE
                      </Button>
                      <Button outline color="primary" onClick={() => imagePaginationClick("next")}>
                        NEXT PAGE
                      </Button>
                    </Col>
                  }
                </Row>
              </CardHeader>
              <CardBody className="pt-1">
                <Spinner loading={isLoading}>
                  <Row>
                    {imagePixabayTotal > 0
                      ? pixabayImages.map((img, idx) => {
                        let active = "";
                        if (selectedImages.indexOf(img.largeImageURL) !== -1) {
                          active = "img-tool-active";
                        }
                        if ( img.imageWidth < 500 || img.imageHeight < 500 ) {
                          active = "img-tool-notgood";
                        }

                        return (
                          <Col key={`img-pixabay-${idx}`} xs={{ size: "auto" }}>
                            <div className="p-1 d-block mb-4" style={{ position: "relative" }}>
                              {!active && <i className="ni ni-image img-tool-preview-icon text-white" onClick={() => toggle(img.largeImageURL)} />}
                              <img className={active} src={img.previewURL} alt="pixabay-img-preview" width="100px" height="auto" max-height="100px" onClick={() => active !== "img-tool-notgood" && toggleImageFromSelected(img.largeImageURL)} />
                            </div>
                          </Col>
                        )
                      })
                      : <NoImageResults />
                    }
                  </Row>
                </Spinner>
              </CardBody>
            </Card>
          </div>
        </Row>
      }

      {page > 0 &&
        <Row>
          <div className="col mt-2">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="justify-content-between align-items-center">
                  <Col xs={{ size: "auto" }} className={"align-items-center"}>
                    <div style={{ display: "inline-flex", alignItems: "center" }}>
                      <h3 className="mb-0">UNSPLASH</h3>
                      {imageUnsplashTotal > 0 &&
                        <div style={{ fontSize: "0.8rem", paddingLeft: "0.5rem" }}>(images matching your criteria: {imageUnsplashTotal})</div>
                      }
                    </div>
                  </Col>
                  {imageUnsplashTotal > 0 &&
                    <Col xs={{ size: "auto" }}>
                      <span style={{ marginRight: "0.8rem" }}><small>Page: {page} of {Math.ceil(imageUnsplashTotal / 20)}</small></span>
                      <Button outline color="primary"
                        disabled={page <= 1}
                        onClick={() => imagePaginationClick("prev")}>
                        PREV PAGE
                      </Button>
                      <Button outline color="primary" onClick={() => imagePaginationClick("next")}>
                        NEXT PAGE
                      </Button>
                    </Col>
                  }
                </Row>
              </CardHeader>
              <CardBody className="pt-1">
                <Spinner loading={isLoading}>
                  <Row>
                    {imageUnsplashTotal > 0
                      ? unsplashImages.map((img, idx) => {
                        let active = "";
                        if (selectedImages.indexOf(img.urls.raw) !== -1) {
                          active = "img-tool-active";
                        }

                        if ( img.width < 500 || img.height < 500 ) {
                          active = "img-tool-notgood";
                        }

                        return (
                          <Col key={`img-pixabay-${idx}`} xs={{ size: "auto" }}>
                            <div className="p-1 d-block mb-4" style={{ position: "relative" }}>
                              {!active && <i className="ni ni-image img-tool-preview-icon text-white" onClick={() => toggle(img.urls.full)} />}
                              <img className={active} src={img.urls.thumb} alt="pixabay-img-preview" width="100px" height="auto" max-height="100px" onClick={() => active !== "img-tool-notgood" && toggleImageFromSelected(img.urls.raw)} />
                            </div>
                          </Col>
                        )
                      })
                      : <NoImageResults />
                    }
                  </Row>
                </Spinner>
              </CardBody>
            </Card>
          </div>
        </Row>
      }


      {page > 0 &&
        <Row>
          <div className="col mt-2">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="justify-content-between align-items-center">
                  <Col xs={{ size: "auto" }} className={"align-items-center"}>
                    <div style={{ display: "inline-flex", alignItems: "center" }}>
                      <h3 className="mb-0">FLICKR</h3>
                      {imageFlickrTotal > 0 &&
                        <div style={{ fontSize: "0.8rem", paddingLeft: "0.5rem" }}>(images matching your criteria: {imageFlickrTotal})</div>
                      }
                    </div>
                  </Col>
                  {imageFlickrTotal > 0 &&
                    <Col xs={{ size: "auto" }}>
                      <span style={{ marginRight: "0.8rem" }}><small>Page: {page} of {Math.ceil(imageFlickrTotal / 20)}</small></span>
                      <Button outline color="primary"
                        disabled={page <= 1}
                        onClick={() => imagePaginationClick("prev")}>
                        PREV PAGE
                      </Button>
                      <Button outline color="primary" onClick={() => imagePaginationClick("next")}>
                        NEXT PAGE
                      </Button>
                    </Col>
                  }
                </Row>
              </CardHeader>
              <CardBody className="pt-1">
                <Spinner loading={isLoading}>
                  <Row>
                    {imageFlickrTotal > 0
                      ? flickrImages.map((img, idx) => {
                        let active = "";
                        if (selectedImages.indexOf(img.url) !== -1) {
                          active = "img-tool-active";
                        }
                        return (
                          <Col key={`img-pixabay-${idx}`} xs={{ size: "auto" }}>
                            <div className="p-1 d-block mb-4" style={{ position: "relative" }}>
                              {!active && <i className="ni ni-image img-tool-preview-icon text-white" onClick={() => toggle(img.url)} />}
                              <img className={active} src={img.url} alt="pixabay-img-preview" width="100px" height="auto" max-height="100px" onClick={() => toggleImageFromSelected(img.url)} />
                            </div>
                          </Col>
                        )
                      })
                      : <NoImageResults />
                    }
                  </Row>
                </Spinner>
              </CardBody>
            </Card>
          </div>
        </Row>
      }



      {page > 0 &&
        <Row>
          <div className="col mt-2">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="justify-content-between align-items-center">
                  <Col xs={{ size: "auto" }} className={"align-items-center"}>
                    <div style={{ display: "inline-flex", alignItems: "center" }}>
                      <h3 className="mb-0">CreativeCommons</h3>
                      {imageCCTotal > 0 &&
                        <div style={{ fontSize: "0.8rem", paddingLeft: "0.5rem" }}>(images matching your criteria: {imageCCTotal})</div>
                      }
                    </div>
                  </Col>
                  {imageCCTotal > 0 &&
                    <Col xs={{ size: "auto" }}>
                      <span style={{ marginRight: "0.8rem" }}><small>Page: {page} of {Math.ceil(imageCCTotal / 20)}</small></span>
                      <Button outline color="primary"
                        disabled={page <= 1}
                        onClick={() => imagePaginationClick("prev")}>
                        PREV PAGE
                      </Button>
                      <Button outline color="primary" onClick={() => imagePaginationClick("next")}>
                        NEXT PAGE
                      </Button>
                    </Col>
                  }
                </Row>
              </CardHeader>
              <CardBody className="pt-1">
                <Spinner loading={isLoading}>
                  <Row>
                    {imageCCTotal > 0
                      ? ccImages.map((img, idx) => {
                        let active = "";
                        if (selectedImages.indexOf(img.url) !== -1) {
                          active = "img-tool-active";
                        }
                        return (
                          <Col key={`img-pixabay-${idx}`} xs={{ size: "auto" }}>
                            <div className="p-1 d-block mb-4" style={{ position: "relative" }}>
                              {!active && <i className="ni ni-image img-tool-preview-icon text-white" onClick={() => toggle(img.url)} />}
                              <img className={active} src={img.thumbnail} alt="pixabay-img-preview" width="100px" height="auto" max-height="100px" onClick={() => toggleImageFromSelected(img.url)} />
                            </div>
                          </Col>
                        )
                      })
                      : <NoImageResults />
                    }
                  </Row>
                </Spinner>
              </CardBody>
            </Card>
          </div>
        </Row>
      }

      {page > 0 &&
        <Row>
          <div className="col mt-2">
            <Card className="shadow">
              <CardHeader className="bg-white border-0">
                <Row className="justify-content-between align-items-center">
                  <Col xs={{ size: "auto" }} className={"align-items-center"}>
                    <div style={{ display: "inline-flex", alignItems: "center" }}>
                      <h3 className="mb-0">DepositPhotos ({`Downloaded this month: ${depositPhotosMonthlyCount}`})</h3>
                      {imageDepositPhotosTotal > 0 &&
                        <div style={{ fontSize: "0.8rem", paddingLeft: "0.5rem" }}>(images matching your criteria: {imageDepositPhotosTotal})</div>
                      }
                    </div>
                  </Col>
                  {imageDepositPhotosTotal > 0 &&
                    <Col xs={{ size: "auto" }}>
                      <span style={{ marginRight: "0.8rem" }}><small>Page: {page} of {Math.ceil(imageDepositPhotosTotal / 20)}</small></span>
                      <Button outline color="primary"
                        disabled={page <= 1}
                        onClick={() => imagePaginationClick("prev")}>
                        PREV PAGE
                      </Button>
                      <Button outline color="primary" onClick={() => imagePaginationClick("next")}>
                        NEXT PAGE
                      </Button>
                    </Col>
                  }
                </Row>
              </CardHeader>
              <CardBody className="pt-1">
                <Spinner loading={isLoading}>
                  <Row>
                    {imageDepositPhotosTotal > 0
                      ? depositPhotosImages.map((img, idx) => {
                        let imgURL = "depositphotos#"+img.id+"#"+img.thumbnail;
                        let active = "";
                        if (selectedImages.indexOf(imgURL) !== -1) {
                          active = "img-tool-active";
                        }

                        if ( img.width < 500 || img.height < 500 ) {
                          active = "img-tool-notgood";
                        }

                        return (
                          <Col key={`img-depositphotos-${idx}`} xs={{ size: "auto" }}>
                            <div className="p-1 d-block mb-4" style={{ position: "relative" }}>
                              {!active && <i className="ni ni-image img-tool-preview-icon text-white" onClick={() => toggle(img.url_big)} />}
                              <img className={active} src={img.thumbnail} alt="depositphotos-img-preview" width="100px" height="auto" max-height="100px" onClick={() => active !== "img-tool-notgood" && toggleImageFromSelected(imgURL)} />
                            </div>
                          </Col>
                        )
                      })
                      : <NoImageResults />
                    }
                  </Row>
                </Spinner>
              </CardBody>
            </Card>
          </div>
        </Row>
      }

      <Modal isOpen={modal} toggle={toggle} className="image-tool-preview-modal">
        <ModalBody className="pb-0">
          <img src={modalImageUrl} className="img-fluid" alt="" />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>CLOSE</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ImageGallery;


const NoImageResults = () => (
  <Col>
    <p>No image results for that search!</p>
  </Col>
)
