import React, {useState, useEffect} from "react";
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button,  FormGroup, Label, Input } from "reactstrap";

const FbRuleAccountsModal = (props) => {
  const { isLoading, editingRule, editRuleModal, toggleEditRuleModal, ruleByName={}, fbAccounts} = props;

  
  const [fbaccounts, setFbAccounts] = useState([]);
  const [addedAccounts, setAddedFbAccounts] = useState([]);

  useEffect(() => {
  	if ( editingRule && ruleByName[editingRule.name] ) {
  		setFbAccounts(ruleByName[editingRule.name].fbAccounts);
  	}
  },[editingRule]);

  // console.log(fbaccounts, fbAccounts, fbAccounts.filter((f) => editingRule && f.activity === editingRule.activity));

  const addFbAccount = (e) => {
  	let exists = fbaccounts.indexOf(e.target.value);
  	if ( exists === -1 ) {
  		setFbAccounts([...fbaccounts, e.target.value])
  		setAddedFbAccounts([...addedAccounts, e.target.value]);
  	}
  }

  return (
		<Modal isOpen={editRuleModal} toggle={toggleEditRuleModal} size="lg">
	    <ModalHeader toggle={toggleEditRuleModal}>Add fb account to rule {editingRule.name}:</ModalHeader>
	    <ModalBody>
	      <Row>
	        <Col>
	          <FormGroup>
	            <Label for="fbAccount">Apply Rule to: </Label>
	            <Input type="select" name="fbaccounts" id="fbAccount" multiple onChange={(e) => addFbAccount(e)} value={fbaccounts} style={{height:"250px"}}>
	              {fbAccounts.filter((f) => editingRule && f.activity === editingRule.activity)
	              	.map((f,i) => <option key={`account-${i}`} value={f.id}>{f.bm} - {f.name}</option>)}
	            </Input>
	          </FormGroup>
	        </Col>
	      </Row>
	    </ModalBody>
	    <ModalFooter>
	      <Button color="secondary" onClick={toggleEditRuleModal}>CLOSE</Button>
	      <Button color="primary" disabled={isLoading} onClick={() => props.saveFbRuleAccounts(editingRule, addedAccounts)}>SAVE</Button>
	    </ModalFooter>
	  </Modal>
  )
}

export default FbRuleAccountsModal;

