export default {
	"tq_activities": {
		DIMENSIONS_INIT: [
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Bidded Clicks", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "tq", name: "TQ", checked: true, sortable: true, showInGraph: true, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "site_id", name: "Site ID", checked: false, sortable: true },
			{ selector: "market", name: "Market", checked: false, sortable: true },
			{ selector: "domain", name: "Domain", checked: false, sortable: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true },
			{ selector: "deviceType", name: "Device", checked: false, sortable: true },
			{ selector: "campaign_id", name: "CampaignID", checked: false, sortable: true },
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true },
			{ selector: "placement_id", name: "PlacementID", checked: false, sortable: true },
			{ selector: "place_name", name: "Placement", checked: false, sortable: true },
			{ selector: "advertiser_name", name: "Account", checked: false, sortable: true }
		],
		"FILTERS_INIT": [
			{ selector: "site_id", name: "Site ID", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "domain", name: "Domain", values: [] },
			{ selector: "keyword", name: "Keyword", values: [] },
			{ selector: "deviceType", name: "Device", values: [] },
			{ selector: "campaign_id", name: "CampaignID", values: [] },
			// { selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "placement_id", name: "PlacementID", values: [] },
			// { selector: "place_name", name: "Placement", values: [] }
		],
		CHART_DIMENSIONS: ["tq"]
	}
};