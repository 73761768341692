import React, {useState} from "react";
import {Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input } from "reactstrap";

const UTM_CAMPAIGN = [
  { id: "automotive", name: "automotive" },
  { id: "education", name: "education" },
  { id: "fashion", name: "fashion" },
  { id: "finance", name: "finance" },
  { id: "flight", name: "flight" },
  { id: "generic", name: "generic" },
  { id: "health", name: "health" },
  { id: "household", name: "household" },
  { id: "services", name: "services" },
  { id: "shopping", name: "shopping" },
  { id: "tech", name: "tech" },
  { id: "travel", name: "travel" }
  // { value: "business", label: "business" },
  // { value: "internet", label: "internet" },
  // { value: "health", label: "health" },
  // { value: "astrology", label: "astrology" },
  // { value: "gambling", label: "gambling" }
];


const LANG = [
  {id: "italian",name: "italian"},
  {id: "english",name: "english"},
  {id: "german",name: "german"},
  {id: "french",name: "french"},
  {id: "spanish",name: "spanish"}
];


const MapFanPagetModal = (props) => {
  const { fanPagesModal, toggleFanPagesModal, mappableFanPages, isLoading } = props;
  

  const [fanpagetobemapped, setFanPageToBeMapped] = useState("");
  const [fanpageNametobemapped, setFanPageNameToBeMapped] = useState("");
  const [utm_campaign, setUTMCampaign] = useState("");
  const [lang, setLang] = useState("");
  const [access_token, setFanPageAccessToken] = useState("");

  return (
    <Modal isOpen={fanPagesModal} toggle={toggleFanPagesModal} size="lg">
      <ModalHeader toggle={toggleFanPagesModal}>MAP NEW FANPAGE:</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Input type="select" name="fanPageSelect" id="fanPageSelect" value={fanpagetobemapped} onChange={(e) => {
            setFanPageToBeMapped(e.target.value);
            const selectedFanPage = mappableFanPages.filter(m => m.id === e.target.value);
            setFanPageNameToBeMapped(selectedFanPage[0].name);
            setFanPageAccessToken(selectedFanPage[0].access_token);
          }}>
            <option key="empty-page" value="">----</option>
            {mappableFanPages.map((fanpage,i) => <option key={fanpage.id} value={fanpage.id}>{fanpage.name} - ({fanpage.id})</option>)}
          </Input>
        </FormGroup>
        {fanpagetobemapped !== "" && <div>
        <FormGroup>
          <Label for="ruleName">Vertical: </Label>
          <Input type="select" name="utm_campaign" id="utm_campaign" onChange={(e) => setUTMCampaign(e.target.value)} value={utm_campaign}>
            <option key="empty-utm_campaign" value="">----</option>
            {UTM_CAMPAIGN.map((utm_campaign,i) => <option key={utm_campaign.id} value={utm_campaign.name}>{utm_campaign.name}</option>)}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="ruleName">Lang: </Label>
          <Input type="select" name="lang" id="lang" onChange={(e) => setLang(e.target.value)} value={lang}>
            <option key="empty-lang" value="">----</option>
            {LANG.map((lang,i) => <option key={lang.id} value={lang.name}>{lang.name}</option>)}
          </Input>
        </FormGroup>
        </div>}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleFanPagesModal}>CLOSE</Button>
        <Button color="primary" onClick={() => props.saveFanPageMap({id: fanpagetobemapped, name: fanpageNametobemapped, utm_campaign, lang, access_token})} 
          disabled={isLoading}>MAP</Button>
      </ModalFooter>
    </Modal>
  )
}

export default MapFanPagetModal;
