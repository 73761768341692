import React, { useState, useEffect } from "react";
import moment from 'moment';

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css';

import { API } from "aws-amplify";
import { NavLink as NavLinkRRD } from "react-router-dom";

// import Spinner from "../../../components/Spinner";

import {
  NavLink,
  Button,
  Card,
  Form,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

import DataTable from 'react-data-table-component';


const UTM_CAMPAIGN = [
    { id: "automotive", name: "automotive" },
    { id: "education", name: "education" },
    { id: "fashion", name: "fashion" },
    { id: "finance", name: "finance" },
    { id: "flight", name: "flight" },
    { id: "gambling", name: "gambling" },
    { id: "health", name: "health" },
    { id: "household", name: "household" },
    { id: "services", name: "services" },
    { id: "shopping", name: "shopping" },
    { id: "tech", name: "tech" },
    { id: "travel", name: "travel" }
    // { value: "business", label: "business" },
    // { value: "internet", label: "internet" },
    
    // { value: "astrology", label: "astrology" }
  ];

const DOMAINs = [
  // "B00kmarks",
  // "Priceloose",
  "Betterdeals",
  // "Top5",
  // "DailyBuzzFeed",
  // "Smarter",
  // "Simpli",
  // "ExpertsNow",
  // "Helpwire",
  // "Clicksco"
]

const columns = [
  {
    name: 'Date',
    selector: 'date',
    sortable: true,
    compact: true
  },
  {
    name: 'WEEK',
    selector: 'wok',
    sortable: true,
    format: (row) => moment(row.date).week(),
    compact: true
  },
  {
    name: 'Market',
    selector: 'market',
    sortable: true,
    compact: true
  },
  {
    name: 'Keyword',
    selector: 'keyword',
    sortable: true,
    compact: true,
    grow: 3
  },
  {
    name: "Utm Campaign",
    selector: "utm_campaign",
    sortable: true
  },
  {
    name: "FB Account",
    selector: "advertiser_name",
    sortable: true
  },
  {
    name: "Domain",
    selector: "domain",
    sortable: true
  },
  {
    name: "Device",
    selector: "device",
    sortable: true
  },
  {
    name: "Site",
    selector: "site",
    sortable: true
  }
];


//- filtra by keyword.
const RecyclingKeywords = (props) => {


  const maxDate = moment();
  const initDate = moment().subtract(1, 'days').toDate();
  const [startDate, setStartDate] = useState(initDate);
  const [endDate, setEndDate] = useState(initDate);

  const [utm_campaign, setUtmCampaign] = useState("");
  const [domain, setDomain] = useState("");
  const [inputFilterSearchText, setFilterInputValue] = useState("");
  const [throttleAJAXCALLTimeout, setThrottleAJAXCALLTimeout] = useState(null);
  
  useEffect(() => {
    clearTimeout(throttleAJAXCALLTimeout);
    setThrottleAJAXCALLTimeout(setTimeout(async () => {
      fetchData();
    }, 500));
    return () => {
      clearTimeout(throttleAJAXCALLTimeout);
    }
  }, [inputFilterSearchText]);
  // const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const [table, setTable] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    let filterAPIParams = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
      utm_campaign,
      inputFilterSearchText,
      domain
    };
    const table = await API.get('customAPI', '/keyword-tools/planner', {
      queryStringParameters: filterAPIParams
    });
    setTable(table);
    // console.log(table);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData()
  }, []);


  useEffect(() => {
    fetchData()
  }, [startDate, endDate, utm_campaign, domain]);


  return (
    <Container className="mt-2" fluid>
      <Row>
        <Col>
         <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="4">
                  <h3 className="mb-0">Keyword Reciclying Bin</h3>
                </Col>
                <Col>
                  <Button className="btn-info float-right" size="sm">
                    <NavLink
                      to='/admin/keyword-tools/automation/planner'
                      tag={NavLinkRRD}
                    >
                      Keyword Planner
                    </NavLink>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col lg="2">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-username">
                        Start Date:
                      </label>
                      <DatePicker
                        selected={startDate}
                        onChange={date => setStartDate(date)}
                        dateFormat="yyyy-MM-dd"
                        calendarClassName="datepickerCustomStyle"
                        maxDate={maxDate} className="form-control"
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="2">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-username">
                        End Date:
                    </label>
                      <DatePicker selected={endDate} onChange={date => setEndDate(date)} dateFormat="yyyy-MM-dd" calendarClassName="datepickerCustomStyle"
                        maxDate={maxDate} className="form-control" />
                    </FormGroup>
                  </Col>
                  <Col lg="2">
                    <FormGroup>
                      <label className="form-control-label">
                        UTM CAMPAIGN
                      </label>
                      <Input type="select" onChange={(e) => setUtmCampaign(e.target.value)} value={utm_campaign}>
                        <option value="">----</option>
                        {UTM_CAMPAIGN.map((vertical,idx) => <option key={`v-${idx}`} value={vertical.id}>{vertical.name}</option>)}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col lg="2">
                    <FormGroup>
                      <label className="form-control-label">
                        DOMAINs
                      </label>
                      <Input type="select" onChange={(e) => setDomain(e.target.value)} value={domain}>
                        <option value="">----</option>
                        {DOMAINs.map((d,idx) => <option key={`d-${idx}`} value={d}>{d}</option>)}
                      </Input>
                    </FormGroup>
                  </Col>
                  
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col>
         <Card className="shadow">
            <CardHeader className="bg-white border-0">
              <Row className="justify-content-end">
                <Col xs={6} lg={4}>
                  <input
                    className="form-control"
                    type="search"
                    placeholder={`Search by keyword ...`}
                    disabled={isLoading}
                    value={inputFilterSearchText}
                    onChange={(e) => setFilterInputValue(e.target.value)}
                  />
                </Col>
                <Col xs={{ size: "auto" }} className="pl-0">
                  <button className="btn btn-icon btn-primary btn-sm" type="button" 
                  onClick={e => { e.preventDefault(); }}>
                    <span className="btn-inner--icon"><i className="ni ni-cloud-download-95"></i></span>
                  </button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="">
              <DataTable
                progressPending={isLoading}
                noHeader={true}
                striped={true}
                highlightOnHover
                dense={true}
                columns={columns}
                data={table}
                defaultSortField="date"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default RecyclingKeywords;
