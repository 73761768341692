import React from "react";
import { Row, Spinner } from "reactstrap"

export default (props) => {
  if (props.loading || props.isLoading) {
    return <Row className={props.className ? props.className : "no-gutters justify-content-center"} style={props.style} >
      <Spinner color={props.color ? props.color : "primary"} />
    </Row>
  } else {
    return (props.children)
  }
}
