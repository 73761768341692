import React, { useState, useMemo } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Tooltip } from "reactstrap";

import { Row, Col, ButtonGroup, Button } from "reactstrap";


const CellMenu = (props) => {
    const [copiedText, setCopiedText] = useState("");
    const [tooltipOpen, setTooltipOpen] = useState(false);

    const tooltipId = useMemo(
        () => "tooltip" + Math.floor(Math.random() * 1000),
        []
    );

    const toggle = () => setTooltipOpen(!tooltipOpen);

    const handleClick = (e, data) => {
        console.log(props.selector, props.value);
        props.setFilterValue(props.selector, props.value);
    }

    return (
        <div>
            <div id={tooltipId}>
                {props.value}
            </div>
            <Tooltip placement="auto" isOpen={tooltipOpen} autohide={false} target={tooltipId} toggle={toggle}>
                <Row>
                    <Col>
                        <ButtonGroup size="sm" vertical>
                            <Button className="btn-neutral btn-icon">
                                <CopyToClipboard text={props.value} onCopy={() => setCopiedText(props.value)}>
                                    <div>{copiedText === props.value ? `Copied!` : <><i className="fa fa-copy mr-1" />Copy</>}</div>
                                </CopyToClipboard>
                            </Button>
                            <Button className="btn-neutral btn-icon" onClick={handleClick}>
                                <i className="fa fa-filter"/>Filter By
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Tooltip>            
        </div>
    )
};

export default CellMenu;