import React, { PureComponent, useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import DataTable, { defaultThemes } from 'react-data-table-component';
import { useLocation } from 'react-router-dom';
import { API } from 'aws-amplify';
import { Card, ButtonGroup, Button, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { LineChart, XAxis, YAxis, Legend, Tooltip, CartesianGrid, BarChart, Bar, Line, ResponsiveContainer } from 'recharts';

import TableFilterHeader from "components/Headers/TableFiltersHeader.jsx";
import Pagination from "./pagination.js";

import CONFIG from "./config"
import { fetchDataApi, computeColumns } from "./api"

import CellMenu from "../../components/CellMenu";
import RulesModal from "../../components/RulesModal";

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fontSize={10} fill="#666" transform="rotate(-35)">{payload.value} ({payload.value !== "auto" && moment(payload.value).format('dd')})</text>
      </g>
    );
  }
}


const customStyles = {
  // header: {
  //   style: {
  //     minHeight: '56px',
  //   },
  // },
  // headRow: {
  //   style: {
  //     borderTopStyle: 'solid',
  //     borderTopWidth: '1px',
  //     borderTopColor: defaultThemes.default.divider.default,
  //   },
  // },
  // headCells: {
  //   style: {
  //     '&:not(:last-of-type)': {
  //       borderRightStyle: 'solid',
  //       borderRightWidth: '1px',
  //       borderRightColor: defaultThemes.default.divider.default,
  //     },
  //   },
  // },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        borderRightStyle: 'solid',
        borderRightWidth: '1px',
        borderRightColor: defaultThemes.default.divider.default,
      },
    },
  },
};


const getCell = (row, cellName, setFilterValue) => {
  const value = row[cellName] ? row[cellName] : "n/a";
  return <CellMenu value={value} setFilterValue={setFilterValue} selector={cellName}/>
};

const ReportPage = (props) => {
  const table = props.table;
  const { reportname, reportType } = props;
  const initDate = CONFIG[reportType].DATE || moment().subtract(1,"days").toDate();
  
  const [startDate, setStartDate] = useState(initDate);
  const [endDate, setEndDate] = useState(initDate);
  const [dimensions, setDimensions] = useState(CONFIG[reportType].DIMENSIONS_INIT);
  const [breakdown, setBreakdown] = useState(CONFIG[reportType].BREAKDOWN_INIT);
  // filter date range option
  const [filterDateOpt, setFilterDateOpt] = useState("daterange");
  const [filters, setFilters] = useState(CONFIG[reportType].FILTERS_INIT);
  const [isFiltersLoading, setFilterIsLoading] = useState(false);
  const [inputFilterSearchText, setFilterInputValue] = useState("");
  const [overwriters, setOverwriteRs] = useState(null);
  const [inputSearchPlaceholder, setInputSearchPlaceholder] = useState(breakdown.map(b => b.checked && b.searchable ? b.name : false).filter(f => f).join(", "));
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderby] = useState(false);
  // const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(150);
  const [page, setPage] = useState(1);
  const [throttleAJAXCALLTimeout, setThrottleAJAXCALLTimeout] = useState(null);

  const [chartDimensions, setChartDimensions] = useState(CONFIG[reportType].CHART_DIMENSIONS);
  const [chartData, setChartData] = useState([]);
  const [chartType, setChartType] = useState("line");

  const location = useLocation();

  const toggleChartDimension = (togDimension) => {
    let nextChartDimensions = [...chartDimensions];
    
    let currIdx = -1;
    let currIdxR = -1;
    if ( togDimension.endsWith("_r") ) {
      currIdxR = nextChartDimensions.indexOf(togDimension);
      currIdx = nextChartDimensions.indexOf(togDimension.replace("_r", ""));
    } else {
      currIdxR = nextChartDimensions.indexOf(togDimension + "_r");
      currIdx = nextChartDimensions.indexOf(togDimension);
    }

    if (currIdx !== -1) {
      nextChartDimensions.splice(currIdx, 1);
    } 
    if ( currIdxR !== -1 ) {
      nextChartDimensions.splice(currIdxR, 1);
    } 

    if ( (currIdx === -1 && currIdxR === -1) || 
        (togDimension.endsWith("_r") && currIdxR === -1) ||
        (!togDimension.endsWith("_r")  && currIdx === -1) ) {
      nextChartDimensions.push(togDimension);
    }
    setChartDimensions(nextChartDimensions);
  }


  const [label, setLabel] = useState({});
  useEffect(() => {
    let newLabel = chartDimensions.reduce((label, dimension) => {
      if ( dimension.endsWith("_r") ) {
        label['right'] = label['right'] || {value: "",angle: -90, position: 'insideTopRight'};
        let yLabel = dimension.replace("_r","");
        if ( label['right'].value === "" ) { 
          label['right'].value = yLabel;
        } else {
          label['right'].value += ", " + yLabel;
        }
      } else {
        label['left'] = label['left'] || {value: "",angle: 90, position: 'insideTopLeft'};
        if ( label['left'].value === "" ) { 
          label['left'].value = dimension;
        } else {
          label['left'].value += ", " + dimension;
        }
      }
      return label;
    }, {});
    setLabel(newLabel);
  }, [chartDimensions])

  const setFilterValue = (selector, value) => {
    let newFilters = filters.map((filter) => {
      let newFilter = { ...filter };
      if (newFilter.selector === selector) {
        if ( newFilter.values.length ) {
          newFilter.values = newFilter.values.map((k) => {
            if (k.value === value) {
              k.isChecked = !k.isChecked;
            }
            return k;
          });
        } else {
          newFilter.isActive = true;
          newFilter.values = [{
            isChecked: true,
            value: value
          }];
        }
      }/* else {
        newFilter.values = [];
      }*/
      return newFilter;
    });
    setFilters(newFilters);
  }
  const resetFilters = () => {
    setFilters([...CONFIG[reportType].FILTERS_INIT]);
  }

 
  const loadFilterValue = async (reload=false) => {
    setFilterIsLoading(true);
    let filterAPIParams = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD")
    };
    if (filterDateOpt === "last7days"){
      filterAPIParams = {
        startDate: moment().subtract("7", "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD")
      };
    } else if ( filterDateOpt === "last30days") {
      filterAPIParams = {
        startDate: moment().subtract("30", "days").format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD")
      };
    } else if ( filterDateOpt === "all") {
      filterAPIParams = {};
    }

    let [filter] = filters.filter((f) => f.isActive);

    if ( filter ) {
      if ( filter.values.length === 0 || reload===true) {
        console.log("RELOADING FILTER VALUES", filter, filterDateOpt);
        filterAPIParams["filter"] = filter.selector;
        filterAPIParams["table"] = table;
        let response = await API.get('customAPI', '/filters', {
          queryStringParameters: filterAPIParams
        });
        filter.values = response.values.reduce((acc, row) => {
          if (row[filter.selector]) {
            acc.push({
              value: row[filter.selector],
              isChecked: false
            });
          }
          return acc;
        }, []);
      }
    }
    setFilterIsLoading(false);
  }

  const toggleFilter = async (toggle) => {
    let newFilters = filters.map((filter) => {
      let newFilter = { ...filter };
      if (newFilter.selector === toggle && !newFilter.isActive) {
        newFilter.isActive = true;
      } else {
        newFilter.isActive = false;
      }
      return newFilter;
    });
    setFilters(newFilters);
  }

  const toggleBreakdown = (toggled) => {
    let newBreakdown = breakdown.map((b) => {
      if (b.selector === toggled) {
        return { ...b, checked: b.checked ? false : true };
      }
      return { ...b };
    });
    setBreakdown(newBreakdown)
    setInputSearchPlaceholder(newBreakdown.map(b => b.checked && b.searchable ? b.name : false).filter(f => f).join(", "));
  }

  const toggleDimension = (toggled) => {
    let newDimensions = dimensions.map((b) => {
      if (b.selector === toggled) {
        return { ...b, checked: b.checked ? false : true };
      }
      return { ...b };
    });
    setDimensions(newDimensions);
  }

  const fetchData = async (numpage) => {
    setLoading(true);

    try {
      let response = await fetchDataApi({
        startDate: startDate,
        endDate: endDate,
        page: numpage,
        inputFilterSearchText: inputFilterSearchText,
        overwriters,
        perPage: perPage,
        dimensions: dimensions,
        filters: filters,
        breakdown: breakdown,
        table: table,
        orderBy: orderBy,
        rulesConfig
      }, reportType, location.search);
      setData(response.data);

      let groupby = breakdown.reduce((acc, r) => {
        if (r.checked) {
          acc.push(r.selector);
        }
        return acc;
      }, []);

      if (groupby.indexOf("date") !== -1 || groupby.indexOf("week")  !== -1 ) {
        setChartType("line");
        let chartData = response.data.reduce((acc, row) => {
          let tmpRow = { ...row };
          if (groupby.indexOf("week") !== -1) {
            tmpRow.date = tmpRow.week; 
          }
          if (groupby.indexOf("hour") !== -1) {
            tmpRow.date = tmpRow.date + " " + ((tmpRow.hour < 10) ? `0${tmpRow.hour}` : tmpRow.hour) + ":00:00";
          }
          acc[tmpRow.date] = acc[tmpRow.date] || {
            date: tmpRow.date
          };
          dimensions.forEach((d) => {
            if (acc[tmpRow.date][d.selector]) {
              acc[tmpRow.date][d.selector] = acc[tmpRow.date][d.selector] + tmpRow[d.selector]
            } else {
              acc[tmpRow.date][d.selector] = tmpRow[d.selector];
            }
          });
          return acc;
        }, {});

        setChartData(Object.keys(chartData).sort((a, b) => new Date(a).getTime() - new Date(b).getTime()).map((date) => {
          return chartData[date];
        }));

      } else {
        setChartType("bar");
        // create bar chart!
        let chartData = response.data.reduce((acc, row) => {
          let uniqueKey = groupby.map((column) => {
            return row[column];
          }).join("_");

          acc[uniqueKey] = acc[uniqueKey] || {
            name: uniqueKey
          };

          dimensions.forEach((d) => {
            if (acc[uniqueKey][d.selector]) {
              acc[uniqueKey][d.selector] = acc[uniqueKey][d.selector] + row[d.selector]
            } else {
              acc[uniqueKey][d.selector] = row[d.selector];
            }
          });
          return acc;
        }, {});

        setChartData(Object.keys(chartData).map((uniqueKey) => {
          return chartData[uniqueKey];
        }));
      }

      // setTotalRows(response.totalRecords);
    } catch (e) {
      console.log(e);
      // setTotalRows(0);
      setData([]);
    }
    setLoading(false);
  };


  const downloadData = async () => {
    setLoading(true);
    try {
      let response = await fetchDataApi({
        startDate: startDate,
        endDate: endDate,
        page: page,
        perPage: perPage,
        inputFilterSearchText: inputFilterSearchText,
        dimensions: dimensions,
        filters: filters,
        breakdown: breakdown,
        download: true,
        orderBy,
        table,
        rulesConfig
      }, reportType);
      if (response.downloadUrl)
        window.open(response.downloadUrl);
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  }

  const onSelectPreset = (preset) => {
    const {breakdown, filters, dimensions} = preset;
    setBreakdown(breakdown);
    setDimensions(dimensions);
    setFilters(filters);
  }


  const handleSort = (column, sortDirection) => {
    // simulate server sort
    setOrderby({selector:column.selector, sortDirection});
    // setLoading(true);
    // // instead of setTimeout this is where you would handle your API call.
    // setTimeout(() => {
    //   setData(orderBy(data, column.selector, sortDirection));
    //   setLoading(false);
    // }, 100);
  };

  const onClear = () => {
    setBreakdown([...CONFIG[reportType].BREAKDOWN_INIT]);
    setDimensions([...CONFIG[reportType].DIMENSIONS_INIT]);
    setFilters([...CONFIG[reportType].FILTERS_INIT]);
    setChartDimensions([...CONFIG[reportType].CHART_DIMENSIONS]);
    setStartDate(initDate);
    setEndDate(initDate);
    setFilterInputValue("");
    setChartData([]);
    setOrderby({});
    setData([]);
    setPage(1);
    setRules({});
  }


  const [isRerunning, setIsRerunning] = useState(false);
  // const [pollScheduleJobStatusTimeout, setPollScheduleJobStatusTimeout] = useState(null);
  const scheduleRerun = async (rerunDate) => {
    setIsRerunning(true);
    console.log("HELLO NEED A RERUN", table, reportname, moment(rerunDate).format("YYYY-MM-DD"));
    // const {entryID} = 
    await API.post('customAPI', `/scheduler`, {
      body: {
        table: table,
        date: moment(rerunDate).format("YYYY-MM-DD")
      }
    });
    setIsRerunning(false);
    // setPollScheduleJobStatusTimeout(setInterval(async () => {
    //   const status = await API.get('customAPI', `/scheduler`, {
    //     queryStringParameters: {
    //       entryID: entryID,
    //       table: table
    //     }
    //   });
    //   if ( status.pending === false ) {
    //     setIsRerunning(false);
    //     clearInterval(pollScheduleJobStatusTimeout);
    //   }
    // }, 30000));
  }



  useEffect(() => {
    console.log("CLEAR, NEW REPORT TYPE!");
    onClear()
  }, [reportType]);

  useEffect(() => {
    console.log("FILTER!", filters, filterDateOpt);
    loadFilterValue();
  }, [filters])
  
  useEffect(() => {
    console.log("FILTER DATE OPT CHANGE!!", filters, filterDateOpt);
    loadFilterValue(true);
  }, [filterDateOpt])

  useEffect(() => {
    clearTimeout(throttleAJAXCALLTimeout);
    setThrottleAJAXCALLTimeout(setTimeout(async () => {
      fetchData(page);
    }, 500));
    return () => {
      clearTimeout(throttleAJAXCALLTimeout);
    }
  }, [inputFilterSearchText, perPage, page, orderBy]);

  const momizedColumns = useMemo(() => {
    let breakDownCustom = breakdown.map(b => {
      let nb = {...b}
      if ( ["date", "hour"].indexOf(b.selector) === -1) {
        nb.cell = (row) => getCell(row, b.selector, setFilterValue);
      }
      return nb;
    });
    return computeColumns(breakDownCustom, dimensions);
  }, [breakdown, dimensions]);
  // console.log(props);


  // RULES!
  const [rulesModal, setRuleModal] = useState(false);
  const [rulesConfig, setRules] = useState({});
  const selectRuleOpFilter = (selector, op) => {
    let rules = {...rulesConfig};
    rules[selector] = rules[selector] || {};
    rules[selector].op = op;
    setRules({...rules});
  }
  const selectRuleValueFilter = (selector, value) => {
    let rules = {...rulesConfig};
    rules[selector] = rules[selector] || {};
    rules[selector].value = value;
    setRules({...rules});
  }
  const toggleRulesModal = () => setRuleModal(!rulesModal);


  return (
    <div>
      <RulesModal rulesModal={rulesModal} toggleRulesModal={toggleRulesModal} dimensions={dimensions} 
          selectRuleOpFilter={selectRuleOpFilter} rulesConfig={rulesConfig} selectRuleValueFilter={selectRuleValueFilter} />
      <TableFilterHeader
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        maxDate={new Date()}
        toggleDimension={toggleDimension}
        toggleBreakdown={toggleBreakdown}
        toggleFilter={toggleFilter}
        onSelectFilterValue={setFilterValue}
        filters={filters}
        filterDateOpt={filterDateOpt}
        isFiltersLoading={isFiltersLoading}
        breakdown={breakdown}
        dimensions={dimensions}
        onSubmit={() => fetchData(1)}
        onClear={onClear}
        resetFilters={resetFilters}
        onChangeFilterDateOpt={(opt) => {
          setFilterDateOpt(opt.target.value);
        }}
        reportname={reportname}
        reportType={reportType}
        scheduleRerun={scheduleRerun}
        isRerunning={isRerunning}
        selectPreset={onSelectPreset}
      />
      <Container className="mt-2" fluid>
        <Row>
          <Col>
            {/* Table header and download btn */}
            <Card className="card shadow">
              <CardHeader className="">
                <Row className="justify-content-end">
                  {reportType === "yahooAdp" && ["andrea.simonetti", "rancan", "saverio.cresti", "enrico.rutelli", "chiacchiaretta"].indexOf(props.userAuth.username) !== -1 ? 
                  <Col xs={4} lg={2}>
                    <input
                      className="form-control"
                      type="number"
                      placeholder={`RS ${table.startsWith("ymyahoo") ? '( 0.67 )' : '( 0.75 )' }`}
                      disabled={loading}
                      value={overwriters || ""}
                      onChange={(e) => setOverwriteRs(e.target.value)}
                    />
                  </Col> : null
                  }
                  {inputSearchPlaceholder && <Col xs={6} lg={4}>
                    <input
                      className="form-control"
                      type="search"
                      placeholder={`Search by ${inputSearchPlaceholder} ...`}
                      disabled={loading}
                      value={inputFilterSearchText}
                      onChange={(e) => setFilterInputValue(e.target.value)}
                    />
                  </Col>}
                  <Col xs={{ size: "auto" }} className="pl-0">
                    <button className="btn btn-icon btn-success" type="button" style={{ fontSize: "1.15rem", padding: "0.55rem 1.15rem" }} onClick={e => { e.preventDefault(); toggleRulesModal(); }}>
                      <span className="btn-inner--text">
                        Rules
                      </span>
                      <span className="btn-inner--icon"><i className="ni ni-ruler-pencil"></i></span>
                    </button>
                  </Col>
                  <Col xs={{ size: "auto" }} className="pl-0">
                    <button className="btn btn-icon btn-primary" type="button" style={{ fontSize: "1.15rem", padding: "0.55rem 1.15rem" }} onClick={e => { e.preventDefault(); downloadData() }}>
                      <span className="btn-inner--icon"><i className="ni ni-cloud-download-95"></i></span>
                    </button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                {/* Table */}
                <DataTable
                  title={reportname}
                  columns={momizedColumns}
                  data={data}
                  onSort={handleSort}
                  sortServer
                  progressPending={loading}
                  persistTableHead={true}
                  noHeader={true}
                  customStyles={customStyles}
                  striped={true}
                  highlightOnHover
                  dense={true}
                  pagination
                  paginationServer
                  paginationComponent={Pagination}
                  onChangePage={(p) => setPage(p)}
                  paginationPerPage={perPage}
                  paginationRowsPerPageOptions={[50, 100, 150, 250, 1000]}
                  onChangeRowsPerPage={(npp) => setPerPage(npp)}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="mt-2">
            <Card className="shadow">
              <CardBody>
                <p>Select Graphs Dimensions:</p>
                <ButtonGroup size="sm">
                  {dimensions.map((b,i) => {
                    if (b.checked && b.showInGraph) {
                      return <ButtonGroup key={i} size="sm" className="mr-1">
                          <Button color="secondary" key={b.selector +"_l"} onClick={() => toggleChartDimension(b.selector)} active={chartDimensions.indexOf(b.selector) !== -1}>&lt;</Button>
                          <Button color="secondary" key={b.selector}>{b.name}</Button>
                          <Button color="secondary" key={b.selector +"_r"} onClick={() => toggleChartDimension(b.selector + "_r")} active={chartDimensions.indexOf(b.selector+ "_r") !== -1}>&gt;</Button>
                        </ButtonGroup>
                    }
                    return null;
                  })}
                </ButtonGroup>
              </CardBody>
              <ResponsiveContainer aspect={3}>
                {chartType === "line" ? <LineChart
                  data={chartData}
                  margin={{
                    top: 10, right: 30, left: 30, bottom: 30,
                  }}
                >
                  <CartesianGrid strokeDasharray="1 1" />
                  <XAxis dataKey="date" height={60} tick={<CustomizedAxisTick />} />
                  <YAxis yAxisId="left" label={label.left} />
                  <YAxis yAxisId="right" orientation="right" label={label.right}/>
                  <Tooltip />
                  <Legend />
                  {chartDimensions.map((dimension, idx) => {
                    let color = ["#82ca9d", "#fb6340", "#5e72e4", "#2dce89", "#f5365c", "#172b4d", "#11cdef", "#5e72e4", "#8884d8",];
                    if ( dimension.endsWith("_r")) {
                      return <Line key={idx} yAxisId="right" type="monotone" dataKey={dimension.replace("_r","")} stroke={color[idx % color.length]}>
                                  {/*<LabelList dataKey={dimension.replace("_r","")} position="insideTop" angle="45"  />*/}
                              </Line>  
                    }
                    return <Line key={idx} yAxisId="left" type="monotone" dataKey={dimension} stroke={color[idx % color.length]} />
                  })}
                </LineChart> :
                  <BarChart
                    data={chartData}
                    margin={{
                      top: 30, right: 30, left: 0, bottom: 30,
                    }}
                  >
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {chartDimensions.map((dimension, idx) => {
                      let color = ["#8884d8", "#82ca9d", "#172b4d", "#5e72e4", "#f4f5f7", "#11cdef", "#2dce89", "#f5365c", "#fb6340"];
                      return <Bar key={idx} type="monotone" dataKey={dimension} fill={color[idx % color.length]} />
                    })}
                  </BarChart>}

              </ResponsiveContainer>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ReportPage;
