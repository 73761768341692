import React, { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
// import sw from 'stopword';
// import stringSimilarity from "string-similarity";
// import cx from "classnames";

import Spinner from "../../../components/Spinner";

import { API } from "aws-amplify";

import {
	Button,
	Card,
	CardHeader,
	CardBody,
	FormGroup,
	Label,
	Form,
	Input,
	Container,
	Row,
	Col,
	Table,
	Modal,
	ModalHeader,
	ModalBody,
	Progress
} from "reactstrap";

import FixImageModal from "./imageFixModal";

const FacebookBulkTool = (props) => {

	// const [listOfKeywords, setListOfKeywords] = useState([]);
	const [uploadCsvText, setUploadCsvText] = useState("");

	const handleUploadCsvTextChange = (e) => {
		setUploadCsvText(e.target.value);
	}


	const [newKeywordList, setNewKeywordList] = useState("");

	const handleNewKeywordListChange = (e) => {
		setNewKeywordList(e.target.value);
	}
	
	const [isLoading, setIsLoading] = useState(false);

	// const loadListOfKeywords = async () => {
	// 	setIsLoading(true);
	// 	const listOfAllimages = await Storage.get("input_market_keyword.txt", { download: true });
	// 	setListOfKeywords(listOfAllimages.Body.toString("utf8").split("\n"));
	// 	setIsLoading(false);
	// }

	// useEffect(() => {
	// 	loadListOfKeywords();
	// },[])

	const listS3Thumbs = async (folder, continuationToken=false, list=[]) => {
		var params = {};
		if ( continuationToken ) {
			params.ContinuationToken = continuationToken;
		}
		let results = await Storage.list(folder, params);
		list = [...list, ...results.list];
		if ( results.NextContinuationToken ) {
			return await listS3Thumbs(folder, results.NextContinuationToken, list)
		}
		return list;
	}


	const searchInMarket = async (market, keyword, continuationToken=false, list=[]) => {
		var params = {};
		if ( continuationToken ) {
			params.ContinuationToken = continuationToken;
		}
		let results = await Storage.list(market.toUpperCase()+"/"+keyword, params);
		list = [...list, ...results.list];
		if ( results.NextContinuationToken ) {
			return await searchInMarket(market, keyword, results.NextContinuationToken, list)
		}
		// console.log("searchInUk", list);
		let suggestionsMap = list.reduce((suggestions, s3path) => {
			let [market, suggestion] = s3path.key.split("/");
			suggestions[market + "/" + suggestion] = suggestions[market + "/" + suggestion] || [];
			suggestions[market + "/" + suggestion].push(s3path.key);
			return suggestions;
		}, {});
		return suggestionsMap;
	}


	const translate = async (market, keyword, translateTo) => {
		let {TranslatedText=false} = await API.get('customAPI', '/translate', {
			queryStringParameters: {
				market: market.toLowerCase(),
				keyword,
				translateTo: translateTo.toLowerCase() 
			}
		});
		return TranslatedText;
	}

	const [imageLookup, setImageLookup] = useState({});

	const getKeywordRelated = async (market, keyword) => {
		let response = await API.get('mlAPI', '/related-keyword', {
			queryStringParameters: {
				market: market.toLowerCase(),
				keyword,
				count:20
			}
		});
		return response.related.map((r) => r.response);
		// console.log(response);
	}
		
	const execImageLookup = async () => {
		setIsLoading(true);
		let imageLookup = {};
		const inputList = uploadCsvText.split("\n").map((row) => row.split(",").map((el) => el.trim()))
		
		for ( let [market=false,keyword=false] of inputList ) {
			if ( market === false || keyword === false ) {
				continue;
			}
			imageLookup[market] = imageLookup[market] || {};
			imageLookup[market][keyword] = imageLookup[market][keyword] || {
				"keyword": keyword,
				"market": market,
				"lookupKeyword": "",
				"suggestions": [],
				"images": []
			};
			
			const listImageFound = await listS3Thumbs(market.toUpperCase() + "/" + keyword.toLowerCase() + "/");
			
			if ( listImageFound.length <= 2 ) {
				

				// TRANSLATE KEYWORD AND LOOKUP FOR IMAGES
				const marketLookup = ["uk", "it", "es", "de", "fr", "nl"];
				let keywordTranslated = {};
				
				for (let translateTo of marketLookup ) { 
					if ( Object.keys(imageLookup[market][keyword]["suggestions"]).length >= 20 ) {
						break;
					}
					if ( translateTo !== market ) {
						let keywordToSearch = await translate(market, keyword, translateTo);
						if ( keywordToSearch ) {
							keywordTranslated[market] = keywordToSearch;
							console.log("TRANSLATE " + keyword + " in " + translateTo + " - LOOKING FOR IMAGES: " + translateTo + "/" + keywordToSearch);
							let relatedImages = await searchInMarket(translateTo, keywordToSearch + "/");
							imageLookup[market][keyword]["suggestions"] = {...imageLookup[market][keyword]["suggestions"], ...relatedImages};
						}
					}
				}

				if ( Object.keys(imageLookup[market][keyword]["suggestions"]).length <= 20 ) {
					
					let relatedKeyword = await getKeywordRelated(market, keyword);
					// console.log(relatedKeyword);
			
					imageLookup[market][keyword]["relatedKeyword"] = relatedKeyword;
					for ( let rk of relatedKeyword ) {

						if ( Object.keys(imageLookup[market][keyword]["suggestions"]).length >= 20 ) {
							break;
						}

						console.log("Related Keyword: " + rk + " TO " + keyword + " - LOOKING FOR IMAGES: " + rk);
						let rkImages = await searchInMarket(market, rk + "/");
						imageLookup[market][keyword]["suggestions"] = {...imageLookup[market][keyword]["suggestions"], ...rkImages};
					}
				}

				// if ( Object.keys(imageLookup[market][keyword]["suggestions"]).length <= 20 ) {
					// try the translation of the related in english only!
					// for ( let market of Object.keys(keywordTranslated) ) {
					// 	for ( let keyword of keywordTranslated[market] ) {
					// 		let relatedKeyword = await getKeywordRelated(market, keyword);
					// 		for ( let rk of relatedKeyword ) {
					// 			if ( Object.keys(imageLookup[market][keyword]["suggestions"]).length >= 20 ) {
					// 				break;
					// 			}
					// 			console.log("Related Keyword: " + rk + " TO " + keyword + " - LOOKING FOR IMAGES: " + rk);
					// 			let [searchMkt, searchKwd] = rk.split("/");
					// 			let rkImages = await searchInMarket(searchMkt, searchKwd);
					// 			imageLookup[market][keyword]["suggestions"] = {...imageLookup[market][keyword]["suggestions"], ...rkImages};
					// 		}
					// 	}
					// }
				// }
				
			} else {
				imageLookup[market][keyword]["images"] = listImageFound;
			}
		}
		// console.log(imageLookup);
		setImageLookup({...imageLookup});
		setIsLoading(false);
	}


	const copyImages = async (market, keyword, origins, replace ) => {
		// setIsLoading(true);
		// console.log(market, keyword, origins);
		const getBlob = async (fileUri) => {
			const resp = await fetch(fileUri);
			const imageBody = await resp.blob();
			return imageBody;
		};
		setAlertMsg(prev => [...prev, `IMAGE UPLOAD TO ${market.toUpperCase()}/${keyword.toLowerCase()}/ STARTING`]);
		
		const lastImage = await Storage.list(market.toUpperCase()+"/"+keyword.toLowerCase()+"/");
		let imageIdx = lastImage.list.length + 1;
		if ( replace ) {
			imageIdx = 1;
		}

		for ( let key of origins ) {

			setAlertMsg(prev => [...prev, `UPLOADING IMAGE TO ${market.toUpperCase()}/${keyword.toLowerCase()}/${imageIdx}`]);

			console.log(`COPY https://s3-eu-west-1.amazonaws.com/static.blogger.co.uk/thumbs/${encodeURIComponent(key)}`);
			
			const blob = await getBlob(`https://s3-eu-west-1.amazonaws.com/static.blogger.co.uk/thumbs/${encodeURIComponent(key)}`);

			console.log(`COPY TO ${market.toUpperCase()}/${keyword.toLowerCase()}/${imageIdx}`);

			await Storage.put(`${market.toUpperCase()}/${keyword.toLowerCase()}/${imageIdx}`, blob, {
				contentType: blob.type,
				ACL: 'public-read',
				level: 'public',
				progressCallback(progress) {
					setPercentage((progress.total/progress.loaded)*100);
					console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
				}
			});
			imageIdx++;
		}
	}

	const getDepositPhotos = async (imageID) => {
		let response = await API.get('customAPI', '/image-tool/download/depositphotos', {
			queryStringParameters: {
				depositPhotoID: imageID
			}
		});
		return response.downloadLink || false;
	}

	const uploadNewImages = async (market, keyword, newImages, replace ) => {
		// console.log(selectedImages);
		const getBlob = async (fileUri) => {
			const resp = await fetch(fileUri);
			console.log(fileUri, resp);
			const imageBody = await resp.blob();
			console.log(imageBody);
			return imageBody;
		};
		
		setAlertMsg(prev => [...prev, `IMAGE UPLOAD TO ${market.toUpperCase()}/${keyword.toLowerCase()}/ STARTING`]);
		const blobFiles = [];
		for ( let imageURI of newImages ) {
			if ( imageURI.startsWith("depositphotos:") ) {
        let [infoPath,] = imageURI.split('---');
        let depositPhotoID = infoPath.replace("depositphotos:", "");
        imageURI = await getDepositPhotos(depositPhotoID);
        if ( imageURI === false ) {
        	setAlertMsg(prev => [...prev, `ERROR UPLOADING DEPOSITPHOTO ID: ${depositPhotoID} SKIPPING!`]);
        	continue;
        }
      } 
			blobFiles.push(await getBlob(imageURI));
		}
		const lastImage = await Storage.list(market.toUpperCase()+"/"+keyword.toLowerCase()+"/");
		let imageIdx = lastImage.list.length + 1;
		
		if ( replace ) {
			imageIdx = 1;
		}

		for ( let blob of blobFiles ) {
			setAlertMsg(prev => [...prev, `UPLOADING IMAGE TO ${market.toUpperCase()}/${keyword.toLowerCase()}/${imageIdx}`]);
			await Storage.put(market.toUpperCase()+"/"+keyword.toLowerCase()+"/" + imageIdx, blob, {
				contentType: blob.type,
				ACL: 'public-read',
				level: 'public',
				progressCallback(progress) {
					setPercentage((progress.total/progress.loaded)*100);
					console.log(`Uploaded: ${progress.loaded}/${progress.total} ${progress.total/progress.loaded|0}%`);
				}
			});
			setPercentage(0);
			imageIdx++;
		}
	}

	const uploadLocalFiles = async (market, keyword, files, replace ) => {
		
		setAlertMsg(prev => [...prev, `LOCAL IMAGEs UPLOAD TO ${market.toUpperCase()}/${keyword.toLowerCase()}/ STARTING`]);
		
		const lastImage = await Storage.list(market.toUpperCase()+"/"+keyword.toLowerCase()+"/");
		let imageIdx = lastImage.list.length + 1;
		if ( replace ) {
			imageIdx = 1;
		}

		for ( let blob of files ) {

			setAlertMsg(prev => [...prev, `UPLOADING LOCAL IMAGE ${blob.name} TO ${market.toUpperCase()}/${keyword.toLowerCase()}/${imageIdx}`]);

			await Storage.put(`${market.toUpperCase()}/${keyword.toLowerCase()}/${imageIdx}`, blob, {
				contentType: blob.type,
				ACL: 'public-read',
				level: 'public',
				progressCallback(progress) {
					setPercentage((progress.total/progress.loaded)*100);
					console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
				}
			});
			imageIdx++;
		}
	}


	const [localFiles, setLocalFiles] = useState([]);
	const selectLocalFile = (files, toggle) => {
		let newDropzoneFiles = [...localFiles];
		for ( let f = 0; f < files.length; f++ ) {
			let file = files[f];
			let fileAlreadyExists = false;
			for (let i = 0; i < localFiles.length; i++) {
				let droppedFile = localFiles[i];
				if (droppedFile.name === file.name) {
					fileAlreadyExists = true;
					if (toggle)
						newDropzoneFiles.splice(i, 1);
				}
			}
			if (!fileAlreadyExists) {
				newDropzoneFiles.push(file);
			}
		};
		setLocalFiles(newDropzoneFiles);
	}


	// STEP IN THE FIX IMAGE MODAL!
	let [step, setStep] = useState(1);
	const fixImages = async (selectedImages, selectedKeywords, image, localFiles, newKeywordList, replace=false ) => {
		setIsLoading(true);

		toggleImageFixModal();
		let imagesFromS3 = selectedImages.filter((k) => k.startsWith("s3://")).map((s) => s.replace("s3://",""));
		let imagesFromApi = selectedImages.filter((k) => !k.startsWith("s3://"));
		
		await copyImages(image.market, image.keyword, imagesFromS3, replace);
		await uploadNewImages(image.market, image.keyword, imagesFromApi, replace);
		await uploadLocalFiles(image.market, image.keyword, localFiles, replace);

		for ( let selectedKeyword of selectedKeywords ) {
			let [market,keyword] = selectedKeyword.split("/");

			let listOldImg = await listS3Thumbs(market.toUpperCase() + "/" + keyword.toLowerCase() + "/");
			for ( let img of listOldImg ) {
				console.log("REMOVE " + img.key);
				await Storage.remove(img.key);
			}
			
			await copyImages(market, keyword, imagesFromS3, replace);
			await uploadNewImages(market, keyword, imagesFromApi, replace);
			await uploadLocalFiles(market, keyword, localFiles, replace);
		}

		newKeywordList = newKeywordList.split("\n");
		for ( let rowKeyword of newKeywordList ) {
			let [market=false, keyword=false] = rowKeyword.split(",");
			if (market && keyword) {
				market = market.trim();
				keyword = keyword.trim();

				let listOldImg = await listS3Thumbs(market.toUpperCase() + "/" + keyword.toLowerCase() + "/");
				for ( let img of listOldImg ) {
					console.log("REMOVE " + img.key);
					await Storage.remove(img.key);
				}
				
				await copyImages(market, keyword, imagesFromS3, replace);
				await uploadNewImages(market, keyword, imagesFromApi, replace);
				await uploadLocalFiles(market, keyword, localFiles, replace);
			}

		}

		setIsLoading(false);
		await reloadTable(image.market, image.keyword);
	}

	const reloadTable = async (market, keyword) => {
		let listImageFound = await listS3Thumbs(market.toUpperCase() + "/" + keyword.toLowerCase() + "/");
		let newImageLookup = {...imageLookup};
		newImageLookup[market][keyword] = {
			"keyword": keyword,
			"market": market,
			"lookupKeyword": "",
			"suggestions": [],
			"images": []
		};
		newImageLookup[market][keyword]["images"] = listImageFound;
		setImageLookup({...newImageLookup});
	}



	const [imagesFromApi, setImagesFromApi ] = useState(false);
	const [isLoadingNewImages, setIsLoadingNewImages] = useState(false);
	const [pagination, setPagination] = useState({});

	// useEffect(() => {
	//   console.log("PAIGNATION CHANGED", pagination);
	//   searchForImages()
	// }, [pagination["pexels"]])
	// useEffect(() => {
	//   console.log("PAIGNATION CHANGED", pagination);
	// }, [pagination["pexels"]])
	// useEffect(() => {
	//   console.log("PAIGNATION CHANGED", pagination);
	// }, [pagination["pexels"]])
	// useEffect(() => {
	//   console.log("PAIGNATION CHANGED", pagination);
	// }, [pagination["pexels"]])

	const loadImages = async (source, keyword, page=1) => {

		let imagesFromSource = await API.get('customAPI', '/image-tool/search/'+source, {
			queryStringParameters: {
				query: keyword,
				page: page,
				perPage: 20
			}
		});
		return imagesFromSource;
	}

	const searchForImages = async (keyword, source=false, page=1) => {
		setIsLoadingNewImages(true);

		console.log("SEARCH FOR " + keyword, source);
		
		if ( source === false ) {
			let images = await Promise.all([
				loadImages('pexels', keyword), 
				loadImages('pixabay', keyword),
				loadImages('unsplash', keyword),
				loadImages('flickr', keyword),
				loadImages('depositphotos', keyword)
			]);
			setImagesFromApi({
				"pexels": images[0],
				"pixabay": images[1],
				"unsplash": images[2],
				"flickr": images[3],
				"depositphotos": images[4]
			});
		} else {
			let imagesFromASource = await loadImages(source, keyword, page);
			setImagesFromApi({...imagesFromApi, ...{[source]: imagesFromASource}});
		}
		setIsLoadingNewImages(false)
	}


	const [selectedImages, setSelectedImages] = useState([]);
	const selectImage = (url) => {
		// console.log(img);
		let imageIndex = selectedImages.indexOf(url);
		if ( imageIndex === -1 ) {
			setSelectedImages([...selectedImages, url]);  
		} else {
			selectedImages.splice(imageIndex, 1);
			setSelectedImages([...selectedImages]);
		}
	};

	const [selectedKeywords, setSelectedKeywords] = useState([]);
	const selectKeyword = (url) => {
		// console.log(img);
		let imageIndex = selectedKeywords.indexOf(url);
		if ( imageIndex === -1 ) {
			setSelectedKeywords([...selectedKeywords, url]);  
		} else {
			selectedKeywords.splice(imageIndex, 1);
			setSelectedKeywords([...selectedKeywords]);
		}
	};

	const [imageToFix, setImageToFix] = useState(false);
	const [imageFixModal, setImageFixModal] = useState(false);
	const toggleImageFixModal = () => {
		setImagesFromApi(false);
		setSelectedImages([]);
		setSelectedKeywords([]);
		setPagination({});
		setStep(1);
		setImageFixModal(!imageFixModal);
		setNewKeywordList("");
	}



	const [alertMsg, setAlertMsg] = useState([]);
	const [percentage, setPercentage] = useState(0);
	const onDismissAlert = () => {
		setAlertMsg([]);
	};

	return (
		<Container className="mt-2" fluid>
			<Row>
				<Col>
					<Card className="bg-secondary shadow">
						<CardHeader className="border-0">
							<Row className="align-items-center">
								<Col xs="8">
									<h3 className="mb-0">IMAGE BULK TOOL</h3>
								</Col>
							</Row>
						</CardHeader>
						<CardBody>
							<Spinner loading={isLoading}>
								<Table className="align-items-center table-flush table-striped table-sm" responsive>
									<tbody>
										{Object.keys(imageLookup).map((market) => {
												return Object.keys(imageLookup[market]).map((keyword, idx) => {
													// if ( imageLookup[market][keyword]["images"].length > 0  ) {
													//   return 
													// }
													// console.log(imageLookup[market][keyword]);
													let classNameForInfo = imageLookup[market][keyword]["images"].length > 0 
																								? "bg-green" 
																								: Object.keys(imageLookup[market][keyword]["suggestions"]).length > 0 
																									? "bg-warning"
																									: "bg-danger";


													let columnInfo = imageLookup[market][keyword]["images"].length > 0 
																	? `${imageLookup[market][keyword]["images"].length} images found!`
																	: `${Object.keys(imageLookup[market][keyword]["suggestions"]).length} related keywords`


													return <tr key={idx}>
														<td>
															{market}
														</td>
														<td className={classNameForInfo} style={{color: "white"}}>
															{keyword} <span className="float-right">{columnInfo}</span>
														</td>
														<td>
															{imageLookup[market][keyword]["images"].length === 0 
																? <i className="fas fa-wrench" style={{cursor:"pointer"}} onClick={(e) => {setImageToFix(imageLookup[market][keyword]); toggleImageFixModal();}}> FIX ME!</i>
																: <span>OK!</span>}
														</td>
													</tr>
												})
											})
										}
									</tbody>
								</Table>
								<Form>
									<Row className="mt-4">
										<Col md={{size: 6,offset: 3}}>
											 <FormGroup>
												<Label htmlFor="upload-csv-text">Market, Keyword</Label>
												<Input
													placeholder="market,keyword"
													type="textarea"
													rows="10"
													name="upload-csv-text"
													id="upload-csv-text"
													value={uploadCsvText}
													onChange={handleUploadCsvTextChange}
												/>
											</FormGroup>
										</Col>
									</Row>
									<div className="text-center">
											<Button className="mt-4" color="primary" type="button" onClick={() => execImageLookup()} >
												LOOKUP IMAGEs
											</Button>
									</div>
								</Form>
							</Spinner>
						</CardBody>
					</Card>
				</Col>
			</Row>


			<FixImageModal image={imageToFix} isOpen={imageFixModal} toggle={toggleImageFixModal} 
												isLoading={isLoading} fixImages={fixImages} selectImage={selectImage} 
												selectedImages={selectedImages} searchForImages={searchForImages}
												isLoadingNewImages={isLoadingNewImages} imagesFromApi={imagesFromApi} 
												selectedKeywords={selectedKeywords} selectKeyword={selectKeyword} step={step} setStep={setStep}
												setPagination={setPagination} pagination={pagination} localFiles={localFiles}
												selectLocalFile={selectLocalFile} newKeywordList={newKeywordList}
												handleNewKeywordListChange={handleNewKeywordListChange} />



			<Modal isOpen={!!alertMsg.length} toggle={onDismissAlert} size="lg" className="full-screen">
				<ModalHeader className="pb-0" toggle={onDismissAlert}>IMAGE STATUS</ModalHeader>
				<ModalBody>
					<Spinner loading={isLoading}>
						<h4 className="alert-heading">Success! Your images were uploaded!</h4>
					</Spinner>
					{isLoading && <hr/>}
					{isLoading && alertMsg && alertMsg.map((url, idx) =>
						<div key={`${url}-${idx}`}>
							{url}
						</div>
					)}
					{isLoading && <div className="text-center">{percentage}%</div>}
					{isLoading && <Progress value={percentage} max="100" />}
				</ModalBody>
			</Modal>



		</Container>
	);
}

export default FacebookBulkTool;
