import React, {useState, useEffect} from "react";
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button,  Form, FormText, FormGroup,
  Label, Input, InputGroupText, InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, InputGroup, InputGroupAddon, ListGroupItem, ListGroup } from "reactstrap";

const FbRuleModal = (props) => {
  const { fbRuleModal, toggleNewRuleModal } = props;

  const { fbAccounts } = props;
  
  const fbByUtmCampaign = fbAccounts.reduce((acc, row) => {
    if ( row.utm_campaign ) {
      acc[row.utm_campaign] = acc[row.utm_campaign] || [];
      acc[row.utm_campaign].push(row);
    }
    return acc;
  }, {});

  const fbByDomain = fbAccounts.reduce((acc, row) => {
    if ( row.domain ) {
      acc[row.domain] = acc[row.domain] || [];
      acc[row.domain].push(row);
    }
    return acc;
  }, {});

  const fbByBuyingStrategy = fbAccounts.reduce((acc, row) => {
    if ( row.buying_strategy ) {
      acc[row.buying_strategy] = acc[row.buying_strategy] || [];
      acc[row.buying_strategy].push(row);
    }
    return acc;
  }, {});

  // const fbBusinessManager = fbAccounts.reduce((acc, row) => {
  //   if ( row.bm ) {
  //     acc[row.bm] = acc[row.bm] || [];
  //     acc[row.bm].push(row);
  //   }
  //   return acc;
  // }, {});

  const fbAccountsByActivity = fbAccounts.reduce((acc, row) => {
    if ( row.activity ) {
      acc[row.activity] = acc[row.activity] || [];
      acc[row.activity].push(row);
    }
    return acc;
  }, {});

  const [ruleName, setRuleName] = useState("");
  const [ruleBid, setRuleBid] = useState(0);
  const [ruleBidCol, setRuleBidCol] = useState("bid");
  const [ruleAction, setRuleAction] = useState("PAUSE")

  const [ruleWhenToRestart, setRuleWhenToRestart] = useState("");

  const [ruleSkipIfLearning, setRuleSkipIfLearning] = useState(false);

  const [ruleActivity, setRuleActivity] = useState("");
  const [fbaccounts, setFbAccounts] = useState([]);
  const addFbAccount = (e) => {
    const selectedOptions = [...e.target.selectedOptions].map(o => o.value);
    setFbAccounts(selectedOptions)
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);

  const [conditions, setConditions] = useState([]);
  const [ruleApplyTo, setRuleApplyTo] = useState("all");

  const ruleConditionPeriod = {
    "today":"Today",
    "yesterday":"Yesterday",
    "last2days": "Last two days: condition must be true every single day",
    "last3days":"Last three days: condition must be true every single day",
    "last7days":"Last 7 days: condition must be true every single day",
    "lifetime":"Lifetime: condition must be true every single day",
    "aggr2days": "Sum of last two days: condition must be true after aggregating",
    "aggr3days": "Sum of last three days: condition must be true after aggregating",
    "aggr7days": "Sum of last 7 days: condition must be true after aggregating"
  };

  
  const [condition, setCondition] = useState({dimension: "", op: ">", value: 0, period: ""});
  const addCondition = (e) => {
    e.preventDefault();
    setConditions([...conditions, condition]);
    console.log(conditions);
    setCondition({dimension: "", op: ">", value: 0, period: ""});
  }

  const [ruleSchedule, setRuleSchedule] = useState("DAILY");

  const [ruleLearningPhase, setRuleLearningPhase] = useState("");

  const reset = () => {
    setRuleName("");
    setRuleAction("PAUSE");
    setFbAccounts([]);
    setConditions([]);
    setCondition({dimension: "", op: ">", value: 0, period: ""});
    setRuleSchedule("DAILY");
    setRuleScheduleTimeOpts([]);
    setRuleScheduleTime("");
    setRuleLearningPhase("");
    setRuleTimeRange("");
    setDimensions([]);
    setBuyingStrategy(false);
    setFbAccountDomain(false);
    setFbAccountUtmCampaign(false);
  }

  const [dimensions, setDimensions] = useState([]);
  useEffect(() => {
    let activityFilter = ruleActivity;
    if ( activityFilter === "techads_iacbing2" ) {
      activityFilter = "techads_bing";
    }

    if (props.CONFIG[activityFilter] && props.CONFIG[activityFilter].DIMENSIONS_INIT) {
      let newdimensions = props.CONFIG[activityFilter].DIMENSIONS_INIT.map((d) => ({
        name: d.name,
        selector: d.selector
      }));
      setDimensions(newdimensions);
    }
  }, [ruleActivity])


  const [RULE_SCHEDULE_TIME_OPTs, setRuleScheduleTimeOpts] = useState({});

  useEffect(() => {
    let timeOpts = Object.keys(props.times).reduce((acc, f) => {
      if ( ruleSchedule === "DAILY" && f.startsWith("d") ) {
        acc[f] = props.times[f];
      } else if ( ruleSchedule === "HOURLY" && !f.startsWith("d") ) {
        acc[f] = props.times[f];
      }
      acc[""] = props.times[""]
      return acc;
    }, {});
    setRuleScheduleTimeOpts(timeOpts);
  }, [ruleSchedule])


  const [buying_strategy, setBuyingStrategy] = useState(false);
  const [fbdomain, setFbAccountDomain] = useState(false);
  const [fbUtmCampaign, setFbAccountUtmCampaign] = useState(false);
  
  const [ruleScheduleTime, setRuleScheduleTime] = useState("");
  const [ruleTimeRange, setRuleTimeRange] = useState("");

  const [ruleWhenToRestartUpdateCol, setRuleWhenToRestartUpdateCol] = useState("budget");
  const [ruleWhenToRestartValueCol, setRuleWhenToRestartValueCol] = useState(0);
  const [dropdownRestartRule, setDropdownRestartRule] = useState(false);
    
  const toggleDropDownRestartRule = () => setDropdownRestartRule(!dropdownRestartRule);

  const [update_pause_and_restart, setUpdatePauseAndRestart] = useState(false)

  return (
    <Modal isOpen={fbRuleModal} toggle={toggleNewRuleModal} size="lg">
      <ModalHeader className="pb-1" toggle={toggleNewRuleModal}>Create a rule!</ModalHeader>
      <ModalBody className="pt-1">
        <Form>
          <Row form>
            <Col md={3}>
              <FormGroup>
                <Label for="fbAccount">Activity *: </Label>
                <Input bsSize="sm" type="select" name="ruleActivity" id="ruleActivity" onChange={(e) => setRuleActivity(e.target.value)} value={ruleActivity}>
                  <option key="empty-activity" value="">----</option>
                  {Object.keys(fbAccountsByActivity).map((activity,i) => <option key={`activity-${i}`} value={activity}>{activity}</option>)}
                </Input>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="fbAccount">Buying Strategy: </Label>
                <Input bsSize="sm" type="select" name="buying_strategy" id="buying_strategy" onChange={(e) => setBuyingStrategy(e.target.value)} value={buying_strategy}>
                  <option key="empty-buying_strategy" value="">----</option>
                  {Object.keys(fbByBuyingStrategy).map((buying_strategy,i) => <option key={`buying_strategy-${i}`} value={buying_strategy}>{buying_strategy}</option>)}
                </Input>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="fbAccount">Domain: </Label>
                <Input bsSize="sm" type="select" name="domain" id="domain" onChange={(e) => setFbAccountDomain(e.target.value)} value={fbdomain}>
                  <option key="empty-domain" value="">----</option>
                  {Object.keys(fbByDomain).map((domain,i) => <option key={`domain-${i}`} value={domain}>{domain}</option>)}
                </Input>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="fbAccount">UTM Campaign: </Label>
                <Input bsSize="sm" type="select" name="utmCampaign" id="utmCampaign" onChange={(e) => setFbAccountUtmCampaign(e.target.value)} value={fbUtmCampaign}>
                  <option key="empty-utmCampaign" value="">----</option>
                  {Object.keys(fbByUtmCampaign).map((utmCampaign,i) => <option key={`utmCampaign-${i}`} value={utmCampaign}>{utmCampaign}</option>)}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="fbAccount">Account: </Label>
            <Input type="select" name="fbaccounts" id="fbAccount" multiple onChange={addFbAccount} value={fbaccounts} rows={10}>
              {props.fbAccounts.filter((f) => {
                if (f.activity === ruleActivity) {
                  if ( buying_strategy && f.buying_strategy !== buying_strategy ) {
                    return false;
                  }
                  if ( fbdomain && f.domain !== fbdomain ) {
                    return false;
                  }
                  if ( fbUtmCampaign && f.utm_campaign !== fbUtmCampaign ) {
                    return false;
                  }
                  return true;
                } 
                return false;
              }).map((f,i) => <option key={`account-${i}`} value={f.id}>{f.bm} - {f.name}</option>)}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="ruleName">Rule Name: </Label>
            <Input type="text" name="ruleName" id="ruleName" placeholder="Rule name" onChange={(e) => setRuleName(e.target.value)} value={ruleName} />
          </FormGroup>
          <FormGroup>
            <Label for="ruleAction">Action: </Label>
            <Input type="select" name="ruleAction" id="ruleAction" placeholder="Action" onChange={(e) => setRuleAction(e.target.value)} value={ruleAction}>
              <option value="PAUSE">PAUSE</option>
              <option value="PAUSE_RESTART">PAUSE AND RESTART</option>
              <option value="BID">BID</option>
              <option value="BUDGET">BUDGET</option>
            </Input>
          </FormGroup>
          {ruleAction === "PAUSE_RESTART" ?
          <div style={{marginBottom:"4px"}}><FormGroup>
            <Label for="ruleName">When to restart:</Label>
            <FormText color="muted">
                <ul>
                  <li>+2 two days from today</li>
                  <li>*2021-08-18, i.e Restart on the 18 of August</li>
                </ul>
              </FormText>
            <Input type="text" name="when_to_restart" placeholder="i.e +1d or YYYY-MM-DD" 
                onChange={(e) => setRuleWhenToRestart(e.target.value)} value={ruleWhenToRestart} />
            </FormGroup>
            <FormGroup check inline>
              <Label check><b>Modify on restart: </b></Label>&nbsp;
              <Label check>
                <Input type="radio" name="update_pause_and_restart" value={true} onChange={() => setUpdatePauseAndRestart(false)} required defaultChecked/>No
              </Label>
            </FormGroup>
            <FormGroup check inline>
              <Label check>
                <Input type="radio" name="update_pause_and_restart" value={false} onChange={() => setUpdatePauseAndRestart(true)} required/>Yes
              </Label>
            </FormGroup>
            {update_pause_and_restart && <FormGroup>
              <ul>
                <li>+10%, -10%, i.e add/remove percentage of current {ruleWhenToRestartUpdateCol}</li>
                <li>10$, -10$, i.e add/remove 10$ to current {ruleWhenToRestartUpdateCol}</li>
                <li>*3, i.e Set {ruleWhenToRestartUpdateCol} to 3 dollars</li>
              </ul>
              <InputGroup>
                <InputGroupButtonDropdown addonType="append" isOpen={dropdownRestartRule} 
                    toggle={() => toggleDropDownRestartRule()}>
                  <DropdownToggle caret>{ruleWhenToRestartUpdateCol}</DropdownToggle>
                  <DropdownMenu>
                    {['bid', 'budget'].map(op => <DropdownItem key={op} onClick={() => setRuleWhenToRestartUpdateCol(op)}>{op}</DropdownItem>)}
                  </DropdownMenu>
                </InputGroupButtonDropdown>&nbsp;
                <Input onChange={(e) => setRuleWhenToRestartValueCol(e.target.value)} value={ruleWhenToRestartValueCol}/>
              </InputGroup>
            </FormGroup>}
          </div>
          : null}


          {ruleAction === "BID" &&
            <FormGroup>
              <Label for="ruleName">Bid change:</Label>
              <FormText color="muted">
                <ul>
                  <li>10% of current bid or rpc</li>
                  <li>100% of current bid or rpc</li>
                  <li>120% of current bid or rpc</li>
                </ul>
              </FormText>
              <InputGroup>
                <Input type="text" name="bid" placeholder="i.e +10%, -10%" onChange={(e) => setRuleBid(e.target.value)} value={ruleBid} />
                <InputGroupAddon addonType="append">
                  <InputGroupText>OF</InputGroupText>
                </InputGroupAddon>
                <InputGroupAddon addonType="append">
                  <Input type="select" name="bidChangeLevel" id="bidChangeLevel" 
                    onChange={(e) => setRuleBidCol(e.target.value)} value={ruleBidCol}>
                    <option value="bid">CURRENT BID</option>
                    <option value="rpc">RPC</option>
                  </Input>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          }
          {ruleAction === "BUDGET" ?
          <FormGroup>
            <Label for="ruleName">Budget change:</Label>
            <FormText color="muted">
                <ul>
                  <li>+10%, -10%, i.e add/remove percentage of current budget</li>
                  <li>10$, -10$, i.e add/remove 10$ to current budget</li>
                  <li>*3, i.e Set budget to 3 dollars</li>
                </ul>
              </FormText>
            <Input type="text" name="budget" placeholder="i.e +10%, -10% or +10$,-10$, *3" onChange={(e) => setRuleBid(e.target.value)} value={ruleBid} />
          </FormGroup> : null}
          <Row form className="bg-secondary p-2">
            <Col md={12}>
            <FormGroup>
              <Label>Conditions: </Label>
              <InputGroup>
                <Input type="select" name="dimension" id="dimension" value={condition.dimension} onChange={(e) => setCondition({...condition,dimension:e.target.value})}>
                   <option key={-1}>----</option>
                   {dimensions.map((dimension) => <option key={dimension.selector} value={dimension.selector}>{dimension.name}</option>)}
                </Input>
                <InputGroupButtonDropdown addonType="append" isOpen={dropdownOpen} 
                    toggle={() => toggleDropDown()}>
                  <DropdownToggle caret>{condition.op}</DropdownToggle>
                  <DropdownMenu>
                    {['>', '>=', "=", "<", "<="].map(op => <DropdownItem key={op} onClick={() => setCondition({...condition, op:op})}>{op}</DropdownItem>)}
                  </DropdownMenu>
                </InputGroupButtonDropdown>
                <InputGroupAddon addonType="append">
                   <Input onChange={(e) => setCondition({...condition,value:e.target.value})} value={condition.value}/>
                </InputGroupAddon>
                <InputGroupAddon addonType="append">
                   <Button color="primary" onClick={addCondition} disabled={condition.dimension === ""}>Add!</Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <ListGroup>
                {conditions.map((c, idx) => <ListGroupItem  key={`cond-${idx}`}>{ruleConditionPeriod[c.period]} {c.dimension}{c.op}{c.value}</ListGroupItem>)}
              </ListGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Label>Time Range: </Label>
              <FormGroup>
                <Input type="select" name="ruleConditionPeriod" id="ruleConditionPeriod" onChange={(e) => setRuleTimeRange(e.target.value)} value={ruleTimeRange}>
                  <option>---</option>
                  {Object.keys(ruleConditionPeriod).filter((conditionVal) => {
                    if ( ruleSchedule === "DAILY" && conditionVal === "today" ) {
                      return false;
                    }
                    return true;
                  }).map((conditionVal) => <option key={conditionVal} value={conditionVal}>{ruleConditionPeriod[conditionVal]}</option>)}
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <Label>Apply to: </Label>
              <FormGroup check>
                <Label check>
                  <Input type="radio"  name="ruleApplyTo" value="all" checked={ruleApplyTo==="all" ? true : false} onChange={(e) => setRuleApplyTo(e.target.value)} />
                  All campaigns
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                   <Input type="radio"  name="ruleApplyTo" value="new" checked={ruleApplyTo==="new" ? true : false} onChange={(e) => setRuleApplyTo(e.target.value)} />
                   New campaigns ( campaigns with no data 2 days ago!)
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                   <Input type="radio"  name="ruleApplyTo" value="old" checked={ruleApplyTo==="old" ? true : false} onChange={(e) => setRuleApplyTo(e.target.value)} />
                   Old campaigns ( running since at least 2 days ago!)
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio"  name="ruleApplyTo" value="adset" checked={ruleApplyTo==="adset" ? true : false} onChange={(e) => setRuleApplyTo(e.target.value)} />
                  All Adset
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <hr/>
          <FormGroup check>
            <Label check>
              <Input type="checkbox" checked={ruleSkipIfLearning} onChange={(e) => setRuleSkipIfLearning(!ruleSkipIfLearning)} />{' '}
              Skip if less than 50 conversions ( LEARNING PHASE )
            </Label>
          </FormGroup>
          <hr/>
          <FormGroup>
            <Label for="ruleSchedule">Schedule: </Label>
            <Input type="select" name="ruleSchedule" id="ruleSchedule" onChange={(e) => setRuleSchedule(e.target.value)} value={ruleSchedule}>
              <option value="HOURLY">HOURLY</option>
              <option value="DAILY">DAILY</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="ruleSchedule">WHEN: </Label>
            <Input type="select" name="ruleScheduleTime" id="ruleScheduleTime" onChange={(e) => setRuleScheduleTime(e.target.value)} value={ruleScheduleTime}>
              {Object.keys(RULE_SCHEDULE_TIME_OPTs).map(value => <option key={value} value={value}>{props.times[value]}</option>)}
            </Input>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleNewRuleModal}>CLOSE</Button>
        <Button color="primary" onClick={() => {
          
          // let finalConditions = conditions.map((c) => {
          //   let cc = {...c};
          //   cc.applyTo = ruleApplyTo;
          //   return cc;
          // });

          let finalRuleAction = ruleAction;
          if ( ruleAction === "BID") {
            finalRuleAction = `${ruleAction}_${ruleBid}_${ruleBidCol}`;   
          } else if ( ruleAction === "BUDGET" ) {
            finalRuleAction = `${ruleAction}_${ruleBid}`;
          } else if ( ruleAction === "PAUSE_RESTART" ) {
            if ( update_pause_and_restart ) {
              finalRuleAction = `${ruleAction}_${ruleWhenToRestart}_${ruleWhenToRestartUpdateCol}_${ruleWhenToRestartValueCol}`;
            } else {
              finalRuleAction = `${ruleAction}_${ruleWhenToRestart}`;
            }
          }
          props.saveRule({ruleName, ruleActivity, ruleAction: `${finalRuleAction}`, fbaccounts, conditions, ruleTimeRange, ruleApplyTo, ruleSchedule, ruleScheduleTime, ruleSkipIfLearning }); 
        
          reset();
          toggleNewRuleModal();
        }}>SAVE</Button>
      </ModalFooter>
    </Modal>
  )
}

export default FbRuleModal;
