import React, { useState, useEffect } from "react";
import { API } from 'aws-amplify';
import cx from "classnames";

// import moment from "moment";
// import md5 from 'md5';
// import DatePicker from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.min.css';
// import DataTable from 'react-data-table-component';
// import memoize from 'memoize-one';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Table,
  Col,
  UncontrolledTooltip
  // List
  /*Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,*/
  // Input,
  // ButtonGroup,
  // Label,
} from "reactstrap";


import CreateRPCModal from "../../components/CreateRPCModal";

import CONFIG from "../reports/config.js"

import Spinner from "../../components/Spinner";

const RPCTool = (props) => {


  const [isLoading, setIsLoading] = useState(false);

  const blackRPC = {
    // type: "shuffle",
    label: '',
    value: '0'
  };
  const [rpc, setRPC] = useState({...blackRPC});

  const handleRPCChange = (e) => {
    console.log(e.target.name, e.target.value);
    // if ( e.target.selectedOptions ) {
    //   setRPC({
    //     ...rpc,
    //     [e.target.name]: Array.from(e.target.selectedOptions, (item) => item.value),
    //   });
    // } else {
    setRPC({
      ...rpc,
      [e.target.name]: e.target.value,
    });
    // }
  }

  const saveRPC = async () => {
    setIsLoading(true);
    // console.log(redirect);
    let response = await API.post('customAPI', '/rpc-tool', {
      body: {...rpc}
    });
    setRPCList(response.rpcList);
    setIsLoading(false);
    setRPC({...blackRPC});
    toggleCreateRPC();
  }

  const deleteRPC = async (rpc) => {
    setIsLoading(true);
    let response = await API.del('customAPI', '/rpc-tool', {
      body: {...rpc}
    });
    setRPCList(response.rpcList);
    setIsLoading(false);
  }
  
  const [editMode, setEditingMode] = useState(false);
  const editRPC = async (rpc) => {
    // console.log("QUI", redirect);
    setEditingMode(true);
    setRPC({...rpc});
    setCreateRPC(!createRPC);
  }

  const [createRPC, setCreateRPC] = useState(false);
  const toggleCreateRPC = () => {
    setRPC({...blackRPC});
    setEditingMode(false);
    setCreateRPC(!createRPC);
  }

  const [rpcList, setRPCList] = useState([]);
  const loadSettings = async () => {
    let {rpcList=[]} = await API.get('customAPI', '/rpc-tool');
    setRPCList(rpcList);
  }

  useEffect(() => {
    loadSettings();
  }, []);


  const refreshToken = async () => {
    setIsLoading(true);
    await API.put('customAPI', '/rpc-tool', {
      body: {
        op: "refresh_redis"
      }
    });
    setIsLoading(false);
  }


  // console.log(redirects);

  return (
    <Container className="mt-2" fluid>
      <Row>
        <Col>
          <Card className="shadow">
            <CardHeader className={cx("border-0")}>
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">RPC TOOL</h3>
                </Col>
                <Col className="text-right" xs="4">
                  <button className="btn btn-icon btn-danger" type="button" 
                    style={{paddingTop:0, paddingLeft: 5, paddingBottom:0, paddingRight:5}}
                    onClick={e => { e.preventDefault(); refreshToken(); }}>
                    <i className="fas fa-plus"></i> REFRESH RPC
                  </button>
                  <button className="btn btn-icon btn-warning" type="button" 
                    style={{paddingTop:0, paddingLeft: 5, paddingBottom:0, paddingRight:5}}
                    onClick={e => { e.preventDefault(); setCreateRPC(true); }}>
                    <i className="fas fa-plus"></i> Create RPC
                  </button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Spinner isLoading={isLoading}>
                <Table striped bordered hover responsive size="sm">
                  <thead className="thead-light">
                    <tr>
                      <th></th>
                      <th scope="col">Label (rv)</th>
                      <th scope="col">RPC</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rpcList.map((rpc, idx) => {
                      rpc = {...blackRPC, ...rpc};
                      return <tr key={idx}>
                        <td style={{paddingLeft:"0.5rem", paddingRight: "0.5rem" }}>
                          <Button size="sm" color="warning" onClick={e => {e.preventDefault(); editRPC(rpc);}}><i className="fas fa-edit"></i></Button>
                          <Button type="icon" size="sm" color="danger" onClick={e => {e.preventDefault(); deleteRPC(rpc);}}><i className="fa fa-trash"></i></Button>
                        </td>
                        <td>{rpc.label}</td>
                        <td>{rpc.value}</td>
                      </tr>
                    })}
                  </tbody>
                </Table>
              </Spinner>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <CreateRPCModal createRPC={createRPC} toggleCreateRPC={toggleCreateRPC} 
        rpc={rpc} setRPC={setRPC} handleRPCChange={handleRPCChange} 
        isLoading={isLoading} saveRPC={saveRPC} editMode={editMode} />
     
    </Container>
  );
}


export default RPCTool;
