export const MARKETS = [
  { value: "ar", flag: "ar", label: "Argentina"},
  { value: "at", flag: "at", label: "Austria" },
  { value: "au", flag: "au", label: "Australia" },
  { value: "be", flag: "be", label: "Belgium" },
  { value: "bo", flag: "bo", label: "Bolivia" },
  { value: "br", flag: "br", label: "Brazil" },
  { value: "ca", flag: "ca", label: "Canada" },
  { value: "ch", flag: "ch", label: "Switzerland" },
  { value: "cl", flag: "cl", label: "Chile"},
  { value: "co", flag: "co", label: "Colombia"},
  { value: "de", flag: "de", label: "Germany" },
  { value: "dz", flag: "dz", label: "Algeria" },
  { value: "ec", flag: "ec", label: "Ecuador" },
  { value: "dk", flag: "dk", label: "Danmark" },
  { value: "es", flag: "es", label: "Spain" },
  { value: "fi", flag: "fi", label: "Finland" },
  { value: "fr", flag: "fr", label: "France" },
  { value: "hk", flag: "hk", label: "Hong Kong"},
  { value: "ie", flag: "ie", label: "Ireland"},
  { value: "in", flag: "in", label: "India" },
  { value: "id", flag: "id", label: "Indonesia"},
  { value: "it", flag: "it", label: "Italy" },
  // { value: "my", flag: "my", label: "Malaysia"},
  { value: "mx", flag: "mx", label: "Mexico" },
  { value: "nl", flag: "nl", label: "Netherlands" },
  { value: "no", flag: "no", label: "Norway" },
  { value: "nz", flag: "nz", label: "New Zealand" },
  { value: "pe", flag: "pe", label: "Peru"},
  { value: "ph", flag: "ph", label: "Philippines"},
  { value: "se", flag: "se", label: "Sweden" },
  { value: "sn", flag: "sn", label: "Senegal" },
  { value: "sg", flag: "sg", label: "Singapore"},
  { value: "tw", flag: "tw", label: "Taiwan" },
  { value: "th", flag: "th", label: "Thailand" },
  { value: "tn", flag: "tn", label: "Tunisia" },
  { value: "uk", flag: "gb", label: "United Kingdom" },
  { value: "us", flag: "us", label: "United States" },
  { value: "ve", flag: "ve", label: "Venezuela"},
  { value: "vn", flag: "vn", label: "Vietnam"}  
];
