import React, {useState} from "react";
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, InputGroup,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupAddon,ListGroup,
  FormText,
  ButtonGroup,
  ListGroupItem} from "reactstrap";

import Spinner from "./Spinner";

const CreateTaboolaRuleModal = (props) => {
  const { isLoading, createRuleModal, toggleCreateRuleModal, 
          rule, setRule, handleRuleChange, TIMEs, CONFIG, 
          taboolaAccounts, ruleConditionPeriod, saveRule, campaignList, copyMode=false, editMode=false } = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);

  const revenueDimensions = CONFIG["yahooAdp"].DIMENSIONS_INIT.map((d) => ({
    name: d.name,
    selector: d.selector
  }));


  const costDimensions = [
    {
      name: "Clicks",
      selector: "clicks"
    }, {
      name: "Impressions",
      selector: "impressions"
    }, {
      name: "visible_impressions",
      selector: "visible_impressions"
    }, {
      name: "Spent",
      selector: "spent"
    }, {
      name: "conversions_value",
      selector: "conversions_value"
    }, {
      name: "roas",
      selector: "roas",
    }, {
      name: "CTR",
      selector: "ctr",
    }, {
      name: "VCTR",
      selector: "vctr",
    }, {
      name: "CPM",
      selector: "cpm",
    }, {
      name: "VCPM",
      selector: "vcpm",
    }, {
      name: "CPC",
      selector: "cpc",
    }, {
      name: "CPA",
      selector: "cpa",
    }, {
      name: "CPA CLICKS",
      selector: "cpa_clicks",
    }, {
      name: "CPA VIEWS",
      selector: "cpa_views",
    }, {
      name: "Conversions",
      selector: "cpa_actions_num",
    }, {
      name: "CPA Conversion Rate",
      selector: "cpa_conversion_rate",
    }, {
      name: "CPA Conversion Rate Clicks",
      selector: "cpa_conversion_rate_clicks"
    }, {
      name: "CPA Conversion Rate Views",
      selector: "cpa_conversion_rate_views"
    }
  ]

  let dimensions = revenueDimensions;
  if ( rule.type === "cost" ) {
    dimensions = costDimensions;
  }
  // const [dimensions, setDimensions] = useState(revenueDimensions);

  // const [ruleType, setRuleType] = useState(...rule.type);
  // useEffect(() => {
  //   console.log("HERE! " + ruleType + " rule.type " + rule.type);
  //   if ( rule.type !== ruleType ) {
      
  //     setRule({...rule, ...{type: ruleType}});
  //     if ( ruleType === "cost" ) {
  //       setDimensions(costDimensions);
  //     }
  //     if ( ruleType === "revenue" ) {
  //       setDimensions(revenueDimensions);
  //     }
  //   }
  // }, [ruleType])


  const [condition, setCondition] = useState({dimension: "", op: ">", value: 0, period: ""});
  const addCondition = (e) => {
    e.preventDefault();
    let newConditions = [...rule.conditions, condition];
    setRule({...rule, ...{conditions: newConditions}});
    setCondition({dimension: "", op: ">", value: 0, period: ""});
  }


  const renderCreateForm = () => {
      return (<>
      <FormGroup>
        <label
          className="form-control-label"
          htmlFor="input-rulename"
        >
          Rule Name:
        </label>
        <Input
          className="form-control"
          id="input-rulename"
          name="name"
          type="text"
          placeholder="Rule name"
          value={rule.name} 
          onChange={handleRuleChange}
        />
      </FormGroup>
      <Row className="mb-1">
        <Col>
          <Label>Apply to: </Label>
          <FormGroup check>
            <Label check>
              <Input type="radio"  name="applyTo" value="all" checked={rule.applyTo==="all" ? true : false} onChange={handleRuleChange} />
              All campaign outcome at Campaign level
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
               <Input type="radio"  name="applyTo" value="account" checked={rule.applyTo==="account" ? true : false} onChange={handleRuleChange} />
               All campaign outcome at Account level
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
               <Input type="radio"  name="applyTo" value="campaign" checked={rule.applyTo==="campaign" ? true : false} onChange={handleRuleChange} />
               Specific campaign
            </Label>
          </FormGroup>
        </Col>
      </Row>
      {rule.applyTo === "campaign" &&
        <FormGroup>
          <Label for="spcific-campaign">Campaign: </Label>
          <Spinner isLoading={isLoading}>
            <Input type="select" id="spcific-campaign" name="applyToCampaign" value={rule.applyToCampaign} onChange={handleRuleChange} >
              <option key="empty-spcific-campaign" value="">-- Campaign --</option>
              {campaignList.map(campaign => <option key={campaign.id} value={campaign.id}>{campaign.name}</option>)}
            </Input>
          </Spinner>
        </FormGroup>}
      {/*<FormGroup>
        <Label for="tblevel">Level: </Label>
        <Input type="select" id="tblevel" name="level" value={rule.level} onChange={handleRuleChange} >
          <option key="empty-tblevel" value="">---</option>
          <option key="tblevel-placement" value="placement">placement</option>
          <option key="tblevel-" value="placement">placement</option>
        </Input>
      </FormGroup>*/}
      <FormGroup>
        <Label for="ruleAction">Action: </Label>
        <Input type="select" name="action" id="ruleAction" value={rule.action} onChange={(e) => setRule({...rule, ...{"action": e.target.value}})}>
          <option value="BLACKLIST">BLACKLIST PLACEMENT</option>
          <option value="BUDGET">ADJUST BUDGET</option>
          {/*<option value="DAILY">DAILY</option>*/}
        </Input>
      </FormGroup>
      {rule.action === "BUDGET" ?
      <FormGroup>
        <Label for="action_modifier">Budget change:</Label>
        <FormText color="muted">
            <ul>
              <li>+10%, -10%, i.e add/remove percentage of current budget</li>
              <li>10$, -10$, i.e add/remove 10$ to current budget</li>
              <li>*3, i.e Set budget to 3 dollars</li>
            </ul>
          </FormText>
        <Input type="text" name="action_modifier" placeholder="i.e +10%, -10% or +10$,-10$, *3" onChange={handleRuleChange} value={rule.action_modifier} />
      </FormGroup> : null}
      <Row form className="bg-secondary p-2">
        <Col md={12}>
        <FormGroup>
          <Label>Conditions: </Label>
          <InputGroup>
            <Input type="select" name="dimension" id="dimension" value={condition.dimension} onChange={(e) => setCondition({...condition,dimension:e.target.value})}>
               <option key={-1}>----</option>
               {dimensions.map((dimension) => <option key={dimension.selector} value={dimension.selector}>{dimension.name}</option>)}
            </Input>
            <InputGroupButtonDropdown addonType="append" isOpen={dropdownOpen} 
                toggle={() => toggleDropDown()}>
              <DropdownToggle caret>{condition.op}</DropdownToggle>
              <DropdownMenu>
                {['>', '>=', "=", "<", "<="].map(op => <DropdownItem key={op} onClick={() => setCondition({...condition, op:op})}>{op}</DropdownItem>)}
              </DropdownMenu>
            </InputGroupButtonDropdown>
            <InputGroupAddon addonType="append">
               <Input onChange={(e) => setCondition({...condition,value:e.target.value})} value={condition.value}/>
            </InputGroupAddon>
            <InputGroupAddon addonType="append">
               <Button color="primary" onClick={addCondition} disabled={condition.dimension === ""}>Add!</Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup>
            {rule.conditions.map((c, idx) => <ListGroupItem  key={`cond-${idx}`}>{ruleConditionPeriod[c.period]} {c.dimension}{c.op}{c.value}</ListGroupItem>)}
          </ListGroup>
        </Col>
      </Row>
      <FormGroup>
        <Label for="ruleSchedule">Schedule: </Label>
        <Input type="select" name="schedule" id="ruleSchedule" value={rule.schedule} onChange={handleRuleChange}>
          <option value="HOURLY">HOURLY</option>
          <option value="DAILY">DAILY</option>
        </Input>
      </FormGroup>
      <FormGroup>
        <Label for="ruleScheduleTime">WHEN: </Label>
        <Input type="select" name="time" id="ruleScheduleTime" value={rule.time} onChange={handleRuleChange}>
          {Object.keys(TIMEs).map(value => <option key={value} value={value}>{TIMEs[value]}</option>)}
        </Input>
      </FormGroup>
      <Row>
        <Col md={12}>
          <Label>Time Range: </Label>
          <FormGroup>
            <Input type="select" name="period" id="ruleConditionPeriod" value={rule.period} onChange={handleRuleChange}>
              <option>---</option>
              {Object.keys(ruleConditionPeriod).filter((conditionVal) => {
                if ( rule.schedule === "DAILY" && conditionVal === "today" ) {
                  return false;
                }
                return true;
              }).map((conditionVal) => <option key={conditionVal} value={conditionVal}>{ruleConditionPeriod[conditionVal]}</option>)}
            </Input>
          </FormGroup>
        </Col>
      </Row>
    </>);
  }

  console.log(rule);

  return (
		<Modal isOpen={createRuleModal} toggle={toggleCreateRuleModal} size="lg">
      <ModalHeader toggle={toggleCreateRuleModal}>Create Rule Modal</ModalHeader>
      <ModalBody>
    	 	<Form>
          {copyMode === false && 
            <FormGroup>
              <Label for="tbaccount">Rule TYPE: </Label>
              <ButtonGroup size="lg" className="btn-block">
                <Button className="w-100" color="primary" type="button" name="via-csv" disabled={rule.type==="revenue"} 
                  onClick={() => setRule({...rule, ...{type: "revenue"}})}>REVENUE BASED</Button>
                <Button className="w-100" color="info" type="button" name="via-api" disabled={rule.type==="cost"} 
                  onClick={() => setRule({...rule, ...{type: "cost"}})}>COST BASED</Button>
              </ButtonGroup>
            </FormGroup>
          }
          <FormGroup>
            <Label for="tbaccount">Account: </Label>
            <Input type="select" id="tbaccount" name="account" value={rule.account} onChange={handleRuleChange} 
               disabled={editMode}>
              <option key="empty-account" value="">----</option>
              {Object.keys(taboolaAccounts).map((account,i) => <option key={`account-${i}`} value={account}>{taboolaAccounts[account].name}</option>)}
            </Input>
    			</FormGroup>
          {!copyMode && renderCreateForm()}
      	</Form>
      </ModalBody>
      <ModalFooter>
	      <Button color="secondary" onClick={toggleCreateRuleModal}>CLOSE</Button>
	      <Button color="primary" disabled={isLoading} onClick={saveRule}>SAVE</Button>
	    </ModalFooter>
    </Modal>
  )
}

export default CreateTaboolaRuleModal;

