import React from "react";

import { FormGroup, Button, Input, Form } from "reactstrap";
import {
  Card,
  CardBody,
  Row,
  Col,
  Modal, 
  ModalHeader, 
  ModalBody
} from "reactstrap";

import Spinner from "../../components/Spinner";

const NewUserModel = (props) => {
  const {isLoading} = props;
  return (
    <Modal isOpen={props.isOpen} toggle={() => props.toggle()} size="lg" className="full-screen">
    <ModalHeader className="pb-0" toggle={() => props.toggle()}>NEW USER ACCOUNT</ModalHeader>
    <ModalBody>
      <Spinner isLoading={isLoading}>
      	<Row>
  				<Col className="order-xl-1" xl="12">
  	        <Card className="bg-secondary shadow pt-0">
  	          <CardBody>
  	            <Form onSubmit={props.createUser}>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Username
                          </label>
                          <Input
                          	name="username"
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="Username"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-password"
                          >
                            Password
                          </label>
                          <Input
                          	name="password"
                            className="form-control-alternative"
                            id="input-password"
                            placeholder="****"
                            type="password"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                          	name="email"
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="Please enter a valid email address"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            First name
                          </label>
                          <Input
                          	name="firstname"
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="First name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Last name
                          </label>
                          <Input
                          	name="surname"
                            className="form-control-alternative"
                            id="input-last-name"
                            placeholder="Last name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <Button type="submit" className="btn-block" color="primary">SAVE</Button>
  	            </Form>
  	          </CardBody>
  	        </Card>
  	      </Col>
  	    </Row>
      </Spinner>
    </ModalBody>
    </Modal>
  );
};

export default NewUserModel;