import React, { useState, useEffect } from "react";
import cx from "classnames";
import { API } from 'aws-amplify';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Col
} from "reactstrap";

import memoize from 'memoize-one';
import DataTable from 'react-data-table-component';

import MapAccountModal from "../../../components/MapAccountModal.js";
import MapFanPageModal from "../../../components/MapFanPageModal.js";
import MapFacebookAccountRules from "../../../components/MapFacebookAccountRules.js";

const fanPagesSelector = (selector, mapFacebookPages, selectEditingRow) => {

  

  return (row, idx) => {
    let fanPagesSelected = JSON.parse(row.fanPages) || [];
    // console.log(fanPagesSelected);
    if ( fanPagesSelected.length === 0 ) {
      return <Button  className="list-unstyled" onClick={() => selectEditingRow(row)} key={`page${idx}`}>???</Button>;
    } else {
      return (<ul style={{cursor:"pointer"}} className="list-unstyled" onClick={() => selectEditingRow(row)} key={`page${idx}`}>
        {fanPagesSelected.map((pageid, idx) => <li key={pageid}>{mapFacebookPages[pageid] ? mapFacebookPages[pageid].name : pageid}</li>)}
      </ul>);  
    }
    
  }
}

const columns = memoize((businessManagerMap, mapFacebookPages, selectEditingRow, updateBuyingStrategy, deleteMap, loadAccountRules, selectEditingRowCmsInfo) => [
  { grow:0, compact:true, width: "40px", cell: (row) => (
    <Button color="danger" size="sm" type="select" value={row.accountID} onClick={(e) => deleteMap(row.accountID)}>
      <i className="fas fa-times"></i>
    </Button>)
  },
  { selector: "activity", name: "Activity", sortable: true, compact:true},
  { selector: "business_manager", name: "Business Manager", sortable: true, format: (map) => businessManagerMap[map.businessManagerID] ? businessManagerMap[map.businessManagerID].name : map.businessManagerID },
  { selector: "accountName", name: "Account Name", sortable: true, wrap: true, 
      cell: (row, idx) => (
        <div style={{cursor:"pointer"}} onClick={() => loadAccountRules(row)}>{row.accountName} ({row.accountID}) 
          {/**<br/><Button color="primary" size="sm" type="select" onClick={(e) => console.log(row)}>
            Rules <i className="fa fa-ruler"></i>
          </Button>*/}
        </div>)},
  { selector: "utm_campaign", name: "Vertical", sortable: true},
  { selector: "buying_strategy", name: "Buying Strategry", sortable: true, cell: (row) => (
    <Input size="sm" type="select" value={row.buying_strategy} onChange={(e) => updateBuyingStrategy(e.target.value, row)}>
      <option key="empty-strategy" value="mix">MIX</option>
      <option key="lowestcost" value="lowestcost">Lowest Cost</option>
      <option key="bidcap" value="bidcap">Bid Cap</option>
    </Input>)
  },
  { selector: "pixel", name: "Pixel ID", sortable: true, 
          cell: (row, idx) => <div style={{cursor:"pointer"}} onClick={() => selectEditingRowCmsInfo(row)}>{row.pixel}</div>},
  { selector: "domain", name: "Domain", sortable: true,
    cell: (row, idx) => <div style={{cursor:"pointer"}} onClick={() => selectEditingRowCmsInfo(row)}>{row.domain}</div>},
  { selector: "utm_source", name: "UTM Souce", sortable: true, compact: true,
    cell: (row, idx) => <div style={{cursor:"pointer"}} onClick={() => selectEditingRowCmsInfo(row)}>{row.utm_source}</div>},
  { selector: "fanPages", name: "Fan Pages", 
      cell: fanPagesSelector("fanPages", mapFacebookPages, selectEditingRow)
  }
]);

const FacebookAccountMapper = (props) => {

  const [isLoading, setIsLoading] = useState(false);
	const [businessManagerList, setBusinessManagerList] = useState([]);
	const [businessManagerMap, setBusinessManagerMap] = useState({});
	const [mapping, setMapping] = useState([]);
	
	const setBusinessMangerToken = (bmID, token) => {
		const newBusinessManagerList = businessManagerList.reduce((acc, bm) => {
			if ( bm.id === bmID ) {
				bm.token = token;
			}
			acc.push(bm);
			return acc;
		},[])
		setBusinessManagerList(newBusinessManagerList);
	}

	const saveBusinessManager = async () => {
		setIsLoading(true);
		await API.post('customAPI', '/facebookAccountMapper/businessManager', {
			body: {
        businessManagerList
      }
		});
		toggleTokenModal();
   	setIsLoading(false);
	}

  // TOKEN MODAL
	const [tokenModal, setTokenModal] = useState(false);
  const toggleTokenModal = () => setTokenModal(!tokenModal);

  // LINK ACCOUNT!
  const [facebookAccountRules, setFacebookAccountRules] = useState([])
  const [mapAccountModal, setMapAccountModal] = useState(false);
  const [editingFacebookAccountRule, setEditingFacebookAccountRules] = useState(false);
  const toggleMapAccountModal = () => {
    setMapAccountModal(!mapAccountModal);
  }
  const loadAccountRules = async (row) => {
    console.log(row);
    let fbAccountRules = await API.get('customAPI', '/facebookAccountMapper/accountRules', {
      queryStringParameters: {
        businessManagerID: row.businessManagerID,
        accountID: row.accountID,
        activity: row.activity
      }
    });
    setEditingFacebookAccountRules(row);
    setFacebookAccountRules(fbAccountRules);
    toggleFacebookAccountRuleModal();
  }

  const saveAccountRules = async (newRules) => {

    toggleFacebookAccountRuleModal();
    
    setIsLoading(true);
    
    for ( let newrule of newRules ) {
      await API.put('customAPI', '/fbrules', {
        body: {
          op: "addFBAccounts",
          fbrule: newrule,
          fbrule_accounts: [editingFacebookAccountRule.accountID]
        }
      });
    }
    
    setIsLoading(false);

  }


  const [cmsProperties, setCmsProperties] = useState({});
 	const [facebookPages, setFacebookPages] = useState([]);
 	const [mapFacebookPages, setFacebookPagesMap] = useState({});
  const [pixels, setPixels] = useState([]);
  const loadSettings = async () => {
    setIsLoading(true);

    let {landingPages} = await API.get('customAPI', '/facebookAccountMapper/cms');
    setCmsProperties(landingPages);

    let {pixels} = await API.get('customAPI', '/facebookAccountMapper/pixel');
    setPixels(pixels);

    let response = await API.get('customAPI', '/facebookAccountMapper/businessManager');
    setBusinessManagerList(response.businessManagerList);
    const businessManagerMap = response.businessManagerList.reduce((acc, row) => {
    	acc[row.id] = row;
    	return acc;
    }, {})
    setBusinessManagerMap(businessManagerMap);

    let facebookPages = await API.get('customAPI', '/fb_fanpage/list');
    setFacebookPages(facebookPages);
    const mapFacebookPages = facebookPages.reduce((acc, page) => {
    	acc[page.id] = page;
    	return acc;
    }, {});
    setFacebookPagesMap(mapFacebookPages);

    const {mapping} = await API.get('customAPI', '/facebookAccountMapper/mapping');
    setMapping(mapping);
    setIsLoading(false);
  }

  useEffect(() => {
    loadSettings();
  }, []);

  const saveMapping = async (map) => {
    setIsLoading(true);
    await API.post('customAPI', '/facebookAccountMapper/mapping', {
			body: {
        ...map
      }
		});
    const {mapping} = await API.get('customAPI', '/facebookAccountMapper/mapping');
    setMapping(mapping);
    setIsLoading(false);
  }


  
  const [selectedFacebookPages, setSelectedFacebookPages] = useState([]);
  const [editFanPagesModal, setEditFanPagesModal] = useState(false);
  const toggleEditFanPagesModal = () => {
    setEditFanPagesModal(!editFanPagesModal);
  }
  const [editingRow, setEditingRow] = useState({});
  const selectEditingRow = (row) => {
    setEditingRow(row);
    setSelectedFacebookPages(JSON.parse(row.fanPages))
    toggleEditFanPagesModal();
  }

  const [editingRowCmsInfo, setEditingRowCmsInfo] = useState(false);
  const toggleEditCmsInfoModal = () => {
    setEditingRowCmsInfo(!editingRowCmsInfo);
  }
  const selectEditingRowCmsInfo = (row) => {
    setEditingRow(row);
    toggleEditCmsInfoModal();
  }
  const updateMappedAccount = async () => {
    setIsLoading(true);
    await API.put('customAPI', '/facebookAccountMapper/mapping', {
      body: {
        op: "updateMappedAccountCMS",
        accountID: editingRow.accountID,
        domain: editingRow.domain,
        pixel: editingRow.pixel,
        utm_source: editingRow.utm_source
      }
    });
    const {mapping} = await API.get('customAPI', '/facebookAccountMapper/mapping');
    setMapping(mapping);
    toggleEditCmsInfoModal();
    setIsLoading(false);
  }

  const editFacebookPages = (e) => {
    const selectedOptions = [...e.target.selectedOptions].map(o => o.value);
    setSelectedFacebookPages(selectedOptions)
  }
  const saveEditingFanPages = async () => {
    setIsLoading(true);
    await API.put('customAPI', '/facebookAccountMapper/mapping', {
      body: {
        op: "updateFacebookPages",
        accountID: editingRow.accountID,
        selectedFacebookPages: selectedFacebookPages
      }
    });
    const {mapping} = await API.get('customAPI', '/facebookAccountMapper/mapping');
    setMapping(mapping);
    // await saveMapping({...editingRow, buyingStrategy: editingRow.buying_strategy, businessManager: editingRow.businessManagerID, selectedFacebookPages});
    toggleEditFanPagesModal();
    setIsLoading(false);
  }



  const [fanPagesModal, setFanPagesModal] = useState(false);
  const toggleFanPagesModal = () => {
    setFanPagesModal(!fanPagesModal);
  }

  const [facebookAccountRuleModal, setFacebookAccountRuleModal] = useState(false);
  const toggleFacebookAccountRuleModal = () => {
    setFacebookAccountRuleModal(!facebookAccountRuleModal);
  }

  const updateBuyingStrategy = async (buying_strategy, row) => {
    setIsLoading(true);
    await API.put('customAPI', '/facebookAccountMapper/mapping', {
      body: {
        op: "updateBuyingStrategy",
        accountID: row.accountID,
        buyingStrategy: buying_strategy
      }
    });
    const {mapping} = await API.get('customAPI', '/facebookAccountMapper/mapping');
    setMapping(mapping);
    setIsLoading(false);
  }

  const saveFanPageMap = async (fanpageMap) => {
    setIsLoading(true);
    await API.post('customAPI', '/facebookAccountMapper/fanpages', {
      body: {
        ...fanpageMap
      }
    });
    let facebookPages = await API.get('customAPI', '/fb_fanpage/list');
    setFacebookPages(facebookPages);
    const mapFacebookPages = facebookPages.reduce((acc, page) => {
      acc[page.id] = page;
      return acc;
    }, {});
    setFacebookPagesMap(mapFacebookPages);
    toggleFanPagesModal();
    setIsLoading(false);
  }
  const [mappableFanPages, setMappableFanPages] = useState([])
  const loadMappableFanPages = async () => {
    let {tobemapped} = await API.get('customAPI', '/facebookAccountMapper/fanpages');
    setMappableFanPages(tobemapped);
  }
  useEffect(() => {
    if ( fanPagesModal ) {
      loadMappableFanPages()
    }
  }, [fanPagesModal])
  
  const [view, setView] = useState("fbaccount");

  const refreshToken = async (row) => {
    setIsLoading(true);
    await API.put('customAPI', '/facebookAccountMapper/fanpages', {
      body: {
        ...row
      }
    });
    let facebookPages = await API.get('customAPI', '/fb_fanpage/list');
    setFacebookPages(facebookPages);
    const mapFacebookPages = facebookPages.reduce((acc, page) => {
      acc[page.id] = page;
      return acc;
    }, {});
    setFacebookPagesMap(mapFacebookPages);
    setIsLoading(false);
  }

  const deleteMap = async (accountID) => {
    setIsLoading(true);
    await API.del('customAPI', '/facebookAccountMapper/mapping', {
      body: {
        accountID: accountID
      }
    });
    const {mapping} = await API.get('customAPI', '/facebookAccountMapper/mapping');
    setMapping(mapping);
    setIsLoading(false);
  }

  return (
    <Container className="mt-2" fluid>
      <Row>
        <Col>
          <Card className="bg-secondary shadow">
            <CardHeader className={cx("border-0")}>
              <Row className="align-items-center">
                <Col xs="2">
                  <h3>Facebook Account Mapper</h3>
                </Col>
                <Col xs="4">
                  <small>FbAccount</small><Label className="custom-toggle custom-toggle-danger" style={{margin:0}}>
                    <Input type="checkbox" defaultChecked={view==="fanpages"} onClick={(e) => {
                      let view = e.target.checked ? "fanpages" : "fbaccount"; 
                      setView(view); 
                    }} />
                    <span className="custom-toggle-slider rounded-circle" data-label-off="OFF" data-label-on="ON"></span>
                  </Label><small>FanPages</small>
                </Col>
                <Col className="text-right" xs="6">
                  {["andrea.simonetti", "rancan"].indexOf(props.userAuth.username) !== -1 ? 
                  	<button className="btn btn-icon btn-danger" type="button" 
                      style={{paddingTop:0, paddingLeft: 5, paddingBottom:0, paddingRight:5}}
                      onClick={e => { e.preventDefault(); setTokenModal(true); }}>
                      <i className="fas fa-plus"></i> Facebook TOKEN
                    </button> : null}
                  <button className="btn btn-icon btn-primary" type="button" 
                    style={{paddingTop:0, paddingLeft: 5, paddingBottom:0, paddingRight:5}}
                    onClick={e => { e.preventDefault(); setFanPagesModal(true); }}>
                    <i className="fas fa-plus"></i> Map new Page
                  </button>
                  <button className="btn btn-icon btn-warning" type="button" 
                    style={{paddingTop:0, paddingLeft: 5, paddingBottom:0, paddingRight:5}}
                    onClick={e => { e.preventDefault(); setMapAccountModal(true); }}>
                    <i className="fas fa-plus"></i> Map new Account
                  </button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  {view === "fbaccount" ? 
                    <DataTable
                      columns={columns(businessManagerMap, mapFacebookPages, selectEditingRow, 
                                updateBuyingStrategy, deleteMap, loadAccountRules, selectEditingRowCmsInfo)}
                      data={mapping}
                      progressPending={isLoading}
                      persistTableHead={true}
                      noHeader={true}
                      striped={true}
                      highlightOnHover
                      dense={true}
                    /> : <DataTable
                    columns={[
                      { selector: "id", name: "ID", sortable: true, compact:true},
                      { selector: "name", name: "Name", sortable: true, compact:true},
                      { selector: "utm_campaign", name: "UTM Campaign", sortable: true, compact: true},
                      {selector: "igAccount", name: "igAccount", sortable: true, compact: true, cell: (row) => (
                        <div><Button size="sm" color="warning" className="mr-1" onClick={() => refreshToken(row)}><i className="fas fa-sync-alt"></i></Button>{row.igAccount}</div>
                      )},
                      {selector: "token", name: "Token"}
                    ]}
                    data={facebookPages}
                    progressPending={isLoading}
                    persistTableHead={true}
                    noHeader={true}
                    striped={true}
                    highlightOnHover
                    dense={true}
                    />
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      
      <Modal isOpen={editingRowCmsInfo} toggle={toggleEditCmsInfoModal} size="lg">
        <ModalHeader toggle={toggleEditCmsInfoModal}>EDIT CMS INFO/DOMAIN:</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="ruleName">Domain: </Label>
            <Input type="select" name="domain" id="domain" onChange={(e) => setEditingRow({...editingRow, ...{"domain": e.target.value}})} value={editingRow.domain}>
              <option key="empty-domain" value="">----</option>
              {Object.keys(cmsProperties).map((domain,i) => <option key={domain} value={domain}>{domain}</option>)}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="ruleName">UTM SOURCE: </Label>
            <Input type="select" name="utm_source" id="utm_source" onChange={(e) => {
              let pixelID = cmsProperties[editingRow.domain][e.target.value].pixel_id || "";
              setEditingRow({...editingRow, ...{"utm_source": e.target.value, "pixel": pixelID}})
            }} value={editingRow.utm_source}>
              <option key="empty-utm_source" value="">----</option>
              {Object.keys(cmsProperties[editingRow.domain] || {}).map((utm_source,i) => <option key={utm_source} value={utm_source}>{utm_source}</option>)}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="ruleName">PIXEL ID: </Label>
            <Input type="select" name="pixel" id="pixel" onChange={(e) => setEditingRow({...editingRow, ...{"pixel": e.target.value}})} value={editingRow.pixel}>
              <option key="empty-utm_source" value="">----</option>
              {pixels.map((pixel,i) => <option key={`pixel-${i}`} value={pixel.id}>{pixel.name}</option>)}
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleEditCmsInfoModal}>CLOSE</Button>
          <Button color="primary" onClick={updateMappedAccount} disabled={isLoading}>EDIT</Button>
        </ModalFooter>
      </Modal>


      <Modal isOpen={editFanPagesModal} toggle={toggleEditFanPagesModal} size="lg">
        <ModalHeader toggle={toggleEditFanPagesModal}>EDIT FAN PAGES:</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Input type="select" name="fanPages" id="fanPages" value={selectedFacebookPages} onChange={editFacebookPages} multiple style={{height:"250px"}}>
              <option key="empty-page" value="">----</option>
              {facebookPages.map((fanpage,i) => <option key={fanpage.id} value={fanpage.id}>{fanpage.name} - ({fanpage.id})</option>)}
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleEditFanPagesModal}>CLOSE</Button>
          <Button color="primary" onClick={saveEditingFanPages} disabled={isLoading}>EDIT</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={tokenModal} toggle={toggleTokenModal} size="lg">
        <ModalHeader toggle={toggleTokenModal}>TOKEN MODAL</ModalHeader>
        <ModalBody>
        	<Form>
	        	{businessManagerList.map((bm) => {
	        		return <FormGroup key={bm.id}>
		            <Label for="ruleName">{bm.name}</Label>
		            <Input type="textarea" name="token" placeholder="ACCESS TOKEN" onChange={(e) => setBusinessMangerToken(bm.id, e.target.value)} value={bm.token} />
		          </FormGroup>
	        	})}
	        </Form>
        </ModalBody>
        <ModalFooter>
        	<Button color="secondary" onClick={toggleTokenModal}>CLOSE</Button>
					<Button color="primary" onClick={saveBusinessManager} disabled={isLoading}>SAVE</Button>
        </ModalFooter>
      </Modal>
      <MapAccountModal mapAccountModal={mapAccountModal} facebookPages={facebookPages} toggleMapAccountModal={toggleMapAccountModal} businessManagerList={businessManagerList} saveMapping={saveMapping}
          cmsProperties={cmsProperties} pixels={pixels} />

      <MapFanPageModal fanPagesModal={fanPagesModal} toggleFanPagesModal={toggleFanPagesModal} saveFanPageMap={saveFanPageMap}
        mappableFanPages={mappableFanPages} isLoading={isLoading} />

      <MapFacebookAccountRules facebookAccountRules={facebookAccountRules} 
            facebookAccountRuleModal={facebookAccountRuleModal} 
            saveAccountRules={saveAccountRules} editingFacebookAccountRule={editingFacebookAccountRule}
            toggleFacebookAccountRuleModal={toggleFacebookAccountRuleModal} isLoading={isLoading} />

    </Container>
  );
}





export default FacebookAccountMapper;
