import React from "react";
import cx from "classnames";

import {
  Card,
  CardBody,
  Row,
  Button, 
  Form,
  Col, 
  ButtonGroup,
  Modal, ModalHeader, ModalBody, ModalFooter,
  ListGroup, ListGroupItem
} from "reactstrap";


function chunk(array, size) {
  const chunked_arr = [];
  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
}

const UserRoutesModel = (props) => {
	const isActive = (route) => {
		return props.user.attributes.routes && 
						props.user.attributes.routes.indexOf(route) !== -1;
	}

  const createSetupRoutes = () => {
    return props.allRoutes.map((route, key) => {
      if (route.children) {
      	let columns = chunk(route.children.filter(child => child.icon), 3);
        return (
      		<Row key={key}>
      			<Col xl={12} className="p-3">
	      			<div className="mb-2">
		    	      <i className={route.icon} />
		            <h3 style={{display:"inline"}} className="ml-1">{route.name}</h3>
		          </div>
		        </Col>
		        {columns.map((col, kkk) => <Col xl={3} key={kkk}>
	    	     		<ListGroup>
			            {col.map((child, childkey) => {
			            	let {block, table, name} = child;
										const ROUTE_KEY = `${block}:${table||name}` ;
			            	let activeChild = isActive(ROUTE_KEY);
			              return <ListGroupItem tag="button" key={childkey} active={activeChild} 
					              	onClick={e => {e.preventDefault(); props.activateRoute(ROUTE_KEY);}}>
					              	{child.name}</ListGroupItem>
			            })}
	        			</ListGroup>
        			</Col>
        		)}
      		</Row>);
      }
      let {block, name} = route;
			const ROUTE_KEY = `${block}:${name}`;
      if ( route.component === "DashboardRealTime") {
        return (
          <Row  key={key}>
          <Col xl={12} className="p-3">
            <ButtonGroup>
              <Button size="sm" onClick={e => {e.preventDefault(); props.activateRoute(ROUTE_KEY);}}  className={cx(isActive(ROUTE_KEY) ? "active" : "")} >
                <i className={route.icon} />
                <h3 style={{display:"inline"}} className="ml-1">{route.name}</h3>
              </Button>
              <Button size="sm" disabled={!isActive(ROUTE_KEY)} onClick={e => {e.preventDefault(); props.toggleDashboardRealTimeModal();}}><i className="fas fa-cogs"></i></Button>
            </ButtonGroup>
          </Col></Row>
        );
      } else {
        return (
          <Row  key={key}>
          <Col xl={12} className="p-3">
            <Button size="sm" onClick={e => {e.preventDefault(); props.activateRoute(ROUTE_KEY);}}  className={cx(isActive(ROUTE_KEY) ? "active" : "")} >
              <i className={route.icon} />
              <h3 style={{display:"inline"}} className="ml-1">{route.name}</h3>
            </Button>
          </Col></Row>
        );  
      }
      

    });
  };

  // const closeBtn = <button className="close" onClick={() => props.close(false)} style={{ fontSize: "2rem" }}>&times;</button>;

  return (
    <Modal isOpen={props.isOpen} toggle={() => props.close(false)} size="lg" className="full-screen">
    <ModalHeader className="pb-0" toggle={() => props.close(false)} 
    	close={<ButtonGroup>
  			<Button className="mr-1" size="sm" color="danger" onClick={() => props.clearAllRoutes()}>CLEAR ALL</Button>
  			<Button size="sm" color="primary" onClick={() => {props.save(); props.close();}}>SAVE</Button>
  		</ButtonGroup>
    	}>
    	USER ROUTES  	
    </ModalHeader>
    <ModalBody>
    	<Row>
				<Col className="order-xl-1" xl="12">
	        <Card className="bg-secondary shadow pt-0">
	          <CardBody>
	            <Form>
	            	{createSetupRoutes()}
	            </Form>
	          </CardBody>
	        </Card>
	      </Col>
	    </Row>
    </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => {props.save(); props.close();}}>SAVE</Button>
      </ModalFooter>
    </Modal>
  );
}

export default UserRoutesModel;