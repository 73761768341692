import React, {useState,useEffect} from "react";
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input } from "reactstrap";
import { API } from 'aws-amplify';




const UTM_CAMPAIGN = [
  { id: "automotive", name: "automotive" },
  { id: "education", name: "education" },
  { id: "fashion", name: "fashion" },
  { id: "finance", name: "finance" },
  { id: "flight", name: "flight" },
  { id: "generic", name: "generic" },
  { id: "health", name: "health" },
  { id: "household", name: "household" },
  { id: "services", name: "services" },
  { id: "shopping", name: "shopping" },
  { id: "tech", name: "tech" },
  { id: "travel", name: "travel" }
  // { value: "business", label: "business" },
  // { value: "internet", label: "internet" },
  // { value: "health", label: "health" },
  // { value: "astrology", label: "astrology" },
  // { value: "gambling", label: "gambling" }
];



const MapAccountModal = (props) => {
  const { mapAccountModal, toggleMapAccountModal, cmsProperties } = props;

  const [domain, setDomain] = useState("");
  const [utm_source, setUTMSource] = useState("");

  const [pixel, setPixel] = useState("");
  useEffect(() => {
    if ( cmsProperties[domain] && cmsProperties[domain][utm_source] ) {
      setPixel(cmsProperties[domain][utm_source].pixel_id)
    }
  }, [domain, utm_source])

  const [businessManager, selectBM] = useState("");
  useEffect(() => {
    if ( businessManager !== "")
      loadFacebookAccounts();
  }, [businessManager]);

  const [accountName, setAccountName] = useState("");
  const [accountID, setAccountID] = useState("");
  useEffect(() => {
    if (accountID) {
      const [account] = facebookAccounts.filter((a) => a.id === accountID);
      setAccountName(account.name)
    }
  }, [accountID])

  const [facebookAccounts, setFacebookAccounts] = useState([]);
  const loadFacebookAccounts = async () => {
    let {facebookAccounts} = await API.get('customAPI', '/facebookAccountMapper/facebookAccount', {
      queryStringParameters: {
        businessManagerID: businessManager
      }
    });
    if ( facebookAccounts )
      setFacebookAccounts(facebookAccounts);
  }

  const [utm_campaign, setUTMCampaign] = useState("");


  const [selectedFacebookPages, setSelectedFacebookPages] = useState([]);
  const addFacebookPage = (e) => {
    const selectedOptions = [...e.target.selectedOptions].map(o => o.value);
    setSelectedFacebookPages(selectedOptions)
  }

  const [activity, setActivity] = useState("");
  const [timezone, setTimezone] = useState("");
  const [buyingStrategy, setBuyingStrategy] = useState("mix");

  return (
    <Modal isOpen={mapAccountModal} toggle={toggleMapAccountModal} size="lg">
      <ModalHeader className="pb-1" toggle={toggleMapAccountModal}>Facebook Account Mapping</ModalHeader>
      <ModalBody className="pt-1">
        <Row>
          <Col>
            <Form>
              <FormGroup>
                <Label for="fbAccount">Activity: </Label>
                <Input type="select" name="activity" id="activity" onChange={(e) => setActivity(e.target.value)} value={activity}>
                  <option key="empty-activity" value="">----</option>
                  {["yahooAdp", "medianet", "techads", "clickso", "techads_bing", "youniversal_xml", "ymyahoo_simply", "bing2click", "mgid_perion", "mq", "techads_iacbing2", "freestar", "dg_yahooxml"].map((act,i) => <option key={act} value={act}>{act}</option>)}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="fbAccount">Business Manager: </Label>
                <Input type="select" name="businessManager" id="businessManager" onChange={(e) => selectBM(e.target.value)} value={businessManager}>
                  <option key="empty-bm" value="">----</option>
                  {props.businessManagerList.map((bm,i) => <option key={bm.id} value={bm.id}>{bm.name}</option>)}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="ruleName">Account Name: </Label>
                <Input type="select" name="accountID" id="accountID" onChange={(e) => setAccountID(e.target.value)} value={accountID}>
                  <option key="empty-account" value="">----</option>
                  {facebookAccounts.map((account,i) => <option key={account.id} value={account.id}>{account.name}</option>)}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="ruleName">Vertical: </Label>
                <Input type="select" name="utm_campaign" id="utm_campaign" onChange={(e) => setUTMCampaign(e.target.value)} value={utm_campaign}>
                  <option key="empty-utm_campaign" value="">----</option>
                  {UTM_CAMPAIGN.map((utm_campaign,i) => <option key={utm_campaign.id} value={utm_campaign.name}>{utm_campaign.name}</option>)}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="ruleName">Buying Strategy: </Label>
                <Input type="select" name="buyingStrategy" id="buyingStrategy" value={buyingStrategy} onChange={(e) => setBuyingStrategy(e.target.value)}>
                  <option key="empty-strategy" value="mix">MIX</option>
                  <option key="lowestcost" value="lowestcost">Lowest Cost</option>
                  <option key="bidcap" value="bidcap">Bid Cap</option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="ruleName">Domain: </Label>
                <Input type="select" name="domain" id="domain" onChange={(e) => setDomain(e.target.value)} value={domain}>
                  <option key="empty-domain" value="">----</option>
                  {Object.keys(cmsProperties).map((domain,i) => <option key={domain} value={domain}>{domain}</option>)}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="ruleName">UTM SOURCE: </Label>
                <Input type="select" name="utm_source" id="utm_source" onChange={(e) => setUTMSource(e.target.value)} value={utm_source}>
                  <option key="empty-utm_source" value="">----</option>
                  {Object.keys(cmsProperties[domain] || {}).map((utm_source,i) => <option key={utm_source} value={utm_source}>{utm_source}</option>)}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="ruleName">PIXEL ID: </Label>
                <Input type="select" name="pixel" id="pixel" onChange={(e) => setPixel(e.target.value)} value={pixel}>
                  <option key="empty-utm_source" value="">----</option>
                  {props.pixels.map((pixel,i) => <option key={`pixel-${i}`} value={pixel.id}>{pixel.name}</option>)}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="ruleName">FAN PAGEs: </Label>
                <Input type="select" name="fanPages" id="fanPages" value={selectedFacebookPages} onChange={addFacebookPage} multiple style={{height:"250px"}}>
                  <option key="empty-page" value="">----</option>
                  {props.facebookPages.map((fanpage,i) => <option key={fanpage.id} value={fanpage.id}>{fanpage.name} - ({fanpage.id})</option>)}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="ruleName">TIMEZONE: </Label>
                <Input type="select" name="timezone" id="timezone" value={timezone} onChange={(e) => setTimezone(e.target.value)}>
                  <option key="empty-page" value="">----</option>
                  <option key="UTC" value="UTC">UTC</option>
                  <option key="GMT" value="GMT">GMT</option>
                  <option key="PST" value="PST">PST</option>
                </Input>
              </FormGroup>
            </Form>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleMapAccountModal}>CLOSE</Button>
        <Button color="primary" onClick={() => {
          props.saveMapping({
            activity,
            businessManager,
            accountName,
            accountID,
            utm_campaign,
            buyingStrategy,
            domain,
            utm_source,
            pixel,
            selectedFacebookPages,
            timezone
          });
          toggleMapAccountModal();
        }}>SAVE</Button>
      </ModalFooter>
    </Modal>
  )
}

export default MapAccountModal;
