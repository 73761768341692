import React, {useState, useEffect} from "react";
import { API } from "aws-amplify";

import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, 
				Button, ListGroup, ListGroupItem} from "reactstrap";

const RealTimeDashboardModal = (props) => {
  const { isOpen, toggleModal} = props;


  const isActive = (key) => {
  	return props.user.attributes.rt_dashboard_widgets &&
  					Array.isArray(props.user.attributes.rt_dashboard_widgets) && 
            props.user.attributes.rt_dashboard_widgets.indexOf(key) !== -1;
  }


  const [widgets, setWidgets] = useState([]);

  const loadWidgets = async () => {
		const widgets =  await API.get('customAPI', '/dashboard-realtime-widgets', {
	  	queryStringParameters: {
	  		all:true
	  	}
	  });
	  setWidgets(widgets);
	}

  useEffect(() => {
  	loadWidgets()
  }, [])

  return (
		<Modal isOpen={isOpen} toggle={toggleModal}>
	    <ModalHeader toggle={toggleModal}>DASHBOARD REAL TIME WIDGETs:</ModalHeader>
	    <ModalBody>
	      <Row>
	        <Col>
	        	<ListGroup>
	            {widgets.map((child, childkey) => {
	            	let {title, table, type} = child;
								const WIDGET_KEY = `${type}:${table}` ;
	            	let activeChild = isActive(WIDGET_KEY);
	              return <ListGroupItem tag="button" key={childkey} active={activeChild} 
	              					onClick={e => {e.preventDefault(); props.activateWidgetRealTime(WIDGET_KEY);}}>{title}</ListGroupItem>
	            })}
      			</ListGroup>
	        </Col>
	      </Row>
	    </ModalBody>
	    <ModalFooter>
	      <Button color="secondary" onClick={toggleModal}>CLOSE</Button>
	    </ModalFooter>
	  </Modal>
  )
}



export default RealTimeDashboardModal;

