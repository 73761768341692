/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */
import { ConsoleLogger as Logger, Hub, Credentials, Parser, } from '@aws-amplify/core';
import * as S3 from 'aws-sdk/clients/s3';
const logger = new Logger('AWSS3Provider');
const AMPLIFY_SYMBOL = (typeof Symbol !== 'undefined' &&
    typeof Symbol.for === 'function'
    ? Symbol.for('amplify_default')
    : '@@amplify_default');
const dispatchStorageEvent = (track, event, attrs, metrics, message) => {
    if (track) {
        Hub.dispatch('storage', {
            event,
            data: { attrs, metrics },
            message,
        }, 'Storage', AMPLIFY_SYMBOL);
    }
};
const localTestingStorageEndpoint = 'http://localhost:20005';
/**
 * Provide storage methods to use AWS S3
 */
export default class AWSS3Provider {
    /**
     * Initialize Storage with AWS configurations
     * @param {Object} config - Configuration object for storage
     */
    constructor(config) {
        this._config = config ? config : {};
        logger.debug('Storage Options', this._config);
    }
    /**
     * get the category of the plugin
     */
    getCategory() {
        return AWSS3Provider.CATEGORY;
    }
    /**
     * get provider name of the plugin
     */
    getProviderName() {
        return AWSS3Provider.PROVIDER_NAME;
    }
    /**
     * Configure Storage part with aws configuration
     * @param {Object} config - Configuration of the Storage
     * @return {Object} - Current configuration
     */
    configure(config) {
        logger.debug('configure Storage', config);
        if (!config)
            return this._config;
        const amplifyConfig = Parser.parseMobilehubConfig(config);
        this._config = Object.assign({}, this._config, amplifyConfig.Storage);
        if (!this._config.bucket) {
            logger.debug('Do not have bucket yet');
        }
        return this._config;
    }
    /**
     * Get a presigned URL of the file or the object data when download:true
     *
     * @param {String} key - key of the object
     * @param {Object} [config] - { level : private|protected|public, download: true|false }
     * @return - A promise resolves to Amazon S3 presigned URL on success
     */
    async get(key, config) {
        const credentialsOK = await this._ensureCredentials();
        if (!credentialsOK) {
            return Promise.reject('No credentials');
        }
        const opt = Object.assign({}, this._config, config);
        const { bucket, download, cacheControl, contentDisposition, contentEncoding, contentLanguage, contentType, expires, track, } = opt;
        const prefix = this._prefix(opt);
        const final_key = prefix + key;
        const s3 = this._createS3(opt);
        logger.debug('get ' + key + ' from ' + final_key);
        const params = {
            Bucket: bucket,
            Key: final_key,
        };
        // See: https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html#getObject-property
        if (cacheControl)
            params.ResponseCacheControl = cacheControl;
        if (contentDisposition)
            params.ResponseContentDisposition = contentDisposition;
        if (contentEncoding)
            params.ResponseContentEncoding = contentEncoding;
        if (contentLanguage)
            params.ResponseContentLanguage = contentLanguage;
        if (contentType)
            params.ResponseContentType = contentType;
        if (download === true) {
            return new Promise((res, rej) => {
                s3.getObject(params, (err, data) => {
                    if (err) {
                        dispatchStorageEvent(track, 'download', {
                            method: 'get',
                            result: 'failed',
                        }, null, `Download failed with ${err.message}`);
                        rej(err);
                    }
                    else {
                        dispatchStorageEvent(track, 'download', { method: 'get', result: 'success' }, { fileSize: Number(data.Body['length']) }, `Download success for ${key}`);
                        res(data);
                    }
                });
            });
        }
        if (expires) {
            params.Expires = expires;
        }
        return new Promise((res, rej) => {
            try {
                const url = s3.getSignedUrl('getObject', params);
                dispatchStorageEvent(track, 'getSignedUrl', { method: 'get', result: 'success' }, null, `Signed URL: ${url}`);
                res(url);
            }
            catch (e) {
                logger.warn('get signed url error', e);
                dispatchStorageEvent(track, 'getSignedUrl', { method: 'get', result: 'failed' }, null, `Could not get a signed URL for ${key}`);
                rej(e);
            }
        });
    }
    /**
     * Put a file in S3 bucket specified to configure method
     * @param {String} key - key of the object
     * @param {Object} object - File to be put in Amazon S3 bucket
     * @param {Object} [config] - { level : private|protected|public, contentType: MIME Types,
     *  progressCallback: function }
     * @return - promise resolves to object on success
     */
    async put(key, object, config) {
        const credentialsOK = await this._ensureCredentials();
        if (!credentialsOK) {
            return Promise.reject('No credentials');
        }
        const opt = Object.assign({}, this._config, config);
        const { bucket, track, progressCallback } = opt;
        const { contentType, contentDisposition, cacheControl, expires, metadata, tagging, } = opt;
        const { serverSideEncryption, SSECustomerAlgorithm, SSECustomerKey, SSECustomerKeyMD5, SSEKMSKeyId, } = opt;
        const type = contentType ? contentType : 'binary/octet-stream';
        const prefix = this._prefix(opt);
        const final_key = prefix + key;
        const s3 = this._createS3(opt);
        logger.debug('put ' + key + ' to ' + final_key);
        const params = {
            Bucket: bucket,
            Key: final_key,
            Body: object,
            ContentType: type,
        };
        if (cacheControl) {
            params.CacheControl = cacheControl;
        }
        if (contentDisposition) {
            params.ContentDisposition = contentDisposition;
        }
        if (expires) {
            params.Expires = expires;
        }
        if (metadata) {
            params.Metadata = metadata;
        }
        if (tagging) {
            params.Tagging = tagging;
        }
        if (serverSideEncryption) {
            params.ServerSideEncryption = serverSideEncryption;
            if (SSECustomerAlgorithm) {
                params.SSECustomerAlgorithm = SSECustomerAlgorithm;
            }
            if (SSECustomerKey) {
                params.SSECustomerKey = SSECustomerKey;
            }
            if (SSECustomerKeyMD5) {
                params.SSECustomerKeyMD5 = SSECustomerKeyMD5;
            }
            if (SSEKMSKeyId) {
                params.SSEKMSKeyId = SSEKMSKeyId;
            }
        }
        try {
            const upload = s3.upload(params).on('httpUploadProgress', progress => {
                if (progressCallback) {
                    if (typeof progressCallback === 'function') {
                        progressCallback(progress);
                    }
                    else {
                        logger.warn('progressCallback should be a function, not a ' +
                            typeof progressCallback);
                    }
                }
            });
            const data = await upload.promise();
            logger.debug('upload result', data);
            dispatchStorageEvent(track, 'upload', { method: 'put', result: 'success' }, null, `Upload success for ${key}`);
            return {
                key: data.Key.substr(prefix.length),
            };
        }
        catch (e) {
            logger.warn('error uploading', e);
            dispatchStorageEvent(track, 'upload', { method: 'put', result: 'failed' }, null, `Error uploading ${key}`);
            throw e;
        }
    }
    /**
     * Remove the object for specified key
     * @param {String} key - key of the object
     * @param {Object} [config] - { level : private|protected|public }
     * @return - Promise resolves upon successful removal of the object
     */
    async remove(key, config) {
        const credentialsOK = await this._ensureCredentials();
        if (!credentialsOK) {
            return Promise.reject('No credentials');
        }
        const opt = Object.assign({}, this._config, config);
        const { bucket, track } = opt;
        const prefix = this._prefix(opt);
        const final_key = prefix + key;
        const s3 = this._createS3(opt);
        logger.debug('remove ' + key + ' from ' + final_key);
        const params = {
            Bucket: bucket,
            Key: final_key,
        };
        return new Promise((res, rej) => {
            s3.deleteObject(params, (err, data) => {
                if (err) {
                    dispatchStorageEvent(track, 'delete', { method: 'remove', result: 'failed' }, null, `Deletion of ${key} failed with ${err}`);
                    rej(err);
                }
                else {
                    dispatchStorageEvent(track, 'delete', { method: 'remove', result: 'success' }, null, `Deleted ${key} successfully`);
                    res(data);
                }
            });
        });
    }
    /**
     * List bucket objects relative to the level and prefix specified
     * @param {String} path - the path that contains objects
     * @param {Object} [config] - { level : private|protected|public }
     * @return - Promise resolves to list of keys for all objects in path
     */
    async list(path, config) {
    	  const credentialsOK = await this._ensureCredentials();
        if (!credentialsOK) {
            return Promise.reject('No credentials');
        }
        const opt = Object.assign({}, this._config, config);
        const { bucket, track, maxKeys } = opt;
        const prefix = this._prefix(opt);
        const final_path = prefix + path;
        const s3 = this._createS3(opt);
        logger.debug('list ' + path + ' from ' + final_path);
        const params = {
            Bucket: bucket,
            Prefix: final_path,
            MaxKeys: maxKeys
        };
        if ( opt.ContinuationToken ) {
        	params.ContinuationToken = opt.ContinuationToken;
        }
        if ( opt.Delimiter ) {
        	params.Delimiter = opt.Delimiter;
        }
       	if ( opt.StartAfter ) {
        	params.StartAfter = opt.StartAfter;
        }
        return new Promise((res, rej) => {
            s3.listObjectsV2(params, (err, data) => {
                if (err) {
                    logger.warn('list error', err);
                    dispatchStorageEvent(track, 'list', { method: 'list', result: 'failed' }, null, `Listing items failed: ${err.message}`);
                    rej(err);
                }
                else {
                    const list = data.Contents.map(item => {
                        return {
                            key: item.Key.substr(prefix.length),
                            eTag: item.ETag,
                            lastModified: item.LastModified,
                            size: item.Size,
                        };
                    });
                    dispatchStorageEvent(track, 'list', { method: 'list', result: 'success' }, null, `${list.length} items returned from list operation`);
                    logger.debug('list', list);
                    if ( data.NextContinuationToken ) {
                    	res({list,NextContinuationToken:data.NextContinuationToken})	
                    } else {
                    	res({list});	
                    }
                    
                }
            });
        });
    }
    /**
     * @private
     */
    _ensureCredentials() {
        return Credentials.get()
            .then(credentials => {
            if (!credentials)
                return false;
            const cred = Credentials.shear(credentials);
            logger.debug('set credentials for storage', cred);
            this._config.credentials = cred;
            return true;
        })
            .catch(err => {
            logger.warn('ensure credentials error', err);
            return false;
        });
    }
    /**
     * @private
     */
    _prefix(config) {
        const { credentials, level } = config;
        const customPrefix = config.customPrefix || {};
        const identityId = config.identityId || credentials.identityId;
        const privatePath = (customPrefix.private !== undefined ? customPrefix.private : 'private/') +
            identityId +
            '/';
        const protectedPath = (customPrefix.protected !== undefined
            ? customPrefix.protected
            : 'protected/') +
            identityId +
            '/';
        const publicPath = customPrefix.public !== undefined ? customPrefix.public : 'public/';
        switch (level) {
            case 'private':
                return privatePath;
            case 'protected':
                return protectedPath;
            default:
                return publicPath;
        }
    }
    /**
     * @private
     */
    _createS3(config) {
        const { bucket, region, credentials, dangerouslyConnectToHttpEndpointForTesting, } = config;
        let localTestingConfig = {};
        if (dangerouslyConnectToHttpEndpointForTesting) {
            localTestingConfig = {
                endpoint: localTestingStorageEndpoint,
                s3BucketEndpoint: true,
                s3ForcePathStyle: true,
            };
        }
        return new S3(Object.assign({ apiVersion: '2006-03-01', params: { Bucket: bucket }, signatureVersion: 'v4', region,
            credentials }, localTestingConfig));
    }
}
AWSS3Provider.CATEGORY = 'Storage';
AWSS3Provider.PROVIDER_NAME = 'AWSS3';