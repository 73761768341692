import React, { useState, useEffect } from "react";
import { API } from 'aws-amplify';
import cx from "classnames";

// import moment from "moment";
// import md5 from 'md5';
// import DatePicker from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.min.css';
// import DataTable from 'react-data-table-component';
// import memoize from 'memoize-one';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Table,
  Col,
  UncontrolledTooltip
  // List
  /*Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  FormGroup,*/
  // Input,
  // ButtonGroup,
  // Label,
} from "reactstrap";


import CreateShuffleRuleModal from "../../components/CreateShuffleRuleModal";
import CreateRedirectModal from "../../components/CreateRedirectModal";

import CONFIG from "../reports/config.js"

import Spinner from "../../components/Spinner";

const RedirectTool = (props) => {


  const [isLoading, setIsLoading] = useState(false);

  const blankRedirect = {
    // type: "shuffle",
    name: '',
    referer: 'hide',
    block_if_referer: "donot_block_if_referer",
    block_if_no_referer: "donot_block_if_no_referer",
    traffic_type: ["desktop", "tablet", "mobile"],
    frequencycap: {
      count: 0,
      ttl: -1
    },
    list_ip_targeting: "include",
    list_ip: [],
    list_market_targeting: "include",
    list_market: [],
    list_os_targeting: "include",
    list_os: [],
    list_browser_targeting: "include",
    list_browser: [],
    list_asn_targeting: "include",
    list_asn: [],
    backfill_url: "none",
    list_redirect_url: [],
    keywordshuffle: [],
    kshuffle: [],
    cloak: "donot_cloak",
    shuffleRules: []
  };
  const [redirect, setRedirect] = useState({...blankRedirect});

  const handleRedirectChange = (e) => {
    // console.log(e.target.name, e.target.value);
    // if ( e.target.selectedOptions ) {
    //   setRedirect({
    //     ...redirect,
    //     [e.target.name]: Array.from(e.target.selectedOptions, (item) => item.value),
    //   });
    // } else {
    setRedirect({
      ...redirect,
      [e.target.name]: e.target.value,
    });
    // }
  }

  const saveRedirect = async () => {
    setIsLoading(true);
    // console.log(redirect);
    let response = await API.post('customAPI', '/redirect-tool', {
      body: {...redirect}
    });
    setRedirectList(response.redirectList);
    setIsLoading(false);
    setRedirect({...blankRedirect});
    toggleCreateRedirect();
  }

  const saveShuffleRuleRedirect = async () => {
    setIsLoading(true);
    // console.log(redirect);
    let response = await API.post('customAPI', '/redirect-tool', {
      body: {...redirect}
    });
    setRedirectList(response.redirectList);
    setIsLoading(false);
    setRedirect({...blankRedirect});
    toggleCreateShuffleRule();
  }

  const deleteRedirect = async (redirect) => {
    setIsLoading(true);
    let response = await API.del('customAPI', '/redirect-tool', {
      body: {...redirect}
    });
    setRedirectList(response.redirectList);
    setIsLoading(false);
  }
  
  const [copyMode, setCopyMode] = useState(false);
  const [editMode, setEditingMode] = useState(false);
  const editRedirect = async (redirect) => {
    // console.log("QUI", redirect);
    setEditingMode(true);
    setCopyMode(false);
    setRedirect({...redirect});
    setCreateRedirect(!createRedirect);
  }
  const copyRedirect = async (redirect) => {
    console.log("COPY", redirect);
    setEditingMode(false);
    setCopyMode(true);
    setRedirect({...redirect});
    setCreateRedirect(!createRedirect);
  }

  const [createRedirect, setCreateRedirect] = useState(false);
  const toggleCreateRedirect = () => {
    setRedirect({...blankRedirect});
    setCreateRedirect(!createRedirect);
  }

  // SHUFFLE RULE 
  const [createShuffleRule, setCreateShuffleRule] = useState(false);
  const toggleCreateShuffleRule = (redirect) => {
    setCreateShuffleRule(!createShuffleRule);
  }

  const [redirectList, setRedirectList] = useState([]);
  const loadSettings = async () => {
    let {redirectList=[]} = await API.get('customAPI', '/redirect-tool');
    setRedirectList(redirectList);
  }

  useEffect(() => {
    loadSettings();
  }, []);


  const refreshToken = async () => {
    setIsLoading(true);
    await API.put('customAPI', '/redirect-tool', {
      body: {
        op: "refresh_redis"
      }
    });
    setIsLoading(false);
  }


  // console.log(redirects);

  return (
    <Container className="mt-2" fluid>
      <Row>
        <Col>
          <Card className="shadow">
            <CardHeader className={cx("border-0")}>
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Redirect TOOL</h3>
                </Col>
                <Col className="text-right" xs="4">
                  <button className="btn btn-icon btn-danger" type="button" 
                    style={{paddingTop:0, paddingLeft: 5, paddingBottom:0, paddingRight:5}}
                    onClick={e => { e.preventDefault(); refreshToken(); }}>
                    <i className="fas fa-plus"></i> REFRESH REDIRs
                  </button>
                  <button className="btn btn-icon btn-warning" type="button" 
                    style={{paddingTop:0, paddingLeft: 5, paddingBottom:0, paddingRight:5}}
                    onClick={e => { e.preventDefault(); setCreateRedirect(true); }}>
                    <i className="fas fa-plus"></i> Create redirect
                  </button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Spinner isLoading={isLoading}>
                <Table striped bordered hover responsive size="sm">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">ACTIONs</th>
                      <th scope="col">Name (TID)</th>
                      <th scope="col">Redirect URL</th>
                      <th scope="col">Backfill URL</th>
                      <th scope="col">Keyword SHUFFLES</th>
                      <th scope="col">K SHUFFLES</th>
                      <th scope="col">Referer</th>
                      <th scope="col">Block If Referer</th>
                      <th scope="col">Block If No Referer</th>
                      <th scope="col">Traffic Type</th>
                      <th scope="col">Frequency Cap</th>
                      <th scope="col">Ip Targeting</th>
                      <th scope="col">ASN Targeting</th>
                      <th scope="col">Market Targeting</th>
                      <th scope="col">Os Targeting</th>
                      <th scope="col">Browser Targeting</th>
                    </tr>
                  </thead>
                  <tbody>
                    {redirectList.map((redirect, idx) => {
                      redirect = {...blankRedirect, ...redirect};
                      return <tr key={idx}>
                        <td style={{paddingLeft:"0.5rem", paddingRight: "0.5rem" }}>
                          <Button size="sm" color="warning" onClick={e => {e.preventDefault(); editRedirect(redirect);}}><i className="fas fa-edit"></i></Button>
                          <Button size="sm" color="secondary" onClick={e => {e.preventDefault(); copyRedirect(redirect);}}><i className="fas fa-copy"></i></Button>
                          <Button type="icon" size="sm" color="danger" onClick={e => {e.preventDefault(); deleteRedirect(redirect);}}><i className="fa fa-trash"></i></Button>
                          {(typeof redirect.keywordshuffle === "string" && redirect.keywordshuffle.split(/\n/).length > 1)  || redirect.keywordshuffle.length > 1 &&
                            <Button type="icon" size="sm" color="info" onClick={e => {
                              e.preventDefault(); 
                              setRedirect(redirect);
                              toggleCreateShuffleRule(redirect);
                            }}><i className="fas fa-random"></i></Button>
                          }
                        </td>
                        <td>{redirect.name}</td>
                        <td>
                          <ul className="list-unstyled">
                            {redirect.list_redirect_url.map((r, iddx) => <div key={`r-${idx}-url-${iddx}`}>
                              <li color="primary" id={`r-${idx}-url-${iddx}`}>{r.url.substr(0,100)}, {r.percentage}</li>
                              <UncontrolledTooltip placement="right" target={`r-${idx}-url-${iddx}`}>
                                <span>{r.url}, {r.percentage}</span>
                              </UncontrolledTooltip>
                              </div>
                            )}
                          </ul>
                        </td>
                        <td>{redirect.backfill_url}</td>
                        <td>{redirect.cloak}</td>
                        <td>{(typeof redirect.keywordshuffle === "string") ? redirect.keywordshuffle.split(/\n/).length : redirect.keywordshuffle.length}</td>
                        <td>{(typeof redirect.kshuffle === "string") ? redirect.kshuffle.split(/\n/).length : redirect.kshuffle.length}</td>
                        <td>{redirect.referer}</td>
                        <td>{redirect.block_if_referer}</td>
                        <td>{redirect.block_if_no_referer}</td>
                        <td>{JSON.stringify(redirect.traffic_type)}</td>
                        <td>{JSON.stringify(redirect.frequencycap)}</td>
                        <td>{redirect.list_ip && redirect.list_ip.length > 0 ? `${redirect.list_ip_targeting}: ${JSON.stringify(redirect.list_ip)}`: ``}</td>
                        <td>{redirect.list_asn && redirect.list_asn.length > 0 ? `${redirect.list_asn_targeting}: ${JSON.stringify(redirect.list_asn)}`: ``}</td>
                        <td>{redirect.list_market && redirect.list_market.length > 0 ? `${redirect.list_market_targeting}: ${JSON.stringify(redirect.list_market)}`: ``}</td>
                        <td>{redirect.list_os && redirect.list_os.length > 0 ? `${redirect.list_os_targeting}: ${JSON.stringify(redirect.list_os)}`: ``}</td>
                        <td>{redirect.list_browser && redirect.list_browser.length > 0 ? `${redirect.list_browser_targeting}: ${JSON.stringify(redirect.list_browser)}`: ``}</td>
                      </tr>
                    })}
                  </tbody>
                </Table>
              </Spinner>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <CreateRedirectModal createRedirect={createRedirect} toggleCreateRedirect={toggleCreateRedirect} 
        redirect={redirect} setRedirect={setRedirect} handleRedirectChange={handleRedirectChange} 
        isLoading={isLoading} saveRedirect={saveRedirect} copyMode={copyMode} editMode={editMode} />

      <CreateShuffleRuleModal createShuffleRule={createShuffleRule} toggleCreateShuffleRule={toggleCreateShuffleRule} 
        redirect={redirect} isLoading={isLoading} CONFIG={CONFIG} 
        setRedirect={setRedirect} saveShuffleRuleRedirect={saveShuffleRuleRedirect} />

     {/* <Modal isOpen={createRedirect} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Last Execution Output</ModalHeader>
        <ModalBody>
          <ListGroup flush>
            {last_execution_output.map((line, iddx) =>
              <ListGroupItem color="info" key={`redirect-execution-time-${iddx}`}>{line}</ListGroupItem>
            )}
          </ListGroup>
        </ModalBody>
      </Modal>*/}
     
    </Container>
  );
}


export default RedirectTool;
