import { API } from 'aws-amplify';
import moment from 'moment';
// import qs from 'query-string';


const computeColumns = (breakdown, dimensions) => {

  let columns = breakdown.reduce((acc, r) => {
    if (r.checked) {
      acc.push(r);
    }
    return acc;
  }, []);

  columns = dimensions.reduce((acc, r) => {
    if (r.checked) {
      acc.push(r);
    }
    return acc;
  }, columns);

  return columns;
}


const fetchDataApi = async (params, reportType, reportname) => {
  let groupby = params.breakdown.reduce((acc, r) => {
    if (r.checked) {
      if ( r.selector === "campaign_name" || 
            r.selector === "place_name" || 
            r.selector === "advertiser_name" ) {
        acc.push("r."+r.selector);
      } else {
        acc.push("t."+r.selector);
      }
    }
    return acc;
  }, []);

  let applyFilters = {};
  params.filters.forEach((filter) => {
    if (filter.values.length) {
      let filterValue = [];
      filter.values.map((v) => v.isChecked && filterValue.push(v.value));
      if (filterValue.length) {
        applyFilters[filter.selector] = filterValue;
      }
    }
  });


  let queryStringParameters = {
    startDate: moment(params.startDate).format("YYYY-MM-DD"),
    endDate: moment(params.endDate).format("YYYY-MM-DD"),
    page: params.page,
    perPage: params.perPage,
    inputFilterSearchText: params.inputFilterSearchText,
    columns: computeColumns(params.breakdown, params.dimensions).map((c) => c.selector).join(","),
    groupby: groupby.join(","),
    filters: JSON.stringify(applyFilters),
    rules: JSON.stringify(params.rulesConfig),
    table: params.table,
    orderBy: params.orderBy ? JSON.stringify(params.orderBy) : false,
    overwriters: params.overwriters,
    source: reportname
  }
  if (params.download) {
    queryStringParameters.download = true;
  }
  
  return await API.get('customAPI', `/tqreport`, {
    queryStringParameters: queryStringParameters
  });
}

export {
  fetchDataApi, computeColumns
}
