import React, { useState } from "react";
import {
  Button,
  Label,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

import { MARKETS } from "../MARKETS.js";
import FlagListGroup from "../../../components/flagListGroup";

import SearchedImagesCard from "./selectedImages/searchedImagesCard";
import DropzoneImagesCard from "./selectedImages/dropzoneImagesCard";


const UploadForm = (props) => {

  const [keywordLists, setKeyword] = useState({});
  const [selectedMarkets, addSelectedMarket] = useState([]);
  const { selectedImages, dropzoneFiles, handleUploadImages, isLoading, toggleImageFromSelected, addLocalImageToSelected, toggle } = props;

  const selectMarket = (newMarket) => {
    let index = selectedMarkets.indexOf(newMarket);
    if (index === -1) {
      addSelectedMarket([...selectedMarkets, newMarket])
    } else {
      selectedMarkets.splice(index, 1);
      addSelectedMarket([...selectedMarkets]);
    }
  }

  return (
    <Row>
      <Col>
        <Card className="shadow bg-secondary">
          <CardHeader>
            <Row className="justify-content-between align-items-center">
              <Col>
                <h3 className="mb-0">UPLOAD CONFIGURATION</h3>
              </Col>
              <Col xs={{ size: "auto" }}>
                <Button outline color="primary" onClick={() => props.backToGallery()}>
                  BACK TO GALLERY
                </Button>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {selectedImages.length > 0 &&
              <SearchedImagesCard {...{ selectedImages, toggleImageFromSelected, toggle }} />
            }
            {dropzoneFiles.length > 0 &&
              <DropzoneImagesCard {...{ dropzoneFiles, addLocalImageToSelected, toggle }} />
            }
            <Form>
              {/* Description */}
              <FormGroup>
                <Label>MARKET SELECTION:</Label>
                <FlagListGroup markets={MARKETS} selectedMarkets={selectedMarkets} onClick={selectMarket} />
              </FormGroup>
              <Row>
                {selectedMarkets.map((market) =>
                  <Col xs={6} md={4} key={`keyword-for-${market}`}>
                    <FormGroup>
                      <Label>Keyword List for <span className="text-primary" style={{ textTransform: "uppercase" }}>{market}</span></Label>
                      <Input
                        className="form-control-alternative"
                        placeholder="List of keyword, one per row!"
                        rows="10"
                        type="textarea"
                        value={keywordLists[market]}
                        onChange={(e) => {
                          let inputKeyword = e.target.value;
                          setKeyword((prev) => {
                            prev[market] = inputKeyword
                            return { ...prev };
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <div className="text-center">
                <Button className="mt-4" color="primary" onClick={() => handleUploadImages(keywordLists)} disabled={isLoading || (selectedMarkets.length === 0)}>
                  UPLOAD
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default UploadForm;
