import React, { useState } from "react";
import Spinner from "../../../components/Spinner";
import cx from "classnames";
import { API } from "aws-amplify";
import moment from "moment";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

import FlagListGroup from "../../../components/flagListGroup.js";

const Upload = (props) => {

  const [selectedMarkets, addSelectedMarket] = useState([]);
  const [label, setLabel] = useState(`${props.userAuth.attributes.email}_${moment().format("YYYY-MM-DD HH:MM:ss")}`);
  const [UTMCampaign, setUTMCampaign] = useState("");
  const [offlineTestType, setOfflineTestType] = useState("real_time");
  const [keywordLists, setKeyword] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const MARKETS = [
    { value: "at", flag: "at", label: "Austria" },
    { value: "ar", flag: "ar", label: "Argentina"},
    { value: "au", flag: "au", label: "Australia" },
    { value: "br", flag: "br", label: "Brazil" },
    { value: "ca", flag: "ca", label: "Canada" },
    { value: "ch", flag: "ch", label: "Switzerland" },
    { value: "de", flag: "de", label: "Germany" },
    { value: "dk", flag: "dk", label: "Danmark" },
    { value: "es", flag: "es", label: "Spain" },
    { value: "fi", flag: "fi", label: "Finland" },
    { value: "fr", flag: "fr", label: "France" },
    { value: "in", flag: "in", label: "India" },
    { value: "it", flag: "it", label: "Italy" },
    { value: "mx", flag: "mx", label: "Mexico" },
    { value: "nl", flag: "nl", label: "Netherlands" },
    { value: "no", flag: "no", label: "Norway" },
    { value: "se", flag: "se", label: "Sweden" },
    { value: "uk", flag: "gb", label: "United Kingdom" },
    { value: "us", flag: "us", label: "United States" }
  ];





  const UTM_CAMPAIGN = [
    { value: "automotive", label: "automotive" },
    { value: "education", label: "education" },
    { value: "fashion", label: "fashion" },
    { value: "finance", label: "finance" },
    { value: "flight", label: "flight" },
    { value: "health", label: "health" },
    { value: "household", label: "household" },
    { value: "services", label: "services" },
    { value: "shopping", label: "shopping" },
    { value: "tech", label: "tech" },
    { value: "travel", label: "travel" },
    // { value: "business", label: "business" },
    // { value: "internet", label: "internet" },
    
    // { value: "astrology", label: "astrology" },
    // { value: "gambling", label: "gambling" }
  ];

  const selectMarket = (newMarket) => {
    let index = selectedMarkets.indexOf(newMarket);
    if (index === -1) {
      addSelectedMarket([...selectedMarkets, newMarket])
    } else {
      selectedMarkets.splice(index, 1);
      addSelectedMarket([...selectedMarkets]);
    }
  }
  const testKeywords = async () => {
  	setIsLoading(true);
    API.post('customAPI', '/offline-tester', {
  		body: {
  			keywordLists,
        offlineTestType,
        offlineLabel: label,
  			UTMCampaign
  		}
  	});
    alert("OFFLINE TEST SCHEDULED, HEAD TO CAPACITOR v2 TO ANALYSE YOUR RESULT AS SOON AS THEY ARE AVAILABLE!");
    setIsLoading(false);
    addSelectedMarket([])
    setLabel(`${props.userAuth.attributes.email}_${moment().format("YYYY-MM-DD HH:MM:ss")}`);
    setUTMCampaign("");
    setOfflineTestType("real_time");
    setKeyword({});
    // props.history.push({
    //   pathname: '/admin/keyword-tools/capacitor',
    //   search: "?uploadID=" + encodeURIComponent(label)
    // })
  }

  return (
    <Container className="mt-2" fluid>
      <Row>
        <Col>
          <Card className="bg-secondary shadow">
            <CardHeader className={cx("border-0")}>
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">OFFLINE TESTER</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
            	<Form>
                {/* Description */}
                <Row>
                	<Col lg="6">
                		<FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-label"
                        >
                          Upload label
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-label"
                          onChange={(e) => setLabel(e.target.value)}
                          value={label}
                          type="text">
                        </Input>
                      </FormGroup>
                	</Col>
                  <Col lg="4">
                    <label className="form-control-label">How many tests?</label>
                    {["real_time", "offline_test"].map((type, kkk) => {
                      let typeLabel = type === "real_time" ? "Test now, only one time!" 
                        : type === "offline_test" ? "Test at  7am, 12am and 8pm" 
                        : "??????"
                      return <Row form key={kkk}>
                      <Col>
                        <FormGroup check>
                          <Label check>
                            <Input type="radio" name="deal_type" value={type}
                              checked={offlineTestType === type}
                              onChange={e => {
                                setOfflineTestType(e.target.value);
                              }}/>{' '}
                            {typeLabel}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    })}
                  </Col>
	        		   	<Col lg="2" className="text-right">
	                  <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-utmcampaign"
                        >
                          UTM CAMPAIGN
                        </label>
	                    <Input
	                      className="form-control-alternative"
	                      id="input-utmcampagn"
	                      placeholder="UTM_CAMPAIGN"
	                      onChange={(e) => setUTMCampaign(e.target.value)}
	                      type="select">
	                      {[<option key="empty">UTM_CAMPAIGN</option>].concat(UTM_CAMPAIGN.map((m, key) => <option key={key} value={m.value}>{m.label}</option>))}
	                    </Input>
	                  </FormGroup>
	                </Col>
	              </Row>
                <Row>
                  <Col lg="12">
                    <FormGroup>
                      <Label>MARKET SELECTION:</Label>
                      <FlagListGroup fill={true} markets={MARKETS} selectedMarkets={selectedMarkets} onClick={selectMarket} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {selectedMarkets.map((market) =>
                    <Col xs={6} md={4} key={`keyword-for-${market}`}>
                      <FormGroup>
                        <Label>Keyword List for <span className="text-primary" style={{ textTransform: "uppercase" }}>{market}</span></Label>
                        <Input
                          className="form-control-alternative"
                          placeholder="List of keyword, one per row!"
                          rows="10"
                          type="textarea"
                          value={keywordLists[market]}
                          onChange={(e) => {
                            let inputKeyword = e.target.value;
                            setKeyword((prev) => {
                              prev[market] = inputKeyword
                              return { ...prev };
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
                <div className="text-center">
                  <Spinner loading={isLoading}>
                  	 	<Button className="mt-4" color="primary" type="button" onClick={() => testKeywords()} >
                        TEST
                      </Button>
                  </Spinner>
                </div>
        			</Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Upload;
