import React, { useState, useEffect } from "react";
import { API } from 'aws-amplify';
import { Row, Col } from "reactstrap";
import Spinner from "../../components/Spinner";
import WidgetTabs from "./widget_tabs";

const Widget = (props) => {

  const {title, type, table, currency} = props;

  // const [flipped, setFlipped] = useState(false);
  const columns = [
    {
    	label: "Profit",
    	selector: "profit",
    	suffix: "currency"
    },
    {
      label: "Revenue",
      selector: "revenue",
      suffix: "currency"
    },
    {
    	label: "CVR",
    	selector: "cvr",
    	suffix: "percentage"
    },
    {
    	label: "RPC",
    	selector: "rpc",
    	suffix: "currency"
    },
    {
    	label: "CPM",
    	selector: "cpm",
    	suffix: "currency"
    },
    {
    	label: "CPC",
    	selector: "cpc",
    	suffix: "currency"
    },
    {
    	label: "ROI",
    	selector: "roi",
    	suffix: "percentage"
    }
  ];
  const [data,setData] = useState([]);
  // const [dataBySourceByDate,setDataBySourceByDate] = useState({});
  const [loading, isLoading] = useState(false);

  const loadData = async () => {
    isLoading(true);
    const res = await API.get('customAPI', '/dashboard-realtime', {
      queryStringParameters: {
        type, table, columns: columns.map((c) => c.selector)
      }
    });
    setData(res);
    isLoading(false);
  }

  useEffect(() => {
    if ( table ) {
      loadData();
    }
  }, [table])

  return (
  	<>
  	<h1>{title}</h1>
  	<Row>
  		<Spinner isLoading={loading}>
  		{data.length ? data.map((row, idx) => {
  			return <WidgetTabs data={row} key={idx} columns={columns} currency={currency}/>
  		}) : 
  		<Col><h1 className="text-danger">NO DATA!</h1></Col>}
  		</Spinner>
    </Row>
    </>
  	 
  )
}

export default Widget;
