import React, { useState, useEffect } from "react";
import { API } from 'aws-amplify';
import cx from "classnames";

// import moment from "moment";
// import md5 from 'md5';
// import DatePicker from 'react-datepicker'
// import 'react-datepicker/dist/react-datepicker.min.css';
// import DataTable from 'react-data-table-component';
// import memoize from 'memoize-one';

import {
	Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Table,
  Col,
  ListGroup,
  ListGroupItem,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
  /*ModalFooter,
  FormGroup,*/
  // Input,
  // ButtonGroup,
  // Label,
} from "reactstrap";


import CreateTaboolaRuleModal from "../../../components/CreateTaboolaRuleModal";
import CONFIG from "../../reports/config.js"

const TIMEs = {
  "": "Once every hour/day ( as soon as revenue are out, or cost based everyhour )",
  "d0": "SUNDAY - only run if today is Sunday",
  "d1": "MONDAY - only run if today is Monday",
  "d2": "TUESDAY - only run if today is Tuesday",
  "d3": "WEDNESDAY - only run if today is Wednesday",
  "d4": "THURSDAY - only run if today is Thursday",
  "d5": "FRIDAY - only run if today is Friday",
  "d6": "SATURDAY - only run if today is Saturday",
  "d-0": "SKIP SUNDAY - only run if today is not a Sunday",
  "d-1": "SKIP MONDAY - only run if today is not a Monday",
  "d-2": "SKIP TUESDAY - only run if today is not a Tuesday",
  "d-3": "SKIP WEDNESDAY - only run if today is not a Wednesday",
  "d-4": "SKIP THURSDAY - only run if today is not a Thursday",
  "d-5": "SKIP FRIDAY - only run if today is not a Friday",
  "d-6": "SKIP SATURDAY - only run if today is not a Saturday",
  "0": "This rule run at 00:00 UTC ( only run at 00:00 UTC )",
  "1": "This rule run at 01:00 UTC ( only run at 01:00 UTC )",
  "2": "This rule run at 02:00 UTC ( only run at 02:00 UTC )",
  "3": "This rule run at 03:00 UTC ( only run at 03:00 UTC )",
  "4": "This rule run at 04:00 UTC ( only run at 04:00 UTC )",
  "5": "This rule run at 05:00 UTC ( only run at 05:00 UTC )",
  "6": "This rule run at 06:00 UTC ( only run at 06:00 UTC )",
  "7": "This rule run at 07:00 UTC ( only run at 07:00 UTC )",
  "8": "This rule run at 08:00 UTC ( only run at 08:00 UTC )",
  "9": "This rule run at 09:00 UTC ( only run at 09:00 UTC )",
  "10": "This rule run at 10:00 UTC ( only run at 10:00 UTC )",
  "11": "This rule run at 11:00 UTC ( only run at 11:00 UTC )",
  "12": "This rule run at 12:00 UTC ( only run at 12:00 UTC )",
  "13": "This rule run at 13:00 UTC ( only run at 13:00 UTC )",
  "14": "This rule run at 14:00 UTC ( only run at 14:00 UTC )",
  "15": "This rule run at 15:00 UTC ( only run at 15:00 UTC )",
  "16": "This rule run at 16:00 UTC ( only run at 16:00 UTC )",
  "17": "This rule run at 17:00 UTC ( only run at 17:00 UTC )",
  "18": "This rule run at 18:00 UTC ( only run at 18:00 UTC )",
  "19": "This rule run at 19:00 UTC ( only run at 19:00 UTC )",
  "20": "This rule run at 20:00 UTC ( only run at 20:00 UTC )",
  "21": "This rule run at 21:00 UTC ( only run at 21:00 UTC )",
  "22": "This rule run at 22:00 UTC ( only run at 22:00 UTC )",
  "23": "This rule run at 23:00 UTC ( only run at 23:00 UTC )",
  "0_revenue": "This rule run as soon as the 00:00 (UTC) revenue are available and it will continue to run till midnight",
  "1_revenue": "This rule run as soon as the 01:00 (UTC) revenue are available and it will continue to run till midnight",
  "2_revenue": "This rule run as soon as the 02:00 (UTC) revenue are available and it will continue to run till midnight",
  "3_revenue": "This rule run as soon as the 03:00 (UTC) revenue are available and it will continue to run till midnight",
  "4_revenue": "This rule run as soon as the 04:00 (UTC) revenue are available and it will continue to run till midnight",
  "5_revenue": "This rule run as soon as the 05:00 (UTC) revenue are available and it will continue to run till midnight",
  "6_revenue": "This rule run as soon as the 06:00 (UTC) revenue are available and it will continue to run till midnight",
  "7_revenue": "This rule run as soon as the 07:00 (UTC) revenue are available and it will continue to run till midnight",
  "8_revenue": "This rule run as soon as the 08:00 (UTC) revenue are available and it will continue to run till midnight",
  "9_revenue": "This rule run as soon as the 09:00 (UTC) revenue are available and it will continue to run till midnight",
  "10_revenue": "This rule run as soon as the 10:00 (UTC) revenue are available and it will continue to run till midnight",
  "11_revenue": "This rule run as soon as the 11:00 (UTC) revenue are available and it will continue to run till midnight",
  "12_revenue": "This rule run as soon as the 12:00 (UTC) revenue are available and it will continue to run till midnight",
  "13_revenue": "This rule run as soon as the 13:00 (UTC) revenue are available and it will continue to run till midnight",
  "14_revenue": "This rule run as soon as the 14:00 (UTC) revenue are available and it will continue to run till midnight",
  "15_revenue": "This rule run as soon as the 15:00 (UTC) revenue are available and it will continue to run till midnight",
  "16_revenue": "This rule run as soon as the 16:00 (UTC) revenue are available and it will continue to run till midnight",
  "17_revenue": "This rule run as soon as the 17:00 (UTC) revenue are available and it will continue to run till midnight",
  "18_revenue": "This rule run as soon as the 18:00 (UTC) revenue are available and it will continue to run till midnight",
  "19_revenue": "This rule run as soon as the 19:00 (UTC) revenue are available and it will continue to run till midnight",
  "20_revenue": "This rule run as soon as the 20:00 (UTC) revenue are available and it will continue to run till midnight",
  "21_revenue": "This rule run as soon as the 21:00 (UTC) revenue are available and it will continue to run till midnight",
  "22_revenue": "This rule run as soon as the 22:00 (UTC) revenue are available and it will continue to run till midnight",
  "23_revenue": "This rule run as soon as the 23:00 (UTC) revenue are available and it will continue to run till midnight",
};

const ruleConditionPeriod = {
  "today":"Today",
  "yesterday":"Yesterday",
  // "aggr365days":"Sum of last year: condition must be true after aggregating",
  "aggr30days":"Sum of last month: condition must be true after aggregating",
  "aggr2days": "Sum of last two days: condition must be true after aggregating",
  "aggr3days": "Sum of last three days: condition must be true after aggregating",
  "aggr7days": "Sum of last 7 days: condition must be true after aggregating"
};

const ruleApplyTO = {
	"all": "All campaign outcome at Campaign level",
	"account": "All campaign outcome at Account level",
	"campaign": "Specific campaign"
}

const TaboolaRules = (props) => {


  const [isLoading, setIsLoading] = useState(false);

  const [createRuleModal, setCreateRuleModal] = useState(false);

  const [taboolaAccounts, setTaboolaAccounts] = useState([]);

  const blankRule = {
  	account: "",
  	name: '',
  	applyTo: 'all',
  	applyToCampaign: "",
  	level: "placement",
  	action: "BLACKLIST",
    action_modifier: 0,
  	conditions: [],
  	schedule: "DAILY",
		time: "",
		period: "",
    type: "revenue"
  };
  const [rule, setRule] = useState({...blankRule});

  const handleRuleChange = (e) => {
    if ( e.target.selectedOptions ) {
      setRule({
        ...rule,
        [e.target.name]: Array.from(e.target.selectedOptions, (item) => item.value),
      });
    } else {
    	setRule({
  	    ...rule,
  	    [e.target.name]: e.target.value,
  	  });
    }
  }

  const saveRule = async () => {
  	setIsLoading(true);
  	console.log(rule);
  	let response = await API.post('customAPI', '/keyword-tools/taboola/rules', {
      body: {...rule}
    });
    
    // console.log(response);
    // console.log(rule);
    // response = await API.get('customAPI', '/keyword-tools/taboola/rules');
    // setCustomRules(response.rules);

    setTaboolaRules(response.rules);
    setIsLoading(false);
  	setRule({...blankRule});
  	toggleCreateRuleModal();
  }

  const [accountSelected, setAccountSelected] = useState("");
  useEffect(() => {

  	if ( rule.account !== -1 && rule.applyTo === "campaign" && accountSelected !== rule.account) {
  		setAccountSelected(rule.account);
  		loadCampaign()
  	}
  }, [rule]);

  const [campaignList, setCampaignList] = useState([]);
  const loadCampaign = async () => {
  	if ( rule.account !== -1 ) {
  		setIsLoading(true);
  		let response = await API.get('customAPI', '/keyword-tools/taboola/campaigns', {
  			queryStringParameters: {
  				account: rule.account
  			}
  		});
  		setCampaignList(response.campaigns);
  		setIsLoading(false);
  	}
  }

  const deleteRule = async (rule) => {
    setIsLoading(true);
    let response = await API.del('customAPI', '/keyword-tools/taboola/rules', {
      body: {...rule}
    });
    setTaboolaRules(response.rules);
    setIsLoading(false);
  }


  const [copyMode, setCopyMode] = useState(false);
  const [editMode, setEditingMode] = useState(false);
  const editRule = async (rule) => {
    console.log(rule.account);
    setEditingMode(true);
    setCopyMode(false);
    setRule({...rule, ["conditions"]: JSON.parse(rule.conditions)});
    setCreateRuleModal(!createRuleModal);
  }
  const copyRule = async (rule) => {
    console.log(rule.account);
    setEditingMode(false);
    setCopyMode(true);
    setRule({...rule, ["conditions"]: JSON.parse(rule.conditions)});
    setCreateRuleModal(!createRuleModal);
  }

  const toggleCreateRuleModal = () => {
  	setRule({...blankRule});
    setEditingMode(false);
    setCopyMode(false);
  	setCreateRuleModal(!createRuleModal);
  }


  const [lastExecutionOutput, setLastExecutionOutput] = useState([]);
  const [lastExecutionModal, setLastExecutionModal] = useState(false);
  const showLastExecution = (rule) => {
    console.log(rule.last_execution_output);
    setLastExecutionOutput(JSON.parse(rule.last_execution_output));
    setLastExecutionModal(true);
  }

  const [rules, setTaboolaRules] = useState([]);
  const loadSettings = async () => {
  	let response = await API.get('customAPI', '/keyword-tools/taboolaAutomation');
  	setTaboolaAccounts(response.taboolaAccounts);
  	let {rules} = await API.get('customAPI', '/keyword-tools/taboola/rules');
  	setTaboolaRules(rules);
  }

  useEffect(() => {
    loadSettings();
  }, []);


  // console.log(rules);

  return (
    <Container className="mt-2" fluid>
      <Row>
        <Col>
          <Card className="shadow">
            <CardHeader className={cx("border-0")}>
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Taboola rules dashboard</h3>
                </Col>
                <Col className="text-right" xs="3">
                  <button className="btn btn-icon btn-warning" type="button" 
                    style={{paddingTop:0, paddingLeft: 5, paddingBottom:0, paddingRight:5}}
                    onClick={e => { e.preventDefault(); setCreateRuleModal(true); }}>
                    <i className="fas fa-plus"></i> Create rule
                  </button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
            	<Table striped bordered hover responsive className="align-items-center">
	              <thead className="thead-light">
	                <tr>
	                  <th scope="col">Name</th>
	                  <th scope="col">Action</th>
                    <th scope="col">Rule Type</th>
	                  <th scope="col">Accounts</th>
                    <th scope="col">Apply To</th>
	                  <th scope="col">Schedule</th>
	                  <th scope="col">Period</th>
	                  <th scope="col">Time</th>
                    <th scope="col">Last Execution</th>
                    <th scope="col">Actions</th>
	                </tr>
	              </thead>
	              <tbody>
	            		{rules.map((rule, idx) => {
	            			return <tr key={idx}>
	                    <td> 
	                    	<span href="#" id={`rule-${idx}`}>{rule.name}</span>
	                    	<UncontrolledTooltip placement="right" target={`rule-${idx}`}>
									        <ListGroup flush>
									          {JSON.parse(rule.conditions).map((c, iddx) => 
									            <ListGroupItem color="warning" key={`rule-${idx}-cond-${iddx}`}>{c.dimension} {c.op} {c.value}</ListGroupItem>
									          )}
									        </ListGroup>
									      </UncontrolledTooltip>
	                    </td>
	                    <td>{rule.action}</td>
                      <td>{rule.type}</td>
                      <td>{taboolaAccounts[rule.account] ? taboolaAccounts[rule.account].name : rule.account}</td>
	                    <td>{ruleApplyTO[rule.applyTo] || rule.applyTo}</td>
	                    <td>{rule.schedule}</td>
	                    <td>{rule.period}</td>
	                    <td>{TIMEs[rule.time]}</td>
                      <td><span style={{cursor: "pointer"}} onClick={(e) => {e.preventDefault(); showLastExecution(rule);}}>{rule.last_execution_time}</span></td>
	                    <td>
	                      <Button size="sm" color="danger" onClick={e => {e.preventDefault(); deleteRule(rule);}}>DELETE</Button>
                        <Button size="sm" color="warning" onClick={e => {e.preventDefault(); editRule(rule);}}>EDIT</Button>
                        <Button size="sm" color="secondary" onClick={e => {e.preventDefault(); copyRule(rule);}}>COPY</Button>
	                    </td>
	                  </tr>
	                })}
	              </tbody>
	            </Table>
                  {/*<DataTable
                    responsive={true}
                    columns={columns(toggleEditRuleModal,setLastExecutionOutput, toggle, saveNotification, setEditingRule, setSelectedSkipDates, toggleSkipUntillModal, deleteRule, fbAccountsMapping)}
                    data={customRules.filter((r) => ruleNameFilter && ruleNameFilter.length ? r.name === ruleNameFilter : true)
                      .filter((r) => accountNameFilter && accountNameFilter.length ? r.fbAccount == accountNameFilter : true)
                      .filter((r) => filterByActivity && filterByActivity.length ? r.activity === filterByActivity : true)
                      .filter((r) => filterByDomain && filterByDomain.length && fbAccountsMapping[r.fbAccount] ? fbAccountsMapping[r.fbAccount].domain === filterByDomain : true)
                      .filter((r) => filterByBuyingStrategy && filterByBuyingStrategy.length && fbAccountsMapping[r.fbAccount] ? fbAccountsMapping[r.fbAccount].buying_strategy === filterByBuyingStrategy : true)
                      .filter((r) => filterByUTMCampaign && filterByUTMCampaign.length && fbAccountsMapping[r.fbAccount] ? fbAccountsMapping[r.fbAccount].utm_campaign === filterByUTMCampaign : true)}
                    progressPending={isLoading}
                    persistTableHead={true}
                    noHeader={true}
                    striped={true}
                    highlightOnHover
                    dense={true}
                    pagination={true}
                    paginationPerPage={50}
                    paginationRowsPerPageOptions={[10,25,50,100,150,500,1000]}
                  />*/}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <CreateTaboolaRuleModal createRuleModal={createRuleModal} toggleCreateRuleModal={toggleCreateRuleModal} 
      	rule={rule} setRule={setRule} handleRuleChange={handleRuleChange} 
      	TIMEs={TIMEs} CONFIG={CONFIG} taboolaAccounts={taboolaAccounts} ruleConditionPeriod={ruleConditionPeriod}
      	isLoading={isLoading} saveRule={saveRule} campaignList={campaignList} copyMode={copyMode} 
        editMode={editMode} />

     <Modal isOpen={lastExecutionModal} toggle={() => setLastExecutionModal(!lastExecutionModal)} size="lg">
        <ModalHeader toggle={() => setLastExecutionModal(!lastExecutionModal)}>Last Execution Output</ModalHeader>
        <ModalBody>
          <ListGroup flush>
            {console.log(lastExecutionOutput)}
            {lastExecutionOutput.map((line, iddx) =>
              <ListGroupItem color="info" key={`rule-execution-time-${iddx}`}>{line}</ListGroupItem>
            )}
          </ListGroup>
        </ModalBody>
      </Modal>
     
    </Container>
  );
}


export default TaboolaRules;
