import React, {useState} from "react";
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, InputGroup,
  InputGroupAddon} from "reactstrap";

// import Spinner from "./Spinner";

const CreateRPCModal = (props) => {
  const { isLoading, createRPC, toggleCreateRPC, 
          rpc, setRPC, handleRPCChange, saveRPC, editMode} = props;

  console.log(rpc);

  return (
		<Modal isOpen={createRPC} toggle={toggleCreateRPC} size="lg">
      <ModalHeader toggle={toggleCreateRPC}>Create RPC Modal</ModalHeader>
      <ModalBody className="pt-0">
    	 	<Form>
         	<FormGroup>
            <Label for="input-redirectname">
              Label:
            </Label>
            <Input
              className="form-control"
              id="input-rpclabel"
              name="label"
              type="text"
              disabled={editMode}
              placeholder="RPC Label (&rv=label)"
              value={rpc.label}
              onChange={handleRPCChange}
            />
          </FormGroup>
         	<FormGroup>
            <Label>Value: </Label>
            <Input
              className="form-control"
              id="input-rpc-value"
              name="value"
              type="number"
              placeholder="RPC Value"
              value={rpc.value}
              onChange={handleRPCChange}
            />
          </FormGroup>
      	</Form>
      </ModalBody>
      <ModalFooter>
	      <Button color="secondary" onClick={toggleCreateRPC}>CLOSE</Button>
	      <Button color="primary" disabled={isLoading} onClick={saveRPC}>SAVE</Button>
	    </ModalFooter>
    </Modal>
  )
}

export default CreateRPCModal;

