import React, {useState} from "react";
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, ListGroup, ListGroupItem, FormGroup, Input, Label, Button } from "reactstrap";

const MoveToModal = (props) => {
  const { isOpen, toggle, movingKeyword } = props;

  const [seed, setSeed] = useState("");

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="pb-1" toggle={toggle}>Group more keyword!</ModalHeader>
      <ModalBody className="pt-1">
        <Row>
          <Col>
            <ListGroup>
              {Object.keys(movingKeyword).map((k, i) => (
                <ListGroupItem key={i}>{k}</ListGroupItem>  
              ))}
            </ListGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>Keyword seed</Label>
              <Input
                className="form-control-alternative"
                placeholder="Seed"
                type="select"
                value={seed}
                onChange={(e) => setSeed(e.target.value)}
              >
                <option value="---">----</option>
                {props.groups.map((m) => <option key={m.seed} value={m.seed}>{m.seed}</option>)}
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => {
            props.seedKeyword(Object.keys(movingKeyword), seed);
            toggle();
        }}>MOVE</Button>
      </ModalFooter>
    </Modal>
  )
}

export default MoveToModal;
