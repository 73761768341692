
import React from 'react';

const Header = (props) => {
  const { style, node } = props;
  const iconStyle = { color: "lightslategrey", marginRight: '0.5rem' };

  // If it's an image render somthing custom
  if (!node.children && node.image) {
    return (
      <div style={style.base}>
        <div style={{ ...style.title, lineHeight: "40px" }}>
          <i className="far fa-file-image" style={{ ...iconStyle, fontSize: "1rem" }} />
          <div className="mr-2 hover-primary">{node.image.key}</div>
          <div className="d-block p-1 h-100" style={{ position: "relative" }}>
            <img alt="" src={`https://s3-eu-west-1.amazonaws.com/static.blogger.co.uk/thumbs/${node.image.key}?v=${new Date().getTime()}`} width="55px" height="auto" max-height="100px" />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={style.base}>
        <div style={{ ...style.title }}>
          <i className="fa fa-folder" style={iconStyle} />
          {node.name}
        </div>
      </div>
    );
  }

};

export default Header;
