import React from "react";
import moment from "moment";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { LineChart, XAxis, Line, ResponsiveContainer } from 'recharts';

import CustomizedAxisTick from "../../../components/CustomizedAxisTick";
import Spinner from "../../../components/Spinner";


const calculateDelta = (newVal=0, origVal=0) => {
  const delta = ((newVal - origVal) / Math.abs(origVal)).toFixed(2) * 100
  if (delta === Infinity) {
    return 0
  }
  return delta
}

const Stats = (props) => {
  // console.log(props);
  const { loading, data={}, currency="!!", displayDate } = props;
  
  const dayBeforeDisplayDate = moment(displayDate).subtract(1, "days").format("YYYY-MM-DD");

  let delta = false;
  try {
    delta = calculateDelta(data[displayDate].profit, data[dayBeforeDisplayDate].profit);
    delta = delta.toFixed(1);
  } catch (e) {}
      
  return (
    <Card className="card-stats mb-4 pointer">
      <CardBody>
        <CardTitle tag="h5" className="text-uppercase text-muted">
          {props.title}
        </CardTitle>
        <Spinner loading={loading}>
          <p className="h3 mb-0">
            <small className="text-muted">Revenue:</small>
            <span className="float-right">{(data[displayDate] ? data[displayDate].revenue : 0).toFixed(2)} {currency}</span>
          </p>
          <p className="h3 mb-0">
            <small className="text-muted">Cost:</small>
            <span className="float-right">{(data[displayDate] ? data[displayDate].cost : 0).toFixed(2)} {currency}</span>
          </p>
          <p className="h2 mb-1">
            <small className="text-muted">Profit:</small>
            <span className="float-right font-weight-bold">{(data[displayDate] ? data[displayDate].profit : 0).toFixed(2)} {currency}</span>
            <small className="float-right text-muted" style={{ marginRight: "5px", marginTop: "2px" }}>({(data[dayBeforeDisplayDate] ? data[dayBeforeDisplayDate].profit : 0).toFixed(2)} {currency})</small>
          </p>
          <ResponsiveContainer id="little-chart" aspect={3}>
            <LineChart
              width={200}
              height={200}
              data={Object.keys(data)
                .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
                .map((date) => {
                  return {
                    ...data[date],
                    date: date
                  };
                })}
            >
              <XAxis dataKey="date" height={60} tick={<CustomizedAxisTick />} />
              <Line type="monotone" dataKey="profit" stroke="#0088FE" dot={false} />
            </LineChart>
          </ResponsiveContainer>
          <Row className="justify-content-between mt-3 align-content-center">
            <Col>
              <p className="mb-0 text-muted text-sm">
                { delta ?
                  <><span className={delta < 0 ? 'text-danger mr-2' : 'text-success mr-2'}>
                    <i className={delta < 0 ? 'fa fa-arrow-down pr-1' : 'fa fa-arrow-up pr-1'} />
                    {delta}%
                  </span>
                  <span className="text-nowrap"> Since yesterday</span>
                  </>
                  : 
                  <span className='text-danger mr-2' style={{fontSize:"0.7rem"}}>
                    <i className='fa fa-exclamation pr-1' />
                    not enough data to show delta!
                  </span>
                }
              </p>
            </Col>
            <Col xs={{ size: "auto" }} className="pointer" onClick={props.handleFlip}>
              <i className="fas fa-chart-pie text-primary" style={{ fontSize: "1.55rem" }} ></i>
            </Col>
          </Row>
        </Spinner>
      </CardBody>
    </Card>
  )
}

export default Stats;
