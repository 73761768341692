import React from "react";
import cx from "classnames";

import SelectImages from "./imageFixModalStep/selectImages";
import LocalFilesPreview from "./imageFixModalStep/localFilesPreview.js";

import {
  Card,
  CardBody,
  Row,
  Col,
  Modal, 
  ModalHeader, 
  ModalBody,
  ListGroup,
  ListGroupItem,
  Button,
  FormGroup,
  Label,
  Input
} from "reactstrap";

import { LazyLoadImage } from 'react-lazy-load-image-component';

import Spinner from "../../../components/Spinner";

const ImageFixModal = (props) => {
  const {isLoading, image, fixImages, selectedImages, selectKeyword, 
          selectedKeywords, step, setStep, localFiles,
            newKeywordList, handleNewKeywordListChange} = props;

  
  return (
    <Modal isOpen={props.isOpen} toggle={() => props.toggle()} size="lg" className="full-screen">
      {(selectedImages.length + localFiles.length) > 1 &&
        <div className="sticky-top">
          <Button color="danger" onClick={(e) => {
            if ( step === 2 ) {
              fixImages(selectedImages, selectedKeywords, image, localFiles, newKeywordList);
            } else {
              setStep(2);
            }
          }} className="float-right m-2"><i className="far fa-copy"></i> DONE</Button>
          {step === 2 && <Button color="info" onClick={(e) => setStep(1)} className="float-right m-2">
                            <i className="far fa-copy"></i> BACK</Button>}
        </div>
      }
      <ModalHeader className="pb-0" toggle={() => props.toggle()}>FIX IMAGEs FOR {image.market}/{image.keyword}</ModalHeader>
      <ModalBody>
        <Spinner isLoading={isLoading}>
        {step === 1 && <SelectImages {...props} /> }
        {step === 2 && 
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow pt-0">
                  <CardBody>
                    <h3>Selected images:</h3>
                    <Row>
                      {selectedImages.map((imagePath, i) => {
                        let loadUrl = imagePath;
                        if ( imagePath.startsWith("s3://") ) {
                          loadUrl = `https://s3-eu-west-1.amazonaws.com/static.blogger.co.uk/thumbs/${imagePath.replace('s3://','')}?v=${new Date().getTime()}`;
                        }
                        if ( imagePath.startsWith("depositphotos:") ) {
                          let infoPath = imagePath.split('---');
                          loadUrl = infoPath[1];
                        }
                        return <Col xs={2} key={`image-${i}`} className="mb-2">
                                  <LazyLoadImage
                                    alt={`image-${i}`}
                                    className="img-fluid"
                                    src={loadUrl} />
                              </Col>
                      })}
                    </Row>
                    <hr/>
                    <LocalFilesPreview dropzoneFiles={localFiles} />
                    <hr/>
                    <Row>
                      <Col>
                        <h3>Do you want to replace the images for other keyword?</h3>
                        <ListGroup flush>
                          {image!==false && Object.keys(image.suggestions).map((sugg, idx) => {
                            return <ListGroupItem key={`sugg-${idx}`} tag="button" action onClick={(e) => selectKeyword(sugg)}
                                      className={cx("img-fluid p-1", selectedKeywords.indexOf(sugg) !== -1 ? "active" : "")}>{sugg}</ListGroupItem>
                          })}
                        </ListGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <h3>Do you want to use these images for new keywords?</h3>
                          <Input
                            placeholder="market,keyword"
                            type="textarea"
                            rows="10"
                            name="upload-csv-text"
                            id="upload-csv-text"
                            value={newKeywordList}
                            onChange={handleNewKeywordListChange}
                          />
                        </FormGroup>
                      </Col>

                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          }
        </Spinner>
      </ModalBody>  
    </Modal>
  );
};

export default ImageFixModal;