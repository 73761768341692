import React from "react";

import KeywordList from "./keywordList"
import {
  Row
} from "reactstrap";

const Board = React.memo(({groups, saveEditingSeed, deleteKeyword, makeitSeedKeyword, moveTo, archiveSeed, collapseAll, keywordTouched}) => {

  let columns = [];
  let i,j,chunk = 5;
  for (i=0,j=groups.length; i<j; i+=chunk) {
    columns.push(groups.slice(i,i+chunk));
  }

  if ( groups.length === 0 ) {
    return <h1>SELECT A MARKET TO LOAD!</h1>;
  }
  
  return columns.map((col, i) => (
    <Row className="bg-secondary pb-4" key={`col-${i}`}>
      {col.map((row, ind) => (
        <KeywordList key={(i*chunk)+ind} ind={(i*chunk)+ind} row={row} saveEditingSeed={saveEditingSeed} 
          deleteKeyword={deleteKeyword} makeitSeedKeyword={makeitSeedKeyword} moveTo={moveTo} 
          keywordTouched={keywordTouched} archiveSeed={archiveSeed} isListOpen={!collapseAll} />
      ))}
    </Row>
  ))
});

export default Board;