import React, { useState, useEffect } from "react";
import Spinner from "../../../components/Spinner";
import cx from "classnames";
import { API } from "aws-amplify";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css';
import moment from "moment";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table,
  Badge
} from "reactstrap";


const languageFilterOpt = [
  {
    value: "",
    label: "--- SELECT ---"
  },
  {
    value: "it",
    label: "Italian"
  },
  {
    value: "en",
    label: "English"
  },
  {
    value: "es",
    label: "Spanish"
  },
  {
    value: "nl",
    label: "Dutch"
  },
  {
    value: "pt",
    label: "Portuguese"
  },
  {
    value: "no",
    label: "Norwegian"
  }
]

const marketFilterOpt = [
  {value: "", label: "-- SELECT --"},
  { value: "ar", label: "Argentina"},
  { value: "at", label: "Austria" },
  { value: "au", label: "Australia" },
  { value: "be", label: "Belgium" },
  { value: "bo", label: "Bolivia" },
  { value: "br", label: "Brazil" },
  { value: "ca", label: "Canada" },
  { value: "ch", label: "Switzerland" },
  { value: "cl", label: "Chile"},
  { value: "co", label: "Colombia"},
  { value: "de", label: "Germany" },
  { value: "dz", label: "Algeria" },
  { value: "ec", label: "Ecuador" },
  { value: "dk", label: "Danmark" },
  { value: "es", label: "Spain" },
  { value: "fi", label: "Finland" },
  { value: "fr", label: "France" },
  { value: "hk", label: "Hong Kong"},
  { value: "ie", label: "Ireland"},
  { value: "in", label: "India" },
  { value: "id", label: "Indonesia"},
  { value: "it", label: "Italy" },
  // { value: "my", label: "Malaysia"},
  { value: "mx", label: "Mexico" },
  { value: "nl", label: "Netherlands" },
  { value: "no", label: "Norway" },
  { value: "nz", label: "New Zealand" },
  { value: "pe", label: "Peru"},
  { value: "ph", label: "Philippines"},
  { value: "se", label: "Sweden" },
  { value: "sn", label: "Senegal" },
  { value: "sg", label: "Singapore"},
  { value: "tw", label: "Taiwan" },
  { value: "th", label: "Thailand" },
  { value: "tn", label: "Tunisia" },
  { value: "gb", label: "United Kingdom" },
  { value: "us", label: "United States" },
  { value: "ve", label: "Venezuela"},
  { value: "vn", label: "Vietnam"}  
];


const FacebookBulkTool = (props) => {

  const maxDate = useState(moment(new Date()).toDate());
  const [startDate, setStartDate] = useState(moment(new Date()).toDate());
  const [endDate, setEndDate] = useState(moment(new Date()).toDate());

  const [marketFilter, setMarketFilter] = useState("");
  const [languageFilter, setLanguageFilter] = useState("");
  const [adLibrarySearchList, setAdLibrarySearchList] = useState("");
    const handleAdLibrarySearchList = (e) => {
    setAdLibrarySearchList(e.target.value);
  }

  // const [proxyList, setProxList] = useState("");
  // const handleProxyList = (e) => {
  //   setProxList(e.target.value);
  // }
  
  const [isLoading, setIsLoading] = useState(false);

  const [jobs, setJobList] = useState([]);
  const loadAllJobs = async () => {
    let response = await API.get('customAPI', '/keyword-tools/facebook-adlibrary-tool');
    setJobList(response);
  }

  const downloadReport = async (jobid, searchTerm) => {
    let {signedUrl} = await API.get('customAPI', '/keyword-tools/facebook-adlibrary-tool', {
      queryStringParameters: {
        download: true,
        jobId: jobid,
        searchTerm
      }
    });
    window.open(signedUrl) 
  }

  useEffect(() => {
    loadAllJobs()
  },[])

  const execBulkActions = async () => {
  	setIsLoading(true);
    await API.post('customAPI', '/keyword-tools/facebook-adlibrary-tool', {
  		body: {
        startDate: moment(startDate).format("YYYY-MM-DD"),
        endDate: moment(endDate).format("YYYY-MM-DD"),
        languageFilter,
  			adLibrarySearchList,
        proxyList: "",
        marketFilter
  		}
  	});
    alert("BULK ACTIONS DONE, HEAD OVER TO FACEBOOK TO CHECK!");
  	setIsLoading(false);
  }

//en
// 

  return (
    <Container className="mt-2" fluid>
      <Row>
        <Col>
          <Card className="bg-secondary shadow">
            <CardHeader className={cx("border-0")}>
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">FACEBOOK ADLIBRARY TOOL</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
            	<Form>
                <Row className="mt-4">
                  <Col md={{size: 6}}>
                     <FormGroup>
                      <Label htmlFor="adlibrary-search">Search by keyword or advertiser</Label>
                      <Input
                        placeholder="List of keyword or advertiser to search"
                        type="textarea"
                        rows="12"
                        name="adlibrary-search"
                        id="adlibrary-search"
                        value={adLibrarySearchList}
                        onChange={handleAdLibrarySearchList}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={{size: 4, offset: 2}}>
                    {/*<FormGroup>
                      <Label htmlFor="proxy-list">Proxy list to use:</Label>
                      <p style={{fontSize:"0.7rem", marginBottom:"0px"}}>Open https://hidemy.name/en/proxy-list/?type=4&anon=4#list and copy paste a list of ip:port</p>
                      <Input
                        placeholder="Copy paste from https://hidemy.name/en/proxy-list/?type=4&anon=4#list ex ip:port"
                        type="textarea"
                        rows="2"
                        name="proxy-list"
                        id="proxy-list"
                        value={proxyList}
                        onChange={handleProxyList}
                      />
                    </FormGroup>*/}
                    <FormGroup>
                      <Label htmlFor="language">Filter by language</Label>
                      <Input
                        type="select"
                        name="language"
                        id="language"
                        value={languageFilter}
                        onChange={(e) => setLanguageFilter(e.target.value)}
                      >
                        {languageFilterOpt.map((opt, i) => <option key={i} value={opt.value}>{opt.label}</option>)}
                      </Input>
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="market">Filter by market</Label>
                      <Input
                        type="select"
                        name="market"
                        id="market"
                        value={marketFilter}
                        onChange={(e) => setMarketFilter(e.target.value)}
                      >
                        {marketFilterOpt.map((opt, i) => <option key={i} value={opt.value}>{opt.label}</option>)}
                      </Input>
                    </FormGroup>
                    <Row form>
                      <Col sm={6}>
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-username">
                            Start Date:
                          </label>
                          <DatePicker
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            dateFormat="yyyy-MM-dd"
                            calendarClassName="datepickerCustomStyle"
                            maxDate={maxDate} className="form-control"
                          />
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <label className="form-control-label" htmlFor="input-username">
                            End Date:
                        </label>
                          <DatePicker selected={endDate} onChange={date => setEndDate(date)} dateFormat="yyyy-MM-dd" calendarClassName="datepickerCustomStyle"
                            maxDate={maxDate} className="form-control" />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <div className="text-center">
                  <Spinner loading={isLoading}>
                  	 	<Button className="mt-4" color="primary" type="button" onClick={() => execBulkActions()} >
                        EXEC
                      </Button>
                  </Spinner>
                </div>
        			</Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">JOB STATUS</th>
                  <th scope="col">SEARCH TERM</th>
                  <th scope="col">OUTPUT</th>
                </tr>
              </thead>
              <tbody>
                {jobs.map((job, idx) => {
                  return <tr key={idx}>
                    <td>
                      <Badge color="" className="badge-dot mr-4">
                        <i className={cx("border-0", job.status === "COMPLETED" ? "bg-green" : (job.status === "ERROR" ? "bg-red" : "bg-yellow"))} />
                        {job.id} {job.status}
                      </Badge>
                    </td>
                    <td>
                      {job.searchTerm}
                    </td>
                    <td>
                      {job.status==="COMPLETED" ? 
                        <Button size="sm" onClick={e => downloadReport(job.id, job.searchTerm) } color="light">DOWNLOAD</Button>
                        : job.output
                      }
                    </td>
                    {/**<td>
                      <Button size="sm" onClick={e => { selectUser(user);setUserDealsModalOpen(true);}} 
                        color="light">DEALS</Button>
                    </td>
                    <td onClick={e => {e.preventDefault(); deleteUser(user);}}>
                      <Button size="sm" onClick={e => deleteUser(user)} color="danger">DELETE</Button>
                    </td>*/}
                  </tr>
                })}
              </tbody>
            </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default FacebookBulkTool;
