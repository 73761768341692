import React, { useState } from "react";
import { API } from "aws-amplify";
import cx from "classnames";
import { NavLink as NavLinkRRD } from "react-router-dom";

import { Button } from "reactstrap";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  NavLink,
  Input,
  ListGroup,
  ListGroupItem,
  Badge,
} from "reactstrap";

import FlagListGroup from "../../../components/flagListGroup.js";


const MARKETS = [
  { value: "at", flag: "at", label: "Austria" },
  { value: "ch", flag: "ch", label: "Switzerland" },
  { value: "de", flag: "de", label: "Germany" },
  { value: "dk", flag: "dk", label: "Danmark" },
  { value: "es", flag: "es", label: "Spain" },
  { value: "fi", flag: "fi", label: "Finland" },
  { value: "fr", flag: "fr", label: "France" },
  { value: "it", flag: "it", label: "Italy" },
  { value: "nl", flag: "nl", label: "Netherlands" },
  { value: "no", flag: "no", label: "Norway" },
  { value: "se", flag: "se", label: "Sweden" },
  { value: "uk", flag: "gb", label: "United Kingdom" },
  { value: "us", flag: "us", label: "United States" }
];

const UTM_CAMPAIGN = [
  { id: "automotive", name: "automotive" },
  { id: "education", name: "education" },
  { id: "fashion", name: "fashion" },
  { id: "finance", name: "finance" },
  { id: "flight", name: "flight" },
  { id: "health", name: "health" },
  { id: "household", name: "household" },
  { id: "services", name: "services" },
  { id: "shopping", name: "shopping" },
  { id: "tech", name: "tech" },
  { id: "travel", name: "travel" }
  // { value: "business", label: "business" },
  // { value: "internet", label: "internet" },
  // { value: "astrology", label: "astrology" },
  // { value: "gambling", label: "gambling" }
];



const KeywordPlanner = (props) => {

 	const [keywords, setKeywords] = useState({});
 	const [bucketConfiguration, setBucketConfiguration] = useState({});
 	const [buckets, setBuckets] = useState([]);

 	const createBuckets = async () => {
 		
    const newBuckets = await API.post('customAPI', '/keyword-tools/planner', {
      body: {keywords, bucketConfiguration}
    });
    setBuckets(newBuckets);
    setKeywords({});
    setBucketConfiguration({});
    setMarketList([]);
    setUtmCampaignList({}); 
 	}


	const [marketList, setMarketList] = useState([]);
  const toggleMarket = (newMarket) => {
    // let index = marketList.indexOf(newMarket);
    // if (index === -1) {
    //   setMarketList([...marketList, newMarket])
    // } else {
    //   marketList.splice(index, 1);
    //   setMarketList([...marketList]);
    // }
    setMarketList([newMarket]);
  }

  const [utmCampaignList, setUtmCampaignList] = useState({});
  const toggleUtmCampaign = (newUTM, market) => {
  	let list = utmCampaignList[market] || []

    let index = list.indexOf(newUTM);
    if (index === -1) {
      list.push(newUTM)
    } else {
      list.splice(index, 1);
    }
    utmCampaignList[market] = list;
    console.log(utmCampaignList);
    setUtmCampaignList({...utmCampaignList});
  }

  return (
    <Container className="mt-2" fluid>
    	<Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Keyword Planner</h3>
                </Col>
                <Col xs="3">
                  <Button className="btn-primary float-right" size="sm">
                    <NavLink
                      to='/admin/keyword-tools/automation/recyclingkeywords'
                      tag={NavLinkRRD}
                    >
                      RECYCLING KEYWORDs
                    </NavLink>
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
            	<FormGroup className="mb-3">
                <Label>MARKET SELECTION:</Label>
                <FlagListGroup fill={true} markets={MARKETS} selectedMarkets={marketList} onClick={toggleMarket} />
              </FormGroup>
              {marketList.map((market, key) => {
		            return <Form key={"form-"+market + "-" + key}>
		              <Row className="mb-3">
		              	<FormGroup className="mb-3">
                			<Label>UTM_CAMPAIGN SELECTION:</Label>
			              	<ListGroup className="list-group-horizontal" style={{ flexWrap: "wrap"}}>
									      {UTM_CAMPAIGN.map((m) =>
									        <ListGroupItem key={m.id} onClick={() => toggleUtmCampaign(m.id, market)} 
									        	className={cx("countryItem flex-fill", { 
									        		active: utmCampaignList[market] && utmCampaignList[market].includes(m.id)})}>
									          <span style={{ textTransform: "uppercase" }}>{m.name}</span>
									        </ListGroupItem>
									      )}
									    </ListGroup>
									  </FormGroup>
		              </Row>
		              <Row>
		              	{utmCampaignList[market] && utmCampaignList[market].map((utmCampaign, key) => {
			              	return <Col lg="4" key={"keyword-list-"+utmCampaign}>
			                  <FormGroup>
			                    <label
			                      className="form-control-label"
			                      htmlFor="input-keyword"
			                    >
			                      {utmCampaign} - KEYWORD LIST 
			                    </label>
			                    <Input
			                      name="keyword"
			                      className="form-control-alternative"
			                      id="input-keyword"
			                      placeholder="List keyword"
			                      type="textarea"
			                      onChange={(e) => {
			                      	// console.log(e.target.value);
			                      	const keywordList = e.target.value.split("\n");
			                      	if ( keywordList.length ) {
				                      	const keywordByUTMCampaign = keywordList.reduce((acc, line) => {
				                      		// const [keyword, utm_campaign] = line.split(",").map(v => v.trim());
				                      		acc[market] = acc[market] || {};
				                      		acc[market][utmCampaign] = acc[market][utmCampaign] || [];
				                      		acc[market][utmCampaign].push(line);
				                      		return acc;
				                      	}, {...keywords});
				                      	setKeywords(keywordByUTMCampaign);
				                      }
			                      }}
			                      rows={10}>
			                    </Input>
			                  </FormGroup>
			                </Col>
			            	})}
			              </Row>
			              <Row>
			                <Col>
			                	<h3>{market} - BUCKET CONFIGURATION:</h3>
			                	<ListGroup>
			                	{keywords[market] ? Object.keys(keywords[market]).map((utm_campaign) => {
			                		return <ListGroupItem key={utm_campaign+"-"+market}>
			                			<FormGroup row key={"confinput_"+utm_campaign}>
			                        <Col sm={3}>
			                        <Input
			                          className="form-control-alternative"
			                          id={`input-${utm_campaign}`}
			                          onChange={(e) => {
			                          	let nbucketConfiguration = {...bucketConfiguration};
			                          	nbucketConfiguration[market] = nbucketConfiguration[market] || {};
			                          	nbucketConfiguration[market][utm_campaign] = e.target.value;
			                          	setBucketConfiguration(nbucketConfiguration);
			                        	}}
			                          value={bucketConfiguration[market] ? bucketConfiguration[market][utm_campaign] : 0}
			                          type="number"></Input>
			                        </Col>
			                        <Label
			                        	sm={9}
			                          className="form-control-label"
			                          htmlFor={`input-${utm_campaign}`}
			                        >
			                          keywords of <b>{utm_campaign}</b> per bucket ( <Badge color="info" pill>Total: {keywords[market][utm_campaign].length}</Badge> )
			                        </Label>
			                      </FormGroup>
		                			</ListGroupItem>;
			                	}) : null}
										    </ListGroup>
			                </Col>
			              </Row>
                    <Button type="button" className="btn-block" color="primary" onClick={createBuckets}>
                    CREATE BUCKETS</Button>
		              <hr/>
		            </Form>
              })}
              <h3>BUCKET RESULT:</h3>
	            <ListGroup>
              	{buckets.map((bucket, idx) => {
              		return <ListGroupItem key={idx}>DAY {idx}: {bucket.join(", ")}</ListGroupItem>;
              	})}
						  </ListGroup>
            </CardBody>
          </Card>
        </div>
      </Row>

    </Container>
  );
}

export default KeywordPlanner;
