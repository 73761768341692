import React, { useState, useEffect } from "react";
import Spinner from "../../components/Spinner";
import { API } from "aws-amplify";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Table,
  Col
} from "reactstrap";


import Flag from "react-flags";

import AdTemplateModal from "./adtemplateModal"

const AdTemplate = (props) => {

  // const [selectedMarkets, addSelectedMarket] = useState([]);
  // const [UTMCampaign, setUTMCampaign] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [adtemplateModal, setAdTemplateModal] = useState(false);


  const MARKETS = [
    { value: "at", flag: "at", label: "Austria" },
    { value: "au", flag: "au", label: "Australia" },
    { value: "br", flag: "br", label: "Brazil" },
    { value: "ch", flag: "ch", label: "Switzerland" },
    { value: "de", flag: "de", label: "Germany" },
    { value: "dk", flag: "dk", label: "Danmark" },
    { value: "es", flag: "es", label: "Spain" },
    { value: "fi", flag: "fi", label: "Finland" },
    { value: "fr", flag: "fr", label: "France" },
    { value: "it", flag: "it", label: "Italy" },
    { value: "in", flag: "in", label: "India" },
    { value: "mx", flag: "mx", label: "Mexico" },
    { value: "nl", flag: "nl", label: "Netherlands" },
    { value: "no", flag: "no", label: "Norway" },
    { value: "se", flag: "se", label: "Sweden" },
    { value: "uk", flag: "gb", label: "United Kingdom" },
    { value: "us", flag: "us", label: "United States" }
  ];

 const UTM_CAMPAIGN = [
    { id: "automotive", name: "automotive" },
    { id: "education", name: "education" },
    { id: "fashion", name: "fashion" },
    { id: "finance", name: "finance" },
    { id: "flight", name: "flight" },
    { id: "generic", name: "generic" },
    { id: "health", name: "health" },
    { id: "household", name: "household" },
    { id: "services", name: "services" },
    { id: "shopping", name: "shopping" },
    { id: "tech", name: "tech" },
    { id: "travel", name: "travel" },
    { id:"blackfriday", name: "blackfriday"},
    // { value: "business", label: "business" },
    // { value: "internet", label: "internet" },
    // { value: "health", label: "health" },
    // { value: "astrology", label: "astrology" },
    // { value: "gambling", label: "gambling" }
  ];

  // const selectMarket = (newMarket) => {
  //   let index = selectedMarkets.indexOf(newMarket);
  //   if (index === -1) {
  //     addSelectedMarket([...selectedMarkets, newMarket])
  //   } else {
  //     selectedMarkets.splice(index, 1);
  //     addSelectedMarket([...selectedMarkets]);
  //   }
  // }

  const [adtemplates, setAdTemplates] = useState({});
  // const [editingTemplate, setEditingTemplate] = useState({});
  const [selectedMarket, setMarket] = useState(false);
  const [selecteVertical, setVertical] = useState(false);
  const [utmSources, setUTMSources] = useState([]);

  const loadAdTemplates = async () => {
  	// console.log("HELLO!");
  	setIsLoading(true);
  	let response = await API.get('customAPI', '/adtemplate');
  	setAdTemplates(response.adtemplates);
  	setUTMSources(response.utm_sources);
  	setIsLoading(false);
  }

  useEffect(() => {
  	loadAdTemplates();
  }, [])

  const selectAdTemplate = (market, vertical, template) => {
  	// console.log(market, vertical, template);
  	// setEditingTemplate(template);
  	setMarket(market);
  	setVertical(vertical);
  	setAdTemplateModal(!adtemplateModal)
  }

  const saveTemplate = async (utm_campaign, market) => {
    console.log(adtemplates);
    setIsLoading(true);
  	await API.post('customAPI', '/adtemplate', {
  		body: {
  			template: adtemplates[market][utm_campaign],
  			market: market,
  			utm_campaign: utm_campaign
  		}
  	});
  	setIsLoading(false);
  	setAdTemplateModal(!adtemplateModal)
  }

  const deleteTemplate = async (site_id, utm_campaign, market) => {
    setIsLoading(true);
    console.log("DELETE", site_id, utm_campaign, market);
    await API.del('customAPI', '/adtemplate', {
      body: {
        site_id: site_id,
        market: market,
        utm_campaign: utm_campaign
      }
    });
    await loadAdTemplates()
    setAdTemplateModal(!adtemplateModal)
  }

  const addTitle = (title, site_id, utm_campaign, market) => {
      // setTitleState([...templateTitle, ""]);
      // console.log("APPEND ", title)
      const updateTemplates = {...adtemplates};
      updateTemplates[market] = updateTemplates[market] || {};
      updateTemplates[market][utm_campaign] = updateTemplates[market][utm_campaign] || {};
      updateTemplates[market][utm_campaign][site_id] = updateTemplates[market][utm_campaign][site_id] || {
      	title: [],
      	body: []
      };
      updateTemplates[market][utm_campaign][site_id].title.push(title);
      setAdTemplates(updateTemplates)
  };
   
  const addBody = (body, site_id, utm_campaign, market) => {
      // setTitleState([...templateTitle, ""]);
      // console.log("APPEND ", body)
      const updateTemplates = {...adtemplates};
      updateTemplates[market] = updateTemplates[market] || {};
      updateTemplates[market][utm_campaign] = updateTemplates[market][utm_campaign] || {};
      updateTemplates[market][utm_campaign][site_id] = updateTemplates[market][utm_campaign][site_id] || {
      	title: [],
      	body: []
      };
      updateTemplates[market][utm_campaign][site_id].body.push(body);
      setAdTemplates(updateTemplates)
  };

  const handleTitleChange = (e, site_id, utm_campaign, market) => {
    // console.log(e.target.dataset.idx, e.target.dataset.name, e.target.value)
    const updateTemplates = {...adtemplates};
    updateTemplates[market][utm_campaign][site_id].title[e.target.dataset.idx] = e.target.value;
    setAdTemplates(updateTemplates)
  }

  const handleBodyChange = (e, site_id, utm_campaign, market) => {
    // console.log(e.target.dataset.idx, e.target.dataset.name, e.target.value)
    const updateTemplates = {...adtemplates};
    updateTemplates[market][utm_campaign][site_id].body[e.target.dataset.idx] = e.target.value;
    setAdTemplates(updateTemplates);
  }

  return (
    <Container className="mt-2" fluid>
      <Row>
        <Col>
          <Card className="bg-secondary shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">AD TEMPLATE</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
            	<Spinner loading={isLoading}>
	            	<Table className="align-items-center table-flush" responsive>
	              	<thead className="thead-light">
		                <tr>
		                	<th>Verticals</th>
		                	{MARKETS.map((m, key) => {
		                		return <th scope="col" key={key}>
		                			<Flag name={m.flag} format="png" pngSize={16} shiny={true} alt={m.label} basePath="" />
	                			</th>	
		                	})}
		                </tr>
		              </thead>
		              <tbody>
		            		{UTM_CAMPAIGN.map((utm, key) => {
		            			return <tr key={key}>
		            				<td>{utm.name}</td>
		            				{MARKETS.map((mkt, mkey) => {
		            					if ( adtemplates[mkt.value] && adtemplates[mkt.value][utm.id]) {
		            						return <td key={mkey} onClick={() => selectAdTemplate(mkt.value, utm.id, adtemplates[mkt.value][utm.id])}><i className="fas fa-pen"/></td>;
		            					} else {
		            						return <td key={mkey} onClick={() => selectAdTemplate(mkt.value, utm.id, {})}><i className="fa fa-plus"/></td>;
		            					}
		            				})}
		            			</tr>
		            		})}
		              </tbody>
		            </Table>
		          </Spinner>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <AdTemplateModal isOpen={adtemplateModal} toggle={() => setAdTemplateModal(!adtemplateModal)} 
      	template={adtemplates} saveTemplate={saveTemplate} deleteTemplate={deleteTemplate} market={selectedMarket} utm_campaign={selecteVertical} utmSources={utmSources} 
      	addTitle={addTitle} addBody={addBody}  handleTitleChange={handleTitleChange} handleBodyChange={handleBodyChange} /> 
    </Container>
  );
}

export default AdTemplate;
