import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin.jsx";

function App(props) {
  // const [isAuthenticating, setIsAuthenticating] = useState(true);
  // const [isAuthenticated, userHasAuthenticated] = useState(false);
  // const [state, setState] = useState({ isLoggedIn: false, user: null });

  // const checkLoggedIn = async () => {
  //   try {
  //     const data = await Auth.currentAuthenticatedUser()
  //     const user = { username: data.username, ...data.attributes };
  //     setState({ isLoggedIn: true, user });
  //   } catch (error) { 
  //     console.log(error);
  //     setState({ isLoggedIn: false, user: null });
  //   };
  // };

  // useEffect(() => {
  //   checkLoggedIn();
  // }, []);

  // async function handleLogout() {
  //   await Auth.signOut();

  //   // userHasAuthenticated(false);

  //   // props.history.push("/login");
  // }


  if ( props.authState === "signedIn") {
    let user = props.authData;
    return (
      <Switch>
        <Route path="/admin" render={props => <AdminLayout {...props} userAuth={user} />} />
        <Redirect from="/" to="/admin/index" />
      </Switch>
    );
  } else {
    return <div></div>
  }
} 

export default App;