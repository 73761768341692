import React, { useState } from "react";
import moment from 'moment';

import DatePicker from 'react-datepicker'

import {
  Card,
  Row,
  Label,
  FormGroup, Button,
  Modal, ModalHeader, ModalBody, ModalFooter, Col
} from "reactstrap";


const SchedulerModal = (props) => {
  const [date, setDate] = useState(moment(new Date()).subtract(1, 'days').toDate());

  const closeBtn = <button className="close" onClick={() => props.close(false)} style={{ fontSize: "2rem" }}>&times;</button>;

  return (
    <Modal isOpen={props.isOpen} toggle={() => props.close(false)} size="lg">
      <ModalHeader close={closeBtn} className="pb-0 pt-3">
        <Row className="align-items-center justify-content-between">
          <Col xs={{ size: "auto" }}>
            <h3 className="mb-0">Are you sure you want to rerun the data for {props.reportname}?</h3>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody className="px-3 py-0">
        <Row>
          <Col>
            <Card className="bg-secondary p-3">
              <FormGroup row>
                <Label sm={3} htmlFor="input-username">
                  Select a Date:
                </Label>
                <Col sm={8}>
                  <DatePicker
                    selected={date}
                    onChange={date => setDate(date)}
                    dateFormat="yyyy-MM-dd"
                    calendarClassName="datepickerCustomStyle"
                    maxDate={new Date()} className="form-control"
                  />
                </Col>
              </FormGroup>
            </Card>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="px-7 text-center">
        <Button className="btn-block" color="warning" onClick={() => props.scheduleRerun(date)}>REFRESH DATA!</Button>
      </ModalFooter>
    </Modal>
  );
}

export default SchedulerModal;
