import React, {useState} from "react";
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Button } from "reactstrap";

const EditingTranslationModal = (props) => {
  const { isOpen, toggle, translation, seed } = props;

  const [editTranslation, setEditTranslation] = useState("");

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="pb-1" toggle={toggle}>EDIT EN translation for {seed}!</ModalHeader>
      <ModalBody className="pt-1">
        <Row>
          <Col>
            <FormGroup>
              <Input bsSize="sm" type="text" value={editTranslation || translation} 
                onChange={(e) => setEditTranslation(e.target.value) } /> 
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => {
            props.saveENTranslationEdit(seed, editTranslation);
            toggle();
        }}>EDIT</Button>
      </ModalFooter>
    </Modal>
  )
}

export default EditingTranslationModal;
