import React from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";

import Spinner from "../../../components/Spinner";

const TableDisplay = (props) => {
  // console.log(props);
  const { loading, data, currency="!!", displayDate } = props;
  	
  return (
			<Card className="card-stats mb-4">
        <CardBody>
          <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
             {props.title}
          </CardTitle>
          <Spinner loading={loading}>
            <Row>
            	<Col>
  				      {Object.keys(data).map((source, idx) => {
  				      	let row = {};
                  if ( data[source] && data[source][displayDate] ) {
                    row = data[source][displayDate];
                  }
                  
  				      	return (
  			            <div key={idx}>
  			              <p className="mb-0 mt-3"><small className="font-weight-bold text-capitalize">{source}:</small></p>
  			              <p className="mb-0"><small className="text-muted">Cost: {currency}{row.cost || 0}</small></p>
  			              <p className="mb-0"><small className="text-muted">Revenue: {currency}{row.revenue || 0}</small></p>
  			              <p className="mb-2"><small className="text-muted">Profit: {currency}{row.profit || 0}</small></p>
  			            </div>
  			           );
  					      })}
              </Col>
            </Row>
            <Row className="justify-content-between mt-3 align-content-center">
              <Col></Col>
              <Col xs={{ size: "auto" }} className="pointer" onClick={props.handleFlip}>
                <i className="fas fa-chart-line text-primary" style={{ fontSize: "1.55rem" }}></i>
              </Col>
            </Row>
          </Spinner>
        </CardBody>
      </Card>
  )
}

export default TableDisplay;
