import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
// import cx from "classnames";

import DataTable from 'react-data-table-component';
import FlagListGroup from "../../../components/flagListGroup.js";
import Spinner from "../../../components/Spinner";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  Label, 
  Input
} from "reactstrap";

import Board from "./board";
import UploadKeywordListModal from "./UploadKeywordListModal";
import MoveToModal from "./MoveToModal";
import EditingTranslationModal from "./EditingTranslationModal";

export const MARKETS = [
  { value: "ar", flag: "ar", label: "Argentina"},
  { value: "at", flag: "at", label: "Austria" },
  { value: "au", flag: "au", label: "Australia" },
  { value: "be", flag: "be", label: "Belgium" },
  { value: "bo", flag: "bo", label: "Bolivia" },
  { value: "br", flag: "br", label: "Brazil" },
  { value: "ca", flag: "ca", label: "Canada" },
  { value: "ch", flag: "ch", label: "Switzerland" },
  { value: "cl", flag: "cl", label: "Chile"},
  { value: "co", flag: "co", label: "Colombia"},
  { value: "de", flag: "de", label: "Germany" },
  { value: "dz", flag: "dz", label: "Algeria" },
  { value: "ec", flag: "ec", label: "Ecuador" },
  { value: "dk", flag: "dk", label: "Danmark" },
  { value: "es", flag: "es", label: "Spain" },
  { value: "fi", flag: "fi", label: "Finland" },
  { value: "fr", flag: "fr", label: "France" },
  { value: "hk", flag: "hk", label: "Hong Kong"},
  { value: "ie", flag: "ie", label: "Ireland"},
  { value: "in", flag: "in", label: "India" },
  { value: "id", flag: "id", label: "Indonesia"},
  { value: "it", flag: "it", label: "Italy" },
  // { value: "my", flag: "my", label: "Malaysia"},
  { value: "mx", flag: "mx", label: "Mexico" },
  { value: "nl", flag: "nl", label: "Netherlands" },
  { value: "no", flag: "no", label: "Norway" },
  { value: "nz", flag: "nz", label: "New Zealand" },
  { value: "pe", flag: "pe", label: "Peru"},
  { value: "ph", flag: "ph", label: "Philippines"},
  { value: "se", flag: "se", label: "Sweden" },
  { value: "sn", flag: "sn", label: "Senegal" },
  { value: "sg", flag: "sg", label: "Singapore"},
  { value: "tw", flag: "tw", label: "Taiwan" },
  { value: "th", flag: "th", label: "Thailand" },
  { value: "tn", flag: "tn", label: "Tunisia" },
  { value: "uk", flag: "gb", label: "United Kingdom" },
  { value: "us", flag: "us", label: "United States" },
  { value: "ve", flag: "ve", label: "Venezuela"},
  { value: "vn", flag: "vn", label: "Vietnam"}  
];


const RelatedKeyword = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [seedTable, setSeedTable] = useState([]);

  const [listMarket, setListMarket] = useState([...MARKETS]);
  const [market, setMarket] = useState("");
  
  const loadByMarket = async (newMarket) => {
    // If clicking the same flag - do nothing!
    if (market === newMarket) return
    setMarket(newMarket);

    await loadSeedTable(newMarket);
    await loadBoard(newMarket);
  }

  const loadBoard = async (newMarket = false) => {
    setIsLoading(true);
    let response = await API.get('customAPI', '/keyword-tools/grouping/keyword', {
      queryStringParameters: {
        market: newMarket || market
      }
    });
    setGroups(response);
    setIsLoading(false);
  }

  const loadSeedTable = async (newMarket = false) => {
    setIsLoading(true);
    // console.log("NEW MARKET", market);
    const seedTable = await API.get('customAPI', '/keyword-tools/grouping/seed', {
      queryStringParameters: {
        market: newMarket || market
      }
    });
    setSeedTable(seedTable);
    setIsLoading(false);
  }


  const [uploadKeywordListModal, setUploadKeywordListModal] = useState(false);
  const toggleUploadKeywordListModal = () => {
    if (market) {
      setUploadKeywordListModal(!uploadKeywordListModal);
    } else {
      alert("SELECT A MARKET FIRST!");
    }
  }

  const syncS3Images = async () => {
    if (market) {
      setIsLoading(true);
      // call api and sync with s3!
      await API.get('customAPI', '/keyword-tools/grouping/syncs3', {
        queryStringParameters: {
          market: market
        }
      });
      loadBoard();
      setIsLoading(false);
    } else {
      alert("SELECT A MARKET FIRST!");
    }
  }

  const groupKeywordList = async (keywordList) => {
    setIsLoading(true);
    await API.post('customAPI', '/keyword-tools/grouping/keyword', {
      body: {
        market,
        keywordList
      }
    });
    await loadBoard();
    setIsLoading(false);
  }
  
  const saveEditingSeed = async (seed, editingSeed) => {
    setIsLoading(true);

    await API.put('customAPI', '/keyword-tools/grouping/keyword', {
      body: {
        op: "editSeed",
        seed,
        editingSeed
      }
    });
    await loadBoard();
    setIsLoading(false);
  }


  const makeitSeedKeyword = async (selected) => {
    setIsLoading(true);
    await API.put('customAPI', '/keyword-tools/grouping/keyword', {
      body: {
        op: "seedKeyword",
        keywords: Object.keys(selected)
      }
    });
    await loadBoard();
    setIsLoading(false);
  }


  const deleteKeyword = async (deleted) => {
    setIsLoading(true);
    await API.put('customAPI', '/keyword-tools/grouping/keyword', {
      body: {
        op: "deleteKeyword",
        keywords: Object.keys(deleted).map((r) => deleted[r].idx)
      }
    });
    await loadBoard();
    setIsLoading(false);
  }

  const [moveToModal, setMoveToModal] = useState(false);
  const toggleMoveToModal = () => {
    setMoveToModal(!moveToModal);
  }
  const [movingKeyword, setMovingKeyword] = useState({});
  const moveTo = (selected) => {
    console.log(selected);
    setMovingKeyword(selected);
    toggleMoveToModal();
  }
  const seedKeyword = async (keywords, seed) => {
    setIsLoading(true);
    await API.put('customAPI', '/keyword-tools/grouping/keyword', {
      body: {
        op: "moveKeyword",
        keywords: keywords,
        seed: seed
      }
    });
    await loadBoard();
    setIsLoading(false);
  }

  const runLambdaToGroup = async () => {
    if (!market) {
      alert("SELECT A MARKET FIRST!");
      return;
    }
    setIsLoading(true);
    await API.put('customAPI', '/keyword-tools/grouping/keyword', {
      body: {
        op: "runLambdaGrouping",
        market: market
      }
    });
    await loadBoard();
    setIsLoading(false);
  }
  
  const runLambdaToCategorise = async () => {
    if (!market) {
      alert("SELECT A MARKET FIRST!");
      return;
    }
    setIsLoading(true);
    await API.put('customAPI', '/keyword-tools/grouping/keyword', {
      body: {
        op: "runLambdaCategorise",
        market: market
      }
    });
    await loadSeedTable();
    setIsLoading(false);
  }

  const archiveSeed = async (seed, archived) => {
    setIsLoading(true);
    await API.put('customAPI', '/keyword-tools/grouping/keyword', {
      body: {
        op: "archiveSeed",
        seed: seed,
        archived: archived === 1 ? 0 : 1
      }
    });
    await loadBoard();
    setIsLoading(false);
  }

  const keywordTouched = async (keyword) => {
    API.put('customAPI', '/keyword-tools/grouping/keyword', {
      body: {
        op: "touchKeyword",
        keyword: keyword
      }
    });
  }


  const [view, setView] = useState("grouping");
  const [collapseAll, setCollapseAll] = useState(false);


  const countUncategorizedKeyword = async () => {
    const uncategorized = await API.get('customAPI', '/keyword-tools/grouping/uncategorized');
    
    let newList = listMarket.map((row) => {
      return {
        ...row,
        count: uncategorized[row.value] || 0
      }
    });
    setListMarket(newList);
    // setCategories(categories);
  }

  const [categories, setCategories] = useState([]);
  const loadCategories = async () => {
    const categories = await API.get('customAPI', '/keyword-tools/grouping/categories');
    setCategories(categories);
  }
  useEffect(() => {
    countUncategorizedKeyword();
    loadCategories()
  }, [])

  const updateCategory = async (category, row) => {
    API.put('customAPI', '/keyword-tools/grouping/keyword', {
      body: {
        op: "updateCategory",
        seed: row.seed,
        category
      }
    });
    let newSeedTable = [...seedTable];
    newSeedTable = newSeedTable.map((r) => {
      if ( r.seed === row.seed ) {
        r.category = category;
      }
      return r;
    })
    setSeedTable(newSeedTable);
  }

  // const [subcategories, setSubCategories] = useState([]);
  const updateSubCategory = async  (sub_category, row) => {
    API.put('customAPI', '/keyword-tools/grouping/keyword', {
      body: {
        op: "updateSubCategory",
        seed: row.seed,
        sub_category
      }
    });
    let newSeedTable = [...seedTable];
    newSeedTable = newSeedTable.map((r) => {
      if ( r.seed === row.seed ) {
        r.sub_category = sub_category;
      }
      return r;
    })
    setSeedTable(newSeedTable);
  }



  const [isEditingTranslationModal, setIsEditingTranslationModal] = useState(false);
  const toggleIsEditingTranslationModal = () => {
    if ( isEditingTranslationModal ) {
      // when closing reset! 
      setEditingSeed(false);
      setEditingTranslation(false);
    }
    setIsEditingTranslationModal(!isEditingTranslationModal);
  };
  const [editingSeed, setEditingSeed] = useState(false);
  const [editingTranslation, setEditingTranslation] = useState(false);
  const editTranslation = (seed, translation) => {
    setEditingTranslation(translation);
    setEditingSeed(seed);
    toggleIsEditingTranslationModal();
  }
  const saveENTranslationEdit = async (seed, value) => {
    setIsLoading(true);
    await API.put('customAPI', '/keyword-tools/grouping/keyword', {
      body: {
        op: "editTranslation",
        seed: seed,
        translation: value
      }
    });
    await loadSeedTable();
    setIsLoading(false);
  }


  return (
    <Container fluid>
      <Card className="shadow">
        <CardHeader className="border-0">
          <Row className="align-items-center">
            <Col xs="2">
              <h3 className="mb-0">Group Keywords</h3>
            </Col>
            <Col xs="5">
              <small>Grouping</small><Label className="custom-toggle custom-toggle-danger" style={{margin:0}}>
                <Input type="checkbox" defaultChecked={view==="fanpages"} onClick={(e) => {
                  let view = e.target.checked ? "categorise" : "grouping"; 
                  setView(view); 
                }} />
                <span className="custom-toggle-slider rounded-circle" data-label-off="OFF" data-label-on="ON"></span>
              </Label><small>Categorise</small>
            </Col>
            <Col xs="5">
              {view === "grouping" 
                ? <Button size="sm" className="float-left" color="warning" onClick={runLambdaToGroup} disabled={isLoading}>RUN GROUP ALGO</Button>
                : <Button size="sm" className="float-left" color="warning" onClick={runLambdaToCategorise} disabled={isLoading}>RUN CATEGORISE ALGO</Button>
              }
              <Button size="sm" disabled={isLoading} className="float-right" color="primary" onClick={toggleUploadKeywordListModal}>LOAD FROM LIST</Button>
              <Button size="sm" disabled={isLoading} className="float-right" color="light" onClick={syncS3Images}>SYNC S3 IMAGE REPO</Button>
              <UploadKeywordListModal isOpen={uploadKeywordListModal} toggle={toggleUploadKeywordListModal} groupKeywordList={groupKeywordList} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="bg-secondary">
          <Row>
            <Col>
              <FlagListGroup markets={listMarket} selectedMarkets={market} onClick={loadByMarket} pngSize={16} />
              <Button size="sm" style={{position: "absolute", bottom: "0px", right: "0px"}} color="" onClick={() => setCollapseAll(!collapseAll)}>
                  {collapseAll ? "Expand" : "Collapse"}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Spinner isLoading={isLoading}>
            {view === "grouping" 
                ? <Board groups={groups} saveEditingSeed={saveEditingSeed} keywordTouched={keywordTouched}
                    deleteKeyword={deleteKeyword} makeitSeedKeyword={makeitSeedKeyword} moveTo={moveTo} 
                    archiveSeed={archiveSeed} collapseAll={collapseAll}/>
               :  <DataTable
                    columns={[
                      { selector: "market", name: "Market", sortable: true, compact:true},
                      { selector: "seed", name: "SEED", sortable: true, compact:true},
                      { selector: "seedTranslation", name: "EN Translation", sortable: true, compact: true, cell: (row) => {
                        return <Button outline onClick={() => editTranslation(row.seed, row.seedTranslation)}>{row.seedTranslation}</Button>;  
                      }},
                      { selector: "category", name: "Category", sortable: true, cell: (row) => (
                        <Input bsSize="sm" type="select" value={row.category} onChange={(e) => updateCategory(e.target.value, row)}>
                          <option value=""> ----- </option>
                          {Object.keys(categories).map((c,i) => <option key={`cat-${i}`} value={categories[c].value}>{categories[c].name}</option>)}
                        </Input>
                      )},
                      { selector: "sub_category", name: "Sub Category", sortable: true, cell: (row) => (
                        <Input bsSize="sm" type="select" value={row.sub_category} onChange={(e) => updateSubCategory(e.target.value, row)}>
                          <option value=""> ----- </option>
                          {categories[row.category] && categories[row.category].sub_categories.map((subcategory,i) => <option key={`subcat-${i}`} value={subcategory.value}>{subcategory.name}</option>)}
                        </Input>
                      )},
                    ]}
                    data={seedTable}
                    progressPending={isLoading}
                    persistTableHead={true}
                    noHeader={true}
                    striped={true}
                    highlightOnHover
                    dense={true}
                  />}
              </Spinner>
        </CardBody>
      </Card>
      <EditingTranslationModal isOpen={isEditingTranslationModal} toggle={toggleIsEditingTranslationModal} 
        saveENTranslationEdit={saveENTranslationEdit} seed={editingSeed} translation={editingTranslation} />
      <MoveToModal isOpen={moveToModal} toggle={toggleMoveToModal} 
          movingKeyword={movingKeyword} groups={groups} seedKeyword={seedKeyword} />
    </Container>
  );
}


export default RelatedKeyword;
