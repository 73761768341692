import React from "react";

import {
  Row, Button,
  Modal, ModalHeader, ModalBody, ModalFooter, Col
} from "reactstrap";


const ShareFilterModal = (props) => {
  const closeBtn = <button className="close" onClick={() => props.close(false)} style={{ fontSize: "2rem" }}>&times;</button>;

  return (
    <Modal isOpen={props.isOpen} toggle={() => props.close(false)} size="lg">
      <ModalHeader close={closeBtn} className="pb-0 pt-3">
        <Row className="align-items-center justify-content-between">
          <Col xs={{ size: "auto" }}>
            <h3 className="mb-0">Do you want to share "{props.selectedPreset}" the filters with someone else?</h3>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody className="px-3 py-0">
        <Row>
          <Col>
            <ul title="Breakdown:" className="taglist bold-title">
              {props.breakdown.map((breakdown, i) => breakdown.checked && <li key={breakdown.selector + i}>{breakdown.name}</li>)}
            </ul>
            <ul title="Filters:" className="filter bold-title">
            {props.filters.map((filter, i) => {
              let values = filter.values.filter((c) => c.isChecked).map(c => c.value);
              if (values.length) {
                return <li key={filter.name}>
                  <ul title={`${filter.name}:`} className="taglist bold-title">
                    {values.map((v, i) => <li key={`${filter.name}_{i}`}>{v}</li>)}
                  </ul>
                </li>
              } else {
                return null;
              }
            })}
            </ul>
            <ul title="Dimensions:" className="taglist bold-title">
              {props.dimensions.map((dimension, i) => dimension.checked &&
                <li key={dimension.selector + i}>{dimension.name}</li>
              )}
            </ul>
            <hr/>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="px-7 text-center">
        {props.reportShareID !== false 
          ? <div>
              <p>Copy paste the url generated and share it with love!</p>
              <h1>{window.location.origin}/admin/report-share?shareid={props.reportShareID}</h1>
            </div>
          : <Button className="btn-block" color="warning" onClick={() => props.onShareFilter()}>SHARE {props.selectedPreset}!</Button>
        }
      </ModalFooter>
    </Modal>
  );
}

export default ShareFilterModal;
