import { API } from 'aws-amplify';
import moment from 'moment';
import qs from 'query-string';


const computeColumns = (breakdown, dimensions) => {

  let columns = breakdown.reduce((acc, r) => {
    if (r.checked) {
      acc.push(r);
    }
    return acc;
  }, []);

  columns = dimensions.reduce((acc, r) => {
    if (r.checked) {
      acc.push(r);
    }
    return acc;
  }, columns);

  return columns;
}


const fetchDataApi = async (params, reportType, searchParams) => {
  let groupby = params.breakdown.reduce((acc, r) => {
    if (r.checked) {
      acc.push(r.selector);
    }
    return acc;
  }, []);

  let applyFilters = {};
  params.filters.forEach((filter) => {
    if (filter.values.length) {
      let filterValue = [];
      filter.values.map((v) => v.isChecked && filterValue.push(v.value));
      if (filterValue.length) {
        applyFilters[filter.selector] = filterValue;
      }
    }
  });


  try {
    searchParams = qs.parse(searchParams);
    if ( searchParams.uploadID  ) {
      applyFilters.upload_id = [searchParams.uploadID]
    }
  } catch ( e ) { console.log( "ERROR", e); }

  let queryStringParameters = {
    startDate: moment(params.startDate).format("YYYY-MM-DD"),
    endDate: moment(params.endDate).format("YYYY-MM-DD"),
    page: params.page,
    perPage: params.perPage,
    inputFilterSearchText: params.inputFilterSearchText,
    columns: computeColumns(params.breakdown, params.dimensions).map((c) => c.selector).join(","),
    groupby: groupby.join(","),
    filters: JSON.stringify(applyFilters),
    rules: JSON.stringify(params.rulesConfig),
    table: params.table,
    orderBy: params.orderBy ? JSON.stringify(params.orderBy) : false,
    overwriters: params.overwriters
  }
  if (params.download) {
    queryStringParameters.download = true;
  }
  
  return await API.post('customAPI', `/report`, {
    body: queryStringParameters
  });
}

export {
  fetchDataApi, computeColumns
}
