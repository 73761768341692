import React, { useState } from "react";
import cx from "classnames";
import { Row, Col } from "reactstrap";


const selectedImagesCard = (props) => {
  const { selectedImages, toggleImageFromSelected, showDropzoneImages, toggle } = props;
  const [showImages, setShowImages] = useState(true);

  const headingText = showDropzoneImages ? "SELECTED STOCK IMAGES:" : "SELECTED IMAGES:";
  const hasImages = selectedImages.length > 0;
  // This will contrast the icon to the BG so it always visible if light/dark
  // const mixBlend = { mixBlendMode: "screen" };
  // const isolate = { isolation: "isolate" };

  return (
    <div>
      <Row>
        <Col>
          <p style={{ display: "inline-block", marginRight: "1rem" }}>{headingText}</p>
          {hasImages && <i className={cx("far pointer", (showImages ? "fa-minus-square" : "fa-plus-square"))} onClick={() => setShowImages(!showImages)}></i>}
        </Col>
      </Row>
      {showImages &&
        <Row className="align-items-center">
          {selectedImages.map((url, idx) => {
            let thumbUrl = url;
            if ( url.startsWith("depositphotos#") ) {
              //let imgURL = "depositphotos:"+img.id+":"+img.thumbnail;
              let [prefix, id, depositPhotothumbUrl] = url.split("#")
              thumbUrl = depositPhotothumbUrl;
            }

            return (
              <Col key={`img-chosen-${idx}`} xs={{ size: "auto" }}>
                <div className="d-block p-1 mb-4 h-100" style={{ position: "relative" }}>
                  {toggle && <i className="ni ni-image img-tool-preview-icon text-white" onClick={() => toggle(url + "?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200")} />}
                  <i className="far fa-trash-alt img-tool-trash-icon text-white" onClick={() => toggleImageFromSelected(url)} />
                  <img src={thumbUrl} width="100px" height="auto" max-height="100px" alt="selected-img-preview" />
                </div>
              </Col>);
          })}
        </Row>
      }
    </div>
  )
}

export default selectedImagesCard;
