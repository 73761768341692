import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.min.css';
import classnames from 'classnames';
import {
  Card,
  Row,
  TabContent,
  TabPane, Label,
  Nav, NavItem, NavLink, Input, ButtonGroup, FormGroup, Button,
  Modal, ModalHeader, ModalBody, ModalFooter, Col
} from "reactstrap";

import Spinner from "../Spinner";


const BreakdownDimensionModal = (props) => {
  const [searchFilterInput, setSearchFilterInput] = useState("");

  const closeBtn = <button className="close" onClick={() => props.close(false)} style={{ fontSize: "2rem" }}>&times;</button>;

  return (
    <Modal isOpen={props.isOpen} toggle={() => props.close(false)} size="lg">
      <ModalHeader close={closeBtn} className="pb-0 pt-3"></ModalHeader>
      <ModalBody className="px-3 py-0">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: props.openTab === 'breakdown' })}
              onClick={(e) => { e.preventDefault(); props.toggle('breakdown'); }}
            >
              Breakdown
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: props.openTab === 'filters' })}
              onClick={(e) => { e.preventDefault(); props.toggle('filters'); }}
            >
              Filters
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: props.openTab === 'dimensions' })}
              onClick={(e) => { e.preventDefault(); props.toggle('dimensions'); }}
            >
              Dimensions
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={props.openTab}>
          <TabPane tabId="breakdown">
            <Row>
              <Col>
                <Card body style={{ 'maxHeight': 'calc(100vh - 200px)', 'overflowY': 'auto' }} className="bg-secondary p-3">
                  {props.breakdown.map((breakdown) => {
                    return (
                      <div key={breakdown.selector} className="custom-control custom-checkbox mb-2">
                        <input className="custom-control-input" id={breakdown.selector} type="checkbox" checked={breakdown.checked} onChange={(e) => props.onToggleBreakdown(breakdown.selector)} />
                        <label className="custom-control-label" htmlFor={breakdown.selector} style={{ minWidth: "35%" }}>{breakdown.name}</label>
                      </div>
                    )
                  })}
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="filters">
            <Row>
              <Col>
                <Card body style={{ 'maxHeight': 'calc(100vh - 200px)', 'overflowY': 'auto' }} className="bg-secondary px-3">
                  <Row noGutters>
                    <Col xs={4}>
                      <ButtonGroup vertical>
                        {props.filters.map((filter) => {
                          return (
                            <Button key={filter.selector} active={filter.isActive || false}
                              onClick={(e) => {
                                setSearchFilterInput("");
                                props.onToggleFilter(filter.selector)
                              }}
                            >
                              {filter.name}
                            </Button>
                          )
                        })}
                      </ButtonGroup>
                    </Col>
                    <Col>
                      <FormGroup className="mb-3">
                        {props.filterDateOpt ?
                          <Row className="mb-1">
                            <Col>
                              {["all", "last30days", "last7days", "daterange"].map((lbl,key) =>
                                <FormGroup key={key} check inline>
                                  <Label check style={{fontSize:"12px"}}>
                                    <Input type="radio" 
                                      value={lbl}
                                      checked={lbl === props.filterDateOpt}
                                      onChange={props.onChangeFilterDateOpt}
                                    />{lbl}
                                  </Label>
                                </FormGroup>  
                              )}
                              <FormGroup inline className="float-right mb-0">
                                <Button size="sm" className="btn-link" onClick={props.resetFilters}>Clear all</Button>
                              </FormGroup>
                            </Col>
                          </Row>
                          : null}
                        <Row>
                          <div className="col-9">
                            <Input
                              type="search"
                              name="search"
                              bsSize="sm"
                              placeholder="Search"
                              style={{ height: "auto" }}
                              value={searchFilterInput}
                              onChange={(e) => setSearchFilterInput(e.target.value)}
                            />
                          </div>
                        </Row>
                      </FormGroup>
                      <Spinner loading={props.isFiltersLoading}>
                        <FormGroup>
                          {props.filters.map((filter) => {
                            if (filter.isActive) {
                              return filter.values.filter(item => searchFilterInput.length ? `${item.value}`.toLowerCase().includes(searchFilterInput.toLowerCase()) : true).map((k, idx) => {
                                return <div key={idx + "-" + filter.selector} className="custom-control custom-checkbox mb-2" style={{ marginLeft: "0.7rem" }}>
                                  <input className="custom-control-input" id={`tab-filter-${k.value}`} type="checkbox"
                                    checked={k.isChecked}
                                    onChange={(e) => { props.onSelectFilterValue(filter.selector, k.value) }} />
                                  <label className="custom-control-label" style={{ minWidth: "35%" }} htmlFor={`tab-filter-${k.value}`}>{k.value}</label>
                                </div>
                              })
                            }
                            return null;
                          })}
                        </FormGroup>
                      </Spinner>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="dimensions">
            <Row>
              <Col>
                <Card body style={{ 'maxHeight': 'calc(100vh - 220px)', 'overflowY': 'auto' }} className="bg-secondary p-3">
                  {props.dimensions.map((dimension) => {
                    return <div key={dimension.selector} className="custom-control custom-checkbox mb-2">
                      <input className="custom-control-input" id={dimension.selector} type="checkbox" checked={dimension.checked} onChange={(e) => props.onToggleDimension(dimension.selector)} />
                      <label className="custom-control-label" style={{ minWidth: "35%" }} htmlFor={dimension.selector}>{dimension.name}</label>
                    </div>
                  })}
                </Card>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </ModalBody>
      <ModalFooter className="p-3">
        <Button color="primary" onClick={() => props.close(false)}>Close</Button>
      </ModalFooter>
    </Modal>
  );
}

export default BreakdownDimensionModal;
