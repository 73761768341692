import React from 'react';
import { Pagination, PaginationItem, PaginationLink, Input, FormGroup, Label, Col } from 'reactstrap';


function TablePaginationActions({
  rowsPerPage,
  rowCount,
  onChangePage,
  onChangeRowsPerPage,
  currentPage
}) {

  const handleBackButtonClick = () => {
    let newPage = currentPage - 1;
    onChangePage(newPage);
  };

  const handleNextButtonClick = () => {
    let newPage = currentPage + 1;
    onChangePage(newPage);
  };

  return (
    <Pagination size="sm" aria-label="Page navigation:" className="mt-2 float-right">
      <PaginationItem>
        <FormGroup row>
          <Label className="px-0" size="sm" for="rowperpage" sm={6}>Rows per page:</Label>
          <Col sm={6}>
            <Input id="rowperpage" type="select" bsSize="sm" name="select" value={rowsPerPage} onChange={(e) => {
              onChangeRowsPerPage(parseInt(e.target.value,10))
            }}>
            {[50,100,150,250,500,1000].map((paginationPerPage) => {
              return <option key={paginationPerPage} value={paginationPerPage}>{paginationPerPage}</option>
            })}
            </Input>
          </Col>
        </FormGroup>
      </PaginationItem>
      <PaginationItem disabled={currentPage <= 1}>
        <PaginationLink previous onClick={handleBackButtonClick} />
      </PaginationItem>
      <PaginationItem disabled>
        <PaginationLink href="#">
          {currentPage}
        </PaginationLink>
      </PaginationItem>
      <PaginationItem disabled={rowCount < rowsPerPage}>
        <PaginationLink next onClick={handleNextButtonClick} />
      </PaginationItem>
    </Pagination>
  );
}

export default TablePaginationActions;