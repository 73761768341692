import React, { useState, useEffect } from "react";

import {Modal, ModalHeader, ModalBody, ModalFooter, Button, UncontrolledTooltip, FormGroup, Label, Input } from "reactstrap";


const MapFanPagetModal = (props) => {
  const { facebookAccountRuleModal, toggleFacebookAccountRuleModal, facebookAccountRules } = props;
  
  const [newrules, setNewRules] = useState([])
  const [rulesObj, setRulesObj] = useState([])


  useEffect(() => {
    console.log("NEW EFFECT");
    setNewRules([]);
    setRulesObj([]);
  }, [facebookAccountRuleModal])

  const toggleNewRules = (newRule) => {
    let index = newrules.indexOf(newRule);
    let nextrules = [...newrules]
    if (index === -1) {
      nextrules = [...nextrules, newRule];
      setNewRules(nextrules)
    } else {
      nextrules.splice(index, 1);
      setNewRules([...nextrules]);
    }
    
    let listRules = [];
    for ( let idx of nextrules ) {
      listRules.push(facebookAccountRules.allRules[idx])
    }
    setRulesObj(listRules);
  }

  return (
    <Modal isOpen={facebookAccountRuleModal} toggle={toggleFacebookAccountRuleModal} size="lg">
      <ModalHeader toggle={toggleFacebookAccountRuleModal}>FACEBOOK RULES:</ModalHeader>
      <ModalBody>
        <ul>
        {facebookAccountRules && facebookAccountRules.assignedRules 
          && facebookAccountRules.assignedRules.map((rule,idx) => (
          <li key={`key-${idx}`}>                            
            <span href="#" id={`rule-${idx}`}>{rule.name}</span>
            <UncontrolledTooltip placement="right" target={`rule-${idx}`}>
              <code>{JSON.stringify(rule)}</code>
              {/*<ListGroup flush>
                {JSON.parse(rule.name).map((c, iddx) => 
                  <ListGroupItem color="warning" key={`rule-${idx}-cond-${iddx}`}>{c.dimension} {c.op} {c.value}</ListGroupItem>
                )}
              </ListGroup>*/}
            </UncontrolledTooltip>
          </li>)
        )}
        </ul>
        <hr />
        <div>
          <FormGroup>
            <Label>ASSIGN NEW RULES</Label>
            <Input type="select" name="newrules" id="newrules" onChange={(e) => toggleNewRules(e.target.value)} multiple value={newrules}>
              {facebookAccountRules && facebookAccountRules.allRules 
                  && facebookAccountRules.allRules.map((newrule,i) => <option key={`newrule-${i}`} value={i}>{newrule.name}</option>)}
            </Input>
          </FormGroup>
          <Button color="secondary" block onClick={() => props.saveAccountRules(rulesObj)}>ASSIGN A NEW RULE!</Button>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleFacebookAccountRuleModal}>CLOSE</Button>
      </ModalFooter>
    </Modal>
  )
}

export default MapFanPagetModal;
