import React from "react";
import cx from "classnames";

import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

const Keyword = React.memo(({keywordRow, handleClick, select, isSelected, ind, idx}) => {

  const {keyword, isNew=0} = keywordRow;
  
  return (
    <li className={cx("list-group-item p-1", isSelected ? "bg-primary" : isNew === 1 ? "bg-lightyellow" : "")} style={{cursor: "pointer"}} 
    onClick={() => select(ind, idx, keyword, isNew)}>
      <ContextMenuTrigger id={`${keyword}-${idx}`}>
        {keyword}
      </ContextMenuTrigger>
 
      <ContextMenu id={`${keyword}-${idx}`} className="bg-white">
        <MenuItem data={{action: 'Delete', keyword: keyword, ind: ind, idx:idx}} onClick={handleClick} className="dropdown-item">
          <i className="far fa-trash-alt"></i><span className="ml-2">Delete</span>
        </MenuItem>
        <MenuItem data={{action: 'Seed', keyword: keyword, ind: ind, idx:idx}} onClick={handleClick} className="dropdown-item">
          <i className="fas fa-seedling"></i><span className="ml-2">Seed</span>
        </MenuItem>
        <MenuItem data={{action: 'Move', keyword: keyword, ind: ind, idx:idx}} onClick={handleClick} className="dropdown-item">
          <i className="fas fa-share"></i><span className="ml-2">Move to</span>
        </MenuItem>
      </ContextMenu>
    </li>
  );
});

export default Keyword;