import React, {useState, useEffect} from "react";
import cx from "classnames";
import UploadDropzone from "../../uploadPage/uploadDropzone";
import LocalFilesPreview from "./localFilesPreview.js";

import {
	Card,
  CardBody,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Collapse,
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledCollapse,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";

import { LazyLoadImage } from 'react-lazy-load-image-component';

import Spinner from "../../../../components/Spinner";

const SelectImages = (props) => {
  const {image, selectImage, selectedImages, searchForImages, isLoadingNewImages, 
          imagesFromApi, pagination, setPagination, selectLocalFile, localFiles} = props;

  const [keywordToSearch, setKeywordToSearch] = useState("");
  useEffect(() => {
    if ( image.keyword ) {
      setKeywordToSearch(image.keyword);  
    }
    
  //   if ( image.lookupKeyword ) {
  //     console.log("GO LOOK UP FOR IMAGES " + image.lookupKeyword);
  //     searchForImages(image.lookupKeyword);
  //   }
  }, [image])

  const [collapse, setCollapse] = useState({});
  const toggle = (id) => {
    setCollapse({...collapse, ...{[id]: !collapse[id]}});
  };


  const [activeTab, setActiveTab] = useState(1);
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  const [imageFullScreenUrl, setImageFullScreenUrl] = useState("");
  const [imageFullScreenModal, setImageFullScreenModal] = useState(false);
  const toggleImageFullScreenModal = (imageUrl) => {
    setImageFullScreenUrl(imageUrl);
    setImageFullScreenModal(!imageFullScreenModal);
  }

  console.log(image);

  return (
      <Row>
        <Col className="order-xl-1" xl="12">
          <Card className="bg-secondary shadow pt-0">
            <CardBody>
              {selectedImages.length > 0 && <>
                <Row><Col xs={12}><h3>Selected images:</h3></Col></Row>
                <Row>
                  {selectedImages.map((imagePath, i) => {
                    console.log(imagePath);
                    let loadUrl = imagePath;
                    if ( imagePath.startsWith("s3://") ) {
                      loadUrl = `https://s3-eu-west-1.amazonaws.com/static.blogger.co.uk/thumbs/${imagePath.replace('s3://','')}?v=${new Date().getTime()}`;
                    }
                    if ( imagePath.startsWith("depositphotos:") ) {
                      let infoPath = imagePath.split('---');
                      loadUrl = infoPath[1];
                    }
                    return <Col xs={2} key={`image-${i}`} className="mb-2">
                              <i className="far fa-trash-alt img-tool-trash-icon text-red" onClick={() => selectImage(imagePath)} style={{right: "16px"}}/>
                              <i className="ni ni-image img-tool-preview-icon text-red" onClick={() => toggleImageFullScreenModal(loadUrl)} style={{left: "20px"}} />
                              <LazyLoadImage
                                style={{cursor:"pointer"}}
                                alt={`image-${i}`}
                                className="img-fluid"
                                src={loadUrl} 
                                onClick={(e) => {e.stopPropagation(); selectImage(imagePath)}} />
                          </Col>
                  })}
                </Row>
              </>}
              <Row>
                <Col xs={12}>
                  <h3 id="rk-image-selection" style={{cursor:"pointer"}}>
                    <i className="fas fa-angle-double-down"></i> Image found related to {image.keyword}</h3>
                    <UncontrolledCollapse toggler="#rk-image-selection">
                      {image!==false && Object.keys(image.suggestions).map((sugg, idx) => {
                        return (
                            <div key={`sugg-${idx}`}>
                            <Row>
                              <h4>{sugg}</h4>
                            </Row>
                            <Row>
                              {image.suggestions[sugg].map((s3key, i) => {
                                return <Col xs={2} key={`s3key-${i}`} className="mb-2" style={{maxWidth: "12%"}}>
                                          <i className="ni ni-image img-tool-preview-icon text-red" onClick={(e) => {
                                            e.stopPropagation(); 
                                            toggleImageFullScreenModal(`https://s3-eu-west-1.amazonaws.com/static.blogger.co.uk/thumbs/${s3key}?v=${new Date().getTime()}`)
                                          }} style={{left: "20px"}} />
                                          <LazyLoadImage
                                            alt={s3key} 
                                            src={`https://s3-eu-west-1.amazonaws.com/static.blogger.co.uk/thumbs/${s3key}?v=${new Date().getTime()}`}
                                            className={cx("img-fluid", selectedImages.indexOf("s3://"+s3key) !== -1 ? "img-tool-active" : "")}
                                            onClick={(e) => {e.stopPropagation(); selectImage("s3://"+s3key)}} />
                                      </Col>
                              })}
                            </Row></div>)
                      })}
                    </UncontrolledCollapse>
                </Col>
              </Row>
              <hr/>
              <Row>
                <Col xs={12}>
                  <h3 id="searchkeyword-image-selection" style={{cursor:"pointer"}}>
                    <i className="fas fa-angle-double-down"></i> Search new images</h3>
                  <UncontrolledCollapse toggler="#searchkeyword-image-selection">
                  <div className="bg-white">
                    <FormGroup>
                      <Label for="exampleEmail" sm={4}>Search images for:</Label>
                      <Col sm={6}>
                        <InputGroup>
                          <Input type="text" name="keyword-search"
                                  value={keywordToSearch}
                                  onChange={(e) => setKeywordToSearch(e.target.value)} />
                          <InputGroupAddon addonType="append">
                            <Button onClick={() => searchForImages(keywordToSearch)}><i className="fas fa-search"></i></Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </FormGroup>
                    <Spinner isLoading={isLoadingNewImages}>
                    <Nav tabs>
                      {Object.keys(imagesFromApi).map((source) => (
                        <NavItem key={source}>
                          <NavLink
                            className={cx({ active: activeTab === source })}
                            onClick={() => { toggleTab(source); }}
                          >
                            {source.toUpperCase()} ({imagesFromApi[source].total}) 
                              {imagesFromApi[source].downloadCount ? ` - (${imagesFromApi[source].downloadCount})` : ``}
                          </NavLink>
                        </NavItem>  
                      ))}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                      {Object.keys(imagesFromApi).map((source) => (
                        <TabPane tabId={source} className="p-2" key={source}>
                            <Row>
                              {imagesFromApi[source].images.map((img, i) => {
                                let isImageSizeValid = true;
                                if ( img.width < 500 || img.height < 500 ) {
                                   isImageSizeValid = false;
                                }
                                return <Col xs={2} key={`newimg-${i}`} className="mb-2">
                                    <i className="ni ni-image img-tool-preview-icon text-red" onClick={(e) => {
                                      e.stopPropagation(); 
                                      toggleImageFullScreenModal(img.previewUrl)}} style={{left: "20px"}} />
                                    <LazyLoadImage
                                      alt={img.alt || ""} 
                                      src={img.previewUrl}
                                      className={cx("img-fluid", !isImageSizeValid ? "img-tool-notgood" : (selectedImages.indexOf(img.imageUrl) !== -1 ? "img-tool-active" : ""))}
                                      onClick={(e) => {e.stopPropagation(); isImageSizeValid && selectImage(img.imageUrl)}} />
                                </Col>
                              })}
                            </Row>
                            <Row className="justify-content-end">
                              {imagesFromApi[source].images.length > 0 &&
                                <Col xs={{ size: "auto" }}>
                                  <span style={{ marginRight: "0.8rem" }}><small>Page: {pagination[source] || 1} of {Math.ceil( imagesFromApi[source].total / 20)}</small></span>
                                  <Button outline color="primary"
                                    disabled={pagination[source] === undefined || pagination[source] <= 1}
                                    onClick={() => {
                                      let currentPage = pagination[source] || 1;
                                      setPagination({...pagination, [source] : pagination[source] - 1})
                                      searchForImages(keywordToSearch, source, currentPage-1);
                                    }}>
                                    PREV PAGE
                                  </Button>
                                  <Button outline color="primary" 
                                    disabled={(pagination[source]||1)*20 >= imagesFromApi[source].total}
                                    onClick={() => {
                                      let currentPage = pagination[source] || 1;
                                      setPagination({...pagination, [source] : currentPage + 1});
                                      searchForImages(keywordToSearch, source, currentPage+1);
                                    }}>
                                    NEXT PAGE
                                  </Button>
                                </Col>
                              }
                            </Row>
                        </TabPane>))}
                    </TabContent>
                    </Spinner>
                    </div>
                  </UncontrolledCollapse>
                </Col>
              </Row>
              <hr/>
              <Row>
                <Col xs={12}>
                  <h3><i className="fas fa-angle-double-right"></i> Upload local images for {image.keyword}</h3>
                  <UploadDropzone addLocalImageToSelected={selectLocalFile} />
                  <LocalFilesPreview addLocalImageToSelected={selectLocalFile} dropzoneFiles={localFiles} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Modal isOpen={imageFullScreenModal} toggle={toggleImageFullScreenModal} className="image-tool-preview-modal">
          <ModalBody className="pb-0">
            <img src={imageFullScreenUrl} className="img-fluid" alt="" />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggleImageFullScreenModal}>CLOSE</Button>
          </ModalFooter>
        </Modal>
      </Row>
  );
};

export default SelectImages;