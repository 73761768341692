import React, { useState } from "react";
import cx from "classnames";
import { Card, CardBody, CardTitle, Col, Nav, NavItem, NavLink,TabContent,TabPane } from "reactstrap";

const Widget = (props) => {

  const {columns, data, currency} = props;

  const [activeTab, setactiveTab] = useState("profit");
  const toggleActiveTab = tab => {
    if(activeTab !== tab) setactiveTab(tab);
  }


  const showDelta = (m, data) => {
  	if (m.selector === "profit" || m.selector === "roi" || m.selector === "revenue" ) {
  		return false;
  	} 

    return <>
    	{data[m.selector] > 0 
    		? <i className="fa fa-arrow-up" /> : 
    			data[m.selector] < 0  ? <i className="fa fa-arrow-down" /> : <i className="fas fa-equals"></i>}{" "}{data[m.selector] || "NaN"}
    		{" %"}
		</>;
  }

  return (
  	<Col xl={3}>
	  	<Card className="card-stats mb-4 mb-xl-0">
	  		<CardTitle tag="h5" className="text-uppercase text-muted mb-0 p-2">{data.domain}</CardTitle>
	      <CardBody>
          <TabContent activeTab={activeTab}  className="mb-3">
            {columns.map((m, key) => {
            	
            	let classDeltaYesterday = cx("mr-2", data[`deltaYesterday`][m.selector] > 0 ? "text-success" : "text-danger");
            	let classDeltaWeekAgo = cx("mr-2", data[`deltaWeekAgo`][m.selector] > 0 ? "text-success" : "text-danger");
            	if ( m.selector === "cpm" || m.selector === "cpc" ) {
            		classDeltaYesterday = cx("mr-2", data[`deltaYesterday`][m.selector] > 0 ? "text-danger" : "text-success");
            		classDeltaWeekAgo = cx("mr-2", data[`deltaWeekAgo`][m.selector] > 0 ? "text-danger" : "text-success");
            	} else if ( m.selector === "profit" || m.selector === "revenue" ) {
            		classDeltaYesterday = cx("mr-2");
            		classDeltaWeekAgo = cx("mr-2");
            	}

              return <TabPane tabId={m.selector} key={`tab-${key}`}>
                <p className={cx("h1 font-weight-bold mb-0", data.live[m.selector] < 0 && "text-danger")}>
		              {m.label}: {data.live[m.selector] || "NaN"} {m.suffix==="currency" ? currency : "%"}
		            </p>
		            <p className="mt-3 mb-0 text-muted text-sm">
				          <span className="text-nowrap">Yesterday</span>{": "}
				          <span className={classDeltaYesterday}>
				            {data[`yesterday`][m.selector] || "NaN"} {m.suffix==="currency" ? currency : "%"} {" "}
				            {showDelta(m, data[`deltaYesterday`])}
				          </span>
				        </p>
				        <p className="mt-2 mb-0 text-muted text-xs">
				          <span className="text-nowrap">Last week</span>{": "}
				          <span className={classDeltaWeekAgo}>
				            {data[`weekAgo`][m.selector] || "NaN"} {m.suffix==="currency" ? currency : "%"} {" "}
				            {showDelta(m, data[`deltaWeekAgo`])}
				          </span>
				        </p>
              </TabPane>
            })}
          </TabContent>
          <Nav pills fill>
            {columns.map((m, idx) => {
              return <NavItem key={`tabs-${idx}`} style={{paddingRight: "0.3rem"}}>
                <NavLink
                  className={cx({ active: activeTab === m.selector })}
                  onClick={() => { toggleActiveTab(m.selector); }}
                >
                  <span style={{ textTransform: "uppercase" }}>{m.label}</span>
                </NavLink>
              </NavItem>
            })}
          </Nav>
	      </CardBody>
	    </Card>
	  </Col>
	 )
}

export default Widget;


