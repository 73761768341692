import React, {useState, useEffect} from "react";
import {Container} from "reactstrap";
import { API } from 'aws-amplify';

import Widget from "./widget.js";

const Dashboard = (props) => {
  
  const [userWidgets, setUserWidgets] = useState([]);
  const loadWidgets = async () => {
    const widgets =  await API.get('customAPI', '/dashboard-realtime-widgets');
    setUserWidgets(widgets);
  }

  useEffect(() => {
    loadWidgets()
  }, [])
  return (
    <Container className="mt-4" fluid>
      {userWidgets.filter(w => !!w).map((w, idx) => <Widget key={idx} title={w.title} type={w.type} table={w.table} currency={w.currency} />)}
    </Container>
  );
}

export default Dashboard;
