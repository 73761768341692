import React, { useState, useEffect } from "react";
import Spinner from "../../../components/Spinner";
import cx from "classnames";
import { API } from "aws-amplify";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

const FacebookBulkTool = (props) => {

  const [businessManagerList, setBusinessManagerList] = useState([]);
  const [businessManager, selectBM] = useState("");
  const [action, setAction] = useState("");
  // const [action_level, setActionLevel] = useState("campaign");
  const [uploadCsvText, setUploadCsvText] = useState("");
  const handleUploadCsvTextChange = (e) => {
    setUploadCsvText(e.target.value);
  }
  
  const [isLoading, setIsLoading] = useState(false);


  const loadBusinessManager = async () => {
    let response = await API.get('customAPI', '/facebookAccountMapper/businessManager');
    setBusinessManagerList(response.businessManagerList);
  }


  useEffect(() => {
    loadBusinessManager()
  },[])

  const execBulkActions = async () => {
  	setIsLoading(true);
    await API.post('customAPI', '/keyword-tools/facebook-bulk-tool', {
  		body: {
        businessManager,
        action,
  			action_level: "",
        uploadCsvText
  		}
  	});
    alert("BULK ACTIONS DONE, HEAD OVER TO FACEBOOK TO CHECK!");
  	setIsLoading(false);
  }

  let label = <Label htmlFor="upload-csv-text">CampaignID/adsetID</Label>;
  let placeholder = "ID"
  if ( action === "budget" ) {
    label = <Label htmlFor="upload-csv-text">CampaignID/adsetID,budget</Label>;
    placeholder = "ID,budget";
  }
  if ( action === "bid" ) {
    label = <Label htmlFor="upload-csv-text">CampaignID/adsetID,bid</Label>;
    placeholder = "ID,bid";
  }
  if ( action.startsWith("pause_restart_")) {
    label = <Label htmlFor="upload-csv-text">CampaignID</Label>;
    placeholder = "CampaignID";
  }
  // if ( action_level === "adset" && action === "pause" ) {
  //   label = <Label htmlFor="upload-csv-text">CampaignID,adsetID</Label>;
  //   placeholder = "CampaignID,adsetID";
  // }
  // if ( action_level === "campaign" && action === "bid" ) {
  //   label = <Label htmlFor="upload-csv-text">CampaignID,bid</Label>;
  //   placeholder = "CampaignID,bid";
  // }
  // if ( action_level === "campaign" && action === "budget" ) {
  //   label = <Label htmlFor="upload-csv-text">CampaignID,budget</Label>;
  //   placeholder = "CampaignID,budget";
  // }
  // if ( action_level === "campaign" && action === "pause" ) {
  //   label = <Label htmlFor="upload-csv-text">CampaignID</Label>;
  //   placeholder = "CampaignID";
  // }

  return (
    <Container className="mt-2" fluid>
      <Row>
        <Col>
          <Card className="bg-secondary shadow">
            <CardHeader className={cx("border-0")}>
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">FACEBOOK BULK TOOL</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
            	<Form>
                {/* Description */}
                <Row>
                  <Col md={{size: 3,offset: 3}}>
                    <FormGroup>
                      <Label for="fbAccount">Business Manager: </Label>
                      <Input type="select" name="businessManager" id="businessManager" onChange={(e) => selectBM(e.target.value)} value={businessManager}>
                        <option key="empty-bm" value="">----</option>
                        {businessManagerList.map((bm,i) => <option key={bm.id} value={bm.id}>{bm.name}</option>)}
                      </Input>
                    </FormGroup>
                  </Col>
                	<Col md={{size: 3}}>
                    <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-utmcampaign"
                        >
                          ACTION
                        </label>
                      <Input
                        className="form-control-alternative"
                        id="input-utmcampagn"
                        placeholder="ACTION"
                        onChange={(e) => setAction(e.target.value)}
                        type="select">
                        <option key="empty" value="">ACTION</option>
                        <option key="active" value="active">ACTIVE</option>
                        <option key="pause" value="pause">PAUSE</option>
                        <option key="budget" value="budget">BUDGET CHANGE</option>
                        <option key="bid" value="bid">BID CHANGE</option>
                        <option key="delete" value="delete">DELETE</option>
                        <option key="2" value="pause_restart_7">PAUSE & RESTART IN 7days</option>
                        <option key="pause_restart_14" value="pause_restart_14">PAUSE & RESTART IN 14days</option>
                        <option key="pause_restart_21" value="pause_restart_21">PAUSE & RESTART IN 21days</option>
                      </Input>
                    </FormGroup>
                	</Col>
                  {/*<Col lg="4">
                    <label className="form-control-label">Action level?</label>
                    {["campaign", "adset"].map((level, kkk) => {
                      let actionLevelLabel = level === "campaign" ? "Campaign level!" 
                        : level === "adset" ? "Adset Level" 
                        : "??????"
                      return <Row form key={kkk}>
                      <Col>
                        <FormGroup check>
                          <Label check>
                            <Input type="radio" name="action_level" value={level}
                              checked={action_level === level}
                              onChange={e => {
                                setActionLevel(e.target.value);
                              }}/>{' '}
                            {actionLevelLabel}
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    })}
                  </Col>*/}
	              </Row>
                <Row className="mt-4">
                  <Col md={{size: 6,offset: 3}}>
                     <FormGroup>
                      { (action === "bid" || action === "budget") &&
                      <ul>
                        <li>+10%, -10%, i.e add/remove percentage of current {action}</li>
                        <li>10$, -10$, i.e add/remove 10$ to current {action}</li>
                        <li>*3, i.e Set {action} to 3 dollars</li>
                      </ul> }
                      {label}
                      <Input
                        placeholder={placeholder}
                        type="textarea"
                        rows="10"
                        name="upload-csv-text"
                        id="upload-csv-text"
                        value={uploadCsvText}
                        onChange={handleUploadCsvTextChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <div className="text-center">
                  <Spinner loading={isLoading}>
                  	 	<Button className="mt-4" color="primary" type="button" onClick={() => execBulkActions()} >
                        EXEC
                      </Button>
                  </Spinner>
                </div>
        			</Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default FacebookBulkTool;
