import React, {useState} from "react";
import { API } from 'aws-amplify';

import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, InputGroup,
  InputGroupAddon, DropdownToggle,
  DropdownMenu,
  DropdownItem, InputGroupButtonDropdown, ListGroup, ListGroupItem} from "reactstrap";

// import Spinner from "./Spinner";

const conditionPeriod = {
  // "today":"Today",
  "yesterday":"Yesterday",
  "last2days": "Last two days: condition must be true every single day",
  "last3days":"Last three days: condition must be true every single day",
  "last7days":"Last 7 days: condition must be true every single day",
  "lifetime":"Lifetime: condition must be true every single day",
  "aggr2days": "Sum of last two days: condition must be true after aggregating",
  "aggr3days": "Sum of last three days: condition must be true after aggregating",
  "aggr7days": "Sum of last 7 days: condition must be true after aggregating"
};

const CreateShuffleRuleModal = (props) => {
  const { isLoading, createShuffleRule, toggleCreateShuffleRule, 
            redirect, setRedirect, CONFIG, saveShuffleRuleRedirect} = props;

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropDown = () => setDropdownOpen(!dropdownOpen);

  const dimensions = CONFIG["partnerinsights"].DIMENSIONS_INIT.map((d) => ({
    name: d.name,
    selector: d.selector
  }));


  const [keywordShuffleReplacement, setKeywordShuffleReplacement] = useState("");
  const [action, setAction] = useState("");
  const [period, setPeriod] = useState("");
  const [listCondition, setListCondition] = useState([]);
  const [condition, setCondition] = useState({dimension: "", op: ">", value: 0});
  const addCondition = (e) => {
    e.preventDefault();
    setListCondition([...listCondition, condition]);
    setCondition({dimension: "", op: ">", value: 0});
  }

  const removeCondition = (idx) => {
    let newListCondition = [...listCondition];
    newListCondition.splice(idx,1);
    setListCondition([...newListCondition]);
    setCondition({dimension: "", op: ">", value: 0});
  }

  const createRule = () => {
    // console.log(listCondition);
    // console.log(action);
    // console.log(redirect);
    let newShuffleRule = {
      action: action,
      listCondition,
      period
    };
    
    if ( keywordShuffleReplacement.length ) {
      newShuffleRule.keywordShuffleReplacement = keywordShuffleReplacement;
    }
    
    if ( fileSelected !== "" ) {
      newShuffleRule.googleDriveFile = fileSelected;
    }

    let shuffleRules = [...(redirect.shuffleRules || []), newShuffleRule];
    setRedirect({...redirect, ...{shuffleRules: shuffleRules}});
    setListCondition([]);
    setAction("");
    setPeriod("");
    setKeywordShuffleReplacement("");
  }


  const [googleDriveFileList,setGoogleDriveFileList] = useState([]);
  const loadGoogleDriveFile = async () => {
    if ( googleDriveFileList.length ) {
      setGoogleDriveFileList([]);
      setFileSelected("");
    } else {
      let {fileList} = await API.get('customAPI', '/googledrive');
      setGoogleDriveFileList(fileList);
    }
  }
  const [fileSelected, setFileSelected] = useState("");



  const deleteShuffleRule = (shuffleRule, key) => {
    let shuffleRules = [...redirect.shuffleRules];
    shuffleRules.splice(key, 1)
    setRedirect({...redirect, ...{shuffleRules: shuffleRules}});
  }

  console.log("REDIRECT", redirect.shuffleRules);

  return (  
		<Modal isOpen={createShuffleRule} toggle={toggleCreateShuffleRule} size="lg">
      <ModalHeader toggle={toggleCreateShuffleRule}>Create Keyword Shuffle Logic for {redirect.name}</ModalHeader>
      <ModalBody className="pt-0">
    	 	<Form>

          <FormGroup>
            <Label for="input-keywordshuffle">
              Shuffle Keyword:
            </Label>
            <Input
              className="form-control"
              id="input-keywordshuffle"
              name="keywordshuffle"
              type="textarea"
              placeholder="keywordshuffle"
              value={redirect.keywordshuffle ? redirect.keywordshuffle.join(",") : ""}
              readOnly={true}
              rows={10}
            />
          </FormGroup>


          <Row form className="bg-secondary p-2">
            <h1>CREATE A SHUFFLING RULE</h1>
            <Col md={12}>
              <FormGroup>
                <Label for="ruleAction">Action: </Label>
                <Input type="select" name="action" id="ruleAction" value={action} onChange={(e) => setAction(e.target.value)}>
                  <option value="">--ACTION--</option>
                  <option value="REPLACE">REPLACE</option>
                  <option value="DELETE">DELETE</option>
                </Input>
              </FormGroup>
              {action === "REPLACE" && 
                <FormGroup>
                  <Label for="input-keywordshuffle-replacement">
                    Replacement Keywords:
                  </Label>
                  <button className="btn btn-icon btn-info float-right" type="button" 
                    style={{paddingTop:0, paddingLeft: 5, paddingBottom:0, paddingRight:5}}
                    onClick={e => { e.preventDefault(); loadGoogleDriveFile();  }}>
                    Toggle GoogleDrive
                  </button>
                  {googleDriveFileList.length > 0 ? 
                      <ListGroup flush>
                        {googleDriveFileList
                          .map((file, idx) => <ListGroupItem active={fileSelected === file.id} 
                            onClick={() => setFileSelected(file.id)} 
                            style={{padding: "0.5rem 0.5rem"}} key={`cond-${idx}`}>{file.name}</ListGroupItem>)}
                      </ListGroup>
                    : <Input
                        className="form-control"
                        id="input-keywordshuffle-replacement"
                        name="keywordshuffle-replacement"
                        type="textarea"
                        placeholder="keywordshuffle-replacement"
                        value={keywordShuffleReplacement}
                        onChange={(e) => setKeywordShuffleReplacement(e.target.value)}
                        rows={10}
                      />}
                </FormGroup>}

              <FormGroup>
                <Label>Time Range: </Label>
                <Input type="select" name="period" id="conditionPeriod" value={period} onChange={(e) => setPeriod(e.target.value)}>
                  <option>---</option>
                  {Object.keys(conditionPeriod).map((conditionVal) => <option key={conditionVal} value={conditionVal}>{conditionPeriod[conditionVal]}</option>)}
                </Input>
              </FormGroup>
            
              <FormGroup>
                <Label>Conditions: </Label>
                <InputGroup>
                  <Input type="select" name="dimension" id="dimension" value={condition.dimension} onChange={(e) => setCondition({...condition,dimension:e.target.value})}>
                     <option key={-1}>----</option>
                     {dimensions.map((dimension) => <option key={dimension.selector} value={dimension.selector}>{dimension.name}</option>)}
                  </Input>
                  <InputGroupButtonDropdown addonType="append" isOpen={dropdownOpen} 
                      toggle={() => toggleDropDown()}>
                    <DropdownToggle caret>{condition.op}</DropdownToggle>
                    <DropdownMenu>
                      {['>', '>=', "=", "<", "<="].map(op => <DropdownItem key={op} onClick={() => setCondition({...condition, op:op})}>{op}</DropdownItem>)}
                    </DropdownMenu>
                  </InputGroupButtonDropdown>
                  <InputGroupAddon addonType="append">
                     <Input onChange={(e) => setCondition({...condition,value:e.target.value})} value={condition.value}/>
                  </InputGroupAddon>
                  <InputGroupAddon addonType="append">
                     <Button color="primary" onClick={addCondition} disabled={condition.dimension === ""}>Add!</Button>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>
              <ListGroup flush>
                {listCondition.map((c, idx) => 
                  <ListGroupItem style={{padding: "0.5rem 0.5rem"}} key={`cond-${idx}`}>
                      <Button className="mr-2" onClick={(e) => removeCondition(idx)}>X</Button>
                        {conditionPeriod[period]} {c.dimension}{c.op}{c.value}
                    </ListGroupItem>)}
              </ListGroup>
            </Col>
            <Button block color="primary" disabled={isLoading} onClick={createRule}>CREATE RULE</Button>
          </Row>

          <Row>
            <Col>
              <ListGroup flush>
                {redirect.shuffleRules && redirect.shuffleRules.map((shuffleRule, key) => {
                  return <ListGroupItem key={key}>
                      <Button onClick={(e) => deleteShuffleRule(shuffleRule, key)}>X</Button>Rule {key+1} - {shuffleRule.action} when 
                      <ListGroup flush>{shuffleRule.listCondition.map((c, idx) => <ListGroupItem  key={`cond-${idx}`}>{conditionPeriod[shuffleRule.period]} {c.dimension}{c.op}{c.value}</ListGroupItem>)}</ListGroup>
                    </ListGroupItem>
                })}
              </ListGroup>
            </Col>
          </Row>

      	</Form>
      </ModalBody>
      <ModalFooter>
	      <Button color="secondary" onClick={toggleCreateShuffleRule}>CLOSE</Button>
	      <Button color="primary" disabled={isLoading} onClick={saveShuffleRuleRedirect}>SAVE</Button>
	    </ModalFooter>
    </Modal>
  )
}

export default CreateShuffleRuleModal;

