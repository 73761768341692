import React, {useEffect,} from "react";
import { useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import qs from 'query-string';

const ReportShare = (props) => {

  const location = useLocation();

  const loadReport = async (shareID) => {
    const {name, reportType, preset, path} = await API.get('customAPI', '/report-share', {
      queryStringParameters: {
        "shareID": shareID
      }
    });
    let userPresets = {};
    try {
      userPresets = localStorage.getItem("userPresets") || "{}";
      userPresets = JSON.parse(userPresets);
    } catch (e) { 
      userPresets = {}
    }
    userPresets[reportType] = userPresets[reportType] || {};
    userPresets[reportType][name] = preset;
    localStorage.setItem("userPresets", JSON.stringify(userPresets));
    props.history.push({
      pathname: path
    });
  }

  useEffect(() => {
    const search = qs.parse(location.search);
    if ( search.shareid ) {
      loadReport(search.shareid);
    }
  }, [location])

  return (<div>SAVING REPORT SHARED!</div>);
}

export default ReportShare;