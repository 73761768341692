import React, {useState} from "react";
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Button } from "reactstrap";

const UploadKeywordListModal = (props) => {
  const { isOpen, toggle } = props;
  const [keywordList, setKeywordList] = useState("");

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="pb-1" toggle={toggle}>Group more keyword!</ModalHeader>
      <ModalBody className="pt-1">
        <Row>
          <Col>
            <FormGroup>
              <Input
                className="form-control-alternative"
                placeholder="List of keyword, one per row!"
                rows="10"
                type="textarea"
                value={keywordList}
                onChange={(e) => {
                  let inputKeyword = e.target.value;
                  setKeywordList(inputKeyword);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={() => {
          props.groupKeywordList(keywordList);
          setKeywordList([]);
          toggle();
        }}>LOAD</Button>
      </ModalFooter>
    </Modal>
  )
}

export default UploadKeywordListModal;
