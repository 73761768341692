import React, { useState } from "react";
import { API } from "aws-amplify";

// import DataTable from 'react-data-table-component';

import { Button } from "reactstrap";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal, ModalHeader, ModalBody
} from "reactstrap";


import Spinner from "../../components/Spinner";

const RelatedKeyword = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const [downloadModal, setDownloadModal] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(false);
  // const [asyncExecution, setAsyncExecution] = useState(false);
  const [copyImage, setCopyImage] = useState(false);

  const relatedKeyword = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const form = event.target;
    const fd = new FormData(form);
    setIsLoading(true);
    const data = {};
    for (let key of fd.keys()) {
      data[key] = fd.get(key);
    }
    let {signedUrl} = await API.post('customAPI', '/keyword-tools/relatedkeywords', {
      body: {...data}
    });
    // setAsyncExecution(asyncExecution);
    setDownloadUrl(signedUrl);
    setDownloadModal(true);
    setIsLoading(false);
  }


  return (
    <Container className="mt-2" fluid>
    	<Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Related Keyword</h3>
                </Col>
                {/*<Col>
                  <Button className="btn-info float-right" onClick={downloadTrendly}>
                    TRENDLY
                  </Button>
                </Col>*/}
              </Row>
            </CardHeader>
            <CardBody>
            <Form onSubmit={relatedKeyword}>
              <div className="pl-lg-4">
                <Row>
                  <Col>
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-keyword"
                      >
                        SEED KEYWORD *
                      </label>
                    <Spinner isLoading={isLoading}>
                      <Input
                        type="textarea"
                        placeholder={"List of `market,seedKeyword`, one per row!"}
                        rows="10"
                        name="upload-csv-text"
                        id="upload-csv-text"
                      />
                    </Spinner>
                    </FormGroup>
                    <FormGroup check inline className="mt-4 mb-1" >
                      <Label check>
                        <Input type="checkbox" name="copyImage" /> Copy images from seed keyword to related found!
                      </Label>
                    </FormGroup>
                    <hr/>
                    <Button type="submit" className="btn-block" color="primary" disabled={isLoading}>DOWNLOAD RELATED KEYWORDS</Button>
                  </Col>
                </Row>
              </div>
            </Form>
            <Modal isOpen={downloadModal} toggle={() => setDownloadModal(false)} size="lg">
              <ModalHeader toggle={() => setDownloadModal(false)}></ModalHeader>
              <ModalBody>
                <Row className="text-center">
                  <Col>
                    <h1 className="mt-2 mb-2">Thank you for using us! You will receive an email shortly!!</h1>
                    <hr />
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
            </CardBody>
          </Card>
        </div>
      </Row>

    </Container>
  );
}

export default RelatedKeyword;
