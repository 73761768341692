import React, {useState} from "react";
import {Container, Row, Col, FormGroup, Label } from "reactstrap";
import moment from "moment";
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css';

import FlipCard from "./FlipCard/index.js";

const Dashboard = () => {
  
  const [selectedDate, setDate] = useState(moment().subtract(1, "days").toDate());

  
  return (
    <Container className="mt-4" fluid>
      <Row>
        <Col xs={{ size: 4 }}>
          <FormGroup row className="mb-1">
            <Label for="date" className="text-right" size="sm" sm={2}>Date:</Label>
            <Col sm={10}>
              <DatePicker
                id="date"
                selected={selectedDate}
                onChange={date => setDate(date)}
                dateFormat="yyyy-MM-dd"
                calendarClassName="datepickerCustomStyle"
                maxDate={moment().subtract(1, "days").toDate()} className="form-control form-control-sm"
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg="6" xl="4">
          <FlipCard title="PERION"
              currency={"$"} type="PERIONBING" date={moment(selectedDate).format("YYYY-MM-DD")} />
        </Col>
        <Col lg="6" xl="4">
          <FlipCard title="FREESTAR"
              currency={"$"} type="FREESTAR" date={moment(selectedDate).format("YYYY-MM-DD")} />
        </Col>
        <Col lg="6" xl="4">
          <FlipCard title="BODIS"
              currency={"$"} type="BODIS" date={moment(selectedDate).format("YYYY-MM-DD")} />
        </Col>
        <Col lg="6" xl="4">
          <FlipCard title="DOMAINACTIVE"
              currency={"$"} type="DOMAINACTIVE" date={moment(selectedDate).format("YYYY-MM-DD")} />
        </Col>
        <Col lg="6" xl="4">
          <FlipCard title="YMBING"
              currency={"$"} type="YMBING" date={moment(selectedDate).format("YYYY-MM-DD")} />
        </Col>


        {/******/}
        {/*<Col lg="6" xl="4">
          <FlipCard title="ADP YAHOO" type="ADPYAHOO" date={moment(selectedDate).format("YYYY-MM-DD")} currency={"€"} />
        </Col>*/}
        {/*<Col lg="6" xl="4">
          <FlipCard title="PARKED DOMAINS"
              currency={"$"} type="PARKEDDOMAINS" date={moment(selectedDate).format("YYYY-MM-DD")} />
        </Col>*/}
        {/*<Col lg="6" xl="4">
          <FlipCard title="1&1"
              currency={"$"} type="ONEANDONE" date={moment(selectedDate).format("YYYY-MM-DD")} />
        </Col>*/}
        {/*<Col lg="6" xl="4">
          <FlipCard title="Syndication ADP"
              currency={"€"} type="PI" date={moment(selectedDate).format("YYYY-MM-DD")} />
        </Col>*/}
        {/*<Col lg="6" xl="4">
          <FlipCard title="TECHADS"
              currency={"$"} type="TECHADS" date={moment(selectedDate).format("YYYY-MM-DD")} />
        </Col>*/}
        {/*<Col lg="6" xl="4">
          <FlipCard title="MEDIANET"
              currency={"$"} type="MEDIANET" date={moment(selectedDate).format("YYYY-MM-DD")} />
        </Col>*/}
        {/*<Col lg="6" xl="4">
          <FlipCard title="DONGLE"
              currency={"€"} type="DONGLE" date={moment(selectedDate).format("YYYY-MM-DD")} />
        </Col>*/}
        {/*<Col lg="6" xl="4">
          <FlipCard title="DISPLAYADS"
              currency={"$"} type="DISPLAYADS" date={moment(selectedDate).format("YYYY-MM-DD")} />
        </Col>*/}
        {/**<Col lg="6" xl="4">
          <FlipCard title="YAHOO EXT"
              currency={"€"} type="YAHOO EXT" date={moment(selectedDate).format("YYYY-MM-DD")} />
        </Col>*/}
      </Row>
    </Container>
  );
}

export default Dashboard;
