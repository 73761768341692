import React from "react";
import { Route, Switch } from "react-router-dom";
// core components
// import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import Dashboard from "views/dashboard/index.js";
import DashboardRealTime from "views/dashboard_realtime/index.js";

import ReportPage from "views/reports/index.js";
import TqReportPage from "views/tqreport/index.js"


import ImageTool from "views/image-tool/uploadPage/index.js";
import ExploreImage from "views/image-tool/explorePage/index.js";
import ImageBulkTool from "views/image-tool/bulk/index.js";

import RelatedKeyword from 'views/relatedKeyword/index.js'

import AdTemplate from "views/automation/adtemplate.js";
import AdTemplateNew from "views/keyword-tools/adtemplate/index.js";


import OfflineTesterUpload from "views/keyword-tools/offline-tester/upload";
import KeywordPlanner from "views/keyword-tools/planner/index";
import RecyclingKeywords from "views/keyword-tools/planner/recycling";

import NewFacebookAutomation from "views/keyword-tools/automation/facebook.v1.js";
import FacebookAutomation from "views/keyword-tools/automation/facebook";
import BucksenseAutomation from "views/keyword-tools/automation/bucksense";
import OutbrainAutomation from "views/keyword-tools/automation/outbrain";
import TaboolaAutomation from "views/keyword-tools/automation/taboola";
import TaboolaRules from "views/keyword-tools/automation/taboolaRules";


import FanPagesAutomation from "views/keyword-tools/automation/fanpages.js";
import FbRules from "views/keyword-tools/automation/fbrules";
import FbRulesLog from "views/keyword-tools/automation/fbruleslog";
import FacebookAccountMapper from "views/keyword-tools/automation/facebookAccountMapper";

import GroupKeywords from "views/keyword-tools/grouping/index";

import Users from 'views/user-console/users.js'

import ReportShare from 'views/keyword-tools/reportShare/index';

  
import FacebookBulkTool from 'views/keyword-tools/facebook-bulk-tool/index';  

import FacebookAdlibraryTool from 'views/keyword-tools/facebook-adlibrary-tool/index';
  

import RedirectTool from 'views/redirect-tool/index';

import RPCTool from 'views/rpc-tool/index';


import { API } from 'aws-amplify';

const components = {
  "Dashboard": Dashboard,
  "DashboardRealTime": DashboardRealTime,
  "ReportPage": ReportPage,
  "TqReportPage": TqReportPage,

  
  "BucksenseAutomation": BucksenseAutomation,
  "OutbrainAutomation": OutbrainAutomation,
  "TaboolaAutomation": TaboolaAutomation,
  "FacebookAutomation": FacebookAutomation,
  "NewFacebookAutomation": NewFacebookAutomation,

  "FanPagesAutomation": FanPagesAutomation,
  
  "AdTemplate": AdTemplate,
  "AdTemplateNew": AdTemplateNew,
  
  "ImageTool": ImageTool,
  "FileSystem": ExploreImage,
  "ImageBulkTool": ImageBulkTool,

  "Users": Users,
  "RelatedKeyword": RelatedKeyword,
  
  "GroupKeywords": GroupKeywords,

  "OfflineTesterUpload": OfflineTesterUpload,
  "FacebookBulkTool": FacebookBulkTool,
  "FacebookAdLibraryTool": FacebookAdlibraryTool,

  "KeywordPlanner": KeywordPlanner,
  "RedirectTool": RedirectTool,
  "RPCTool": RPCTool,
  
  "RecyclingKeywords": RecyclingKeywords,
  "FbRules": FbRules,
  "FbRulesLog": FbRulesLog,
  "TaboolaRules": TaboolaRules,
  "FacebookAccountMapper": FacebookAccountMapper,
  "ReportShare": ReportShare

}

class Admin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      routes: []
    };
  }
  componentDidMount() {
    API.get('customAPI', '/routes').then((res) => {
      res.routes = [...res.routes, ...[{
        path: "/report-share",
        name: "Report Share",
        component: "ReportShare",
        layout: "/admin",
        skip: true
      }, {
        path: "/keyword-tools/automation/fb-rules-logs",
        name: "Facebook Rules logs",
        component: "FbRulesLog",
        layout: "/admin",
        skip: true
      }]];
      this.setState({ routes: res.routes });
    }).catch((err) => {
      console.log("ERROROROR", err);
    });
  }
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.children) {
        return this.getRoutes(prop.children);
      }
      if (prop.layout === "/admin") {
        let Component = components[prop.component];
        return (
          <Route
            path={prop.layout + prop.path}
            render={routeProps => <Component {...routeProps} table={prop.table || false} reportType={prop.reportType || false} reportname={prop.reportName || false} {...this.props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  // getBrandText = path => {
  //   for (let i = 0; i < this.state.routes.length; i++) {
  //     if (
  //       this.props.location.pathname.indexOf(
  //         this.state.routes[i].layout + this.state.routes[i].path
  //       ) !== -1
  //     ) {
  //       return this.state.routes[i].name;
  //     }
  //   }
  //   return "Brand";
  // };
  render() {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={this.state.routes}
        />
        <div className="main-content" ref="mainContent">
          <Switch>
            {this.getRoutes(this.state.routes)}
          </Switch>
          {/*<Container fluid>
            <AdminFooter />
          </Container>*/}
        </div>
      </>
    );
  }
}

export default Admin;
