/*eslint-disable*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import { Auth } from 'aws-amplify';
import logo from "assets/img/brand/argon-react-white.png";
import cx from "classnames"
// reactstrap components
import {
  NavbarToggler,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

var ps;

class Sidebar extends React.Component {
  state = {
    collapseOpen: true,
    sideBarOpen: true
  };
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };

  toggleSideBar = () => {
    this.setState({
      sideBarOpen: !this.state.sideBarOpen
    });
  };

  // creates the links that appear in the left menu / Sidebar
  createLinks = routes => {
    return routes.map((prop, key) => {
      if ( prop.skip) {
        return null;
      }
      if (prop.children) {
        return (
          <UncontrolledDropdown key={key} direction="right">
            <DropdownToggle className="pr-0" nav caret>
              <i className={prop.icon} />
              {prop.name}
            </DropdownToggle>
            <DropdownMenu
              modifiers={{
                setStyle: {
                  enabled: true,
                  order: 890,
                  fn: (data) => {
                    return {
                      ...data,
                      styles: {
                        // ...data.styles,
                        position: 'static',
                      },
                    };
                  },
                },
              }}
            >
              {prop.children.filter(child => child.icon).map((child, childkey) =>
                <DropdownItem tag="div" key={childkey}>
                  <NavLink
                    to={child.layout + child.path}
                    tag={NavLinkRRD}
                    onClick={this.closeCollapse}
                    activeClassName="active"
                    style={{textTransform: "uppercase"}}
                  >
                    <i className={child.icon} />
                    {child.name}
                  </NavLink>
                </DropdownItem>
              )}
            </DropdownMenu>
          </UncontrolledDropdown>);
      }
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={this.closeCollapse}
            activeClassName="active"
            style={{textTransform: "uppercase"}}
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };
  render() {
    const { routes } = this.props;
    return (
      <>
        <button className="btn btn-primary d-none d-sm-block toggleSideBarButton" onClick={this.toggleSideBar}>
          <span className={cx("fas fa-arrow-right", { reverse: this.state.sideBarOpen })} />
        </button>
        <Navbar
          className={cx('navbar-vertical fixed-left navbar-light bg-white', { hideSideBar: !this.state.sideBarOpen })}
          id="sidenav-main"
          expand="md"
        >
          <Container fluid>
            {/* Brand */}
            <NavbarBrand className="pt-0">
              <img
                alt="Dashboard Cmind"
                className="navbar-brand-img"
                src={logo}
                style={{ maxWidth: "150px", height: "auto" }}
              />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggleCollapse} />
            {/* Collapse */}
            <Collapse navbar isOpen={this.state.collapseOpen}>
              {/* Collapse header */}
              <div className="navbar-collapse-header">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.toggleCollapse}
                >
                </button>
              </div>
              {/* Navigation */}
              <Nav navbar>
                {this.createLinks(routes)}
                <NavItem href="#" onClick={async (e) => {localStorage.removeItem('X-loginAs');await Auth.signOut();}}>
                  <NavLink>
                    <i className="fas fa-sign-out-alt" style={{ fontSize: "1rem" }} />
                    <span>Logout</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}


Sidebar.defaultProps = {
  routes: [{}]
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};

export default Sidebar;
