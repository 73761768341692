import React, { PureComponent } from "react";

class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" fontSize={10} fill="#666" transform="rotate(-35)">{payload.value}</text>
      </g>
    );
  }
}

export default CustomizedAxisTick;
