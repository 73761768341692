import React from "react";
import Flag from "react-flags";
import cx from "classnames";
import { ListGroup, ListGroupItem } from "reactstrap";

const FlagListGroup = (props) => {
  const { onClick, selectedMarkets, markets, pngSize=32, isLoading=false } = props;

  return (
    <ListGroup className="list-group-horizontal" style={{ flexWrap: "wrap"}}>
      {markets.map((m) =>
        <ListGroupItem disabled={isLoading} key={m.flag} onClick={() => onClick(m.value)} className={cx("countryItem", { active: selectedMarkets.includes(m.value), "flex-fill": props.fill === true })}>
          <Flag name={m.flag} format="png" pngSize={pngSize} shiny={true} alt={m.label} basePath="" />
          <span style={{ textTransform: "uppercase" }}>{m.value}</span>
           {m.hasOwnProperty("count") ? <span className={cx("small", m.count>0 && "text-warning")}>({m.count})</span> : null}
        </ListGroupItem>
      )}
    </ListGroup>
  )
}

export default FlagListGroup;
