import React, {useState} from "react";
import {Col, Modal, ModalHeader, ModalBody, ModalFooter, Button,  FormGroup,
  Label, Input, InputGroupButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, InputGroup } from "reactstrap";

const RulesModal = (props) => {
  const { rulesModal, toggleRulesModal, rulesConfig,
  		selectRuleOpFilter, selectRuleValueFilter } = props;

  const [dropdownOpen, setDropdownOpen] = useState({});
  const toggleDropDown = (selector) => {
  	let dropdownOpenState = {...dropdownOpen};
  	dropdownOpenState[selector] = dropdownOpenState[selector] || false;
  	//toggle it!
  	dropdownOpenState[selector] = !dropdownOpenState[selector];
  	setDropdownOpen(dropdownOpenState);
  }

  return (
    <Modal isOpen={rulesModal} toggle={toggleRulesModal}>
      <ModalHeader className="pb-1" toggle={toggleRulesModal}>Create a rule!</ModalHeader>
      <ModalBody className="pt-1">
        {props.dimensions.filter(d => d.checked).map((dimension) => {
          return <FormGroup row key={dimension.selector}>
            <Label sm={4} className="text-right">
              {dimension.name}
            </Label>
            <Col sm={8}>
            	<InputGroup>
				        <InputGroupButtonDropdown addonType="append" isOpen={dropdownOpen[dimension.selector]} 
				        		toggle={() => toggleDropDown(dimension.selector)}>
				          <DropdownToggle caret>
				            {rulesConfig[dimension.selector] && rulesConfig[dimension.selector].op ? rulesConfig[dimension.selector].op : "Op"}
				          </DropdownToggle>
				          <DropdownMenu>
				          	{['>', '>=', "=", "<", "<="].map(op => <DropdownItem key={op} 
				          		onClick={() => selectRuleOpFilter(dimension.selector, op)}>{op}</DropdownItem>)}
				          </DropdownMenu>
				        </InputGroupButtonDropdown>
				        <Input className="form-control pl-1" onChange={(e) => selectRuleValueFilter(dimension.selector, e.target.value)} 
				        	value={rulesConfig[dimension.selector] && rulesConfig[dimension.selector].value ? rulesConfig[dimension.selector].value : ""}/>
				      </InputGroup>
            </Col>
          </FormGroup>
        })}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggleRulesModal}>APPLY</Button>
      </ModalFooter>
    </Modal>
  )
}

export default RulesModal;
