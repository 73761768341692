import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";

const validateImageDimensions = (imageAccepted, addLocalImageToSelected) => {
  return new Promise((resolve, rej) => {
    var _URL = window.URL || window.webkitURL;
    let img = new Image();
    var objectUrl = _URL.createObjectURL(imageAccepted);
    img.onload = function () {
      if ( this.width < 500 || this.height < 500 ) {
        alert(imageAccepted.name + " is TOO SMALL! " + this.width + " " + this.height);
        resolve(false);
      } else {
        _URL.revokeObjectURL(objectUrl);
        resolve(true);
      }
    };
    img.src = objectUrl;
  });
}


const UploadDropzone = (props) => {

  const onDrop = useCallback(async (acceptedFiles) => {
    let checkedFiles = [];
    for ( let imageAccepted of acceptedFiles ) {
      let isValid = await validateImageDimensions(imageAccepted);
      if ( isValid ) {
        checkedFiles.push(imageAccepted);
      }
    }
    props.addLocalImageToSelected(checkedFiles);
  });

  const onDropRejected = () => alert("File not uploaded, images only please!");

  const {multiple=true} = props;
  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, rejectedFiles=[] } = useDropzone({ onDrop, onDropRejected, accept: props.accept || 'image/*', multiple: multiple });

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragAccept,
    isDragReject
  ]);

  const rejectedFilesItems = rejectedFiles && rejectedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div {...getRootProps({ className: "uploadDropzone", style })}>
      <input {...getInputProps()} />
      {
        isDragActive ?
          <p className="mb-0">Drop images here ...</p>
          : <p className="mb-0">Drag 'n' drop images here, or click to browse...</p>
      }
      {rejectedFiles.length > 0 &&
        <div>
          <h4 style={{ color: "red" }}>Rejected files</h4>
          <ul>
            {rejectedFilesItems}
          </ul>
        </div>
      }
    </div>
  )
}

export default UploadDropzone;


/* STYLES */
const baseStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  cursor: 'pointer',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#5e72e4',
  minHeight: "300px"
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};
