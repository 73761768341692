import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";

import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button
} from "reactstrap";


import DataTable from 'react-data-table-component';
// import Spinner from "../../../components/Spinner";
import CreateAdTemplate from "./CreateAdTemplate";

const languages = [
  { value: "de", flag: "de", label: "German" },
  { value: "pt", flag: "pt", label: "Portugese" },
  { value: "es", flag: "es", label: "Spanish" },
  { value: "it", flag: "it", label: "Italian" },
  { value: "fr", flag: "fr", label: "French" },
  { value: "en", flag: "gb", label: "English" },
  { value: "se", flag: "se", label: "Swedish" },
  { value: "dk", flag: "dk", label: "Danish" },
  { value: "fi", flag: "fi", label: "Finnish" },
  { value: "no", flag: "no", label: "Norwegian" },
  { value: "nl", flag: "nl", label: "Dutch" }
];

 const UTM_CAMPAIGN = [
    { id: "automotive", name: "automotive" },
    { id: "education", name: "education" },
    { id: "fashion", name: "fashion" },
    { id: "finance", name: "finance" },
    { id: "flight", name: "flight" },
    { id: "generic", name: "generic" },
    { id: "health", name: "health" },
    { id: "household", name: "household" },
    { id: "services", name: "services" },
    { id: "shopping", name: "shopping" },
    { id: "tech", name: "tech" },
    { id: "travel", name: "travel" },
    { id:"blackfriday", name: "blackfriday"},
    // { value: "business", label: "business" },
    // { value: "internet", label: "internet" },
    // { value: "health", label: "health" },
    // { value: "astrology", label: "astrology" },
    // { value: "gambling", label: "gambling" }
  ];


const AdTemplate = (props) => {

  const [isLoading, setIsLoading] = useState(false);
 	
  const [adTemplates, setAdTemplates] = useState([]);  

  const loadAdTemplates = async () => {
    setIsLoading(true);
    let response = await API.get('customAPI', '/keyword-tools/adtemplate');
    setAdTemplates(response);
    setIsLoading(false);
  }


  useEffect(() => {
  	loadAdTemplates();
  }, [])


  const [createTemplateModal, setCreateTemplateModal] = useState(false);
  const toggleCreateTemplateModal = () => {
  	setCreateTemplateModal(!createTemplateModal)
  }



  const [updateTemplate, setUpdateTemplate] = useState([]);
  // const [updateTemplateModal, setUpdateTemplateModal] = useState(false);
  // const toggleUpdateTemplateModal = () => {
  //   setUpdateTemplateModal(!updateTemplateModal)
  // }

  // const handleUpdateTemplate = async (template, adtemplate) => {
  //   setIsLoading(true);
  //   await API.put('customAPI', '/keyword-tools/adtemplate', {
  //     body: {
  //       ...template,
  //       adtemplate
  //     }
  //   });
  //   setIsLoading(false);
  //   toggleUpdateTemplateModal();
  // }

  const saveTemplate = async (template, adtemplate, updateTemplate) => {
    console.log(template, adtemplate, updateTemplate);
    setIsLoading(true);
    if ( updateTemplate.label !== "" && template.label !== updateTemplate.label) {
      // i m updating the label! 
      await API.put('customAPI', '/keyword-tools/adtemplate', {
        body: {
          ...template,
          updateLabel: updateTemplate.label
        }
      });
      console.log("UPDATE LABEL " + template.label + " - " + updateTemplate.label);
    } else {
      await API.post('customAPI', '/keyword-tools/adtemplate', {
        body: {
          ...template,
          adtemplate
        }
      });
    }
    setIsLoading(false);
    toggleCreateTemplateModal();
    loadAdTemplates();  
  }

  const deleteTemplate = async ( {label, utm_campaign} ) => {
    setIsLoading(true);
    await API.del('customAPI', '/keyword-tools/adtemplate', {
      body: {
        label, utm_campaign
      }
    });
    setIsLoading(false);
    loadAdTemplates();
  }

  return (
    <Container className="mt-2" fluid>
      <Row>
        <Col>
          <Card className="bg-secondary shadow">
            <CardHeader className="border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">AD TEMPLATE</h3>
                </Col>
                <Col className="text-right" xs="4">
                  <button className="btn btn-icon btn-warning" type="button" 
                    style={{paddingTop:0, paddingLeft: 5, paddingBottom:0, paddingRight:5}}
                    onClick={() => {
                      setUpdateTemplate({
                        label: "",
                        utm_campaign: ""
                      });
                      toggleCreateTemplateModal();
                    }}>
                    <i className="fas fa-plus"></i> Create Template
                  </button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
            	<DataTable
                columns={[
                  { selector: "label", name: "Label", sortable: true, compact:true},
                  { selector: "utm_campaign", name: "UTM Campaign", sortable: true, compact: true},
                  { grow:0, compact:true, width: "40px", cell: (row) => (
                    <Button color="danger" size="sm" onClick={(e) => deleteTemplate(row)}>
                      <i className="fas fa-times"></i>
                    </Button>)
                  },

                ]}
                data={adTemplates}
                progressPending={isLoading}
                persistTableHead={true}
                noHeader={true}
                striped={true}
                highlightOnHover
                dense={true}
                onRowClicked={(row) => { 
                  setUpdateTemplate(row); 
                  // toggleUpdateTemplateModal(); 
                  toggleCreateTemplateModal();
                }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/*<UpdateAdTemplate toggleModal={toggleUpdateTemplateModal} isOpen={updateTemplateModal} 
        updateTemplate={updateTemplate} handleUpdateTemplate={handleUpdateTemplate} languages={languages} />*/}
      <CreateAdTemplate isLoading={isLoading} toggleModal={toggleCreateTemplateModal} isOpen={createTemplateModal} 
      		languages={languages} listUTMCampaign={UTM_CAMPAIGN} saveTemplate={saveTemplate} updateTemplate={updateTemplate} />
    </Container>
  );
}

export default AdTemplate;
