import React, { useState } from "react";

import {
  Row, Label, Input, FormGroup, Button,
  Modal, ModalHeader, ModalBody, ModalFooter, Col
} from "reactstrap";


const SaveFilterModal = (props) => {
  const [preset, setPresetName] = useState("")  
  const closeBtn = <button className="close" onClick={() => props.close(false)} style={{ fontSize: "2rem" }}>&times;</button>;

  return (
    <Modal isOpen={props.isOpen} toggle={() => props.close(false)} size="lg">
      <ModalHeader close={closeBtn} className="pb-0 pt-3">
        <Row className="align-items-center justify-content-between">
          <Col xs={{ size: "auto" }}>
            <h3 className="mb-0">Do you want to save the filters for next time?</h3>
          </Col>
        </Row>
      </ModalHeader>
      <ModalBody className="px-3 py-0">
        <Row>
          <Col>
            <ul title="Breakdown:" className="taglist bold-title">
              {props.breakdown.map((breakdown, i) => breakdown.checked && <li key={breakdown.selector + i}>{breakdown.name}</li>)}
            </ul>
            <ul title="Filters:" className="filter bold-title">
            {props.filters.map((filter, i) => {
              let values = filter.values.filter((c) => c.isChecked).map(c => c.value);
              if (values.length) {
                return <li key={filter.name}>
                  <ul title={`${filter.name}:`} className="taglist bold-title">
                    {values.map((v, i) => <li key={`${filter.name}_{i}`}>{v}</li>)}
                  </ul>
                </li>
              } else {
                return null;
              }
            })}
            </ul>
            <ul title="Dimensions:" className="taglist bold-title">
              {props.dimensions.map((dimension, i) => dimension.checked &&
                <li key={dimension.selector + i}>{dimension.name}</li>
              )}
            </ul>
            <hr/>
            <FormGroup row>
              <Label for="preset_name" sm={{size: 2, offset: 1}}>Preset name:</Label>
              <Col sm={8}>
                <Input type="text" name="preset_name" value={preset} onChange={(e) => setPresetName(e.target.value)} placeholder="Choose a unique name" />
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter className="px-7 text-center">
        <Button className="btn-block" color="warning" onClick={() => props.onSaveFilters(preset)}>SAVE FILTERS!</Button>
      </ModalFooter>
    </Modal>
  );
}

export default SaveFilterModal;
