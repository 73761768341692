import React from "react";
import ReactDOM from "react-dom";
import Amplify, { Auth, Storage } from 'aws-amplify';
import { Authenticator, Greetings, SignUp } from "aws-amplify-react";

import { BrowserRouter } from "react-router-dom";

import "./assets/vendor/nucleo/css/nucleo.css";
import "./assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";

import App from "./App.js";

import AWSS3Provider from "./MyStorageProvider"
// add the plugin
Storage.addPluggable(new AWSS3Provider());

let awsconfig = {
  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  //identityPoolId: "",

  // REQUIRED - Amazon Cognito Region
  region: "eu-west-1",

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: "eu-west-1_6Ao9bFZ6c",

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: "51ldevafsnsq54qsac6mshng5k",

  identityPoolId: "eu-west-1:ec81fcf6-b8c0-438e-b7c2-59331c0d5f94"

  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  //mandatorySignIn: true,

  // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  //authenticationFlowType: "USER_PASSWORD_AUTH"
};

Amplify.configure({
  Auth: awsconfig,
  API: {
    endpoints: [
      {
        name: "customAPI",
        endpoint: process.env.NODE_ENV === "development" ? "http://dev.api.conversionminds.com/v1" : "https://api.cmindtrack.net/v1",
        custom_header: async () => {
          let headers = { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` }
          let loginhas = localStorage.getItem('X-loginAs') || false;
          if ( loginhas !== false ) {
            headers["X-loginAs"] = loginhas;
          }
          return headers;
        }
      },
      {
        name: "mlAPI",
        endpoint: "https://ml.intellagile.com",
      }
    ]
  },
  Storage: {
    AWSS3: {
      bucket: 'static.blogger.co.uk', //REQUIRED -  Amazon S3 bucket name
      region: 'eu-west-1', //OPTIONAL -  Amazon service region,
      customPrefix: {
        public: 'thumbs/',
        protected: 'thumbs/',
        private: 'thumbs/'
      }
    }
  }
});
// import {CustomSignIn} from "./CustomSignIn";
/*
onStateChange={authState => {
      if (authState === "signedIn") {
        checkLoggedIn();
      }
    }}*/
// hide={[SignIn, Greetings]}
ReactDOM.render(
  <BrowserRouter>
    <Authenticator amplifyConfig={awsconfig} hide={[Greetings, SignUp]}>
      <App />
    </Authenticator>
  </BrowserRouter>,
  document.getElementById("root")
);


