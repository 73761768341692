import React, { useState, useEffect } from "react";
import cx from "classnames";
import { API } from 'aws-amplify';

import { Field, Form, Formik } from "formik";
import { ReactstrapSelect } from "reactstrap-formik";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  ListGroup,
  ListGroupItem,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  InputGroupButtonDropdown,
  InputGroup,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";


import Spinner from "../../../components/Spinner";

const FanPagesAutomation = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [feedUrl, setFeedUrl] = useState("");
  const [feedContent, showFeedContent] = useState({items: []});
  
  const [publishContentModal, setPublishContentModal] = useState(false);
  const [content, setContent] = useState(null);

  const [fbPages, setFanPages] = useState([]);
  const [settings, setSettings] = useState({});
  const [languages, setLanguages] = useState([]);
  const [utm_campaigns, setUtmCampaigns] = useState([]);
  const loadSettings = async () => {
    setIsLoading(true);
    let response = await API.get('customAPI', '/fb_fanpage/list');
    const info = response.reduce((acc, curr) => {
      acc[curr.lang] = acc[curr.lang] || {};
      acc[curr.lang][curr.utm_campaign] = acc[curr.lang][curr.utm_campaign] || [];
      acc[curr.lang][curr.utm_campaign].push(curr);
      return acc;
    },{})
    setSettings(info);
    setLanguages(Object.keys(info));
    setIsLoading(false);
  }


  useEffect(() => {
    loadSettings();
  }, []);


  const loadContent = async () => {
    setIsLoading(true);
    try {
      const response = await API.post('customAPI', '/fb_fanpage/load', {
        body: {
          feedUrl: feedUrl
        }
      });
      console.log(response);
      showFeedContent(response)
    } catch ( e ) {
      alert(e.message);
    }
    // setSubmitting(false);
    setIsLoading(false);
  }

  const publishContent =  async (values, { setSubmitting }) => {
    try {
      const fbPages = settings[values.lang][values.utm_campaign];
      const response = await API.post('customAPI', '/fb_fanpage/publish', {
        body: {...values, content, fbPages: fbPages.filter(page => values.fb_pages.indexOf(page.id) !== -1)}
      });
      console.log(response);
    } catch ( e ) {
      alert(e.message);
    }
    setSubmitting(false);
  }

  const [linkType, setLinkType] = useState("feed");
  const [splitButtonOpen, setSplitButtonOpen] = useState(false);
  const toggleSplit = () => setSplitButtonOpen(!splitButtonOpen);

  return (
    <Container className="mt-2" fluid>
      <Row>
        <Col>
          <Card className="bg-secondary shadow">
            <CardHeader className={cx("border-0", false ? "bg-green" : "")}>
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">CONTENT FAN PAGES</h3>
                </Col>
                <Col xs="3">
                  
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <h6 className="heading-small text-muted mb-4">SOURCE SETUP</h6>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-dailybudget"
                    >
                      Feed Url
                    </label>
                    <InputGroup>
                      <InputGroupButtonDropdown addonType="prepend" isOpen={splitButtonOpen} toggle={toggleSplit} className="mr-1">
                        <DropdownToggle split color="primary" />
                        <DropdownMenu>
                          <DropdownItem onClick={() => setLinkType("feed")}>CUSTOM FEED</DropdownItem>
                          <DropdownItem onClick={() => {setLinkType("feed"); setFeedUrl("https://news.google.com/rss/search?q=QUERY&hl=it-IT&gl=IT&ceid=IT:it");}}>GOOGLE FEED NEWS IT</DropdownItem>
                          <DropdownItem onClick={() => {setLinkType("feed"); setFeedUrl("https://news.google.com/rss/search?q=QUERY&hl=pt-BR&gl=BR&ceid=BR:pt");}}>GOOGLE FEED NEWS BR</DropdownItem>
                          <DropdownItem onClick={() => {setLinkType("feed"); setFeedUrl("https://news.google.com/rss/search?q=QUERY&hl=en-GB&gl=GB&ceid=GB:en");}}>GOOGLE FEED NEWS UK</DropdownItem>
                          <DropdownItem onClick={() => {setLinkType("feed"); setFeedUrl("https://news.google.com/rss/search?q=QUERY&hl=de-DE&gl=DE&ceid=DE:de");}}>GOOGLE FEED NEWS DE</DropdownItem>
                          <DropdownItem onClick={() => {setLinkType("feed"); setFeedUrl("https://news.google.com/rss/search?q=QUERY&hl=fr-FR&gl=FR&ceid=FR:fr");}}>GOOGLE FEED NEWS FR</DropdownItem>
                          <DropdownItem onClick={() => {setLinkType("feed"); setFeedUrl("https://news.google.com/rss/search?q=QUERY&hl=es-US&gl=US&ceid=US:es");}}>GOOGLE FEED NEWS ES</DropdownItem>
                          <DropdownItem onClick={() => setLinkType("content")}>LINK TO a POST</DropdownItem>
                        </DropdownMenu>
                      </InputGroupButtonDropdown>
                      <Input
                        className="form-control-alternative"
                        id="input-feedurl"
                        placeholder="Use google news to create a new feed rss url alert"
                        onChange={(e) => setFeedUrl(e.target.value)}
                        value={feedUrl}
                        type="text">
                      </Input>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <label>.</label>
                  {linkType === "feed" 
                    ? <Button className="btn-block" color="primary" onClick={loadContent}>LOAD</Button>
                    : <Button className="btn-block" color="primary" onClick={(e) => { 
                      e.preventDefault(); setContent(feedUrl); setPublishContentModal(true);}}>PUBLISH!</Button>
                  }
                </Col>
              </Row>
              <h6 className="heading-small text-muted mb-4">CONTENT FOUND:</h6>
              <Spinner isLoading={isLoading}>
                <Row>
                  <Col>
                    <ListGroup>
                      {feedContent.items.map((c,i) => <ListGroupItem key={i} className="justify-content-between">{c.title} <br/> {c.link}<Button onClick={() => {setContent(c.link); setPublishContentModal(true);}} size="sm">PUBLISH!</Button></ListGroupItem>)}
                    </ListGroup>
                  </Col>
                </Row>
              </Spinner>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={publishContentModal} toggle={() => setPublishContentModal(false)} size="lg">
        <ModalHeader toggle={() => setPublishContentModal(false)}></ModalHeader>
        <ModalBody>
          <Row className="text-center">
            <Col>
              <Formik
                initialValues={{  lang: '', utm_campaign: '', fb_pages: []}}
                onSubmit={publishContent}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue
                  /* and other goodies */
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <hr className="my-4" />
                    {/* Facebook */}
                    <h6 className="heading-small text-muted mb-4">SELECT FAN PAGE GROUP:</h6>
                    <Row form>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="LANGUAGE"
                            name="lang"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "lang",
                              id: "lang",
                              options: languages,
                              defaultOption: "-- LANGUAGE --"
                            }}
                            onChange={(e) => {
                              setUtmCampaigns(Object.keys(settings[e.target.value]));
                              handleChange(e);
                            }}
                          />
                          {errors.lang && touched.lang && errors.lang}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="UTM_CAMPAIGN"
                            name="utm_campaign"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "utm_campaign",
                              id: "utm_campaign",
                              options: utm_campaigns,
                              defaultOption: "-- UTM CAMPAIGN --"
                            }}
                            onChange={(e) => {
                              console.log(values);
                              console.log(settings[values.lang][e.target.value]);
                              setFanPages(settings[values.lang][e.target.value]);
                              handleChange(e);
                            }}
                          />
                          {errors.utm_campaign && touched.utm_campaign && errors.utm_campaign}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="FAN PAGES"
                            name="fb_pages"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "fb_pages",
                              id: "fb_pages",
                              options: fbPages
                            }}
                            multiple
                          />
                          {errors.fbPages && touched.fbPages && errors.fbPages}
                        </FormGroup>
                      </Col>
                    </Row>
                    <h6 className="heading-small text-muted mb-4">CONTENT PUBLISHING:</h6>
                    <Row form>
                      <Col md={12}>
                        <p>{feedUrl}</p>
                      </Col>
                    </Row>
                    <hr className="my-4" />
                    {/* Description */}
                    <Row className="mt-4">
                      <Col className="text-center">
                        <Button color="primary" type="submit" disabled={isSubmitting}>PUBLISH CONTENT!</Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </Container>
  );
}


export default FanPagesAutomation;
