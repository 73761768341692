export default {
	"yahooAdp": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR", checked: true, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "frequency", name: "Frequency (FB)", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cost", name: "Cost €", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(4) : "n/a", compact: true, center: true },
			{ selector: "cost_orig", name: "Cost $", checked: false, sortable: true, showInGraph: true, format: (row) => row.cost_orig ? (row.cost_orig).toFixed(4) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpm", name: "CPM €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "coverage", name: "Coverage %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "obid", name: "Opt Bid$", checked: false, sortable: false, showInGraph: false, compact: true, center: true },
			{ selector: "tq", name: "TQ", checked: false, sortable: false, showInGraph: false, compact: true, center: true },
			{ selector: "live_campaign", name: "#liveCamp", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "avg_revenue", name: "Avg.Revenue €", checked: false, sortable: true, showInGraph: false, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, searchable: false},
			{ selector: "hour", name: "Hour", checked: false, sortable: true, searchable: false },
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "advertiser_name", name: "Account Name", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "campaign_id", name: "Campaign ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, wrap: true, searchable: true},
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true, searchable: true },
			{ selector: "place_name", name: "Placement", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "place_id", name: "Placement ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "source", name: "Source", checked: false, sortable: true, searchable: true },
			{ selector: "device", name: "Device", checked: false, sortable: true, searchable: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "domain", name: "Domain", checked: false, sortable: true, searchable: true },
			{selector: "utm_campaign", name: "UTMCampaign", checked: false, sortable: true, searchable: false},
			{selector: "buying_strategy", name: "Buying Strategy", checked: false, sortable: true, searchable: false},
			{selector: "site_id", name: "SITE ID", checked: false, sortable: true, searchable: false}
		],
		"FILTERS_INIT": [
			{ selector: "advertiser_name", name: "Account Name", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "campaign_id", name: "Campaign ID", values: [] },
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "place_id", name: "Placement ID", values: [] },
			{ selector: "place_name", name: "Placement", values: [] },
			{ selector: "source", name: "Source", values: [] },
			{ selector: "device", name: "Device", values: [] },
			{ selector: "domain", name: "Domain", values: [] },
			{ selector: "utm_campaign", name: "UTMCampaign", values: [] },
			{ selector: "buying_strategy", name: "Buying Strategy", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"yahooAdpTiktok": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR", checked: true, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "frequency", name: "Frequency (FB)", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cost", name: "Cost €", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(4) : "n/a", compact: true, center: true },
			{ selector: "cost_orig", name: "Cost $", checked: false, sortable: true, showInGraph: true, format: (row) => row.cost_orig ? (row.cost_orig).toFixed(4) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpm", name: "CPM €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "coverage", name: "Coverage %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "obid", name: "Opt Bid$", checked: false, sortable: false, showInGraph: false, compact: true, center: true },
			{ selector: "tq", name: "TQ", checked: false, sortable: false, showInGraph: false, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, searchable: false},
			{ selector: "hour", name: "Hour", checked: false, sortable: true, searchable: false },
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "advertiser_name", name: "Account Name", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "campaign_id", name: "Campaign ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, wrap: true, searchable: true},
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true, searchable: true },
			{ selector: "place_name", name: "Adgroup", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "place_id", name: "Adgroup ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "place_type", name: "AD ID", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "place_app_name", name: "Ad Name", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "source", name: "Source", checked: false, sortable: true, searchable: true },
			{ selector: "device", name: "Device", checked: false, sortable: true, searchable: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "domain", name: "Domain", checked: false, sortable: true, searchable: true },
			{selector: "utm_campaign", name: "UTMCampaign", checked: false, sortable: true, searchable: false},
			{selector: "buying_strategy", name: "Buying Strategy", checked: false, sortable: true, searchable: false},
			{selector: "site_id", name: "SITE ID", checked: false, sortable: true, searchable: false}
		],
		"FILTERS_INIT": [
			{ selector: "advertiser_name", name: "Account Name", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "campaign_id", name: "Campaign ID", values: [] },
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "place_id", name: "Placement ID", values: [] },
			{ selector: "place_name", name: "Placement", values: [] },
			{ selector: "source", name: "Source", values: [] },
			{ selector: "device", name: "Device", values: [] },
			{ selector: "domain", name: "Domain", values: [] },
			{ selector: "utm_campaign", name: "UTMCampaign", values: [] },
			{ selector: "buying_strategy", name: "Buying Strategy", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"yahooAdpGoogleDisplay": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR", checked: true, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "cost", name: "Cost €", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpm", name: "CPM €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "coverage", name: "Coverage %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "obid", name: "Opt Bid$", checked: false, sortable: false, showInGraph: false, compact: true, center: true },
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, searchable: false},
			{ selector: "hour", name: "Hour", checked: false, sortable: true, searchable: false },
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "advertiser_name", name: "Account Name", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "campaign_id", name: "Campaign ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, wrap: true, searchable: true},
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true, searchable: true },
			{ selector: "adgroup_id", name: "Adgroup ID", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "adgroup_name", name: "Adgroup", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "placement", name: "Placement", checked: false, sortable: true, searchable: true },
			{ selector: "source", name: "Source", checked: false, sortable: true, searchable: true },
			{ selector: "device", name: "Device", checked: false, sortable: true, searchable: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "domain", name: "Domain", checked: false, sortable: true, searchable: true }
		],
		"FILTERS_INIT": [
			{ selector: "advertiser_name", name: "Account Name", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "campaign_id", name: "Campaign ID", values: [] },
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "adgroup_id", name: "Placement ID", values: [] },
			{ selector: "adgroup_name", name: "Placement", values: [] },
			{ selector: "placement", name: "Source", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"tonic": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR %", checked: false, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "cost", name: "Cost $", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC $", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA $", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "clickout", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "hour", name: "Hour", checked: false, sortable: true },
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "campaign_id", name: "Campaign ID", checked: false, sortable: true, compact: true, grow: 2 },
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, wrap: true},
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true },
			{ selector: "site_name", name: "Placement", checked: false, sortable: true, wrap: true },
			{ selector: "site_id", name: "Placement ID", checked: false, sortable: true, compact: true, grow: 2 },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true },
			{ selector: "template", name: "Template", checked: false, sortable: true }
		],
		"FILTERS_INIT": [
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "site_name", name: "Placement", values: [] },
			{ selector: "site_id", name: "Placement ID", values: [] },
			{ selector: "template", name: "Template", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"exploreAds": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR %", checked: false, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "cost", name: "COST $", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC $", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA $", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "clickout", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "view", name: "View", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "term_view", name: "Term View", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "ad_click", name: "Ad Click", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, wrap: true},
			{ selector: "campaign_id", name: "CampaignID", checked: false, sortable: true, compact: true, grow: 2 },
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true },
			{ selector: "site_name", name: "Placement", checked: false, sortable: true, wrap: true },
			{ selector: "site_id", name: "Placement Id", checked: false, sortable: true, grow: 2 },
		],
		"FILTERS_INIT": [
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "site_name", name: "Placement", values: [] },
			{ selector: "site_id", name: "Placement Id", values: [] },
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"parkingcrew": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true},
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "ctr", name: "CTR", checked: false, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "cost", name: "Cost $", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC $", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA $", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "clickout", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "coverage", name: "Coverage %", checked: false, sortable: true, showInGraph: false, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "advertiser_name", name: "Account name", checked: false, sortable: true, wrap: true },
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true },
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, wrap: true },
			{ selector: "campaign_id", name: "CampaignID", checked: false, sortable: true, compact: true, grow: 2 },
			{ selector: "domain", name: "Domain", checked: false, sortable: true, wrap: true },
			{ selector: "device", name: "Device", checked: false, sortable: true, wrap: true },
			{ selector: "place_name", name: "Placement", checked: false, sortable: true, wrap: true },
			{ selector: "place_id", name: "Placement ID", checked: false, sortable: true, wrap: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true },
			{ selector: "template", name: "Template", checked: false, sortable: true, wrap: true }
		],
		"FILTERS_INIT": [
			{ selector: "market", name: "Market", values: [] },
			{ selector: "campaign", name: "Campaign", values: [] },
			{ selector: "campaign_id", name: "CampaignID", values: [] },
			{ selector: "domain", name: "Domain", values: [] },
			{ selector: "device", name: "Device", values: [] },
			{ selector: "site_name", name: "Placement", values: [] },
			{ selector: "site_id", name: "Placement ID", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"yahoo_kunam_arb_facebook_activity": {
		DIMENSIONS_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, searchable: false},
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "spent", name: "Spent", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "margin", name: "Margin", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit_share", name: "Profit Share", checked: true, sortable: true, showInGraph: true, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true, center: true, }
		],
		"FILTERS_INIT": [
			{ selector: "market", name: "Market", values: [] }
		],
		CHART_DIMENSIONS: ["spent", "revenue"]
	},
	"domainActiveSynd": {
		DIMENSIONS_INIT: [
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "BiddedSearches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true },
			{ selector: "revenue", name: "Revenue", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "partner_revenue", name: "Partner Revenue", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit", checked: false, sortable: true, showInGraph: true, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "partner", name: "Partner", checked: false, sortable: true, center: true },
			{ selector: "campaign_id", name: "CampaignID", checked: false, sortable: true, compact: true, center: true, },
			{ selector: "campaign_name", name: "CampaignName", checked: false, sortable: true, compact: true, center: true, },
			{ selector: "tg1", name: "tg1", checked: false, sortable: true, compact: true, center: true, },
			{ selector: "tg2", name: "tg2", checked: false, sortable: true, compact: true, center: true, },
		],
		"FILTERS_INIT": [
			{ selector: "partner", name: "Partner",  values: [] },
			{ selector: "campaign_name", name: "CampaignName",  values: [] },
			{ selector: "tg1", name: "tg1",  values: [] },
			{ selector: "tg2", name: "tg2",  values: [] }
		],
		CHART_DIMENSIONS: ["revenue"]
	},
	"oneandone": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR %", checked: true, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "cost", name: "Cost $", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "account_name", name: "Account Name", checked: false, sortable: true, wrap: true},
			{ selector: "campaign_id", name: "Campaign ID", checked: false, sortable: true, compact: true, grow: 2, center: true},
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, wrap: true},
			{ selector: "adgroup_id", name: "Adgroup ID", checked: false, sortable: true, compact: true, center: true, grow: 2 },
			{ selector: "adgroup_name", name: "Adgroup Name", checked: false, sortable: true, wrap: true, center: true, },
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true, center: true, },
			{ selector: "origin", name: "Code", checked: false, sortable: true, center: true, },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true, center: true, }
		],
		"FILTERS_INIT": [
			{ selector: "account_name", name: "Account Name", values: [] },
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "adgroup_name", name: "Adgroup Name", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "origin", name: "Code", values: [] },
			{ selector: "keyword", name: "Keyword", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"oneandone_syndication": {
		DIMENSIONS_INIT: [
			{ selector: "pageviews", name: "PageViews", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true },
			{ selector: "ctr", name: "CTR %", checked: true, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "partner_revenue", name: "Partner Revenue $", checked: false, sortable: true, showInGraph: true, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true, center: true, },
			{ selector: "code", name: "Code", checked: false, sortable: true, center: true },
			{ selector: "partner", name: "Partner", checked: false, sortable: true, center: true }
		],
		"FILTERS_INIT": [
			{ selector: "market", name: "Market", values: [] },
			{ selector: "code", name: "Code", values: [] },
			{ selector: "partner", name: "Partner",  values: [] }
		],
		CHART_DIMENSIONS: ["revenue"]

	},
	"perion_partner": {
		DIMENSIONS_INIT: [
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "clickout", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "partner_revenue", name: "Partner Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "type", name: "Type", checked: false, sortable: true },
			{ selector: "partner", name: "Partner Name", checked: false, sortable: true },
			{ selector: "market", name: "Market", checked: false, sortable: true },
			{ selector: "wallet", name: "Wallet", checked: false, sortable: true }
		],
		"FILTERS_INIT": [
			{ selector: "type", name: "Type", values: [] },
			{ selector: "partner", name: "Partner Name", values: [] },
			{ selector: "market", name: "Market", values: [] }
		],
		CHART_DIMENSIONS: ["revenue"]
	},
	"perion": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clickin", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clickin ? (row.clickin).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR", checked: true, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "cost", name: "Cost $", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpc", name: "CPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpm", name: "CPM $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "clickout", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "ocpc", name: "OCPC $", checked: false, sortable: true, showInGraph: true, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "campaign", name: "Campaign", checked: false, sortable: true, wrap: true },
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true },
			{ selector: "type", name: "Type", checked: false, sortable: true, wrap: true },
			{ selector: "domain", name: "Domain", checked: false, sortable: true },
			{ selector: "device", name: "Device", checked: false, sortable: true }
		],
		"FILTERS_INIT": [
			{ selector: "campaign", name: "Campaign", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "type", name: "Type", values: [] },
			{ selector: "domain", name: "Domain", values: [] },
			{ selector: "device", name: "Device", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"mgid_widget_publisher": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR", checked: true, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, searchable: false},
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "widget_id", name: "Widget ID", checked: false, sortable: true, wrap: true, searchable: true }
		],
		"FILTERS_INIT": [
			{ selector: "widget_id", name: "Widget ID", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "clicks"]
	},
	"mgid_perion": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR", checked: true, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "cost", name: "Cost $", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpm", name: "CPM $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, searchable: false},
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "advertiser_name", name: "Account Name", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "campaign_id", name: "Campaign ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, wrap: true, searchable: true},
			{ selector: "place_id", name: "Widget ID", checked: false, sortable: true, compact: true, grow: 2,  searchable: true },
			{ selector: "place_name", name: "Widget Name", checked: false, sortable: true, wrap: true,  searchable: true },
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true, searchable: true },
			{ selector: "source", name: "Source", checked: false, sortable: true, searchable: true },
			{ selector: "device", name: "Device", checked: false, sortable: true, searchable: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "domain", name: "Domain", checked: false, sortable: true, searchable: true }
		],
		"FILTERS_INIT": [
			{ selector: "advertiser_name", name: "Account Name", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "campaign_id", name: "Campaign ID", values: [] },
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "source", name: "Source", values: [] },
			{ selector: "device", name: "Device", values: [] },
			{ selector: "domain", name: "Domain", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"fb_perion": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR", checked: true, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "cost", name: "Cost $", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpm", name: "CPM $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, searchable: false},
			{ selector: "hour", name: "Hour", checked: false, sortable: true },
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "advertiser_name", name: "Account Name", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "campaign_id", name: "Campaign ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, wrap: true, searchable: true},
			{ selector: "place_id", name: "Adset ID", checked: false, sortable: true, compact: true, grow: 2,  searchable: true },
			{ selector: "place_name", name: "Adset Name", checked: false, sortable: true, wrap: true,  searchable: true },
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true, searchable: true },
			{ selector: "source", name: "Source", checked: false, sortable: true, searchable: true },
			{ selector: "device", name: "Device", checked: false, sortable: true, searchable: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "domain", name: "Domain", checked: false, sortable: true, searchable: true }
		],
		"FILTERS_INIT": [
			{ selector: "advertiser_name", name: "Account Name", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "campaign_id", name: "Campaign ID", values: [] },
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "source", name: "Source", values: [] },
			{ selector: "device", name: "Device", values: [] },
			{ selector: "domain", name: "Domain", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"bing2click": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR %", checked: true, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "cost", name: "Cost €", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpm", name: "CPM €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, compact: true },
			{ selector: "hour", name: "Hour", checked: false, sortable: true },
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "advertiser_name", name: "Account Name", checked: false, sortable: true, wrap: true, center: true,  searchable: true },
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true },
			{ selector: "campaign_id", name: "Campaign ID", checked: false, sortable: true, compact: true, grow: 2,  searchable: true },
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, compact: true, wrap: true, searchable: true },
			{ selector: "place_id", name: "Adset ID", checked: false, sortable: true, compact: true, grow: 2,  searchable: true },
			{ selector: "place_name", name: "Adset Name", checked: false, sortable: true, wrap: true,  searchable: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true,  searchable: true },
			{ selector: "device_type", name: "Device", checked: false, sortable: true },
			{ selector: "utm_campaign", name: "UTMCampaign", checked: false, sortable: true, searchable: false},
			{ selector: "buying_strategy", name: "Buying Strategy", checked: false, sortable: true, searchable: false}
		],
		"FILTERS_INIT": [
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "place_name", name: "Adset Name", values: [] },
			{ selector: "advertiser_name", name: "Account Name", values: [] },
			{ selector: "device_type", name: "Device", values: [] },
			{ selector: "utm_campaign", name: "UTMCampaign", values: [] },
			{ selector: "buying_strategy", name: "Buying Strategy", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"ymyahoo_simply": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR %", checked: true, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "cost", name: "Cost $", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpm", name: "CPM $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, compact: true },
			{ selector: "hour", name: "Hour", checked: false, sortable: true },
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "advertiser_name", name: "Account Name", checked: false, sortable: true, wrap: true, center: true,  searchable: true },
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true },
			{ selector: "campaign_id", name: "Campaign ID", checked: false, sortable: true, compact: true, grow: 2,  searchable: true },
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, compact: true, wrap: true, searchable: true },
			{ selector: "place_id", name: "Adset ID", checked: false, sortable: true, compact: true, grow: 2,  searchable: true },
			{ selector: "place_name", name: "Adset Name", checked: false, sortable: true, wrap: true,  searchable: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true,  searchable: true },
			{ selector: "device_type", name: "Device", checked: false, sortable: true },
			{ selector: "utm_campaign", name: "UTMCampaign", checked: false, sortable: true, searchable: false},
			{ selector: "buying_strategy", name: "Buying Strategy", checked: false, sortable: true, searchable: false},
			{ selector: "domain", name: "Domain", checked: false, sortable: true }
		],
		"FILTERS_INIT": [
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "place_name", name: "Adset Name", values: [] },
			{ selector: "advertiser_name", name: "Account Name", values: [] },
			{ selector: "device_type", name: "Device", values: [] },
			{ selector: "utm_campaign", name: "UTMCampaign", values: [] },
			{ selector: "buying_strategy", name: "Buying Strategy", values: [] },
			{ selector: "domain", name: "Domain", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"partnerinsights_dongle": {
		DIMENSIONS_INIT: [
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Bidded Clicks", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue_partner", name: "Partner Revenue €", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit €", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "coverage", name: "Coverage %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "tq_score", name: "TQ", checked: false, sortable: true, showInGraph: false, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "hour", name: "Hour", checked: false, sortable: true },
			{ selector: "market", name: "Market", checked: false, sortable: true },
			{ selector: "partner", name: "Partner", checked: false, sortable: true },
			{ selector: "source_tag", name: "Source Tag", checked: false, sortable: true, wrap: true },
			{ selector: "client", name: "Client ID", checked: false, sortable: true },
			{ selector: "site_id", name: "Site ID", checked: false, sortable: true },
			{ selector: "K", name: "K", checked: false, sortable: true, wrap: true },
			{ selector: "queryTerm", name: "Keyword", checked: false, sortable: true, wrap: true },
		],
		"FILTERS_INIT": [
			{ selector: "market", name: "Market", values: [] },
			{ selector: "partner", name: "Partner", values: [] },
			{ selector: "source_tag", name: "Source Tag", values: [] },
			{ selector: "client", name: "Client", values: [] },
			{ selector: "site_id", name: "Site ID", values: [] },
			{ selector: "K", name: "K", values: [] },
		],
		CHART_DIMENSIONS: ["revenue"]
	},
	"partnerinsights": {
		DIMENSIONS_INIT: [
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Bidded Clicks", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue_partner", name: "Partner Revenue €", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit €", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "coverage", name: "Coverage %", checked: false, sortable: true, showInGraph: false, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "hour", name: "Hour", checked: false, sortable: true },
			{ selector: "market", name: "Market", checked: false, sortable: true },
			{ selector: "partner", name: "Partner", checked: false, sortable: true },
			{ selector: "source_tag", name: "Source Tag", checked: false, sortable: true, wrap: true },
			{ selector: "client", name: "Client ID", checked: false, sortable: true },
			{ selector: "site_id", name: "Site ID", checked: false, sortable: true },
			{ selector: "kwd", name: "K", checked: false, sortable: true, wrap: true },
			{ selector: "queryTerm", name: "Keyword", checked: false, sortable: true, wrap: true },
		],
		"FILTERS_INIT": [
			{ selector: "market", name: "Market", values: [] },
			{ selector: "partner", name: "Partner", values: [] },
			{ selector: "source_tag", name: "Source Tag", values: [] },
			{ selector: "client", name: "Client", values: [] },
			{ selector: "site_id", name: "Site ID", values: [] },
			{ selector: "kwd", name: "Keyword", values: [] },
		],
		CHART_DIMENSIONS: ["revenue"]
	},
	"partnerinsights_type": {
		DIMENSIONS_INIT: [
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Bidded Clicks", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "tq", name: "TQ", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "coverage", name: "Coverage %", checked: false, sortable: true, showInGraph: false, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "market", name: "Market", checked: false, sortable: true },
			{ selector: "partner", name: "Partner", checked: false, sortable: true },
			{ selector: "source_tag", name: "Source Tag", checked: false, sortable: true, grow: 3 },
			{ selector: "client", name: "Client ID", checked: false, sortable: true },
			{ selector: "site_id", name: "Site ID", checked: false, sortable: true },
			{ selector: "kwd", name: "Keyword", checked: false, sortable: true, wrap: true },
			{ selector: "device", name: "Device", checked: false, sortable: true }
		],
		"FILTERS_INIT": [
			{ selector: "market", name: "Market", values: [] },
			{ selector: "partner", name: "Partner", values: [] },
			{ selector: "source_tag", name: "Source Tag", values: [] },
			{ selector: "client", name: "Client ID", values: [] },
			{ selector: "site_id", name: "Site ID", values: [] },
			{ selector: "kwd", name: "Keyword", values: [] },
			{ selector: "device", name: "Device", values: [] }
		],
		CHART_DIMENSIONS: ["tq"]
	},
	"partnerinsights_sourcetag": {
		DIMENSIONS_INIT: [
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Bidded Clicks", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "tq", name: "TQ", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "coverage", name: "Coverage %", checked: false, sortable: true, showInGraph: false, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "market", name: "Market", checked: false, sortable: true },
			{ selector: "partner", name: "Partner", checked: false, sortable: true },
			{ selector: "source_tag", name: "Source Tag", checked: false, sortable: true, grow: 3 },
			{ selector: "device", name: "Device", checked: false, sortable: true }
		],
		"FILTERS_INIT": [
			{ selector: "market", name: "Market", values: [] },
			{ selector: "partner", name: "Partner", values: [] },
			{ selector: "source_tag", name: "Source Tag", values: [] },
			{ selector: "device", name: "Device", values: [] }
		],
		CHART_DIMENSIONS: ["tq"]
	},
	"extensions_adp": {
		DIMENSIONS_INIT: [
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Bidded Clicks", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "coverage", name: "Coverage", checked: true, sortable: true, showInGraph: true, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "market", name: "Market", checked: false, sortable: true },
			{ selector: "source", name: "Partner ID", checked: false, sortable: true },
			{ selector: "source_tag", name: "Source Tag", checked: false, sortable: true, grow: 3 },
			{ selector: "inMarketCode", name: "SubID1", checked: false, sortable: true },
			{ selector: "subid", name: "SubID2", checked: false, sortable: true, wrap: true },
			{ selector: "token", name: "Token", checked: false, sortable: true, grow: 3 },
			// { selector: "site_id", name: "Site ID", checked: false, sortable: true }
		],
		"FILTERS_INIT": [
			{ selector: "market", name: "Market", values: [] },
			{ selector: "source", name: "Partner ID", values: [] },
			{ selector: "source_tag", name: "Source Tag", values: [] },
			{ selector: "subid", name: "SubID2", values: [] }
		],
		CHART_DIMENSIONS: ["revenue"]
	},
	"extensions_realtime": {
		DIMENSIONS_INIT: [
			// { selector: "opens_chrome_store", name: "Open Chrome Store", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "install", name: "Install", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "unistall", name: "Unistall", checked: true, sortable: true, showInGraph: true, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "market", name: "Market", checked: false, sortable: true },
			{ selector: "source", name: "Source", checked: false, sortable: true },
			{ selector: "source_id", name: "ID Source", checked: false, sortable: true },
			{ selector: "product", name: "Product", checked: false, sortable: true },
			// { selector: "subid", name: "SUBID", checked: false, sortable: true, grow: 3 },
			{ selector: "traffic_source", name: "Traffic Source", checked: false, sortable: true, grow: 3 }
		],
		"FILTERS_INIT": [
			{ selector: "market", name: "Market", values: [] },
			{ selector: "source", name: "Source", values: [] }
		],
		CHART_DIMENSIONS: ["install", "unistall"]
	},
	"medianet": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR %", checked: true, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "cost", name: "Cost $", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpm", name: "CPM $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Bidded Clicks", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "coverage", name: "Coverage %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "hour", name: "Hour", checked: false, sortable: true, searchable: false },
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "advertiser_name", name: "Account Name", checked: false, sortable: true, wrap: true },
			{ selector: "campaign_id", name: "Campaign ID", checked: false, sortable: true, compact: true, grow: 2, searchable: true},
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, wrap: true, searchable: true},
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true, searchable: true },
			{ selector: "place_id", name: "AdSet ID", checked: false, sortable: true, compact: true, grow: 2, searchable: true },
			{ selector: "place_name", name: "AdSet Name", checked: false, sortable: true, compact: true, wrap: true, searchable: true },
			{ selector: "device", name: "Device", checked: false, sortable: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "domain", name: "Domain", checked: false, sortable: true },
			{ selector: "category", name: "Category", checked: false, sortable: true, grow: 2, searchable: true },
			{ selector: "utm_campaign", name: "UTMCampaign", checked: false, sortable: true, searchable: false },
			{ selector: "buying_strategy", name: "Buying Strategy", checked: false, sortable: true, searchable: false},
		],
		"FILTERS_INIT": [
			{ selector: "advertiser_name", name: "Account Name", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "place_name", name: "AdSet Name", values: [] },
			{ selector: "device", name: "Device", values: [] },
			{ selector: "domain", name: "Domain", values: [] },
			{ selector: "category", name: "category", values: [] },
			{ selector: "utm_campaign", name: "UTMCampaign", values: [] },
			{ selector: "buying_strategy", name: "Buying Strategy", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"medianettq": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR %", checked: true, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "cost", name: "Cost $", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpm", name: "CPM $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Bidded Clicks", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "coverage", name: "Coverage %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "tq", name: "TQ", checked: true, sortable: true, showInGraph: false, compact: true, center: true },
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "hour", name: "Hour", checked: false, sortable: true, searchable: false },
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "advertiser_name", name: "Account Name", checked: false, sortable: true, wrap: true },
			{ selector: "campaign_id", name: "Campaign ID", checked: false, sortable: true, compact: true, grow: 2, searchable: true},
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, wrap: true, searchable: true},
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true, searchable: true },
			{ selector: "place_id", name: "AdSet ID", checked: false, sortable: true, compact: true, grow: 2, searchable: true },
			{ selector: "place_name", name: "AdSet Name", checked: false, sortable: true, compact: true, wrap: true, searchable: true },
			{ selector: "device", name: "Device", checked: false, sortable: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "domain", name: "Domain", checked: false, sortable: true },
			{ selector: "category", name: "Category", checked: false, sortable: true, grow: 2, searchable: true },
			{ selector: "utm_campaign", name: "UTMCampaign", checked: false, sortable: true, searchable: false },
			{ selector: "buying_strategy", name: "Buying Strategy", checked: false, sortable: true, searchable: false},
		],
		"FILTERS_INIT": [
			{ selector: "advertiser_name", name: "Account Name", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "place_name", name: "AdSet Name", values: [] },
			{ selector: "device", name: "Device", values: [] },
			{ selector: "domain", name: "Domain", values: [] },
			{ selector: "category", name: "category", values: [] },
			{ selector: "utm_campaign", name: "UTMCampaign", values: [] },
			{ selector: "buying_strategy", name: "Buying Strategy", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"techads": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR", checked: true, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "cost", name: "Cost", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpm", name: "CPM $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "coverage", name: "Coverage %", checked: false, sortable: true, showInGraph: false, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, searchable: false },
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			// { selector: "hour", name: "Hour", checked: false, sortable: true, searchable: false },
			{ selector: "advertiser_name", name: "Account Name", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "campaign_id", name: "Campaign ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, wrap: true, searchable: true},
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true, searchable: true },
			{ selector: "place_name", name: "Placement", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "place_id", name: "Placement ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			// { selector: "source", name: "Source", checked: false, sortable: true, searchable: true },
			// { selector: "device", name: "Device", checked: false, sortable: true, searchable: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "domain", name: "Domain", checked: false, sortable: true, searchable: true },
			{ selector: "utm_campaign", name: "UTMCampaign", checked: false, sortable: true, searchable: false},
			{ selector: "buying_strategy", name: "Buying Strategy", checked: false, sortable: true, searchable: false},
		],
		"FILTERS_INIT": [
			{ selector: "advertiser_name", name: "Account Name", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "place_name", name: "Placement", values: [] },
			// { selector: "source", name: "Source", values: [] },
			// { selector: "device", name: "Device", values: [] },
			{ selector: "domain", name: "Domain", values: [] },
			{ selector: "utm_campaign", name: "UTMCampaign", values: [] },
			{ selector: "buying_strategy", name: "Buying Strategy", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"techads_hourly": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR", checked: true, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "cost", name: "Cost", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpm", name: "CPM $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "coverage", name: "Coverage %", checked: false, sortable: true, showInGraph: false, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, searchable: false },
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "hour", name: "Hour", checked: false, sortable: true, searchable: false },
			{ selector: "advertiser_name", name: "Account Name", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "campaign_id", name: "Campaign ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, wrap: true, searchable: true},
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true, searchable: true },
			{ selector: "place_name", name: "Placement", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "place_id", name: "Placement ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			// { selector: "source", name: "Source", checked: false, sortable: true, searchable: true },
			// { selector: "device", name: "Device", checked: false, sortable: true, searchable: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "domain", name: "Domain", checked: false, sortable: true, searchable: true },
			{ selector: "utm_campaign", name: "UTMCampaign", checked: false, sortable: true, searchable: false},
			{ selector: "buying_strategy", name: "Buying Strategy", checked: false, sortable: true, searchable: false},
		],
		"FILTERS_INIT": [
			{ selector: "advertiser_name", name: "Account Name", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "place_name", name: "Placement", values: [] },
			// { selector: "source", name: "Source", values: [] },
			// { selector: "device", name: "Device", values: [] },
			{ selector: "domain", name: "Domain", values: [] },
			{ selector: "utm_campaign", name: "UTMCampaign", values: [] },
			{ selector: "buying_strategy", name: "Buying Strategy", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"techads_bing": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR", checked: false, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "cost", name: "Cost", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC $", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpm", name: "CPM $", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA $", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "coverage", name: "Coverage %", checked: false, sortable: true, showInGraph: false, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, searchable: false },
			// { selector: "hour", name: "Hour", checked: false, sortable: true, searchable: false },
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "advertiser_name", name: "Account Name", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "campaign_id", name: "Campaign ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, wrap: true, searchable: true},
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true, searchable: true },
			{ selector: "place_name", name: "Placement", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "place_id", name: "Placement ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			// { selector: "source", name: "Source", checked: false, sortable: true, searchable: true },
			// { selector: "device", name: "Device", checked: false, sortable: true, searchable: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "domain", name: "Domain", checked: false, sortable: true, searchable: true },
			{ selector: "utm_campaign", name: "UTMCampaign", checked: false, sortable: true, searchable: false},
			{ selector: "buying_strategy", name: "Buying Strategy", checked: false, sortable: true, searchable: false},
		],
		"FILTERS_INIT": [
			{ selector: "advertiser_name", name: "Account Name", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "place_name", name: "Placement", values: [] },
			// { selector: "source", name: "Source", values: [] },
			// { selector: "device", name: "Device", values: [] },
			{ selector: "domain", name: "Domain", values: [] },
			{ selector: "utm_campaign", name: "UTMCampaign", values: [] },
			{ selector: "buying_strategy", name: "Buying Strategy", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"clicksco": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR", checked: true, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "cost", name: "Cost", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpm", name: "CPM $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "coverage", name: "Coverage %", checked: false, sortable: true, showInGraph: false, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, searchable: false },
			{ selector: "hour", name: "Hour", checked: false, sortable: true, searchable: false },
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "advertiser_name", name: "Account Name", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "campaign_id", name: "Campaign ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, wrap: true, searchable: true},
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true, searchable: true },
			{ selector: "place_name", name: "Placement", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "place_id", name: "Placement ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			// { selector: "source", name: "Source", checked: false, sortable: true, searchable: true },
			// { selector: "device", name: "Device", checked: false, sortable: true, searchable: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true, searchable: true },
			// { selector: "domain", name: "Domain", checked: false, sortable: true, searchable: true },
			{ selector: "utm_campaign", name: "UTMCampaign", checked: false, sortable: true, searchable: false},
			{ selector: "buying_strategy", name: "Buying Strategy", checked: false, sortable: true, searchable: false},
		],
		"FILTERS_INIT": [
			{ selector: "advertiser_name", name: "Account Name", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "place_name", name: "Placement", values: [] },
			{ selector: "utm_campaign", name: "UTMCampaign", values: [] },
			{ selector: "buying_strategy", name: "Buying Strategy", values: [] }
			// { selector: "source", name: "Source", values: [] },
			// { selector: "device", name: "Device", values: [] },
			// { selector: "domain", name: "Domain", values: [] },
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"exmarketspace": {
		DIMENSIONS_INIT: [
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "clicks", name: "Bidded Clicks", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue_partner", name: "Partner Revenue €", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit €", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC €", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "ctr", name: "CTR %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "tq", name: "TQ", checked: true, sortable: true, showInGraph: true, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "hid", name: "HID", checked: false, sortable: true },
			{ selector: "market", name: "MARKET", checked: false, sortable: true },
			{ selector: "partner", name: "PARTNER", checked: false, sortable: true }
		],
		"FILTERS_INIT": [
			{ selector: "hid", name: "HID", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "partner", name: "Partner", values: [] }
		],
		CHART_DIMENSIONS: ["revenue"]
	},
	"exmarketplace": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR", checked: false, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "revenue", name: "Revenue", checked: true, sortable: true, showInGraph: true, format: (row) => row.revenue ? (row.revenue).toFixed(2) : "n/a", compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, searchable: false },
			{ selector: "site_id", name: "SITE ID", checked: false, sortable: true, compact: true },
			{ selector: "domain", name: "Domain", checked: false, sortable: true, grow: 3 },
			{ selector: "source", name: "Source", checked: false, sortable: true },
			{ selector: "device", name: "Device", checked: false, sortable: true },
			{ selector: "adunit_name", name: "Adunit", checked: false, sortable: true, grow: 3 },
			// { selector: "domain", name: "Domain", checked: false, sortable: true, searchable: true },
		],
		"FILTERS_INIT": [
			{ selector: "advertiser_name", name: "Account Name", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "place_name", name: "Placement", values: [] },
			// { selector: "source", name: "Source", values: [] },
			// { selector: "device", name: "Device", values: [] },
			// { selector: "domain", name: "Domain", values: [] },
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"keywordtools_capacitor_v2": {
		DATE: new Date(),
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "clickout", name: "Clicks", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "clickout_position", name: "#ClickPosition", checked: true, sortable: true, showInGraph: true, format: (row) => row.avg_clickout_position ? (row.avg_clickout_position).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "clickout_position_min", name: "Min Click Pos", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "clickout_position_max", name: "Max Click Pos", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "avg_ads", name: "#ADS", checked: false, sortable: true, showInGraph: true, format: (row) => row.avg_ads ? (row.avg_ads).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "max_ads", name: "#MAX ADS", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "min_ads", name: "#MIN ADS", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			// { selector: "avg_position", name: "#AdvPosition", checked: false, sortable: true, showInGraph: true, format: (row) => row.avg_position ? (row.avg_position).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR %", checked: true, sortable: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a"},
			{ selector: "impressions_error", name: "Impressions Blocked", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "clickout_error", name: "Clicks Blocked", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
    	{ selector: "bidded_clicks", name: "Bidded Clicks", checked: false, sortable: true},
    	{ selector: "revenue", name: "Revenue (€)", checked: false, sortable: true, format: (row) => row.revenue ? (row.revenue).toFixed(4) : "n/a"},
    	{ selector: "avg_rpc", name: "Avg.RPC (€)", checked: true, sortable: true, format: (row) => row.avg_rpc ? (row.avg_rpc).toFixed(4) : "n/a"},
    	{ selector: "max_rpc", name: "Max.RPC (€)", checked: true, sortable: true},
    	{ selector: "min_rpc", name: "Min.RPC (€)", checked: true, sortable: true},
    	{ selector: "lastseen", name: "Last Seen", checked: false, sortable: true, grow: 2},
    	// { selector: "life", name: "Life", checked: false, sortable: true},
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, searchable: false },
			{ selector: "hour", name: "Hour", checked: false, sortable: true, compact: true },
			{ selector: "market", name: "Market", checked: false, sortable: true },
			{ selector: "client_id", name: "ClientID", checked: false, sortable: true },
			{ selector: "site_id", name: "SiteID", checked: false, sortable: true },
			{ selector: "utm_source", name: "UTMSource", checked: false, sortable: true },
			{ selector: "source", name: "Source", checked: false, sortable: true },
			{ selector: "domain", name: "Domain", checked: false, sortable: true },
			{ selector: "device", name: "Device", checked: false, sortable: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, searchable: true, grow: 3},
			{ selector: "K", name: "K", checked: false, sortable: true, searchable: true, grow: 3},
			{ selector: "template", name: "Template", checked: false, sortable: true, searchable: true, grow: 3},
			// { selector: "advertiser", name: "Advertiser", checked: false, sortable: true, grow: 5 },
		],
		"FILTERS_INIT": [
			{ selector: "market", name: "Market", values: [] },
			{ selector: "client_id", name: "ClientID", values: [] },
			{ selector: "site_id", name: "SiteID", values: [] },
			{ selector: "utm_source", name: "UTMSource", values: [] },
			{ selector: "source", name: "Source", values: [] },
			{ selector: "domain", name: "Domain", values: [] },
			{ selector: "device", name: "Device", values: [] },
			{ selector: "keyword", name: "Keyword", values: [] },
			{ selector: "K", name: "K", values: [] },
			{ selector: "template", name: "Template", values: [] },
		],
		CHART_DIMENSIONS: ["impressions", "clickout"]
	},
	"keywordtools_capacitor": {
		DATE: new Date(),
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "clickout", name: "Clicks", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "clickout_position", name: "#ClickPosition", checked: true, sortable: true, showInGraph: true, format: (row) => row.avg_clickout_position ? (row.avg_clickout_position).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "avg_ads", name: "#ADS", checked: false, sortable: true, showInGraph: true, format: (row) => row.avg_ads ? (row.avg_ads).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "max_ads", name: "#MAX ADS", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "min_ads", name: "#MIN ADS", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "avg_position", name: "#AdvPosition", checked: false, sortable: true, showInGraph: true, format: (row) => row.avg_position ? (row.avg_position).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "suggestions", name: "Suggestions", checked: false},
    	{ selector: "title", name: "Ad Title", checked: false},
    	{ selector: "description", name: "Ad Description", checked: false},
    	{ selector: "ctr", name: "CTR %", checked: true, sortable: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a"},
    	{ selector: "bidded_clicks", name: "Bidded Clicks", checked: false, sortable: true},
    	{ selector: "revenue", name: "Revenue (€)", checked: false, sortable: true, format: (row) => row.revenue ? (row.revenue).toFixed(4) : "n/a"},
    	{ selector: "avg_rpc", name: "Avg.RPC (€)", checked: true, sortable: true, format: (row) => row.avg_rpc ? (row.avg_rpc).toFixed(4) : "n/a"},
    	{ selector: "max_rpc", name: "Max.RPC (€)", checked: true, sortable: true},
    	{ selector: "min_rpc", name: "Min.RPC (€)", checked: true, sortable: true},
    	{ selector: "lastseen", name: "Last Seen", checked: false, sortable: true, grow: 2},
    	// { selector: "life", name: "Life", checked: false, sortable: true},
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, searchable: false },
			{ selector: "hour", name: "Hour", checked: false, sortable: true, compact: true },
			{ selector: "device", name: "Device", checked: false, sortable: true },
			{ selector: "client_id", name: "ClientID", checked: false, sortable: true },
			{ selector: "site_id", name: "SiteID", checked: false, sortable: true },
			{ selector: "domain", name: "Domain", checked: false, sortable: true },
			{ selector: "source", name: "Source", checked: false, sortable: true },
			{ selector: "market", name: "Market", checked: false, sortable: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, searchable: true, grow: 3},
			{ selector: "advertiser", name: "Advertiser", checked: false, sortable: true, grow: 5 },
		],
		"FILTERS_INIT": [
			{ selector: "market", name: "Market", values: [] },
			{ selector: "source", name: "Source", values: [] },
			{ selector: "device", name: "Device", values: [] },
			{ selector: "client_id", name: "ClientID", values: [] },
			{ selector: "site_id", name: "SiteID", values: [] },
			{ selector: "domain", name: "Domain", values: [] },
			{ selector: "keyword", name: "Keyword", values: [] },
			{ selector: "advertiser", name: "Advertiser", values: [] },
			{ selector: "upload_id", name: "Offline Test ID", values: []},
		],
		CHART_DIMENSIONS: ["impressions", "clickout"]
	},
	"ym_activity": {
		DIMENSIONS_INIT: [
			{ selector: "queries", name: "Queries", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true},
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "tq", name: "TQ", checked: false, sortable: true, showInGraph: true, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, compact: true },
			{ selector: "hour", name: "Hour", checked: false, sortable: true },
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true },
			{ selector: "camptype", name: "Camptype", checked: false, sortable: true, compact: true, grow: 2,  searchable: true },
			{ selector: "campname", name: "Campname", checked: false, sortable: true, compact: true, wrap: true, searchable: true },
			{ selector: "campaign", name: "Campaign", checked: false, sortable: true, compact: true, grow: 2,  searchable: true },
			{ selector: "source", name: "Source", checked: false, sortable: true, wrap: true,  searchable: true },
			{ selector: "device", name: "Device", checked: false, sortable: true, wrap: true,  searchable: true }
		],
		"FILTERS_INIT": [
			{ selector: "market", name: "Market", values: [] },
			{ selector: "campaign", name: "Campaign", values: [] },
			{ selector: "campname", name: "Campname", values: [] }
		],
		CHART_DIMENSIONS: ["revenue"]
	},
	"domainActive": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR", checked: true, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "frequency", name: "Frequency (FB)", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cost", name: "Cost $", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(4) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpm", name: "CPM $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "coverage", name: "Coverage %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "obid", name: "Opt Bid$", checked: false, sortable: false, showInGraph: false, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, searchable: false},
			{ selector: "hour", name: "Hour", checked: false, sortable: true, searchable: false },
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "advertiser_name", name: "Account Name", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true, searchable: true },
			{ selector: "campaign_id", name: "Campaign ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, wrap: true, searchable: true},
			{ selector: "adset_name", name: "Adset", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "adset_id", name: "Adset ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "ad_id", name: "Ad ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "ad_name", name: "Ad", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "source", name: "Source", checked: false, sortable: true, searchable: true },
			{ selector: "device", name: "Device", checked: false, sortable: true, searchable: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "domain", name: "Domain", checked: false, sortable: true, searchable: true },
			{selector: "utm_campaign", name: "UTMCampaign", checked: false, sortable: true, searchable: false},
			{selector: "buying_strategy", name: "Buying Strategy", checked: false, sortable: true, searchable: false},
			{selector: "site_id", name: "SITE ID", checked: false, sortable: true, searchable: false}
		],
		"FILTERS_INIT": [
			{ selector: "advertiser_name", name: "Account Name", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "campaign_id", name: "Campaign ID", values: [] },
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "adset_id", name: "Adset ID", values: [] },
			{ selector: "adset_name", name: "Adset", values: [] },
			{ selector: "ad_id", name: "Ad ID", values: [] },
			{ selector: "ad_name", name: "Ad", values: [] },
			{ selector: "source", name: "Source", values: [] },
			{ selector: "device", name: "Device", values: [] },
			{ selector: "domain", name: "Domain", values: [] },
			{ selector: "utm_campaign", name: "UTMCampaign", values: [] },
			{ selector: "buying_strategy", name: "Buying Strategy", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"freestar_activity": {
		DIMENSIONS_INIT: [
			{ selector: "impressions", name: "Impressions", checked: true, sortable: true, showInGraph: true, compact: true, center: true, format: (row) => row.impressions ? (row.impressions).toFixed(0) : "n/a" },
			{ selector: "clicks", name: "Clicks", checked: true, sortable: true, showInGraph: true, format: (row) => row.clicks ? (row.clicks).toFixed(0) : "n/a", compact: true, center: true },
			{ selector: "ctr", name: "CTR", checked: true, sortable: true, showInGraph: true, format: (row) => row.ctr ? (row.ctr).toFixed(2) : "n/a", compact: true, center: true },
			{ selector: "frequency", name: "Frequency (FB)", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cost", name: "Cost $", checked: true, sortable: true, showInGraph: true, format: (row) => row.cost ? (row.cost).toFixed(4) : "n/a", compact: true, center: true },
			{ selector: "cost_orig", name: "Cost $", checked: false, sortable: true, showInGraph: true, format: (row) => row.cost_orig ? (row.cost_orig).toFixed(4) : "n/a", compact: true, center: true },
			{ selector: "conversions", name: "Conversions", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cvr", name: "CVR %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "scrub_rate", name: "Scrub Rate %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "cpc", name: "CPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpm", name: "CPM $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "cpa", name: "CPA $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Clickout", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "roi", name: "ROI %", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "coverage", name: "Coverage %", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "obid", name: "Opt Bid$", checked: false, sortable: false, showInGraph: false, compact: true, center: true },
			{ selector: "tq", name: "TQ", checked: false, sortable: false, showInGraph: false, compact: true, center: true },
			{ selector: "live_campaign", name: "#liveCamp", checked: false, sortable: true, showInGraph: false, compact: true, center: true },
			{ selector: "avg_revenue", name: "Avg.Revenue $", checked: false, sortable: true, showInGraph: false, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true, searchable: false},
			{ selector: "hour", name: "Hour", checked: false, sortable: true, searchable: false },
			{ selector: "dow", name: "Day of Week", checked: false, sortable: true, searchable: false },
			{ selector: "week", name: "Week", checked: false, sortable: true, searchable: false },
			{ selector: "month", name: "Month", checked: false, sortable: true, searchable: false },
			{ selector: "advertiser_name", name: "Account Name", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "campaign_id", name: "Campaign ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "campaign_name", name: "Campaign", checked: false, sortable: true, wrap: true, searchable: true},
			{ selector: "market", name: "Market", checked: false, sortable: true, compact: true, searchable: true },
			{ selector: "place_name", name: "Placement", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "place_id", name: "Placement ID", checked: false, sortable: true, compact: true, searchable: true, grow: 2 },
			{ selector: "source", name: "Source", checked: false, sortable: true, searchable: true },
			{ selector: "device", name: "Device", checked: false, sortable: true, searchable: true },
			{ selector: "keyword", name: "Keyword", checked: false, sortable: true, wrap: true, searchable: true },
			{ selector: "domain", name: "Domain", checked: false, sortable: true, searchable: true },
			{selector: "utm_campaign", name: "UTMCampaign", checked: false, sortable: true, searchable: false},
			{selector: "buying_strategy", name: "Buying Strategy", checked: false, sortable: true, searchable: false},
			{selector: "site_id", name: "SITE ID", checked: false, sortable: true, searchable: false}
		],
		"FILTERS_INIT": [
			{ selector: "advertiser_name", name: "Account Name", values: [] },
			{ selector: "market", name: "Market", values: [] },
			{ selector: "campaign_id", name: "Campaign ID", values: [] },
			{ selector: "campaign_name", name: "Campaign", values: [] },
			{ selector: "place_id", name: "Placement ID", values: [] },
			{ selector: "place_name", name: "Placement", values: [] },
			{ selector: "source", name: "Source", values: [] },
			{ selector: "device", name: "Device", values: [] },
			{ selector: "domain", name: "Domain", values: [] },
			{ selector: "utm_campaign", name: "UTMCampaign", values: [] },
			{ selector: "buying_strategy", name: "Buying Strategy", values: [] }
		],
		CHART_DIMENSIONS: ["revenue", "cost"]
	},
	"freestar": {
		DIMENSIONS_INIT: [
			{ selector: "searches", name: "Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_searches", name: "Bidded Searches", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "bidded_clicks", name: "Bidded Clicks", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue", name: "Revenue $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "revenue_partner", name: "Partner Revenue $", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "profit", name: "Profit $", checked: false, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "rpc", name: "RPC $", checked: true, sortable: true, showInGraph: true, compact: true, center: true },
			{ selector: "coverage", name: "Coverage %", checked: false, sortable: true, showInGraph: false, compact: true, center: true }
		],
		BREAKDOWN_INIT: [
			{ selector: "date", name: "Date", checked: true, sortable: true },
			{ selector: "hour", name: "Hour", checked: false, sortable: true },
			{ selector: "market", name: "Market", checked: false, sortable: true },
			{ selector: "partner", name: "Partner", checked: false, sortable: true },
			{ selector: "source_tag", name: "Source Tag", checked: false, sortable: true, wrap: true },
			{ selector: "client", name: "Client ID", checked: false, sortable: true },
			{ selector: "site_id", name: "Site ID", checked: false, sortable: true },
			{ selector: "kwd", name: "K", checked: false, sortable: true, wrap: true },
			{ selector: "queryTerm", name: "Keyword", checked: false, sortable: true, wrap: true },
		],
		"FILTERS_INIT": [
			{ selector: "market", name: "Market", values: [] },
			{ selector: "partner", name: "Partner", values: [] },
			{ selector: "source_tag", name: "Source Tag", values: [] },
			{ selector: "client", name: "Client", values: [] },
			{ selector: "site_id", name: "Site ID", values: [] },
			{ selector: "kwd", name: "Keyword", values: [] },
		],
		CHART_DIMENSIONS: ["revenue"]
	},
};