import React, {useState} from "react";
import moment from "moment";

import {
  Card,
  CardBody,
  Row,
  Col, Label,
 	Modal, ModalHeader, ModalBody, ModalFooter,
  FormGroup, Button, Input, Form 
} from "reactstrap";


import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css';

import CONFIG from "../reports/config.js"

const NEW_DEAL = {
	filter_column: "",
	filter_value: "",
	type: "?????",
	revenue_share_value: 0,
	profit_share_value: 0,
	list: []
};

const UserDealsSetupModel = (props) => {
  const [endDate, setEndDate] = useState(moment(new Date()).toDate());

	if (props.isOpen === false || !props.deal) {
		return false;
	}

	let aDeal = {...NEW_DEAL, ...props.deal};

	let {block, name, reportType} = props.allRoutes[props.selectedRoute];
	let filters = CONFIG[reportType] ? CONFIG[reportType].FILTERS_INIT : [];

  return (
    <Modal isOpen={props.isOpen} toggle={() => props.close()} size="lg" className="full-screen">
    <ModalHeader className="pb-0" toggle={() => props.close()}>
    	DEAL CONFIGURATION FOR {name} to {block}
    </ModalHeader>
    <ModalBody>
    	<Row>
				<Col className="order-xl-1" xl="12">
	        <Card className="bg-secondary shadow pt-0">
	          <CardBody>
	          	<Form>
	          		<h6 className="heading-small text-muted mb-4">
              		DEAL PROPS
                </h6>
	          		<Row>
					        <Col md={6}>
					        	<FormGroup>
							        <Label for="deal_filter_column">Column:</Label>
						          <Input type="select" id="deal_filter_column" 
					            	onChange={e => {
						            	let editingDeal = {...aDeal, filter_column: e.target.value};
						            	props.editDeal(editingDeal)
						            }}
					            	value={aDeal.filter_column}
					            >
					            	{[<option key="empty">----</option>].concat(
					            		filters.map((m, key) => <option key={key} value={m.selector}>{m.name}</option>))}
					            </Input>
							      </FormGroup>
					        </Col>
					        <Col md={6}>
					        	<FormGroup>
							        <Label for="deal_filter_value">Value:</Label>
						          <Input
							        	id="deal_filter_value"
						            placeholder="column-list of values i.e site_id-123,123,123,123"
						            onChange={e => {
						            	let editingDeal = {...aDeal, filter_value: e.target.value};
						            	props.editDeal(editingDeal)
						            }}
						            value={aDeal.filter_value}
						          />
							      </FormGroup>
					        </Col>
					      </Row>
					  		<hr />
				     		<h6 className="heading-small mb-4">
              		{aDeal.filter_column === "" ? "NO DEAL SET, SELECT DEAL TYPE" 
              			: "DEAL SET ON " + aDeal.filter_column + " CURRENTLY LIVE:"}
                </h6>
	          		<Row>
							    <Col>
							      <FormGroup row>
							      	<Col sm={3}>
								      	{["profit_share", "revenue_share"].map((type, kkk) => {
								   			let typeLabel = type === "profit_share" ? "Profit Share" : type === "revenue_share" ? "Revenue share" : "??????"
								    		return <FormGroup key={kkk} check>
								          <Label check>
								            <Input type="radio" name="deal_type" value={type}
								            	checked={aDeal.type === type}
								            	onChange={e => {
									            	let editingDeal = {...aDeal, type: type};
									            	props.editDeal(editingDeal)
									            }}/>{' '}
								            {typeLabel}
								          </Label>
								        </FormGroup>
								    		})}
							    		</Col>
							        <Label
							          className="form-control-label text-right"
							          htmlFor="input-ps"
							          sm={3}
							        >
							          <legend>{aDeal.type === "profit_share" ? "Profit Share" : aDeal.type === "revenue_share" ? "Revenue share" : "??????"}</legend>
							        </Label>
							        <Col sm={2}>
								        <Input
								          className="form-control"
								          id="input-ps"
								          type="number"
								          onChange={e => {
							            	let editingDeal = {...aDeal, [`${aDeal.type}_value`]: e.target.value};
							            	props.editDeal(editingDeal);
							            }}
							            value={aDeal[`${aDeal.type}_value`]}
								        />
							        </Col>
							        <Col sm="1">
								   			<Button disabled={aDeal.filter_column === ""} color="primary" 
									   			onClick={(e) => {
									   				console.log("DID I CLICK",aDeal);
									   				if ( aDeal.list ) {
									   					aDeal.list.push({
									   						date: moment(endDate).format("YYYY-MM-DD"),
									   						type: aDeal.type,
																revenue_share_value: aDeal.revenue_share_value,
																profit_share_value: aDeal.profit_share_value
									   					});
									   				} else {
									   					aDeal.list = [{
									   						date: moment(endDate).format("YYYY-MM-DD"),
									   						type: aDeal.type,
																revenue_share_value: aDeal.revenue_share_value,
																profit_share_value: aDeal.profit_share_value
									   					}];
									   				}
									   				let editingDeal = {...aDeal};
							            	props.editDeal(editingDeal)
									   			}}>END</Button>
								   		</Col>
							        <Col lg="3">
		                    <FormGroup row>
		                      <Label sm={4} className="text-right">
		                        Date
		                      </Label>
		                      <Col sm={8}>
		                      <DatePicker
		                        selected={endDate}
		                        onChange={date => setEndDate(date)}
		                        dateFormat="yyyy-MM-dd"
		                        calendarClassName="datepickerCustomStyle"
		                        className="form-control"
		                      />
		                      </Col>
		                    </FormGroup>
		                  </Col>							        
							      </FormGroup>
							    </Col>
							  </Row>
						   	{aDeal.list.length > 0 ? 
						  		<ul className="list-group">
					     		<h6 className="heading-small mb-4">
				           	List of all deals
				          </h6>
						  			{aDeal.list.filter(d => !!d).sort((a, b) => {
							        return new Date(a.date).getTime() - new Date(b.date).getTime();
							      }).map((deal, key) => {
						  				let lastDeal = aDeal.list[key-1] || false;
					  					let typeLabel = deal.type === "profit_share" ? "Profit Share" : deal.type === "revenue_share" ? "Revenue share" : "??????"

					  					let validityText = `${deal[`${deal.type}_value`]}% ${typeLabel} deal applied when date <= ${moment(deal.date).format("YYYY-MM-DD")}`;
					  					if ( lastDeal ) {
					  						validityText = `${deal[`${deal.type}_value`]}% ${typeLabel} deal applied when date > ${lastDeal.date} AND date <= ${moment(deal.date).format("YYYY-MM-DD")}`;
					  					}
					  					return <li key={key} className="list-group-item">
					  						{validityText}
					  					</li>
					  				})}
					  			</ul>
					  			: null}
					  	</Form>
	          </CardBody>
	        </Card>
	      </Col>
	    </Row>
    </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => {
          props.setupDeal(props.selectedRoute, props.deal); 
         	props.close();
        }}>SAVE</Button>
      </ModalFooter>
    </Modal>
  );
}

export default UserDealsSetupModel;