import React, {useState } from "react";

import {
  Card,
  CardBody,
  Row,
  Col,
  Modal, 
  ModalHeader, 
  ModalBody,
  FormGroup, Button, Input
} from "reactstrap";

const AdTemplateModal = (props) => {
    
  // console.log("ADTEMPLATE MODAL ", props);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [utm_source, setUTMSource] = useState("all");
  const [showAddTemplate, addTemplate] = useState(false);

  const templates = props.template[props.market] && props.template[props.market][props.utm_campaign] ? 
                      props.template[props.market][props.utm_campaign] : {};

  const renderAddTemplateForm = () => {
    return <Row>
  <Col lg="12">
    <FormGroup>
      <label
        className="form-control-label"
        htmlFor="input-title"
      >
        CHOOSE THE UTM SOURCE
      </label>
      <Input
        className="form-control-alternative"
        placeholder="TITLE"
        type="select"
        value={utm_source}
        onChange={(e) => setUTMSource(e.target.value)}
      >
        {props.utmSources.map((m, key) => <option key={key} value={m.site_id}>{m.site_id}</option>)}
      </Input>
    </FormGroup>
  </Col>
  <Col lg="12">
    <FormGroup>
      <label
        className="form-control-label"
        htmlFor="input-title"
      >
        TITLE
      </label>
      <Button className="float-right" color="link">
        Append <i className="fa fa-plus" onClick={() => { props.addTitle(title, utm_source, props.utm_campaign, props.market); setTitle("");}} />
      </Button>
      <Input
        className="form-control-alternative"
        placeholder="TITLE"
        type="textarea"
        rows={5}
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
    </FormGroup>
  </Col>
  <Col lg="12">
    <FormGroup>
      <label
        className="form-control-label"
        htmlFor="input-body"
      >
        BODY
      </label>
      <Button className="float-right" color="link">
        Append <i className="fa fa-plus" onClick={() => { props.addBody(body, utm_source, props.utm_campaign, props.market); setBody("");}} />
      </Button>
      <Input
        className="form-control-alternative"
        placeholder="BODY"
        type="textarea"
        rows={5}
        value={body}
        onChange={(e) => setBody(e.target.value)}
      />
    </FormGroup>
  </Col>
</Row>
  }




  return (
    <Modal isOpen={props.isOpen} toggle={() => props.toggle()} size="lg" className="full-screen">
    <ModalHeader className="pb-0" toggle={() => props.toggle()}>
      <Row>
        <Col xl={8}>
          <h4>TEMPLATES FOR MARKET: <b>{props.market}</b>, VERTICAL/UTM_CAMPAIGN: <b>{props.utm_campaign}</b></h4>
        </Col>
        <Col xl={4}>
          <Button color="primary" size="sm" onClick={() => { props.saveTemplate(props.utm_campaign, props.market)}}>SAVE</Button>
          <Button color="success" size="sm" onClick={() => addTemplate(!showAddTemplate)}>ADD NEW</Button>
        </Col>
      </Row>
    </ModalHeader>
    <ModalBody>
     	<Row>
        
				<Col className="order-xl-1" xl="12">
	        <Card className="bg-secondary shadow pt-0">
	          <CardBody>
              {showAddTemplate && renderAddTemplateForm()}
              {Object.keys(templates).map(( site_id ) => {
                console.log(site_id);
                return <Row key={site_id}>
                    <Col lg="8">
                      <h6 className="template-title heading-small text-muted mb-4">
                        TEMPLATE USED BY <b>{site_id}</b> UTM_SOURCE(s)
                      </h6>
                    </Col>
                    <Col lg="4">
                      <Button color="danger" size="sm" className="float-right" onClick={() => { props.deleteTemplate(site_id, props.utm_campaign, props.market)}}>DELETE</Button>
                    </Col>
                    {templates[site_id] && templates[site_id].title.map((title, idx) => {
                      const inputId = `title-${idx}`;
                      return <Col lg="12" key={inputId}>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={inputId}>{`TITLE #${idx + 1}`}</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="TITLE"
                            type="textarea"
                            rows={5}
                            name={inputId}
                            data-idx={idx}
                            data-name="title"
                            id={inputId}
                            value={templates[site_id].title[idx]}
                            onChange={(e) => props.handleTitleChange(e, site_id, props.utm_campaign, props.market)}
                          />
                        </FormGroup>
                      </Col>
                    })}
                    <hr/>
                    {templates[site_id] && templates[site_id].body.map((body, idx) => {
                      const inputId = `body-${idx}`;
                      return <Col lg="12" key={inputId}>
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor={inputId}>{`BODY #${idx + 1}`}</label>
                          <Input
                            className="form-control-alternative"
                            placeholder="BODY"
                            type="textarea"
                            rows={5}
                            name={inputId}
                            data-idx={idx}
                            data-name="body"
                            id={inputId}
                            value={templates[site_id].body[idx]}
                            onChange={(e) => props.handleBodyChange(e, site_id, props.utm_campaign, props.market)}
                          />
                        </FormGroup>
                      </Col>
                    })}
                    <hr />
                  </Row>
              })}
              <Button type="button" className="btn-block" color="primary" onClick={() => { props.saveTemplate(props.utm_campaign, props.market)}}>SAVE</Button>
	          </CardBody>
	        </Card>
	      </Col>
	    </Row>
    </ModalBody>
    </Modal>
  );
};

export default AdTemplateModal;