import React, { useState, useEffect } from "react";
import { API } from 'aws-amplify';
// import Spinner from "../../../components/Spinner";
import classnames from "classnames";
import Flag from "react-flags";

import {
  Row,
  Col,
  ButtonGroup,
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalBody,
  ModalHeader
} from "reactstrap";

import UploadDropzone from "../../image-tool/uploadPage/uploadDropzone";

const allMarkets = [
  { value: "ar", flag: "ar", label: "Argentina"},
  { value: "at", flag: "at", label: "Austria" },
  { value: "au", flag: "au", label: "Australia" },
  { value: "be", flag: "be", label: "Belgium" },
  { value: "bo", flag: "bo", label: "Bolivia" },
  { value: "br", flag: "br", label: "Brazil" },
  { value: "ca", flag: "ca", label: "Canada" },
  { value: "ch", flag: "ch", label: "Switzerland" },
  { value: "cl", flag: "cl", label: "Chile"},
  { value: "co", flag: "co", label: "Colombia"},
  { value: "de", flag: "de", label: "Germany" },
  { value: "dz", flag: "dz", label: "Algeria" },
  { value: "ec", flag: "ec", label: "Ecuador" },
  { value: "dk", flag: "dk", label: "Danmark" },
  { value: "es", flag: "es", label: "Spain" },
  { value: "fi", flag: "fi", label: "Finland" },
  { value: "fr", flag: "fr", label: "France" },
  { value: "hk", flag: "hk", label: "Hong Kong"},
  { value: "ie", flag: "ie", label: "Ireland"},
  { value: "in", flag: "in", label: "India" },
  { value: "id", flag: "id", label: "Indonesia"},
  { value: "it", flag: "it", label: "Italy" },
  // { value: "my", flag: "my", label: "Malaysia"},
  { value: "mx", flag: "mx", label: "Mexico" },
  { value: "nl", flag: "nl", label: "Netherlands" },
  { value: "no", flag: "no", label: "Norway" },
  { value: "nz", flag: "nz", label: "New Zealand" },
  { value: "pe", flag: "pe", label: "Peru"},
  { value: "ph", flag: "ph", label: "Philippines"},
  { value: "se", flag: "se", label: "Sweden" },
  { value: "sn", flag: "sn", label: "Senegal" },
  { value: "sg", flag: "sg", label: "Singapore"},
  { value: "tw", flag: "tw", label: "Taiwan" },
  { value: "th", flag: "th", label: "Thailand" },
  { value: "tn", flag: "tn", label: "Tunisia" },
  { value: "uk", flag: "gb", label: "United Kingdom" },
  { value: "us", flag: "us", label: "United States" },
  { value: "ve", flag: "ve", label: "Venezuela"},
  { value: "vn", flag: "vn", label: "Vietnam"}  
];


const FacebookAutomation = (props) => {

  // const [MARKETS, setMARKETS] = useState(allMarkets);
  const [isLoading, setIsLoading] = useState(false);

  const [cmsProperties, setCmsProperties] = useState({})
  const [mapping, setMapping] = useState([]);
  const [facebookFanPages, setFacebookPagesMap] = useState({});
  const [fbPixels, setFbPixels] = useState([]);
  const [mappedFanPages, setMappedFanPages] = useState([[]]);
  const [fbAccounts, setFbAccounts] = useState([[]]);
  const [savedTemplates, setSavedTemplates] = useState([[]]);


  const [activeMarketTab, setActiveMarketTab] = useState(false);
  const toggleMarketTab = tab => {
    if(activeMarketTab !== tab) setActiveMarketTab(tab);
  }


  const loadSettings = async () => {
    setIsLoading(true);

    let {landingPages} = await API.get('customAPI', '/facebookAccountMapper/cms');
    setCmsProperties(landingPages);

    let {mapping} = await API.get('customAPI', '/facebookAccountMapper/mapping', {
      queryStringParameters: {
        groupby: "accountID",
        bulkupload: true
      }
    });
    setMapping(mapping);

    setFbAccounts([Object.keys(mapping).map((account_id) => {
      return {id: account_id, name: mapping[account_id].accountName};
    })]);

    let facebookPages = await API.get('customAPI', '/fb_fanpage/list');
    const mapFacebookPages = facebookPages.reduce((acc, page) => {
      acc[page.id] = page;
      return acc;
    }, {});
    setFacebookPagesMap(mapFacebookPages);

    let {pixels} = await API.get('customAPI', '/facebookAccountMapper/pixel');
    setFbPixels(pixels);

    setIsLoading(false);
  }


  useEffect(() => {
    loadSettings();
  }, []);


  const loadFanPagesLimit = async (fbAccountID, businessManagerID, nextMappedFanPages, idx) => {
    const fanPageLimits = await API.get("customAPI", "/fb_fanpage/limits", {
      queryStringParameters: {
        fbAccountID, businessManagerID, pageIDList: nextMappedFanPages.map((p) => p.id)
      }
    })
    for ( let page of nextMappedFanPages ) {
      page.name += " - ads running: " + fanPageLimits[page.id].ads_running_or_in_review_count;
    }
    let mappedFanPagesnew = [...mappedFanPages];
    mappedFanPagesnew[idx] = nextMappedFanPages;
    setMappedFanPages(mappedFanPagesnew);
  }

  const loadAdTemplates = async (utm_campaign, idx) => {
    if ( utm_campaign === "" ) {
      const nextSavedTemplatesnew = [...savedTemplates];
      nextSavedTemplatesnew[idx] = [];
      setSavedTemplates(nextSavedTemplatesnew);
    } else {
      const adTemplates = await API.get("customAPI", "/keyword-tools/adtemplate", {
        queryStringParameters: {
          utm_campaign
        }
      });
      let nextSavedTemplates = adTemplates.map((tpl) => ({
        id: tpl.label,
        name: tpl.label
      }));
      // console.log(nextSavedTemplates);
      const nextSavedTemplatesnew = [...savedTemplates];
      nextSavedTemplatesnew[idx] = nextSavedTemplates;
      setSavedTemplates(nextSavedTemplatesnew);  
    }
  }

  const blankAccountSetup = {
    domain: '', 
    utm_source: '',
    fb_businessManagerID: '',
    activity: '',
    fb_account_name: '',
    fb_account: '',
    fb_fanpage: [],
    fb_pixel: '',
    utm_campaign: '',
    template: [],
    market_linked: '',
    async_execution: false,
    liquidity_spread: false,
    liquidity_market_adset_level: false,
    isPerion: false
  };

  const [bulkUpload, setBulkUpload] = useState({
    
    account_setup: [{...blankAccountSetup}],
    cta: '', 
    dynamic_creative: true, 
    is_carousel_ad: false,
    createGridImages: false,
    shutterstock: "noshutterstock", 
    number_of_creatives: 4, 
    device: "",
    os: "",
    daily_budget: 30, 
    daily_budget_type: "campaign",
    bid_cap: 0, 
    bid_strategy: 'Lowest Cost', 
    gender: '', 
    optimized_event: "LEAD",
    adset_starttime: "ASAP",
    placements: "recommended",
    platform: ["facebook", "instagram", "audience_network", "messenger"],
    positions: {
      facebook_positions: ["feed", "right_hand_column", "instant_article", "marketplace", "video_feeds", "story", "search", "instream_video"],
      instagram_positions: ["stream", "story", "explore", "explore_home", "reels", "profile_feed", "ig_search"],
      audience_network_positions: ["classic", "rewarded_video"],
      messenger_positions: ["messenger_home", "story"] //, "sponsored_messages"]
    },
    apiCampaignStatus: "PAUSED",
    alternateSystemUser: false
  });


  const handlePlatformChange = (platform) => {
    let exists = bulkUpload.platform.indexOf(platform);
    let platforms = [...bulkUpload.platform];
    if ( exists === -1 ) {
      handleBulkUploadChange({
        target: {
          name: "platform",
          value: [...platforms, platform]
        }
      });
    } else {
      platforms.splice(exists, 1)
      handleBulkUploadChange({
        target: {
          name: "platform",
          value: platforms
        }
      });
    }
  }

 const handlePositionChange = (platform, position) => {
    
    let exists = bulkUpload.positions[platform].indexOf(position);
    let platformPositions = {...bulkUpload.positions};

    if ( exists === -1 ) {
      platformPositions[platform].push(position);
      
      handleBulkUploadChange({
        target: {
          name: "positions",
          value: {...platformPositions}
        }
      });
    } else {
      platformPositions[platform].splice(exists, 1);

      handleBulkUploadChange({
        target: {
          name: "positions",
          value: {...platformPositions}
        }
      });
    }
  }


  const handleBulkUploadChange = (e) => setBulkUpload({
    ...bulkUpload,
    [e.target.name]: e.target.value,
  });

  const addAccountSetup = () => {
    const nextBulkUpload = {...bulkUpload};
    nextBulkUpload.account_setup = [...nextBulkUpload.account_setup, {...blankAccountSetup}];
    setBulkUpload(nextBulkUpload);

    const newfbAccounts = Object.keys(mapping).map((account_id) => {
      return {id: account_id, name: mapping[account_id].accountName};
    });
    setFbAccounts([...fbAccounts, newfbAccounts]);

    setMappedFanPages([...mappedFanPages, []]);
    setSavedTemplates([...savedTemplates, []]);
  };

  const deleteAccountSetup = (idx) => {
    const nextBulkUpload = {...bulkUpload};
    let accountSetup = [...nextBulkUpload.account_setup];
    accountSetup.splice(idx, 1);
    nextBulkUpload.account_setup = accountSetup;
    setBulkUpload(nextBulkUpload);
  }


  const handleAccountSetupChange = (e, idx) => {
    const nextAccountSetup = {...bulkUpload.account_setup[idx]};
    
    if ( e.target.name === "template" ) {
      nextAccountSetup[e.target.name] = [...e.target.selectedOptions].map(o => o.value);
    }else if ( e.target.name === "fb_fanpage" ) {
      
      const selectedFanPage = e.target.value;
      nextAccountSetup[e.target.name] = [...e.target.selectedOptions].map(o => {
        const fbFanPage = facebookFanPages[selectedFanPage] || {};
        return {
          page: o.value,
          ig_account: fbFanPage.igAccount || -1
        }
      });
      
      // const fbFanPage = facebookFanPages[selectedFanPage];
      // if ( fbFanPage && fbFanPage.igAccount ) {
      //   nextAccountSetup["fb_fanpage_ig_account"] = fbFanPage.igAccount;
      // }

    } else {
      nextAccountSetup[e.target.name] = e.target.value;
    }

    if ( e.target.name === "domain" ) {
      // WHEN SELECTING THE DOMAIN .. FILTER THE FB ACCOUNTS!
      const selectDomain = e.target.value;
      let filteredAccount = Object.keys(mapping).reduce((acc, accountID) => {
        if ( mapping[accountID].domain === selectDomain ) {
          acc.push({id: accountID, name: mapping[accountID].accountName})
        }
        return acc;
      }, [])

      const nextFbAccount = [...fbAccounts];
      nextFbAccount[idx] = filteredAccount;
      setFbAccounts(nextFbAccount);
    }

    if ( e.target.name === "fb_account" ) {
      const fbAccountID = e.target.value
      let nextMappedFanPages = [];
      if ( mapping[fbAccountID] ) {
        const fbPagesId = JSON.parse(mapping[fbAccountID].fanPages);
        if ( fbPagesId.length === 0 || fbPagesId[0]  === "" ) {
          nextMappedFanPages = Object.keys(facebookFanPages).map((pageID) => {
            return {id: pageID, name: facebookFanPages[pageID].name}
          });
        } else {
          nextMappedFanPages = fbPagesId.map((fanPageID) => { 
            return {id: fanPageID, name: facebookFanPages[fanPageID] ? facebookFanPages[fanPageID].name : ""}
          });
        }
        loadFanPagesLimit(fbAccountID, mapping[fbAccountID].businessManagerID, nextMappedFanPages, idx);
      }
      
      const businessManagerID = mapping[fbAccountID] ? mapping[fbAccountID].businessManagerID : "";
      const fbAccountName = mapping[fbAccountID] ? mapping[fbAccountID].accountName : "";
      const activity = mapping[fbAccountID] ? mapping[fbAccountID].activity : "";
      const assignedPixel = mapping[fbAccountID] ? mapping[fbAccountID].pixel : "";
      const domain = mapping[fbAccountID] ? mapping[fbAccountID].domain : "";
      const utm_source = mapping[fbAccountID] ? mapping[fbAccountID].utm_source : "";
      const utm_campaign = mapping[fbAccountID] ? mapping[fbAccountID].utm_campaign : "";                   
      const {site_id=false, domainUrl=false, isPerion=false } = cmsProperties[domain] 
        && cmsProperties[domain][utm_source] ? cmsProperties[domain][utm_source] : {};

      console.log(activity);
      nextAccountSetup["domain"] = domain;
      nextAccountSetup["utm_source"] = utm_source;
      nextAccountSetup["activity"] = activity;
      nextAccountSetup["utm_campaign"] = utm_campaign;
      nextAccountSetup["fb_businessManagerID"] = businessManagerID;
      nextAccountSetup["fb_pixel"] = assignedPixel;
      nextAccountSetup["fb_account_name"] = fbAccountName;
      nextAccountSetup["site_id"] = site_id;
      
      loadAdTemplates(utm_campaign, idx); 

      if ( domainUrl) {
        nextAccountSetup["domainUrl"] = domainUrl;
      }

      nextAccountSetup["isPerion"] = isPerion;
    }

    if ( e.target.name === "utm_campaign" ) {
      loadAdTemplates(e.target.value, idx);
    }

    if ( e.target.name === "utm_source" ) {
      const {domain, utm_source} = nextAccountSetup;
      const {site_id=false, domainUrl=false, isPerion=false} = cmsProperties[domain] ? cmsProperties[domain][utm_source] : {};
      nextAccountSetup["site_id"] = site_id;
      nextAccountSetup["domainUrl"] = domainUrl;
      nextAccountSetup["isPerion"] = isPerion;
    }

    const nextBulkUpload = {...bulkUpload};

    if ( nextAccountSetup["fb_pixel"] == 1262052447293125 ) {
      nextBulkUpload["optimized_event"] = "PURCHASE";
    }
    if ( nextAccountSetup["activity"] === "mq" ) {
      nextBulkUpload["optimized_event"] = "SEARCH";
    }

    nextBulkUpload.account_setup[idx] = nextAccountSetup;
    setBulkUpload(nextBulkUpload);
  }

  const [keywordList, setKeywordList] = useState({});
  const handleKeywordListChange = (e) => {
    const nextKeywordList = {...keywordList};
    nextKeywordList[e.target.dataset.market] = e.target.value;
    setKeywordList(nextKeywordList);
  };


  const [keywordUploadFile, setKeywordUploadFile] = useState(false);
  const handleDropKeywordUploadFile = (file, toggle) => {
    // Check for the various File API support.
    if (window.FileReader) {
      // FileReader are supported.
      const [theFile] = file;
      const reader = new FileReader();
      reader.onload = (evt) => {
        console.log(evt);
        var csv = evt.target.result;
        setUploadCsvText(csv);
        // show the parsed file result!
        setUploadCsvType(false);
      };
      reader.readAsText(theFile);
      setKeywordUploadFile(theFile);
    } else {
      alert('FileReader are not supported in this browser.');
    }
  }

  const [uploadCsvFile, setUploadCsvType] = useState(true);
  const toggleUploadCSVType = () => {
    setUploadCsvType(!uploadCsvFile);
  }
  const [uploadCsvText, setUploadCsvText] = useState("");
  const handleUploadCsvTextChange = (e) => {
    setUploadCsvText(e.target.value);
  }


  const [downloadModal, setDownloadModal] = useState(false)
  const [facebookAutomationResponse, setFacebookAutomationResponse] = useState([]);
  const [keywordWithoutImage, setCsvKeywordWithoutImage] = useState(false);
  const submit = async (e) => {
    setIsLoading(true);
    try {
      const response = await API.post('customAPI', '/keyword-tools/facebookAutomation.v1', {
        body: {
          bulkUpload,
          keywordList,
          uploadCsvText,
          buttonName: e.target.name,
          mode
        }
      });
      
      setFacebookAutomationResponse(response);
      let csvContent = "data:text/csv;charset=utf-8,";
      let noImageKeywords = response.reduce((acc, accountResponse) => {
        acc = [...acc, ...accountResponse.logs
          .filter((l) => l.market && l.keyword)
          .map((log, id) => `${log.market},${log.keyword}`)]
        return acc;
      }, []);

      if ( noImageKeywords.length > 0 ) {
        setCsvKeywordWithoutImage(csvContent+noImageKeywords.join("\n"));
      } else {
        setCsvKeywordWithoutImage(false);
      }

      setDownloadModal(true);
    } catch ( e ) {
      alert(e.message);
    }
    setIsLoading(false);
  }

  const [mode, setMode] = useState("standard");

  return (
    <Container className="mt-2" fluid>
      <Card className="bg-secondary shadow">
        <CardHeader className="border-0 bg-green">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">BULK UPLOAD</h3>
            </Col>
            <Col xs="3">
              <div className="float-right">
                <small className="mr-2">Standard</small>
                <Label className="custom-toggle custom-toggle-danger" style={{margin:0}}>
                  <Input type="checkbox" defaultChecked={mode==="liquidity"} onClick={(e) => {
                    let mode = e.target.checked ? "liquidity" : "standard"; 
                    setMode(mode);
                  }} />
                  <span className="custom-toggle-slider rounded-circle" data-label-off="Standard" data-label-on="Liquidity"></span>
                </Label>
                <small className="ml-2">Liquidity</small>
              </div>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Form>
            <Row>
              <Col>
                <Label className="d-block">
                  Keyword list by market 
                  {activeMarketTab && <Button type="button" size="sm" className="float-right" onClick={() => toggleMarketTab(false)}>Hide keyword list</Button>}</Label>
                <Nav pills fill>
                  <NavItem key="nav-upload-csv" style={{paddingRight: "0.3rem", paddingBottom: "0.3rem"}}>
                    <NavLink
                      className={classnames({ active: activeMarketTab === "upload-csv" })}
                      style={{borderRadius: "0", cursor: "pointer"}}
                      onClick={() => { toggleMarketTab("upload-csv"); }}
                    >
                      <i className="fas fa-upload"></i>
                      <span style={{ textTransform: "uppercase", fontWeight: "bold" }}>CSV</span>
                    </NavLink>
                  </NavItem>
                  {mode === "standard" && allMarkets.map((m, key) => {
                    return <NavItem key={key} style={{paddingRight: "0.3rem", paddingBottom: "0.3rem"}}>
                      <NavLink
                        className={classnames({ active: activeMarketTab === m.value })}
                        style={{borderRadius: "0", cursor: "pointer"}}
                        onClick={() => { toggleMarketTab(m.value); }}
                      >
                        <Flag name={m.flag} format="png" pngSize={16} shiny={true} alt={m.label} basePath="" />
                        <span style={{ textTransform: "uppercase" }}>{m.value}{keywordList[m.value] && " - " + keywordList[m.value].split("\n").length}</span>
                      </NavLink>
                    </NavItem>
                  })}
                </Nav>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <TabContent activeTab={activeMarketTab}>
                  {allMarkets.map((m, key) => {
                    const keywordId = `keyword-${m.value}`;
                    return <TabPane tabId={m.value} key={`tab-${key}`}>
                      <Row className="mt-4">
                        <Col md={{size: 6,offset: 3}}>
                          <FormGroup>
                            <Label htmlFor={keywordId}>Keyword for {m.label}</Label>
                            <Input
                              type="textarea"
                              placeholder="List of keyword, one per row!"
                              rows="10"
                              name={keywordId}
                              id={keywordId}
                              data-market={m.value}
                              value={keywordList[m.value] || []}
                              onChange={handleKeywordListChange}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </TabPane>
                  })}
                  <TabPane tabId="upload-csv" key={`tab-upload-csv`}>
                      <Row className="mt-4">
                        {uploadCsvFile ? <Col md={{size: 6,offset: 3}}>
                          <UploadDropzone multiple={false} accept="text/csv" addLocalImageToSelected={handleDropKeywordUploadFile} />
                          <p>{keywordUploadFile.name}</p>
                        </Col> :
                        <Col md={{size: 6,offset: 3}}>
                           <FormGroup>
                            { mode === "liquidity" 
                              ? <Label htmlFor="upload-csv-text">market,seedKeyword,keyword,bid,budget,device,start_time,optimized_event</Label>
                              : <Label htmlFor="upload-csv-text">market,keyword,bid,start_time,optimized_event</Label>
                            }
                            <Input
                              type="textarea"
                              placeholder={mode === "liquidity" ? "List of `market,seedKeyword,keyword,bid,budget,device,start_time,optimized_event`, one per row!" : "List of `market,keyword,bid,start_time,optimized_event`, one per row!"}
                              rows="10"
                              name="upload-csv-text"
                              id="upload-csv-text"
                              value={uploadCsvText}
                              onChange={handleUploadCsvTextChange}
                            />
                          </FormGroup>
                        </Col>}
                        <Col>
                          <Button className="btn-icon" size="sm" type="button" onClick={toggleUploadCSVType}>
                            <i className="far fa-keyboard"></i>
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={{size: 6,offset: 4}}>
                          <Input type="checkbox" name="split_between_accounts" 
                            onChange={() => setBulkUpload({...bulkUpload, ...{liquidity_spread: !bulkUpload.liquidity_spread}})} /> Split between accounts
                        </Col>
                        <Col md={{size: 6,offset: 4}}>
                          <Input type="checkbox" name="market_adset_level" id="market_adset_level"
                            onChange={() => setBulkUpload({...bulkUpload, ...{liquidity_market_adset_level: !bulkUpload.liquidity_market_adset_level}})} /> <label for="market_adset_level">Market at adset level </label>
                        </Col>
                      </Row>
                    </TabPane>
                </TabContent>
              </Col>
            </Row>
            <hr className="my-2" />
            <h6 className="heading-small text-muted mb-2">Creatives setup</h6>
            <Row form>
              <Col md={3}>
                <FormGroup>
                  <Label for="label">Call to action: </Label>
                  <Input
                    type="select"
                    id="cta"
                    name="cta"
                    value={bulkUpload.cta}
                    onChange={handleBulkUploadChange}
                  >
                    <option key="empty-cta" value="">----</option>
                    {CTA.map((m, key) => <option key={m.id} value={m.id}>{m.name}</option>)}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={{size: 2, offset: 1}}>
                  <Label className="d-block">Dynamic Creative</Label>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="dynamic_creative" value={true} onChange={handleBulkUploadChange} required defaultChecked/>Yes
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="dynamic_creative" value={false} onChange={handleBulkUploadChange} required/>No
                    </Label>
                  </FormGroup>
              </Col>
              <Col md={{size: 2, offset: 0}}>
                  <Label className="d-block">Create 2 Grid Images</Label>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="createGridImages" value={true} onChange={handleBulkUploadChange} required />Yes
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="createGridImages" value={false} onChange={handleBulkUploadChange} required defaultChecked/>No
                    </Label>
                  </FormGroup>
              </Col>
              <Col md={{size: 2, offset: 0}}>
                  <Label className="d-block">Carousel Ad</Label>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="is_carousel_ad" value={true} onChange={handleBulkUploadChange} required />Yes
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="is_carousel_ad" value={false} onChange={handleBulkUploadChange} required defaultChecked/>No
                    </Label>
                  </FormGroup>
              </Col>
              

              <Col md={2}>
                <Label className="d-block">Number of Images</Label>
                <Input
                  type="number"
                  id="number_of_creatives"
                  name="number_of_creatives"
                  value={bulkUpload.number_of_creatives}
                  onChange={handleBulkUploadChange}
                />
              </Col>
              {/**<Col md={{size: 2, offset: 1}}>
                  <Label className="d-block">Use Shutterstock</Label>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="shutterstock" value="shutterstock" checked={bulkUpload.shutterstock=="shutterstock"} required onChange={handleBulkUploadChange} />Yes
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="shutterstock" value="noshutterstock" checked={bulkUpload.shutterstock=="noshutterstock"} required onChange={handleBulkUploadChange} />No
                    </Label>
                  </FormGroup>
              </Col>*/}
            </Row>
            <hr className="my-2" />
            <h6 className="heading-small text-muted mb-4">CAMPAIGN SETUP</h6>
            <Row form>
              { mode === "standard" && 
              <>
              <Col md={3}>
                <FormGroup>
                  <Label for="daily_budget_type">
                    Type Budget
                  </Label>
                  <Input placeholder="daily_budget_type" type="select" name="daily_budget_type" id="daily_budget_type" value={bulkUpload.daily_budget_type} onChange={handleBulkUploadChange}>
                    {["campaign", "adset"].map((m) => <option key={`daily_budget_type-${m}`} value={m}>{m}</option>)}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="daily_budget">
                    Daily Budget
                  </Label>
                  <Input
                    type="number"
                    id="daily_budget"
                    name="daily_budget"
                    value={bulkUpload.daily_budget}
                    onChange={handleBulkUploadChange}
                  />
                </FormGroup>
              </Col>
              </>
            }

              <Col md={3}>
                <FormGroup>
                  <Label for="optimized_event">
                    Optimized event
                  </Label>
                  <Input placeholder="optimized_event" type="select" name="optimized_event" id="optimized_event" value={bulkUpload.optimized_event} onChange={handleBulkUploadChange}>
                    <option key="empty-optimized_event" value="">-- EVENT --</option>
                    {OPTIMIZED_EVENTS.map((m) => <option key={`optimized_event-${m.id}`} value={m.id}>{m.name}</option>)}
                  </Input>
                </FormGroup>
              </Col>
              <Col md={3}>
                <FormGroup>
                  <Label for="bid_strategy">
                    Bid Strategy
                  </Label>
                  <Input placeholder="bid_strategy" type="select" name="bid_strategy" id="bid_strategy" value={bulkUpload.bid_strategy} onChange={handleBulkUploadChange}>
                    <option key="empty-bid_strategy" value="">----</option>
                    {['Lowest Cost With Bid Cap', 'Lowest Cost', 'Lowest Cost With Cost Cap'].map((m,k) => <option key={`bid_strategy-${k}`} value={m}>{m}</option>)}
                  </Input>
                </FormGroup>
              </Col>
              { mode === "standard" && 
              <Col md={3}>
                <FormGroup>
                  <Label for="bid_cap">
                    Bid Cap
                  </Label>
                  <Input
                    disabled={bulkUpload.bid_strategy !== "Lowest Cost With Bid Cap" && bulkUpload.bid_strategy !== "Lowest Cost With Cost Cap"}
                    type="number"
                    id="bid_cap"
                    name="bid_cap"
                    value={bulkUpload.bid_cap}
                    onChange={handleBulkUploadChange}
                  />
                </FormGroup>
              </Col>}
              {/**<Col md={3}>
                <FormGroup>
                  <Label for="gender">
                    GENDER TARGETING
                  </Label>
                  <Input placeholder="gender" type="select" name="gender" id="gender" value={bulkUpload.gender} onChange={handleBulkUploadChange}>
                    <option key="empty-gender" value="">----</option>
                    {GENDER_AUDIENCE.map((m) => <option key={`gender-${m.id}`} value={m.id}>{m.name}</option>)}
                  </Input>
                </FormGroup>
              </Col>*/}
              { mode === "standard" && 
                <Col md={3}>
                  <FormGroup>
                    <Label for="adset_starttime">
                      Start Time (MM/DD/YY HH:MM)
                    </Label>
                    <Input
                      placeholder="i.e 01/01/21 08:00 or 01/01/21 20:00"
                      type="text"
                      id="adset_starttime"
                      name="adset_starttime"
                      value={bulkUpload.adset_starttime}
                      onChange={handleBulkUploadChange}
                    />
                  </FormGroup>
                </Col>}
            </Row>
            <hr className="my-2" />
            <Row>
              <Col lg={12}>
                <FormGroup>
                  <Label className="d-block">Placements:</Label>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="placements" value="recommended" onChange={handleBulkUploadChange} required defaultChecked/>Advantage+ placements (recommended)
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check>
                      <Input type="radio" name="placements" value="manual" onChange={handleBulkUploadChange} required/>Manual placements
                    </Label>
                  </FormGroup>
                </FormGroup>
              </Col>
            </Row>
            {bulkUpload.placements.indexOf("manual") !== -1 &&
              <><Row>
                <Col md={3}>
                  <FormGroup>
                    <Label for="device">
                      DEVICE TARGETING
                    </Label>
                    <Input placeholder="device" type="select" name="device" id="device" value={bulkUpload.device} onChange={handleBulkUploadChange}>
                      {DEVICE_TARGETS.map((m) => <option key={`device-${m.id}`} value={m.id}>{m.name}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <Label className="d-block">Platform: </Label>
                    <FormGroup check>
                      <Input type="checkbox" name="facebook" key="facebook" id="facebook" checked={bulkUpload.platform.indexOf("facebook") !== -1} onChange={(e) => handlePlatformChange("facebook")} />
                      <Label for="facebook" check>Facebook</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input type="checkbox" name="instagram" key="instagram" id="instagram" checked={bulkUpload.platform.indexOf("instagram") !== -1} onChange={(e) => handlePlatformChange("instagram")} />
                      <Label for="instagram" check>Instagram</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input type="checkbox" name="audience_network" key="audience_network" id="audience_network" checked={bulkUpload.platform.indexOf("audience_network") !== -1} onChange={(e) => handlePlatformChange("audience_network")} />
                      <Label for="audience_network" check>Audience Network</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input type="checkbox" name="messenger" key="messenger" id="messenger" checked={bulkUpload.platform.indexOf("messenger") !== -1} onChange={(e) => handlePlatformChange("messenger")} />
                      <Label for="messenger" check>Messenger</Label>
                    </FormGroup>
                  </FormGroup>
                </Col>
                <Col md={9}>
                  {bulkUpload.platform.indexOf("facebook") !== -1 &&
                    <FormGroup>
                      <Label className="d-block">Facebook Positions: </Label>
                      <FormGroup check inline>
                        {ALLPOSITIONS.facebook_positions.map((pos, idx) => (
                          <div key={idx}><Input type="checkbox" name={pos} id={`facebook_positions_${pos}`} checked={bulkUpload.positions.facebook_positions.indexOf(pos) !== -1} onChange={(e) => handlePositionChange("facebook_positions", pos)} />
                          <Label for={`facebook_positions_${pos}`} check>{pos}</Label> </div>
                        ))}
                      </FormGroup>
                    </FormGroup>}
                  {bulkUpload.platform.indexOf("instagram") !== -1 &&
                    <FormGroup>
                      <Label className="d-block">Instagram Positions: </Label>
                      <FormGroup check inline>
                        {ALLPOSITIONS.instagram_positions.map((pos, idx) => (
                          <div key={idx}><Input type="checkbox" name={pos} id={`instagram_positions_${pos}`} checked={bulkUpload.positions.instagram_positions.indexOf(pos) !== -1} onChange={(e) => handlePositionChange("instagram_positions", pos)} />
                          <Label for={`instagram_positions_${pos}`} check>{pos}</Label> </div>
                        ))}
                      </FormGroup>
                    </FormGroup>}
                  {bulkUpload.platform.indexOf("audience_network") !== -1 && <FormGroup>
                    <Label className="d-block">Audience Positions: </Label>
                    <FormGroup check inline>
                      {ALLPOSITIONS.audience_network_positions.map((pos, idx) => (
                        <div key={idx}><Input type="checkbox" name={pos} id={`messenger_positions_${pos}`} checked={bulkUpload.positions.audience_network_positions.indexOf(pos) !== -1} onChange={(e) => handlePositionChange("audience_network_positions", pos)} />
                        <Label for={`messenger_positions_${pos}`} check>{pos}</Label> </div>
                      ))}
                    </FormGroup>
                  </FormGroup>}
                  {bulkUpload.platform.indexOf("messenger") !== -1 && <FormGroup>
                    <Label className="d-block">Messenger Positions: </Label>
                    <FormGroup check inline>
                      {ALLPOSITIONS.messenger_positions.map((pos, idx) => (
                        <div key={idx}><Input type="checkbox" name={pos} id={`messenger_positions_${pos}`} checked={bulkUpload.positions.messenger_positions.indexOf(pos) !== -1} onChange={(e) => handlePositionChange("messenger_positions", pos)} />
                        <Label for={`messenger_positions_${pos}`} check>{pos}</Label> </div>
                      ))}
                    </FormGroup>
                  </FormGroup>}
                </Col>
              </Row></>
            }
            <hr className="my-2" />
            <h6 className="heading-small text-muted mb-4">ACCOUNT SETUP <Button type="button" size="sm" className="float-right" onClick={() => addAccountSetup()}>Add ACCOUNT SETUP</Button></h6>
            {bulkUpload.account_setup.map((v, idx) => {
              return (
                <Row form key={idx} style={idx > 0 ? {"borderTop": "1px #8898aa dashed", paddingTop: "1em"} : {}}>
                  {idx > 0 && 
                    <Col xs={12}>

                      <h6 className="heading-small text-muted mb-4"><Button type="button" color="danger" size="sm" className="float-right" onClick={() => deleteAccountSetup(idx)}>DELETE</Button></h6>
                    </Col>
                  }
                  <Col md={3}>
                    <FormGroup>
                      <Label for="domain">
                        LANDING DOMAIN
                      </Label>
                      <Input placeholder="domain" type="select" name="domain" value={bulkUpload.account_setup[idx].domain} onChange={(e) => handleAccountSetupChange(e, idx)}>
                        <option key="empty-domain" value="">----</option>
                        {Object.keys(cmsProperties).map((m) => <option key={`cms-${m}`} value={m}>{m}</option>)}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="fb_account">
                        FACEBOOK ACCOUNT
                      </Label>
                      <Input placeholder="fb_account" type="select" name="fb_account" value={bulkUpload.account_setup[idx].fb_account} onChange={(e) => handleAccountSetupChange(e, idx)}>
                        <option key="empty-fb_account" value="">----</option>
                        {fbAccounts[idx].map((m) => <option key={`fb_account-${m.id}`} value={m.id}>{m.name}</option>)}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="fb_fanpage">
                        FACEBOOK FAN PAGE
                      </Label>
                      <Input placeholder="fb_fanpage" type="select" multiple name="fb_fanpage" value={bulkUpload.account_setup[idx].fb_fanpage.map((o) => o.page)} onChange={(e) => handleAccountSetupChange(e, idx)}>
                        <option key="empty-fb_fanpage" value="">----</option>
                        {mappedFanPages[idx].map((m) => <option key={`fb_fanpage-${m.id}`} value={m.id}>{m.name || m.id}</option>)}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="fb_pixel">
                        FACEBOOK PIXEL
                      </Label>
                      <Input placeholder="fb_pixel" type="select" name="fb_pixel" value={bulkUpload.account_setup[idx].fb_pixel} onChange={(e) => handleAccountSetupChange(e, idx)}>
                        <option key="empty-fb_pixel" value="">----</option>
                        {fbPixels.map((m) => <option key={`fb_pixel-${m.id}`} value={m.id}>{m.name}</option>)}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="utm_source">
                        UTM SOURCE
                      </Label>
                      <Input placeholder="utm_source" type="select" name="utm_source" value={bulkUpload.account_setup[idx].utm_source} onChange={(e) => handleAccountSetupChange(e, idx)}>
                        <option key="empty-utm_source" value="">----</option>
                        {Object.keys(cmsProperties[bulkUpload.account_setup[idx].domain] || {}).map((m) => <option key={`cms-${m}`} value={m}>{m}</option>)}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="utm_campaign">
                        UTM_CAMPAIGN
                      </Label>
                      <Input placeholder="utm_campaign" type="select" name="utm_campaign" value={bulkUpload.account_setup[idx].utm_campaign} onChange={(e) => handleAccountSetupChange(e, idx)}>
                        <option key="empty-utm_campaign" value="">----</option>
                        {UTM_CAMPAIGN.map((m) => <option key={m.id} value={m.id}>{m.name}</option>)}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="label">AD Template ( multiselect available ): </Label>
                      <Input
                        type="select"
                        multiple
                        style={{height: "150px"}}
                        name="template"
                        value={bulkUpload.account_setup[idx].template}
                        onChange={(e) => handleAccountSetupChange(e, idx)}
                      >
                        {savedTemplates[idx].map((m) => <option key={m.id} value={m.id}>{m.name}</option>)}
                      </Input>
                    </FormGroup>
                  </Col>
                  {/**<Col md={3}>
                    <FormGroup>
                      <Label for="market_linked">
                        Market LINK ( hack for pixel smarter YM )
                      </Label>
                      <Input placeholder="market_linked" type="select" name="market_linked" value={bulkUpload.account_setup[idx].market_linked} onChange={(e) => handleAccountSetupChange(e, idx)}>
                        <option key="empty-market_linked" value="">----</option>
                        {allMarkets.map((m) => <option key={m.value} value={m.value}>{m.label}</option>)}
                      </Input>
                    </FormGroup>
                  </Col>*/}
                </Row>
              );  
            })}
            <Button type="button" size="sm" color="warning" className="float-right" onClick={() => addAccountSetup()}>Add ACCOUNT SETUP</Button>
            <hr/>
            <Row className="mt-4">
              <Col md={{size:3, offset:6}}>
                <Label className="d-block">STATUS VIA API</Label>
                <FormGroup check>
                  <Label check>
                    <Input type="radio" name="apiCampaignStatus" value="PAUSED" checked={bulkUpload.apiCampaignStatus=="PAUSED"} required onChange={handleBulkUploadChange} />PAUSED
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    <Input type="radio" name="apiCampaignStatus" value="ACTIVE" checked={bulkUpload.apiCampaignStatus=="ACTIVE"} required onChange={handleBulkUploadChange} />ACTIVE
                  </Label>
                </FormGroup>
              </Col>
              <Col md={{size:6, offset:3}}>
                <FormGroup check inline className="mt-4 mb-1" >
                  <Label check>
                    <Input type="checkbox" name="async_execution" onChange={() => setBulkUpload({...bulkUpload, ...{async_execution: !bulkUpload.async_execution}})} /> DO NOT WAIT - you will receive the upload file via mail!
                  </Label>
                </FormGroup>
              </Col>
              <Col md={{size:6, offset:3}}>
                <FormGroup check inline className="mt-4 mb-1" >
                  <Label check>
                    <Input type="checkbox" name="async_execution" onChange={() => setBulkUpload({...bulkUpload, ...{alternateSystemUser: !bulkUpload.alternateSystemUser}})} /> 
                    USE ALTERNATIVE SYSTEM USER
                  </Label>
                </FormGroup>
              </Col>
              <Col md={{size:6, offset:3}}>
                <ButtonGroup size="lg" className="btn-block">
                  <Button className="w-100" color="primary" type="button" name="via-csv" disabled={isLoading} onClick={submit}>Generate CSV</Button>
                  {/**<Button className="w-100" color="info" type="button" name="via-api" disabled={isLoading} onClick={submit}>Direct via API</Button>*/}
                  <Button className="w-100" color="cyan" type="button" name="lambda-api" disabled={isLoading} onClick={submit}>Lambda via API</Button>
                </ButtonGroup>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      <Modal isOpen={downloadModal} toggle={() => setDownloadModal(false)} size="lg">
        <ModalHeader toggle={() => setDownloadModal(false)}></ModalHeader>
        <ModalBody>
          {facebookAutomationResponse.map((response, id) => (
            <Row className="text-center" key={id}>
              <Col>
                <h1>{response.fb_account_name}</h1>
                {response.logs.length ? response.logs.map((log, id) => <p className="text-warning" key={`msg-${id}`}>{log.message}</p>) : "SUCCESS!"}
                {response.downloadUrl && <h6 className="mt-2 mb-2">Click DOWNLOAD FILE to get your file!</h6>}
                {response.downloadUrl && <a rel="noopener noreferrer" href={response.downloadUrl} target="_blank" className="btn btn-success">DOWNLOAD FILE</a>}
                {keywordWithoutImage && <p><a rel="noopener noreferrer" href={keywordWithoutImage} target="_blank" className="btn btn-success">NO IMAGE KEYWORD csv</a></p>}
                <hr />
              </Col>
            </Row>
          ))}
        </ModalBody>
      </Modal>
    </Container>
  );
}


  const UTM_CAMPAIGN = [
    { id: "automotive", name: "automotive" },
    { id: "education", name: "education" },
    { id: "fashion", name: "fashion" },
    { id: "finance", name: "finance" },
    { id: "flight", name: "flight" },
    { id: "generic", name: "generic" },
    { id: "health", name: "health" },
    { id: "household", name: "household" },
    { id: "services", name: "services" },
    { id: "shopping", name: "shopping" },
    { id: "tech", name: "tech" },
    { id: "travel", name: "travel" },
    { id:"blackfriday", name: "blackfriday"},
    // { value: "business", label: "business" },
    // { value: "internet", label: "internet" },
    // { value: "health", label: "health" },
    // { value: "astrology", label: "astrology" },
    // { value: "gambling", label: "gambling" }
  ];

  const OPTIMIZED_EVENTS = [
    { name: "LEAD", id: "LEAD" }, 
    { name: "PURCHASE", id: "PURCHASE" }, 
    {name: "ADD_TO_CART", id:"ADD_TO_CART"},
    {name: "SEARCH", id:"SEARCH"},
    {name: "CONTENT_VIEW", id:"CONTENT_VIEW"},
    {name: "clickout", id:"clickout"},
  ];


  const DEVICE_TARGETS = [
    { name: "All", id: ""},
    { name: "Mobile all", id: "mobile" }, 
    { name: "Desktop Only", id: "desktop" }, 
    { name: "Mobile all and Desktop", id: "mobile_all_and_desktop" }, 
    { name: "Mobile split only", id: "mobile_split" }, 
    { name: "Mobile split and desktop", id: "mobile_split_and_desktop" }, 
    { name: "Mobile IOS only", id: "mobile_ios" }, 
    { name: "Mobile Android only", id: "mobile_android" }
    // { name: "mobile", id: "mobile" }, 
    // { name: "desktop", id: "desktop" }, 
    // { name: "both: one campaign for mobile and one for desktop", id:"both"},
    // { name: "all + desktop: one campaign for all devices and one for desktop", id:"all_and_desktop"},
    // { name: "all + mobile: one campaign for all devices and one for mobile", id:"all_and_mobile"},
    // { name: "all + desktop + mobile: one campaign for all devices, one for desktop and one for mobile", id:"all_and_both"}
  ];


  const ALLPOSITIONS = {
    facebook_positions: ["feed", "right_hand_column", "instant_article", "marketplace", "video_feeds", "story", "search", "instream_video"],
    instagram_positions: ["stream", "story", "explore", "explore_home", "reels", "profile_feed", "ig_search"],
    audience_network_positions: ["classic", "rewarded_video"],
    messenger_positions: ["messenger_home", "sponsored_messages", "story"]
  };

  const GENDER_AUDIENCE = [{ name: "Male", id: "Male" }, { name: "Female", id: "Female" }];

  const CTA = [
    { id: "BOOK_TRAVEL", name: "Book Now" },
    { id: "DOWNLOAD", name: "Download" },
    { id: "LEARN_MORE", name: "Learn More" },
    { id: "SHOP_NOW", name: "Shop Now" },
    { id: "SIGN_UP", name: "Sign Up" },
    { id: "GET_QUOTE", name: "Get Quote" },
    { id: "NO_BUTTON", name: "No Button" },
  ];

export default FacebookAutomation;
