import React, { useState } from "react";
import { API, Storage } from 'aws-amplify';
import {
  Button,
  Label,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap";


import ImageGallery from "./imageGallery"
import UploadForm from "./uploadForm"
import Spinner from "../../../components/Spinner";
import UploadDropzone from "./uploadDropzone";


const ImageTool = () => {
  const [queryString, setQueryString] = useState("");
  const [images, loadImages] = useState([]);
  const [pixabayImages, loadPixabay] = useState([]);
  const [unsplashImages, loadUnsplash] = useState([]);
  const [flickrImages, loadFlickr] = useState([]);
  const [ccImages, loadCC] = useState([]);
  const [depositPhotosImages, loadDepositPhotosImages] = useState([]);

  const [imagePexelsTotal, setPexelsTotal] = useState(0);
  const [imagePixabayTotal, setPixabayTotal] = useState(0);
  const [imageUnsplashTotal, setUnsplashTotal] = useState(0);
  const [imageFlickrTotal, setFlickrTotal] = useState(0);
  const [imageCCTotal, setCCTotal] = useState(0);
  const [imageDepositPhotosTotal, setDepositPhotosTotal] = useState(0);

  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedImages, addSelectedImages] = useState([]);
  const [dropzoneFiles, setDropzoneFiles] = useState([]);
  
  const [alertMsg, setAlertMsg] = useState([]);
  

  const [depositPhotosMonthlyCount, setDepositPhotosMonthly] = useState(0);
  const searchImage = async (newPage) => {
    setIsLoading(true);
    setPage(newPage);
    let response = await API.get('customAPI', '/images', {
      queryStringParameters: {
        query: queryString,
        page: newPage
      }
    });

    loadImages([...response.jsonPexels.photos]);
    setPexelsTotal(response.jsonPexels.total_results);
    
    loadPixabay([...response.jsonPixaby.hits]);
    setPixabayTotal(response.jsonPixaby.totalHits);

    loadUnsplash([...response.jsonUnsplash.results])
    setUnsplashTotal(response.jsonUnsplash.total);


    loadFlickr([...response.jsonFlickr.photo])
    setFlickrTotal(response.jsonFlickr.total);


    loadCC([...response.jsonCC.results])
    setCCTotal(response.jsonCC.result_count);
    
    loadDepositPhotosImages([...response.jsondepositPhoto.result])
    setDepositPhotosTotal(response.jsondepositPhoto.count);
    setDepositPhotosMonthly(response.jsondepositPhoto.montlyCount);

    setIsLoading(false);
  }

  const toggleImageFromSelected = (imgURL) => {
    let imageIndex = selectedImages.indexOf(imgURL);
    if (imageIndex === -1) {
      addSelectedImages([...selectedImages, imgURL]);
    } else {
      selectedImages.splice(imageIndex, 1);
      addSelectedImages([...selectedImages]);
    }
  }

  const addLocalImageToSelected = (files, toggle) => {
    let newDropzoneFiles = [...dropzoneFiles];
    files.forEach(file => {
      let fileAlreadyExists = false;
      for (let i = 0; i < dropzoneFiles.length; i++) {
        let droppedFile = dropzoneFiles[i];
        if (droppedFile.name === file.name) {
          fileAlreadyExists = true;
          if (toggle)
            newDropzoneFiles.splice(i, 1);
        }
      }
      if (!fileAlreadyExists) {
        newDropzoneFiles.push(file);
      }
    });
    setDropzoneFiles(newDropzoneFiles);
  }

  const showUploadSection = () => {
    // This is defensive, because disabled "go to upload" should be handling this (which it is but oh well)
    if (selectedImages.length || dropzoneFiles.length) {
      setIsUploading(true);
    }
  }

  const backToGallery = () => {
    setIsUploading(false);
  }

  const handleUploadImages = async (keywordLists) => {
    setIsLoading(true);
    if (selectedImages.length > 0) {
      await uploadSelectedImages(keywordLists, selectedImages)
    }
    if (dropzoneFiles.length > 0) {
      await uploadDropzoneImages(keywordLists);
    }
    setIsLoading(false);
    setIsUploading(false);
    setQueryString("");
    setPage(0);
    addSelectedImages([]);
    loadImages([]);
    setPexelsTotal(0);
    loadPixabay([]);
    setPixabayTotal(0);
  }

  const getDepositPhotos = async (imageID) => {
    let response = await API.get('customAPI', '/image-tool/download/depositphotos', {
      queryStringParameters: {
        depositPhotoID: imageID
      }
    });
    return response.downloadLink || false;
  }

  const uploadSelectedImages = async (keywordLists, selectedImages) => {
    // console.log(selectedImages);
    const getBlob = async (fileUri) => {
      if ( fileUri.indexOf("pixabay") === -1 ) {
        fileUri = fileUri + "?auto=compress&cs=tinysrgb&fit=crop&h=627&w=1200";
      }
      if ( fileUri.indexOf("unsplash.com") !== -1 ) {
        fileUri = fileUri + "&fit=crop&h=627&w=1200";
      }
      if ( fileUri.startsWith("depositphotos#") ) {
        let [prefix, depositPhotoID, thumbUrl] = fileUri.split("#");
        fileUri = await getDepositPhotos(depositPhotoID);
        if ( fileUri === false ) {
          return false;
        }
      }
      const resp = await fetch(fileUri);
      const imageBody = await resp.blob();
      return imageBody;
    };

    setAlertMsg(prev => ["START UPLOADING .... Reading image selected blob ", ...prev]);

    const blobFiles = [];
    for ( let imageURI of selectedImages ) {
      let blob = await getBlob(imageURI);
      if ( blob !== false ) {
        blobFiles.push(blob);
      } else {
        setAlertMsg(prev => ["ERROR UPLOAD .... IMG: " + imageURI + " NOT VALID, SKIP!", ...prev]);
      }
    }
    await uploadFileToS3(keywordLists, blobFiles);
  }

  const uploadDropzoneImages = async (keywordLists) => {
    uploadFileToS3(keywordLists, dropzoneFiles);
  }

  const uploadFileToS3 = async (keywordLists, files) => {

    const markets = Object.keys(keywordLists);
    for ( let market of markets ) {
      const keywords = keywordLists[market].split("\n");
      for ( let keyword of keywords ) {
        keyword = keyword.trim();
        if ( keyword.length < 2 ) {
          setAlertMsg(prev => [`SKIP .... market ${market}, keyword: '${keyword}'`, ...prev]);
          continue;
        }
        setAlertMsg(prev => [`Uploading .... market ${market}, keyword: '${keyword}'`, ...prev]);
        const lastImage = await Storage.list(market.toUpperCase()+"/"+keyword.toLowerCase()+"/");
        let imageIndex = lastImage.list.length + 1;
        for ( let blob of files ) {
          await Storage.put(market.toUpperCase()+"/"+keyword.toLowerCase()+"/" + imageIndex, blob, {
            contentType: blob.type,
            ACL: 'public-read',
            level: 'public',
            progressCallback(progress) {
              setAlertMsg(prev => [`Image ${imageIndex} - Upload (${progress.loaded}/${progress.total}) .... market ${market}, keyword: '${keyword}'`, ...prev]);
            }
          });
          imageIndex++;
        }
      }
    }
  }
  
  const handleSearch = (e) => {
    e.preventDefault();
    searchImage(1);
  }

  // Don't know if I like this tbh
  // Should it be 2 functions like a paginate forward and a paginate back?
  const imagePaginationClick = (paginate) => {
    if (paginate === "next") {
      searchImage(page + 1);
    } else if (paginate === "prev") {
      searchImage(page - 1);
    }
  };

  
  const onDismissAlert = () => {
    setAlertMsg([]);
  };

  return (
    <Container className="mt-2" fluid>
      <Modal isOpen={!!alertMsg.length} toggle={onDismissAlert} size="lg" className="full-screen">
        <ModalHeader className="pb-0" toggle={onDismissAlert}>UPLOADing your IMAGES</ModalHeader>
        <ModalBody>
          <Spinner loading={isLoading}>
            <h4 className="alert-heading">Success! Your images were uploaded!</h4>
          </Spinner>
          {alertMsg && alertMsg.map((url, idx) =>
            <div key={`${url}-${idx}`}>
              {url}
            </div>
          )}
        </ModalBody>
      </Modal>
      {isUploading
        ? <UploadForm
          selectedImages={selectedImages}
          dropzoneFiles={dropzoneFiles}
          handleUploadImages={handleUploadImages}
          isLoading={isLoading}
          backToGallery={backToGallery}
          toggleImageFromSelected={toggleImageFromSelected}
          addLocalImageToSelected={addLocalImageToSelected}
          toggle={false}
        />
        : <>
          <Row className="mb-4">
            <Col>
              <Card className="bg-secondary shadow">
                <CardHeader>
                  <h3 className="mb-0">IMAGE TOOL</h3>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={handleSearch}>
                    <FormGroup row className="align-items-center">
                      <Label xs={{ size: "auto" }} for="image-search-input" className="mb-0">Search:</Label>
                      <Col xs={10} sm={6} lg={8}>
                        <Input type="search" value={queryString} name="query" id="image-search-input" placeholder="Search for images..." onChange={(e) => setQueryString(e.target.value)} />
                      </Col>
                      <Col xs={{ size: "auto" }}>
                        <Spinner loading={isLoading}>
                          <Button color="primary" type="button" onClick={handleSearch}>
                            SEARCH
                          </Button>
                        </Spinner>
                      </Col>
                    </FormGroup>
                  </Form>

                  <Row className="align-items-center">
                    <Col>
                      <p>OR:</p>
                    </Col>
                  </Row>

                  <Row className="align-items-center">
                    <Label xs={{ size: "auto" }} for="new-image-upload" className="mb-0">Upload:</Label>
                    <Col xs={10} sm={6} lg={8}>
                      <UploadDropzone addLocalImageToSelected={addLocalImageToSelected} />
                    </Col>
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>
          <ImageGallery
            toggleImageFromSelected={toggleImageFromSelected}
            selectedImages={selectedImages}
            images={images}
            pixabayImages={pixabayImages}
            imagePexelsTotal={imagePexelsTotal}
            imagePixabayTotal={imagePixabayTotal}
            unsplashImages={unsplashImages}
            imageUnsplashTotal={imageUnsplashTotal}
            flickrImages={flickrImages}
            imageFlickrTotal={imageFlickrTotal}
            ccImages={ccImages}
            imageCCTotal={imageCCTotal}
            depositPhotosImages={depositPhotosImages}
            imageDepositPhotosTotal={imageDepositPhotosTotal}
            depositPhotosMonthlyCount={depositPhotosMonthlyCount}
            isLoading={isLoading}
            showUploadSection={showUploadSection}
            imagePaginationClick={imagePaginationClick}
            page={page}
            dropzoneFiles={dropzoneFiles}
            addLocalImageToSelected={addLocalImageToSelected}
          />
        </>
      }
    </Container>
  );
}

export default ImageTool;
