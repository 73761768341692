import React, { useState, useEffect } from "react";
import { Storage } from "aws-amplify";
import { Treebeard, decorators } from "react-treebeard";
import { isEmpty } from "lodash";
import { Card, CardHeader, CardBody, Container, Row, Col, Modal, ModalHeader, 
            ModalBody, ModalFooter, Button, FormGroup, Input, Label } from "reactstrap";
// import { API } from "aws-amplify";
import Spinner from "../../../components/Spinner";
import FlagListGroup from "../../../components/flagListGroup";
import { TreeStyles } from "./treeBeard/treeStyles";
import * as filters from "./treeBeard/treeFilterHelpers";
import Header from "./treeBeard/treeHeader";
import { MARKETS } from "../MARKETS.js";

// Our hook
function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Return a cleanup function that will be called every time ...
      // ... useEffect is re-called. useEffect will only be re-called ...
      // ... if value changes (see the inputs array below). 
      // This is how we prevent debouncedValue from changing if value is ...
      // ... changed within the delay period. Timeout gets cleared and restarted.
      // To put it in context, if the user is typing within our app's ...
      // ... search box, we don't want the debouncedValue to update until ...
      // ... they've stopped typing for more than 500ms.
      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    // You could also add the "delay" var to inputs array if you ...
    // ... need to be able to change that dynamically.
    [value] 
  );

  return debouncedValue;
}


const FileSystem = (props) => {

  const [folder, setFolder] = useState("");
  const [cursor, setCursor] = useState(false);
  const [treeFolder, setFolders] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(treeFolder);

  const [newKeyword, setNewKeyword] = useState("");
  const [newMarket, setNewMarket] = useState("");


  const [copymodal, setCopyModal] = useState(false);
  const toggleCopymodal = () => setCopyModal(!copymodal);

  const listS3Thumbs = async (marketFolder, continuationToken=false, list=[]) => {
    var params = {};
    if ( continuationToken ) {
      params.ContinuationToken = continuationToken;
    }
    let results = await Storage.list(marketFolder.toUpperCase().trim("/")+"/", params);
    list = [...list, ...results.list];
    if ( results.NextContinuationToken ) {
      return await listS3Thumbs(marketFolder, results.NextContinuationToken, list)
    }
    const treeFolder = processStorageList(list, marketFolder);
    setFolders(treeFolder);
    setData(treeFolder);
    return true;
  }


  function processStorageList(results, folder) {
    const filesystem = results.reduce((imageTree, image) => {
      let [market, keyword=false, imageIdx] = image.key.split("/");
      if ( keyword ) {
        imageTree[keyword] = imageTree[keyword] || {
          name: keyword,
          key: `${market.toUpperCase()}/${keyword}`,
          children: []
        };
        imageTree[keyword].children.push({
          name: imageIdx,
          image
        });
      }
      return imageTree
    }, {});
    // reduce imageName by first letter 
    // { a: [], b: [], c: []}
    let imageGrouped = Object.keys(filesystem).reduce((acc, imageName) => {
      // console.log(acc, imageName[0])
      acc[imageName[0]] = acc[imageName[0]] || [];
      acc[imageName[0]].push(filesystem[imageName]);
      return acc;
    }, {});

    const tree = {
      name: folder.toUpperCase(),
      key: `${folder}`,
      toggled: true,
      children: []
    };
    Object.keys(imageGrouped).forEach((alphabetLetter) => {
      tree.children.push({
        name: alphabetLetter,
        children: imageGrouped[alphabetLetter]
      });
    })
    return tree;
  }

  // const reload = async () => {
  //   setIsLoading(true);
  //   await listS3Thumbs(folder);
  //   setIsLoading(false);
  // }

  const loadFolder = async (newFolder) => {
    // If clicking the same flag - do nothing!
    if (folder === newFolder) return

    setCursor(false);
    setFolder(newFolder);
    setIsLoading(true);
    await listS3Thumbs(newFolder);
    setIsLoading(false);
  }


  const onToggle = (node, toggled) => {
    if (cursor) {
      cursor.active = false;
    }
    node.active = true;
    if (node.children) {
      node.toggled = toggled;
    }
    setCursor(node);
    // if (node.image) {
    //   window.open("https://static.blogger.co.uk/" + node.image.Key, "IMAGE PREVIEW");
    // }
    // setFolders({ ...treeFolder })
  }

   

  const [filter, setFilterValue] = useState("");
  // Now we call our hook, passing in the current searchTerm value.
  // The hook will only return the latest value (what we passed in) ...
  // ... if it's been more than 500ms since it was last called.
  // Otherwise, it will return the previous value of searchTerm.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchFilter = useDebounce(filter, 500);
  
  useEffect(() => {
    if (!isEmpty(data)) {
      if (!filter) {
        return setData(treeFolder);
      }
      let filtered = filters.filterTree(treeFolder, filter);
      filtered = filters.expandFilteredNodes(filtered, filter);
      setData(filtered);
      // console.log("LENGTH " + filtered.children.length, filtered);
      // if ( filtered.children.length < 10 ) {
      //   translate(folder, filter)
      // }
    }
  }, [debouncedSearchFilter])


  // const [suggestions, setSuggestions] = useState([]);
  // const searchInUk = async (keyword, continuationToken=false, list=[]) => {
  //   var params = {};
  //   if ( continuationToken ) {
  //     params.ContinuationToken = continuationToken;
  //   }
  //   let results = await Storage.list("UK/"+keyword, params);
  //   list = [...list, ...results.list];
  //   if ( results.NextContinuationToken ) {
  //     return await searchInUk("UK/"+keyword, results.NextContinuationToken, list)
  //   }
  //   console.log("searchInUk", list);
  //   let suggestionsMap = list.reduce((suggestions, s3path) => {
  //     let [market, suggestion, count] = s3path.key.split("/");
  //     suggestions[suggestion] = 1;
  //     return suggestions;
  //   }, {});
  //   let suggestions = Object.keys(suggestionsMap);
  //   console.log(suggestions);
  //   setSuggestions(suggestions);
  // }


  // const translate = async (market, keyword) => {
  //   let {TranslatedText=false} = await API.get('customAPI', '/translate', {
  //     queryStringParameters: {
  //       market,
  //       keyword
  //     }
  //   });
  //   if ( TranslatedText ) {
  //     console.log("SEARCH IN UK " + TranslatedText);
  //     searchInUk(TranslatedText);
  //   }
  // }

  
  const deleteImageClicked = async (node) => {
    setIsLoading(true);
    try {
      if ( node.children ) {
        for (let child of node.children ) {
          console.log("DELETE " + child.image.key);
          await Storage.remove(child.image.key);
        }
      } else if ( node.image ) {
        console.log("DELETE " + node.image.key);
        await Storage.remove(node.image.key);
      }
    } catch ( e ) {
      alert("ERROR DELETING CONTACT THE DEVs ... open console to take screen shot of error!");
      console.log(node);
      console.log(e);
    }
    setCursor(false);
    // refresh after delete!
    await listS3Thumbs(folder.toUpperCase());
    setIsLoading(false);
  }

  const copyImageClicked = async (copyCursor, toPath) => {
    const getBlob = async (fileUri) => {
      const resp = await fetch(fileUri);
      const imageBody = await resp.blob();
      return imageBody;
    };
    setIsLoading(true);
    toggleCopymodal();
    for ( let child of copyCursor.children ) {
      // console.log("COPY https://s3-eu-west-1.amazonaws.com/static.blogger.co.uk/thumbs/" + child.image.key);
      const blob = await getBlob(`https://s3-eu-west-1.amazonaws.com/static.blogger.co.uk/thumbs/${encodeURIComponent(child.image.key)}`);
      // console.log("COPY TO " + toPath + "/" + child.name);
      await Storage.put(`${toPath}/${child.name}`, blob, {
        contentType: blob.type,
        ACL: 'public-read',
        level: 'public',
        progressCallback(progress) {
          console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        }
      });
    }
    setCursor(false);
    console.log(toPath.split("/"));
    const [newFolder,] = toPath.split("/");
    setFolder(newFolder.toLowerCase());
    await listS3Thumbs(newFolder);
    setIsLoading(false);
  }

  return (
    <Container className="mt-2" fluid>
      <Row>
        <Col>
          <Card className="bg-secondary shadow">
            <CardHeader>
              <h3 className="mb-0">IMAGE EXPLORER</h3>
            </CardHeader>
            <CardBody>

              {/* FLAG CARDS */}
              <Row>
                <Col lg={12}>
                  <p><strong>Folders - By Country</strong></p>
                  <FlagListGroup markets={MARKETS} selectedMarkets={folder} onClick={loadFolder} isLoading={isLoading}/>
                </Col>
              </Row>

              {/* TREE HEADER */}
              <Row className="mt-5">
                <Col>
                  <Row className="align-items-center mb-2">
                    <Col xs={8}>
                      <div className="form-group mb-0 row">
                        <label htmlFor="search-image" className="col-auto col-form-label">Exploring images for <strong className="text-primary">{treeFolder.name}:</strong></label>
                        <div className="col">
                          <input
                            id="search-image"
                            value={filter}
                            className="form-control"
                            onChange={(e) => setFilterValue(e.target.value)}
                            placeholder="Search for folders or images ..."
                            type="search"
                            disabled={(isLoading || isEmpty(data))}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={4}>
                      <h4 className="border-bottom">SELECTED IMAGE:</h4>
                    </Col>
                  </Row>
                  {/** <Row className="align-items-center mb-2">
                      <Col xs={8}>
                        <div className="form-group mb-0 row">
                          <div className="col">
                             <ListGroup className="list-group-horizontal" style={{ flexWrap: "wrap"}}>
                              {[].map((sugg) => <ListGroupItem className="flex-fill">{sugg}</ListGroupItem>)}
                            </ListGroup>
                          </div>
                        </div>
                      </Col>
                    </Row>*/
                  }
                  <Spinner loading={isLoading} style={{ marginTop: "2.5rem" }}>
                    {/* TREE VIEW */}
                    <Row>
                      <Col xs={8}>
                        {!isEmpty(data) && <Treebeard data={data} onToggle={onToggle} style={TreeStyles} decorators={{ ...decorators, Header }} />}
                      </Col>
                      {/* IMG / NODE DETAILS */}
                      {!isEmpty(data) &&
                        <Col xs={4}>
                          {/* STICKY CONTAINER FOR THE IMAGE DETAILS INCASE YOU'RE FAR DOWN THE TREE */}
                          <div style={{ position: "sticky", top: "2rem" }}>
                            <Row className="align-items-center mb-3">
                              <Col className="pt-1">
                                <div><b className="text-primary">Name:</b> {cursor.name}</div>
                                <div><b className="text-primary">Key:</b> {cursor.image ? cursor.image.key : cursor.name} </div>
                              </Col>
                              <Col>
                                <div>{cursor && <i className="far fa-trash-alt img-tool-treebeard-trash-alt" onClick={() => deleteImageClicked(cursor)}>DELETE</i>}</div>
                                <div>{cursor.key && <i className="fas fa-copy img-tool-treebeard-trash-alt" onClick={toggleCopymodal}>COPY</i>}</div>
                              </Col>
                            </Row>
                            <div className="d-block p-1 h-100" style={{ position: "relative" }}>
                              {cursor.image && <img alt={cursor.image && cursor.image.key} src={`https://s3-eu-west-1.amazonaws.com/static.blogger.co.uk/thumbs/${cursor.image && cursor.image.key}?v=${new Date().getTime()}`} className="img-fluid" />}
                            </div>
                          </div>
                        </Col>
                      }
                    </Row>
                  </Spinner>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={copymodal} toggle={toggleCopymodal}>
        <ModalHeader toggle={toggleCopymodal}>Copy images "{cursor.key && cursor.key}" to new keyword</ModalHeader>
        {/*onClick={toggle}*/}
        <ModalBody>
          <Row>
            <Col>
              <FormGroup row>
                <Label
                  className="form-control-label"
                  htmlFor="input-newkeyword"
                  sm={2}
                >
                  New PATH
                </Label>
                <Col sm={{ size: 4 }}>
                  <Input type="select" onChange={(e) => setNewMarket(e.target.value)} value={newMarket}>
                    <option key="empty" value="">----</option>
                    {MARKETS.map((m,i) => <option key={`m-${i}`} value={m.value}>{m.label}</option>)}
                  </Input>
                </Col>
                <Col sm={{ size: 6 }}>
                  <Input
                    name="newkeyword"
                    className="form-control-alternative"
                    id="input-newkeyword"
                    placeholder={cursor.image ? cursor.image.key : cursor.name}
                    value={newKeyword}
                    type="text"
                    onChange={(e) => setNewKeyword(e.target.value)}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => {
            if ( newMarket.length > 0 && newKeyword.length > 0 ) {
              copyImageClicked(cursor, `${newMarket.toUpperCase()}/${newKeyword.toLowerCase()}`)
            } else {
              alert("PLEASE TELL ME A NEW MARKET AND A KEYWORD!");
            }
          }}>COPY</Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
}

export default FileSystem;
