import React, { useState, useEffect } from "react";
import ReactCardFlip from 'react-card-flip';

import Stats from "./stats";
import Table from "./table";

import { API } from 'aws-amplify';


const aggregateByDate = (sum, row) => {
  sum[row.date] = sum[row.date] || {
    revenue: 0,
    cost: 0,
    profit: 0
  };
  sum[row.date].revenue += row.revenue || 0;
  sum[row.date].cost += row.cost || 0;
  sum[row.date].profit = sum[row.date].revenue - sum[row.date].cost;
  return sum;
}

const FlipCard = (props) => {
  const [flipped, setFlipped] = useState(false);

  const [dataByDate,setDataByDate] = useState({});
  const [dataBySourceByDate,setDataBySourceByDate] = useState({});
  const [loading, isLoading] = useState(false);

  const loadData = async () => {
    isLoading(true);
    const res = await API.get('customAPI', '/dashboard', {
      queryStringParameters: {
        date: props.date,
        type: props.type
      }
    });
    let dataByDate = {};
    let dataBySourceByDate = {};
    for (let source of Object.keys(res[props.type])) {
      let sourceData = res[props.type][source];
      sourceData.reduce(aggregateByDate, dataByDate);
      dataBySourceByDate[source] = sourceData.reduce(aggregateByDate, {});
    };
    setDataByDate(dataByDate);
    setDataBySourceByDate(dataBySourceByDate);
    isLoading(false);
  }

  useEffect(() => {
    loadData();
  }, [props.date])


  const handleFlip = () => {
    setFlipped(prev => !prev);
    // setShowOverview(false);
  }

  return (
    <ReactCardFlip isFlipped={flipped}>
      {/* FRONT OF CARD */}
      <Stats title={props.title} 
        handleFlip={handleFlip} loading={loading} data={dataByDate} displayDate={props.date} currency={props.currency} />

      {/* BACK OF CARD */}
      {/* NOTE: Added flipped && to stop unneccesary renders of the "Back of card" */}
      {/* ...this is a tradeoff between unnecessary renders of the back vs render speed after clicking the flip btn */}
      {flipped && <Table title={props.title} 
        handleFlip={handleFlip} loading={loading} data={dataBySourceByDate} displayDate={props.date} currency={props.currency} />}
    </ReactCardFlip>
  )
}

export default FlipCard;
