import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import cx from "classnames";

import { Button } from "reactstrap";
import {
  Badge,
  Card,
  CardHeader,
  CardBody,
  Table,
  Container,
  Row,
  Col
} from "reactstrap";

import Spinner from "../../components/Spinner";
import UserRoutesModel from "./userRoutesModal.js";
import UserDealsModel from "./userDealsModal.js";
import CreateUserModal from "./newUserModal.js";
import RealTimeDashboardModal from "./realTimeDashboardModal"

const Users = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [userRoutesModalOpen, setUserRoutesModalOpen] = useState(false);
  const [userDealsModalOpen, setUserDealsModalOpen] = useState(false);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [user, setUser] = useState({attributes: {}});
  const [allRoutes, setRoutes] = useState([]);

  const selectUser = (user) => {
    let userAttributes = {};
    if (!user.attributes) {
      user.Attributes.forEach((attr) => {
        if ( attr.Name === "custom:routes") {
          userAttributes["routes"] = JSON.parse(Buffer.from(attr.Value, "base64").toString("utf8"));  
        } else if ( attr.Name === "custom:deals") {
          userAttributes["deals"] = JSON.parse(Buffer.from(attr.Value, "base64").toString("utf8"));  
          if ( userAttributes["deals"].length === 0 ) {
            userAttributes["deals"] = {};
          }
        } else if ( attr.Name === "custom:rt_dashboard_widgets") {
          userAttributes["rt_dashboard_widgets"] = JSON.parse(Buffer.from(attr.Value, "base64").toString("utf8"));  
        } else {
          userAttributes[attr.Name] = attr.Value;
        }
      });
      user.attributes = userAttributes;
    }
    // console.log(user);
    setUser(user);
  }

  const saveUser = async () => {
    setIsLoading(true);
    await API.put('customAPI', '/user', {
      body: {
        routes: user.attributes.routes,
        deals: user.attributes.deals || {},
        rt_dashboard_widgets: user.attributes.rt_dashboard_widgets || {},
        Username: user.Username
      }
    });
    loadUsers();
    setIsLoading(false);
  }

 	const loadUsers = async () => {
    setIsLoading(true);
    let response = await API.get('customAPI', '/users');
    setRoutes(response.userRouter)
    setUsers(response.users);
		setIsLoading(false);
  }

  const deleteUser = async (user) => {
  	alert("DELETE " + user.Username + " NOT IMPLEMENTED! Ask your beloved DEV!");
  	// setUsers(users => users.filter((u) => u.Username !== user.Username));
  }

  const clearAllRoutes = () => {
    setUser(user => {
      let newUser = {...user};
      newUser.attributes.routes = [];
      newUser.attributes.deals = {};
      return newUser;
    });
  }

  const activateRoute = async ( route ) => {
    console.log("ACTIVATE", route);
    let newUser = {...user};
    newUser.attributes.routes = newUser.attributes.routes || [];
    let routeIndex = newUser.attributes.routes.indexOf(route);
    if ( routeIndex === -1 ) {
      newUser.attributes.routes.push(route);  
    } else {
      newUser.attributes.routes.splice(routeIndex, 1);
      delete newUser.attributes.deals[route];

    }
    setUser(newUser);
    if ( newUser.attributes.routes.indexOf(route) !== -1 && route === "DASHBOARD:Dashboard Realtime" ) {
      toggleDashboardRealTimeModal();
    }
  }

  const [dashboardRealTimeModal, setDashboardRealTimeModal] = useState(false);
  const toggleDashboardRealTimeModal = () => {
    setDashboardRealTimeModal(!dashboardRealTimeModal);
  }

  const activateWidgetRealTime = async ( widget ) => {
    // console.log("ACTIVATE", widget, user);
    let newUser = {...user};
    if ( !Array.isArray(newUser.attributes.rt_dashboard_widgets) ) {
      newUser.attributes.rt_dashboard_widgets = [];
    }

    let widgetIndex = newUser.attributes.rt_dashboard_widgets.indexOf(widget);
    if ( widgetIndex === -1 ) {
      newUser.attributes.rt_dashboard_widgets.push(widget);  
    } else {
      newUser.attributes.rt_dashboard_widgets.splice(widgetIndex, 1);
      delete newUser.attributes.rt_dashboard_widgets[widget];
    }
    console.log(newUser.attributes);
    setUser(newUser);
  }



  const setupDeal = async ( route, dealInfo ) => {
    console.log("SETUP DEAL", route, dealInfo, user);
    let newUser = {...user}
    newUser.attributes.deals = user.attributes.deals || {};
    newUser.attributes.deals[route] = dealInfo;
    console.log({
      routes: newUser.attributes.routes,
      deals: newUser.attributes.deals,
      Username: newUser.Username
    });
    setIsLoading(true);
    await API.put('customAPI', '/user', {
      body: {
        routes: newUser.attributes.routes,
        deals: newUser.attributes.deals,
        Username: newUser.Username
      }
    });
    // loadUsers();
    setIsLoading(false);
  }

  const createUser = async (event) => {
    event.preventDefault();
    const form = event.target;
    const fd = new FormData(form);
    setIsLoading(true);
    const data = {};
    for (let key of fd.keys()) {
      data[key] = fd.get(key);
    }
  
    const newUser = await API.post('customAPI', '/user', {
      body: {...data}
    });
    
    if ( newUser && newUser.error ) {
      alert("ERROR: " + newUser.error);
    }
    loadUsers();
    setIsLoading(false);
    setCreateUserModal(false);
  }

  useEffect(() => {
  	loadUsers()
  }, [])

  return (
    <Container className="mt-2" fluid>
	    <Card className="shadow">
        <CardHeader className="border-0">
          <h3 className="mb-0">User List</h3>
          <div className="float-right mt--4">
            <Button size="sm"  color="success" onClick={() => setCreateUserModal(true)}>ADD USER</Button>
          </div>
        </CardHeader>
        <CardBody>
          <Spinner isLoading={isLoading}>
          <Row>
            <Col>
            <Table className="align-items-center table-flush" responsive>
              {/*<thead className="thead-light">
                <tr>
                  <th scope="col">Username</th>
                  <th scope="col" />
                  <th scope="col" />
                </tr>
              </thead>*/}
              <tbody>
            		{users.map((user, idx) => {
            			return <tr key={idx}>
                    <td>
                      <Badge color="" className="badge-dot mr-4">
                        <i className={cx("border-0", user.Enabled ? "bg-green" : "bg-red")} />
                        {user.Username}
                      </Badge>
                    </td>
                    <td>
                      <Button size="sm" onClick={e => { localStorage.setItem('X-loginAs', user.Username); alert("REFRESH!");}} color="muted">LOGIN ME</Button></td>
                    <td>
                      <Button size="sm" 
                        onClick={e => {selectUser(user);setUserRoutesModalOpen(true);}} 
                        color="info">ROUTES</Button>
                    </td>
                    <td>
                      <Button size="sm" onClick={e => { selectUser(user);setUserDealsModalOpen(true);}} 
                        color="light">DEALS</Button>
                    </td>
                    <td onClick={e => {e.preventDefault(); deleteUser(user);}}>
                      <Button size="sm" onClick={e => deleteUser(user)} color="danger">DELETE</Button>
                    </td>
                  </tr>
                })}
              </tbody>
            </Table>
            </Col>
          </Row>
          </Spinner>
        </CardBody>
      </Card>

      <CreateUserModal isOpen={createUserModal} toggle={() => setCreateUserModal(!createUserModal)} createUser={createUser} isLoading={isLoading} />

      <UserRoutesModel user={user} allRoutes={allRoutes} 
        isOpen={userRoutesModalOpen} close={setUserRoutesModalOpen} 
        save={saveUser} activateRoute={activateRoute} clearAllRoutes={clearAllRoutes} toggleDashboardRealTimeModal={toggleDashboardRealTimeModal} />

      <UserDealsModel user={user} allRoutes={allRoutes}
        isOpen={userDealsModalOpen} close={setUserDealsModalOpen}
        setupDeal={setupDeal} />


      <RealTimeDashboardModal user={user} isOpen={dashboardRealTimeModal} toggleModal={toggleDashboardRealTimeModal} activateWidgetRealTime={activateWidgetRealTime}/>
    
    </Container>
  );
}

export default Users;
