import React, {useState} from "react";

import {
  Card,
  CardBody,
  Row,
  Col,
  Modal, ModalHeader, ModalBody,
  ListGroup, ListGroupItem
} from "reactstrap";

import UserDealsSetupModal from "./userDealsSetupModal";

const UserDealsModel = (props) => {
	const [selectedRoute, selectRoute] = useState(false);
	const [deal, setDeal] = useState({});

 	let activeRoutes = props.user.attributes.routes 
	          		? props.user.attributes.routes : [];

	let allRoutesFlattened = props.allRoutes.reduce((acc, route) => {
		if ( route.block ) {
			let {block, table, name} = route;
	    const ROUTE_KEY = `${block}:${table||name}` ;
	    acc[ROUTE_KEY] = route;
		}
		if (route.children) { 
    	route.children.forEach((child) => {
    		let {block, table, name} = child;
		    const ROUTE_KEY = `${block}:${table||name}` ;
		    acc[ROUTE_KEY] = child;
    	});
		}
		return acc;
	}, {});
	
	let halfActiveRoutes = activeRoutes.length/2;
	let columns = [activeRoutes];
	if ( halfActiveRoutes > 1 ) {
		columns = [activeRoutes.slice(0, halfActiveRoutes), activeRoutes.slice(halfActiveRoutes)];
	}

  return (
    <Modal isOpen={props.isOpen} toggle={() => props.close(false)} size="lg" className="full-screen">
	    <ModalHeader className="pb-0" toggle={() => props.close(false)}>
	    	USER DEALS
	    </ModalHeader>
	    <ModalBody>
	    	<Row>
					<Col className="order-xl-1" xl="12">
		        <Card className="bg-secondary shadow pt-0">
		          <CardBody>
		          	<Row>
				          {columns.map((activeRoutes, kkk) => {
				          	return <Col xl={6} key={kkk}>
				    	     		<ListGroup>
				    	     		{activeRoutes.map((activeRoute, key) => {
				    	     			let route = allRoutesFlattened[activeRoute] || false;
				          			if ( route ) {
						          		return <ListGroupItem tag="button" key={key} 
						          			active={selectedRoute===activeRoute}
							          		onClick={e => {
						          				if ( props.user.attributes.deals && props.user.attributes.deals[activeRoute] ) {
														 		setDeal(props.user.attributes.deals[activeRoute]);
															} else {
																setDeal({});
															}
					            	  		selectRoute(activeRoute);
					            	  	}}>{route.block} -> {route.name}</ListGroupItem>
								        }
								        return null;
							        })}
							       	</ListGroup>
							      </Col>
			          	})}
		          	</Row>
		          	<UserDealsSetupModal isOpen={selectedRoute!==false} allRoutes={allRoutesFlattened}
		          		selectedRoute={selectedRoute} close={() => selectRoute(false)} 
		          		deal={deal} editDeal={setDeal}
									setupDeal={props.setupDeal}
		          	/>
		          </CardBody>
		        </Card>
		      </Col>
		    </Row>
	    </ModalBody>
    </Modal>
  );
}

export default UserDealsModel;