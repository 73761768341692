import React, { useState, useEffect } from 'react';

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col, ButtonGroup
} from "reactstrap";

import Spinner from "../Spinner";
import BreakdownDimensionsModal from './BreakdownDimensionsModal';
import SchedulerModal from './SchedulerModal';
import SaveFilterModal from './SaveFilterModal';
import ShareFilterModal from './ShareFilterModal';

import { API } from "aws-amplify";

const filterBtnStyle = {
  display: "flex",
  margin: "0 auto",
  cursor: "pointer"
}

// const DatePickerInput = ({ value, onClick, onChange }) => (
// 	<input type="text" className="form-control form-control-alternative" value={value} onClick={onClick} onChange={onChange} />
// );
 
const TableFilterHeader = (props) => {
  const [breakdownDimnesionsModal, setBreakdownDimnesionsModal] = useState(false);
  const [activeBreakdownDimensionsTab, setActiveBreakdownDimensionsTab] = useState('breakdown');
  const [schedulerModal, setSchedulerModal] = useState(false);
  const [saveFilterModal, setSaveFilterModal] = useState(false);
  const [shareFilterModal, setShareFilterModal] = useState(false);


  const toggleBreakdownDimnesionsModal = (tab) => {
    setActiveBreakdownDimensionsTab(tab);
    setBreakdownDimnesionsModal(!breakdownDimnesionsModal);
  };

  const [selectedPreset, setSelectedPreset] = useState(false);
  const [userPresets, setUserPresets] = useState({});
  const [shareID, setReportShareID] = useState(false);
  const onShareFilter = async () => {
    const {shareID} = await API.post('customAPI', '/report-share', {
      body: {
        reportType: props.reportType,
        name: selectedPreset,
        preset: userPresets[selectedPreset],
        path: window.location.pathname
      }
    });
    setReportShareID(shareID);
  }
  const deletePreselect = () => {
    const res = window.confirm(`Do you want to delete ${selectedPreset}`);
    if ( res ) {
      let userPresetsN = {};
      try {
        userPresetsN = localStorage.getItem("userPresets") || "{}";
        userPresetsN = JSON.parse(userPresetsN);
      } catch (e) { 
        userPresetsN = {}
      }
      delete userPresetsN[props.reportType][selectedPreset]
      localStorage.setItem("userPresets", JSON.stringify(userPresetsN));
      setUserPresets(userPresetsN[props.reportType]);
      setSelectedPreset(false);
    }
  }

  const onSaveFilters = (presetname) => {
    let userPresetsN = {};
    try {
      userPresetsN = localStorage.getItem("userPresets") || "{}";
      userPresetsN = JSON.parse(userPresetsN);
    } catch (e) { 
      userPresetsN = {}
    }
    userPresetsN[props.reportType] = userPresetsN[props.reportType] || {};
    userPresetsN[props.reportType][presetname] = {
      breakdown: props.breakdown, 
      dimensions: props.dimensions, 
      filters: props.filters
    };
    localStorage.setItem("userPresets", JSON.stringify(userPresetsN));
    setUserPresets(userPresetsN);
    setSaveFilterModal(false);
  }

  useEffect(() => {
    let userPresetsN = {};
    try {
      userPresetsN = localStorage.getItem("userPresets") || "{}";
      userPresetsN = JSON.parse(userPresetsN);
      userPresetsN = userPresetsN[props.reportType] || {};
    } catch (e) {
      userPresetsN = {}
    }
    if ( Object.keys(userPresets).length === 0 && userPresets.constructor === Object ) {
      setUserPresets(userPresetsN);  
    }
    
  }, [userPresets]);


  let activeFilter = props.filters.reduce((acc, filter) => {
    let values = filter.values.filter((c) => c.isChecked).map(c => c.value);
    if ( values.length ) {
      acc[filter.selector] = {
        name: filter.name,
        values: values
      };
    }
    return acc;
  }, {});
  
  return (
    <>
      <div className="mt-2">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <Col className="order-xl-1" xl="12">
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0">
                    <Row className="align-items-center">
                      <Col xs="3">
                        <h3 className="mb-0">{props.reportname}</h3>
                      </Col>
                      <Col xs="6">
                        <FormGroup row className="mb-0">
                          <Col sm={2}>
                            <Button className="btn-link" size="sm" onClick={() => setSaveFilterModal(true)}>(save report)</Button>
                          </Col>
                          <Col sm={8}>
                            {Object.keys(userPresets).length > 0 &&
                              <Input type="select" bsSize="sm" name="select" onChange={(e) => {
                                setSelectedPreset(e.target.value);
                                props.selectPreset(userPresets[e.target.value]);
                              }}>
                              <option key="--empty--" value={false}>Saved reports:</option>
                              {Object.keys(userPresets).map((name) => {
                                return <option key={name} value={name}>{name}</option>
                              })}
                              </Input>
                            }
                          </Col>
                          {selectedPreset && <Col sm={2}>
                            <ButtonGroup size="sm">
                              <Button color="secondary" className="btn-icon" size="sm" onClick={() => setShareFilterModal(true)}>
                                <i className="fas fa-share"></i>
                              </Button>
                              <Button color="danger" className="btn-icon" size="sm" onClick={() => deletePreselect()}>
                                <i className="far fa-trash-alt"></i>
                              </Button>
                            </ButtonGroup>
                          </Col>}
                        </FormGroup>
                      </Col>
                      {props.hideScheduler ? null :
                        <Col className="text-right" xs="3">
                          <Spinner color="warning" className={"no-gutters justify-content-end"} loading={props.isRerunning} style={{height: "25px"}}>
                            <Button className="btn btn-icon btn-warning" type="button" 
                                style={{paddingTop:0, paddingLeft: 5, paddingBottom:0, paddingRight:5}}
                                onClick={e => { e.preventDefault(); setSchedulerModal(true); }}>
                                <i className="fas fa-sync"></i>
                            </Button>
                          </Spinner>
                        </Col>
                      }
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <div>
                        <Row>
                          <Col lg="2">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="input-username">
                                Start Date:
                              </label>
                              <DatePicker
                                selected={props.startDate}
                                onChange={date => props.setStartDate(date)}
                                dateFormat="yyyy-MM-dd"
                                calendarClassName="datepickerCustomStyle"
                                maxDate={props.maxDate} className="form-control"
                              />
                            </FormGroup>
                          </Col>
                          <Col lg="2">
                            <FormGroup>
                              <label className="form-control-label" htmlFor="input-username">
                                End Date:
                            </label>
                              <DatePicker selected={props.endDate} onChange={date => props.setEndDate(date)} dateFormat="yyyy-MM-dd" calendarClassName="datepickerCustomStyle"
                                maxDate={props.maxDate} className="form-control" />
                            </FormGroup>
                          </Col>
                          <Col lg="5" xl="4">
                            <FormGroup row>
                              <Col className="text-center">
                                <label className="form-control-label">
                                  Breakdown
                                </label>
                                <div style={{ ...filterBtnStyle, backgroundColor: "#F2C94C" }} className="icon icon-shape bg-yellow text-white rounded-circle shadow" onClick={() => toggleBreakdownDimnesionsModal('breakdown')}>
                                  <i className="ni ni-bullet-list-67" />
                                </div>
                              </Col>

                              <Col className="text-center">
                                <label className="form-control-label">
                                  Filters
                                </label>
                                <div style={{ ...filterBtnStyle, backgroundColor: "#EB5757" }} className="icon icon-shape bg-danger text-white rounded-circle shadow" onClick={() => toggleBreakdownDimnesionsModal('filters')}>
                                  <i className="fas fa-filter"></i>
                                </div>
                              </Col>

                              <Col className="text-center">
                                <label className="form-control-label">
                                  Dimensions
                                </label>
                                <div style={{ ...filterBtnStyle, backgroundColor: "#2F80ED" }} className="icon icon-shape bg-icon text-white rounded-circle shadow" onClick={() => toggleBreakdownDimnesionsModal('dimensions')}>
                                  <i className="ni ni-ruler-pencil" />
                                </div>
                              </Col>

                            </FormGroup>
                          </Col>

                          <Col lg={{ size: 3 }} className="ml-auto">
                            <FormGroup className="float-right">
                              <label className="form-control-label" style={{ display: "block" }}>
                                Actions:
                                </label>
                              <Spinner loading={props.isLoading} style={{ width: "160px" }}>
                                <Button color="primary" onClick={props.onSubmit}>Run</Button>
                                <Button outline color="primary" onClick={props.onClear}>Clear</Button>
                              </Spinner>
                            </FormGroup>
                          </Col>
                        </Row>
                        {Object.keys(activeFilter).length > 0 &&
                          <Row>
                            <Col>
                              <details open>
                                <summary style={{fontSize: "0.9rem"}}>Active Filters</summary>
                                {/*<ul title="Breakdown:" className="taglist bold-title">
                                  {props.breakdown.map((breakdown, i) => breakdown.checked && <li key={breakdown.selector + i}>{breakdown.name}</li>)}
                                </ul>*/}
                                <ul className="filter bold-title">
                                {Object.keys(activeFilter).map((selector, i) => {
                                  let values = activeFilter[selector].values;
                                  let filterName = activeFilter[selector].name;
                                  if (values.length) {
                                    return <li key={selector}>
                                      <ul title={`${filterName}:`} className="taglist bold-title">
                                        {values.map((v, i) => (
                                          <li key={`${selector}_${i}`}>
                                            {v}
                                            <Button style={{fontSize: "0.6rem", border: "0px"}} size="sm" className="mt--3 p-0" outline color="warning" onClick={(e) => props.onSelectFilterValue(selector, v)}>
                                              <i className="fas fa-times" />
                                            </Button>
                                          </li>))}
                                      </ul>
                                    </li>
                                  } else {
                                    return null;
                                  }
                                })}
                                </ul>
                                {/*<ul title="Dimensions:" className="taglist bold-title">
                                  {props.dimensions.map((dimension, i) => dimension.checked &&
                                    <li key={dimension.selector + i}>{dimension.name}</li>
                                  )}
                                </ul>*/}
                              </details>
                            </Col>
                          </Row>
                        }
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>


      <ShareFilterModal isOpen={shareFilterModal} close={setShareFilterModal} filters={props.filters} breakdown={props.breakdown} 
         dimensions={props.dimensions} onShareFilter={onShareFilter} selectedPreset={selectedPreset} reportShareID={shareID}/>

      <SaveFilterModal isOpen={saveFilterModal} close={setSaveFilterModal} filters={props.filters} breakdown={props.breakdown} 
         dimensions={props.dimensions} onSaveFilters={onSaveFilters} />
      <SchedulerModal isOpen={schedulerModal} close={setSchedulerModal} reportname={props.reportname} 
        scheduleRerun={(rerunDate) => {props.scheduleRerun(rerunDate); setSchedulerModal(false);}} />

      <BreakdownDimensionsModal isOpen={breakdownDimnesionsModal} close={setBreakdownDimnesionsModal}
        toggle={setActiveBreakdownDimensionsTab} openTab={activeBreakdownDimensionsTab}
        filters={props.filters}
        onToggleFilter={props.toggleFilter}
        onSelectFilterValue={props.onSelectFilterValue}
        breakdown={props.breakdown}
        onToggleBreakdown={props.toggleBreakdown}
        dimensions={props.dimensions}
        onToggleDimension={props.toggleDimension}
        isFiltersLoading={props.isFiltersLoading}
        resetFilters={props.resetFilters}
        filterDateOpt={props.filterDateOpt}
        onChangeFilterDateOpt={props.onChangeFilterDateOpt}
      />
    </>
  );
}

export default TableFilterHeader;

