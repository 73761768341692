import React, { useState, useEffect } from "react";
import { API } from 'aws-amplify';
import Spinner from "../../../components/Spinner";

import { Field, Form, Formik } from "formik";
import { ReactstrapSelect, ReactstrapInput, ReactstrapRadio } from "reactstrap-formik";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Col
} from "reactstrap";


import FlagListGroup from "../../../components/flagListGroup.js";


const allowedMarket = {
  clickso: ["fr", "uk", "de"],
  medianet: ["ca", "uk", "de", "us", "fr", "at", "br", "no", "dk", "es", "fi", "it", "se", "nl"],
  bing2click: ["us", "uk", "de", "it", "fr", "nl", "se", "dk", "no"],
  ymyahoo_simply: ["us", "uk", "dk", "no", "nl", "se", "de", "fr", "it"],
  techads: ["it", "fr", "es", "de", "uk", "us"],//"ch", "at"
  techads_bing_helpwire: ["it", "fr", "es", "de", "uk", "us", "mx", "be", "ie", "ar", "cl", "co", "pe", "ve"], //"ch", "at", 
  techads_bing_expertsnow: ["uk", "fr", "de", "it", "es", "mx", "ca", "us", "au", "ie", "in", "be", "dz", "sn", "tn", "at", "ch", "bo", "mx", "nz", "ar", "cl", "co", "pe", "ve", "ec"],
  yahooAdp: ["ar", "ch", "at", "it", "fr", "de", "uk", "us", "es", "in", "br", "us", "ca", "mx", "no", "nl", "dk", "se", "fi", "sg", "cl", "co", "pe", "ve", "hk", "id", "ph", "tw", "th", "vn"],
  mgid_perion: ["us", "ca", "de", "fr", "uk"],
  techads_iacbing2: ["uk", "fr", "de", "it", "es", "mx", "ca", "us", "au", "ie", "in", "hk", "nl", "se", "dk", "br", "mx", "ch", "at", "no"]
}


const allMarkets = [
  { value: "ar", flag: "ar", label: "Argentina"},
  { value: "at", flag: "at", label: "Austria" },
  { value: "au", flag: "au", label: "Australia" },
  { value: "be", flag: "be", label: "Belgium" },
  { value: "bo", flag: "bo", label: "Bolivia" },
  { value: "br", flag: "br", label: "Brazil" },
  { value: "ca", flag: "ca", label: "Canada" },
  { value: "ch", flag: "ch", label: "Switzerland" },
  { value: "cl", flag: "cl", label: "Chile"},
  { value: "co", flag: "co", label: "Colombia"},
  { value: "de", flag: "de", label: "Germany" },
  { value: "dz", flag: "dz", label: "Algeria" },
  { value: "ec", flag: "ec", label: "Ecuador" },
  { value: "dk", flag: "dk", label: "Danmark" },
  { value: "es", flag: "es", label: "Spain" },
  { value: "fi", flag: "fi", label: "Finland" },
  { value: "fr", flag: "fr", label: "France" },
  { value: "hk", flag: "hk", label: "Hong Kong"},
  { value: "ie", flag: "ie", label: "Ireland"},
  { value: "in", flag: "in", label: "India" },
  { value: "id", flag: "id", label: "Indonesia"},
  { value: "it", flag: "it", label: "Italy" },
  // { value: "my", flag: "my", label: "Malaysia"},
  { value: "mx", flag: "mx", label: "Mexico" },
  { value: "nl", flag: "nl", label: "Netherlands" },
  { value: "no", flag: "no", label: "Norway" },
  { value: "nz", flag: "nz", label: "New Zealand" },
  { value: "pe", flag: "pe", label: "Peru"},
  { value: "ph", flag: "ph", label: "Philippines"},
  { value: "se", flag: "se", label: "Sweden" },
  { value: "sn", flag: "sn", label: "Senegal" },
  { value: "sg", flag: "sg", label: "Singapore"},
  { value: "tw", flag: "tw", label: "Taiwan" },
  { value: "th", flag: "th", label: "Thailand" },
  { value: "tn", flag: "tn", label: "Tunisia" },
  { value: "uk", flag: "gb", label: "United Kingdom" },
  { value: "us", flag: "us", label: "United States" },
  { value: "ve", flag: "ve", label: "Venezuela"},
  { value: "vn", flag: "vn", label: "Vietnam"}  
];


const adTemplateMapping = {
  "ar": "mx",
  "at": "at",
  "au": "au",
  "be": "fr",
  "bo": "mx",
  "br": "br",
  "ca": "us",
  "ch": "ch",
  "cl": "mx",
  "co": "mx",
  "de": "de",
  "dz": "fr",
  "ec": "mx",
  "dk": "dk",
  "es": "es",
  "fi": "fi",
  "fr": "fr",
  "ie": "uk",
  "in": "in",
  "it": "it",
  "mx": "mx",
  "nl": "nl",
  "no": "no",
  "nz": "au",
  "pe": "mx",
  "se": "se",
  "sn": "fr",
  "tn": "fr",
  "uk": "uk",
  "us": "us",
  "ve": "mx",
  "sg": "uk",
  "hk": "uk", 
  "id": "uk", 
  "my": "uk", 
  "ph": "uk", 
  "tw": "uk", 
  "th": "uk", 
  "vn": "uk"
};

const FacebookAutomation = (props) => {


  const [MARKETS, setMARKETS] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [cmsProperties, setCmsProperties] = useState({})
  const [fbPixels, setFbPixels] = useState([]);
  const [fbAccounts, setFbAccounts] = useState([]);
  const [marketList, setMarketList] = useState([]);
  const [keywordList, setKeywordList] = useState({});
  const [adtemplateLang, setAdTemplateLang] = useState({});
  const [mapping, setMapping] = useState([]);
  const [facebookFanPages, setFacebookPagesMap] = useState({});
  const [mappedFanPages, setMappedFanPages] = useState([]);

  // useEffect(() => {
  //   if ( mappedFanPages.length ) {
  //     console.log("LOAD MAPPED FAN PAGES LIMIT", mappedFanPages);
  //     console.log(mapping);
  //   }
  // }, [mappedFanPages]);


  const loadSettings = async () => {
    setIsLoading(true);

    let {landingPages} = await API.get('customAPI', '/facebookAccountMapper/cms');
    setCmsProperties(landingPages);

    let {mapping} = await API.get('customAPI', '/facebookAccountMapper/mapping', {
      queryStringParameters: {
        groupby: "accountID",
        bulkupload: true
      }
    });
    setMapping(mapping);

    setFbAccounts(Object.keys(mapping).map((account_id) => {
      return {id: account_id, name: mapping[account_id].accountName};
    }));

    let facebookPages = await API.get('customAPI', '/fb_fanpage/list');
    const mapFacebookPages = facebookPages.reduce((acc, page) => {
      acc[page.id] = page;
      return acc;
    }, {});
    setFacebookPagesMap(mapFacebookPages);

    let {pixels} = await API.get('customAPI', '/facebookAccountMapper/pixel');
    setFbPixels(pixels);

    setIsLoading(false);
  }


  const toggleMarket = (newMarket) => {
    let index = marketList.indexOf(newMarket);
    if (index === -1) {
      setMarketList([...marketList, newMarket])
      setAdTemplateLang((prev) => {
        prev[newMarket] = adTemplateMapping[newMarket];
        return { ...prev };
      });
    } else {
      marketList.splice(index, 1);
      setMarketList([...marketList]);
      setAdTemplateLang((prev) => {
        delete prev[newMarket];
        return { ...prev };
      });
    }
    
  }

  useEffect(() => {
    loadSettings();
  }, []);

  const [downloadUrl,setDownloadurl] = useState(null);
  const [downloadModal, setDownloadModal] = useState(false)
  const [logs, setLogs] = useState([]);
  const makeMoneyBaby = async (values, { setSubmitting }) => {
    try {
      const {downloadUrl, logs}  = await API.post('customAPI', '/keyword-tools/facebookAutomation', {
        body: {
          marketList,
          keywordList,
          adtemplateLang,
          fbConfig: {...values}
        }
      });
      console.log(downloadUrl);
      console.log(logs);
     
      setLogs(logs);
      setDownloadurl(downloadUrl);  
      setDownloadModal(true);

    } catch ( e ) {
      alert(e.message);
    }
    setSubmitting(false);
  }

  const loadFanPagesLimit = async (fbAccountID, businessManagerID, mappedFanPages) => {
    const fanPageLimits = await API.get("customAPI", "/fb_fanpage/limits", {
      queryStringParameters: {
        fbAccountID, businessManagerID, pageIDList: mappedFanPages.map((p) => p.id)
      }
    })
    for ( let page of mappedFanPages ) {
      page.name += " - ads running: " + fanPageLimits[page.id].ads_running_or_in_review_count;
    }
    setMappedFanPages(mappedFanPages);
  }

  return (
    <Container className="mt-2" fluid>
      <Card className="bg-secondary shadow">
        <CardHeader className="border-0 bg-green">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">BULK UPLOAD</h3>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <Col>
              <Spinner isLoading={isLoading}>
              <Formik
                initialValues={{ domain: '', utm_source: '', device: '', utm_campaign: '', fb_page: '', fbpage_ig_account: '', fb_account: '', fb_pixel: '', daily_budget: 30, 
                  bid_cap: 0, bid_strategy: 'Lowest Cost', gender: '', cta: '', dynamic_ad: true, shutterstock: true, optimized_event: "LEAD", number_of_creatives: 1, async_execution: false, adset_starttime: "ASAP" }}
                onSubmit={makeMoneyBaby}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue
                  /* and other goodies */
                }) => (
                  <Form onSubmit={handleSubmit}>
                    {/* Facebook */}
                    <h6 className="heading-small text-muted mb-4">Facebook information</h6>
                    <Row form>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="Domain"
                            name="domain"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "domain",
                              id: "domain",
                              options: Object.keys(cmsProperties),
                              defaultOption: "-- Domain --"
                            }}
                            onChange={(e) => {
                              const selectDomain = e.target.value;
                              let filteredAccount = Object.keys(mapping).reduce((acc, accountID) => {
                                if ( mapping[accountID].domain === selectDomain ) {
                                  acc.push({id: accountID, name: mapping[accountID].accountName})
                                }
                                return acc;
                              }, [])
                              setFbAccounts(filteredAccount);
                              handleChange(e);
                            }}
                          />
                          {errors.domain && touched.domain && errors.domain}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="FB Account"
                            name="fb_account"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "fb_account",
                              id: "fb_account",
                              options: fbAccounts,
                              defaultOption: "-- FB ACCOUNT --"
                            }}
                            onChange={(e) => {
                              // console.log(e.target.value);
                              const fbAccountID = e.target.value;
                              let mappedFanPages = [];
                              const fbPagesId = JSON.parse(mapping[fbAccountID].fanPages);
                              
                              if ( fbPagesId.length === 0 || fbPagesId[0]  === "" ) {
                                mappedFanPages = Object.keys(facebookFanPages).map((pageID) => {
                                  return {id: pageID, name: facebookFanPages[pageID].name}
                                });
                              } else {
                                mappedFanPages = fbPagesId.map((fanPageID) => { 
                                  return {id: fanPageID, name: facebookFanPages[fanPageID] ? facebookFanPages[fanPageID].name : ""}
                                });   
                              }
                              loadFanPagesLimit(fbAccountID, mapping[fbAccountID].businessManagerID, mappedFanPages);
                              
                              const assignedPixel = mapping[fbAccountID].pixel;                   
                              setFieldValue("fb_pixel", assignedPixel);

                              const domain = mapping[fbAccountID].domain;                   
                              setFieldValue("domain", domain);

                              const utm_source = mapping[fbAccountID].utm_source;                   
                              setFieldValue("utm_source", utm_source);                              

                              const {site_id=false, domainUrl=false } = cmsProperties[domain] 
                                && cmsProperties[domain][utm_source] ? cmsProperties[domain][utm_source] : {};
                              if ( site_id )
                                setFieldValue("site_id", site_id);
                              if ( domainUrl)
                                setFieldValue("domainUrl", domainUrl);
                              // if ( device )
                              //   setFieldValue("device", device);

                              const utm_campaign = mapping[fbAccountID].utm_campaign;                   
                              setFieldValue("utm_campaign", utm_campaign);                             

                              const activity = mapping[fbAccountID].activity;
                              let newMARKETS = [...allMarkets];
                              if ( allowedMarket[activity] ) {
                                newMARKETS = newMARKETS.filter((m) => allowedMarket[activity].indexOf(m.value) !== -1);
                              }
                              if ( activity === "techads_bing" && domain === "ExpertsNow") {
                                newMARKETS = newMARKETS.filter((m) => allowedMarket["techads_bing_expertsnow"].indexOf(m.value) !== -1);
                              }
                              if ( activity === "techads_bing" && domain === "Helpwire") {
                                newMARKETS = newMARKETS.filter((m) => allowedMarket["techads_bing_helpwire"].indexOf(m.value) !== -1);
                              }
                              
                              setMARKETS(newMARKETS);
                              setMarketList([]);
                              handleChange(e);
                            }}
                          />
                          {errors.fb_account && touched.fb_account && errors.fb_account}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="Fan Page"
                            name="fb_page"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "fb_page",
                              id: "fb_page",
                              options: mappedFanPages,
                              defaultOption: "-- FB PAGE --"
                            }}
                            onChange={(e) => {
                              const selectedFanPage = e.target.value;
                              const FBPage = facebookFanPages[selectedFanPage];
                              if ( FBPage && FBPage.igAccount ) {
                                setFieldValue("fbpage_ig_account", FBPage.igAccount);
                              }
                              handleChange(e)
                            }}
                          />
                          {errors.fb_page && touched.fb_page && errors.fb_page}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="FB Pixel"
                            name="fb_pixel"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "fb_pixel",
                              id: "fb_pixel",
                              options: fbPixels,
                              defaultOption: "-- FB PIXEL --"
                            }}
                          />
                          {errors.fb_pixel && touched.fb_pixel && errors.fb_pixel}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="UTM Source"
                            name="utm_source"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "utm_source",
                              id: "utm_source",
                              options: Object.keys(cmsProperties[values.domain] || {}),
                              defaultOption: "-- UTM Source --"
                            }}
                            onChange={(e) => {
                              const {site_id=false, domainUrl=false} = cmsProperties[values.domain] && cmsProperties[values.domain][e.target.value];
                              // if ( pixel_id ) {
                              //   setFieldValue("fb_pixel", pixel_id);
                              // }
                              setFieldValue("site_id", site_id);
                              setFieldValue("domainUrl", domainUrl);
                              handleChange(e)
                            }}
                          />
                          {errors.utm_source && touched.utm_source && errors.utm_source}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="UTM Campaign"
                            name="utm_campaign"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "utm_campaign",
                              id: "utm_campaign",
                              options: UTM_CAMPAIGN,
                              defaultOption: "-- UTM Campaign --"
                            }}
                          />
                          {errors.utm_campaign && touched.utm_campaign && errors.utm_campaign}
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr className="my-4" />
                    {/* Description */}
                    <h6 className="heading-small text-muted mb-4">CAMPAIGN SETUP</h6>
                    <Row form>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="Campaign Daily Budget"
                            name="daily_budget"
                            type="number"
                            component={ReactstrapInput}
                          />
                          {errors.daily_budget && touched.daily_budget && errors.daily_budget}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="Optimized Event"
                            name="optimized_event"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "optimized_event",
                              id: "optimized_event",
                              options: OPTIMIZED_EVENTS,
                              defaultOption: "-- Event --"
                            }}
                          />
                          {errors.optimized_event && touched.optimized_event && errors.optimized_event}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="Campaign Bid Strategy"
                            name="bid_strategy"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "bid_strategy",
                              id: "bid_strategy",
                              options: ['Lowest Cost With Bid Cap', 'Lowest Cost'],
                              defaultOption: "-- Bid Strategy --"
                            }}
                          />
                          {errors.bid_strategy && touched.bid_strategy && errors.bid_strategy}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            disabled={values.bid_strategy !== "Lowest Cost With Bid Cap"}
                            label="Bid Cap Amount"
                            name="bid_cap"
                            type="number"
                            component={ReactstrapInput}
                          />
                          {errors.bid_cap && touched.bid_cap && errors.bid_cap}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="Gender Audience"
                            name="gender"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "gender",
                              id: "gender",
                              options: GENDER_AUDIENCE,
                              defaultOption: "-- ALL --"
                            }}
                          />
                          {errors.gender && touched.gender && errors.gender}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="Device"
                            name="device"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "device",
                              id: "device",
                              options: DEVICE_TARGETS,
                              defaultOption: "-- ALL --"
                            }}
                          />
                          {errors.device && touched.device && errors.device}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="Start Time (MM/DD/YY HH:MM)"
                            name="adset_starttime"
                            type="date_time"
                            component={ReactstrapInput}
                          />
                          {errors.adset_starttime && touched.adset_starttime && errors.adset_starttime}
                        </FormGroup>
                      </Col>
                    </Row>
                    <hr className="my-4" />
                    <h6 className="heading-small text-muted mb-4">CREATIVE SETUP</h6>
                    <Row form>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="Call to action"
                            name="cta"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "cta",
                              id: "cta",
                              options: CTA,
                              defaultOption: "-- CTA --"
                            }}
                          />
                          {errors.cta && touched.cta && errors.cta}
                        </FormGroup>
                      </Col>
                      <Col md={{ size: 2, offset: 2 }}>
                        <legend className="col-form-label">Dynamic Ad</legend>
                        <FormGroup>
                          <Field
                            name="dynamic_ad"
                            component={ReactstrapRadio}
                            value={true}
                            type="radio"
                            label="True"
                          />
                          <Field
                            name="dynamic_ad"
                            component={ReactstrapRadio}
                            value={false}
                            type="radio"
                            label="False"
                          />
                        </FormGroup>
                      </Col>
                      {!values.dynamic_ad && 
                        <><Col md={3}>
                          <FormGroup>
                            <Field
                              label="Number of creatives"
                              name="number_of_creatives"
                              type="number"
                              component={ReactstrapInput}
                            />
                            {errors.number_of_creatives && touched.number_of_creatives && errors.number_of_creatives}
                          </FormGroup>
                        </Col>
                        <Col md={2}>
                          <FormGroup>
                            <legend className="col-form-label">Shutterstock</legend>
                            <Field
                              disabled={values.dynamic_ad === true}
                              name="shutterstock"
                              component={ReactstrapRadio}
                              value={true}
                              type="radio"
                              label="True"
                            />
                            <Field
                              disabled={values.dynamic_ad === true}
                              name="shutterstock"
                              component={ReactstrapRadio}
                              value={false}
                              type="radio"
                              label="False"
                            />
                          </FormGroup>
                        </Col></>}
                    </Row>
                    <hr className="my-4" />
                    {/* Description */}
                    <h6 className="heading-small text-muted mb-4">KEYWORD LIST</h6>
                    <FormGroup>
                      <Label>MARKET SELECTION:</Label>
                      <FlagListGroup fill={MARKETS.length < 25} markets={MARKETS} selectedMarkets={marketList} onClick={toggleMarket} />
                    </FormGroup>
                    <Row>
                      {marketList.map((market) =>
                        <Col xs={6} md={4} key={`keyword-for-${market}`}>
                          <FormGroup>
                            <Label>Keyword List for <span className="text-primary" style={{ textTransform: "uppercase" }}>{market}</span></Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="List of keyword, one per row!"
                              rows="10"
                              type="textarea"
                              value={keywordList[market]}
                              onChange={(e) => {
                                let inputKeyword = e.target.value;
                                setKeywordList((prev) => {
                                  prev[market] = inputKeyword;
                                  return { ...prev };
                                });
                              }}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label>AdTemplate Language for <span className="text-primary" style={{ textTransform: "uppercase" }}>{market}</span></Label>
                            <Input
                              className="form-control-alternative"
                              placeholder="Ad template language"
                              type="select"
                              value={adtemplateLang[market] || adTemplateMapping[market]}
                              onChange={(e) => {
                                const language = e.target.value;
                                setAdTemplateLang((prev) => {
                                  prev[market] = language;
                                  return { ...prev };
                                });
                              }}
                            >
                              <option value="---">----</option>
                              {AD_TEMPLATE_LANGUAGE.map((m) => <option key={m.value} value={m.value}>{m.label}</option>)}
                            </Input>
                          </FormGroup>
                        </Col>
                      )}
                    </Row>
                    <hr />
                    <Row className="mt-4 justify-content-md-center">
                      <Col className="text-center" lg={2}>
                        <Button color="primary" type="submit" size="lg" disabled={isSubmitting}>MAKE MONEY BABE!!!</Button>
                        <FormGroup check inline className="mt-4" >
                          <Label check>
                            <Input type="checkbox" name="async_execution" onChange={(e) => setFieldValue("async_execution", e.target.checked)} /> DO NOT WAIT - you will receive the upload file via mail!
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
              </Spinner>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Modal isOpen={downloadModal} toggle={() => setDownloadModal(false)} size="lg">
        <ModalHeader toggle={() => setDownloadModal(false)}></ModalHeader>
        <ModalBody>
          <Row className="text-center">
            <Col>
              {logs.length ? logs.map((log, id) => <p className="text-warning" key={`msg-${id}`}>{log.message}</p>) : "SUCCESS!"}
            </Col>
          </Row>
          {downloadUrl && 
            <Row className="text-center mt-4">
              <Col>
                <h6 className="mt-2 mb-2">Click DOWNLOAD FILE to get your file!</h6>
                <a rel="noopener noreferrer" href={downloadUrl} target="_blank" className="btn btn-success">DOWNLOAD FILE</a>
              </Col>
            </Row>}
        </ModalBody>
      </Modal>
    </Container>
  );
}

const AD_TEMPLATE_LANGUAGE = [
    { value: "at", flag: "at", label: "Austria" },
    { value: "au", flag: "au", label: "Australia" },
    { value: "br", flag: "br", label: "Brazil" },
    { value: "ch", flag: "ch", label: "Switzerland" },
    { value: "de", flag: "de", label: "Germany" },
    { value: "dk", flag: "dk", label: "Danmark" },
    { value: "es", flag: "es", label: "Spain" },
    { value: "fi", flag: "fi", label: "Finland" },
    { value: "fr", flag: "fr", label: "France" },
    { value: "it", flag: "it", label: "Italy" },
    { value: "in", flag: "in", label: "India" },
    { value: "mx", flag: "mx", label: "Mexico" },
    { value: "nl", flag: "nl", label: "Netherlands" },
    { value: "no", flag: "no", label: "Norway" },
    { value: "se", flag: "se", label: "Sweden" },
    { value: "uk", flag: "gb", label: "United Kingdom" },
    { value: "us", flag: "us", label: "United States" }
  ];



  const UTM_CAMPAIGN = [
    { id: "automotive", name: "automotive" },
    { id: "education", name: "education" },
    { id: "fashion", name: "fashion" },
    { id: "finance", name: "finance" },
    { id: "flight", name: "flight" },
    { id: "generic", name: "generic" },
    { id: "health", name: "health" },
    { id: "household", name: "household" },
    { id: "services", name: "services" },
    { id: "shopping", name: "shopping" },
    { id: "tech", name: "tech" },
    { id: "travel", name: "travel" },
    { id:"blackfriday", name: "blackfriday"},
    // { value: "business", label: "business" },
    // { value: "internet", label: "internet" },
    // { value: "health", label: "health" },
    // { value: "astrology", label: "astrology" },
    // { value: "gambling", label: "gambling" }
  ];

  const OPTIMIZED_EVENTS = [{ name: "LEAD", id: "LEAD" }, { name: "PURCHASE", id: "PURCHASE" }, {name: "ADD_TO_CART", id:"ADD_TO_CART"}];


  const DEVICE_TARGETS = [
    { name: "mobile", id: "mobile" }, 
    { name: "desktop", id: "desktop" }, 
    { name: "both: one campaign for mobile and one for desktop", id:"both"},
    { name: "all + desktop: one campaign for all devices and one for desktop", id:"all_and_desktop"},
    { name: "all + mobile: one campaign for all devices and one for mobile", id:"all_and_mobile"},
    { name: "all + desktop + mobile: one campaign for all devices, one for desktop and one for mobile", id:"all_and_both"}
  ];

  const GENDER_AUDIENCE = [{ name: "Male", id: "Male" }, { name: "Female", id: "Female" }];

  const CTA = [
    { id: "BOOK_TRAVEL", name: "Book Now" },
    { id: "DOWNLOAD", name: "Download" },
    { id: "LEARN_MORE", name: "Learn More" },
    { id: "SHOP_NOW", name: "Shop Now" },
    { id: "SIGN_UP", name: "Sign Up" },
    { id: "GET_QUOTE", name: "Get Quote" }
  ];

export default FacebookAutomation;
