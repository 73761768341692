import React, { useState, useEffect } from "react";
import {Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, 
				Button, Form, FormGroup, Label, Input, Nav,
				NavItem, NavLink, TabContent, TabPane } from "reactstrap";


import classnames from "classnames";

const CreateAdTemplate = (props) => {
  const { isLoading, isOpen, toggleModal, languages, updateTemplate} = props;

  const [template, setTemplate] = useState({
    label: '',
    utm_campaign: ''
  });

  const [disabledUTMCampaign, setDisabledUTMCampaign] = useState(false);
  useEffect(() => {
  	const { label='', utm_campaign='' } = updateTemplate;
  	if ( updateTemplate.template ) {
  		setAdTemplate(JSON.parse(updateTemplate.template));
  		setDisabledUTMCampaign(true);
	  } else {
	  	setAdTemplate({});
	  	setDisabledUTMCampaign(false);
	  }
	  setTemplate({label, utm_campaign});

  }, [props.updateTemplate])

  const handleTemplateChange = (e) => setTemplate({
    ...template,
    [e.target.name]: e.target.value,
  });

  const blankAdTemplate = { title: '', body: '' };
  const [adTemplate, setAdTemplate] = useState({});
  
  const addAdTemplate = (lang) => {
  	const updatedAdTemplates = {...adTemplate};
  	updatedAdTemplates[lang] = [...adTemplate[lang], {...blankAdTemplate}];
    setAdTemplate(updatedAdTemplates);
  };

  const handleAdTemplateChange = (e) => {
	  const updatedAdTemplates = {...adTemplate};
	  updatedAdTemplates[e.target.dataset.lang][e.target.dataset.idx][e.target.dataset.name] = e.target.value;
	  console.log(updatedAdTemplates);
	  setAdTemplate(updatedAdTemplates);
	};

  const [activeTab, setActiveTab] = useState(false);
  const toggle = tab => {
  	if ( !adTemplate[tab] ) {
  		let newAdTemplate = {...adTemplate};
  		newAdTemplate[tab] = [blankAdTemplate];
  		setAdTemplate(newAdTemplate);
  	}
    if(activeTab !== tab) setActiveTab(tab);
  }


  return (
		<Modal isOpen={isOpen} toggle={toggleModal} size="lg">
	    <ModalHeader toggle={toggleModal}>AdTemplate:</ModalHeader>
	    <ModalBody>
	      <Row>
	        <Col>
	        	<Form>
	        		<FormGroup>
	        			<Label for="label">Label: </Label>
			          <Input
					        placeholder="AD TEMPLATE LABEL"
					        type="text"
					        id="label"
					        name="label"
	                value={template.label}
					        onChange={handleTemplateChange}
					      />
				      </FormGroup>
		          <FormGroup>
					      <Label for="utm_campaign">
					        UTM_CAMPAIGN
					      </Label>
					      <Input disabled={disabledUTMCampaign} placeholder="utm_campaign" type="select" name="utm_campaign" id="utm_campaign" value={template.utm_campaign} onChange={handleTemplateChange}>
					        <option key="empty-utm_campaign" value="">----</option>
					        {props.listUTMCampaign.map((m, key) => <option key={key} value={m.id}>{m.name}</option>)}
					      </Input>
					    </FormGroup>
					    {updateTemplate.label !== "" && template.label !== updateTemplate.label ? 
					    	<p>Please save your label change before updating the ad template title and/or body</p>
					    :<Row>
		      			<Col md={3}>
				          <Nav vertical pills>
				          	{languages.map((m, key) => {
				          		return <NavItem key={key}>
							          <NavLink
							            className={classnames({ active: activeTab === m.value })}
							            onClick={() => { toggle(m.value); }}
							          >
							            {m.label} { adTemplate[m.value] ? adTemplate[m.value].length : 0 }
							          </NavLink>
							        </NavItem>
				          	})}
						      </Nav>
						     </Col>
						     <Col md={9}>
						      <TabContent activeTab={activeTab}>
						      	{languages.map((m, key) => {
						      		return <TabPane tabId={m.value} key={`tab-${key}`}>
											  {adTemplate[m.value] && adTemplate[m.value].map((val, idx) => {
											    const titleId = `title-${idx}`;
											    const bodyId = `body-${idx}`;
											    return (
											      <div key={`adtemplate-${idx}`}>
										      	 	<FormGroup>
													      <Label htmlFor={titleId}>{`Title and body #${idx + 1}`}</Label>
												        <Input
												          type="text"
												          placeholder="TITLE"
												          name={titleId}
												          data-idx={idx}
												          data-lang={m.value}
												          id={titleId}
												          data-name="title" 
												          value={adTemplate[m.value][idx].title}
												          onChange={handleAdTemplateChange}
												        />
												        <hr className="my-1" />
												        <Input
												          type="textarea"
												          placeholder="BODY"
												          name={bodyId}
												          data-idx={idx}
												          data-lang={m.value}
												          id={bodyId}
												          data-name="body"
												          value={adTemplate[m.value][idx].body}
												          onChange={handleAdTemplateChange}
												        />
												      </FormGroup>
											      </div>
											    );      
											  })}
											  <Button className="" color="primary" onClick={() => addAdTemplate(m.value)}>Add + Title&Body</Button>
										  </TabPane>
										})}
									</TabContent>
								</Col>
							</Row>}
	        	</Form>
	        </Col>
	      </Row>
	    </ModalBody>
	    <ModalFooter>
	      <Button color="secondary" onClick={toggleModal}>CLOSE</Button>
	      <Button color="primary" disabled={isLoading} onClick={(e) => props.saveTemplate(template, adTemplate, updateTemplate)}>SAVE</Button>
	    </ModalFooter>
	  </Modal>
  )
}



export default CreateAdTemplate;

