import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Row, Col } from "reactstrap";


const LocalFilesPreview = (props) => {
  const { dropzoneFiles, addLocalImageToSelected=false } = props;
  const [showImages, setShowImages] = useState(true);
  const [dropzoneFilesPreview, setDropzoneFilePreview] = useState([]);

  useEffect(() => {
    let newDropzoneFilesPreview = dropzoneFiles.map((file) => {
      const imgURL = URL.createObjectURL(file);
      return {
        file,
        imgURL
      }
    })
    dropzoneFilesPreview.forEach((oldPreview) => {
      let contains = newDropzoneFilesPreview.filter((f) => f.file.name === oldPreview.file.name);
      if (contains.length === 0) {
        URL.revokeObjectURL(oldPreview.imgURL);
      }
    })
    setDropzoneFilePreview(newDropzoneFilesPreview);
    // Specify how to clean up after this effect:
    return function cleanup() {
      dropzoneFilesPreview.map((f) => URL.revokeObjectURL(f.imgURL));
    };
  }, [dropzoneFiles]);


  if ( dropzoneFilesPreview.length === 0 ) {
    return null;
  }

  return (
    <div>
      <Row className="justify-content-between">
        <Col>
          <h3 style={{ display: "inline-block", marginRight: "1rem" }}>FILE SELECTED:</h3>
          <i className={cx("far pointer", (showImages ? "fa-minus-square" : "fa-plus-square"))} onClick={() => setShowImages(!showImages)}></i>
        </Col>
      </Row>
      {showImages &&
        <Row className="align-items-center">
          {dropzoneFilesPreview.map((file, idx) => {
            return <Col key={`img-dropzone-${idx}`} xs={{ size: "auto" }}>
              <div className="d-block p-1 mb-4 h-100" style={{ position: "relative" }}>
                {addLocalImageToSelected && <i className="far fa-trash-alt img-tool-trash-icon text-red" onClick={() => addLocalImageToSelected([file.file], true)} />}
                <img src={file.imgURL} width="100px" height="auto" max-height="100px" alt="dropzone-img-preview" />
              </div>
            </Col>
          })}
        </Row>
      }
    </div>
  )
}

export default LocalFilesPreview;
