 import React, { useState, useEffect } from "react";
import { API } from 'aws-amplify';
import moment from "moment";
import { Field, Form, Formik, FieldArray } from "formik";
import { ReactstrapSelect, ReactstrapInput } from "reactstrap-formik";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
  Col,
  ListGroup,
  ListGroupItem,
  
} from "reactstrap";


import Spinner from "../../../components/Spinner";
import FlagListGroup from "../../../components/flagListGroup.js";

const TaboolaAutomation = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [accounts, setAccounts] = useState({});
  const [settings, setSettings] = useState({});
  const [marketList, setMarketList] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  // const [BLACKLIST, setBlacklist] = useState([]);
  // const [WHITELIST, setWhitelist] = useState([]);
  
  // const [OFFERS, setOffers] = useState([]);

  const loadSettings = async () => {
    setIsLoading(true);
    let response = await API.get('customAPI', '/keyword-tools/taboolaAutomation');
    setSettings(response.landingPages);
    setAccounts(response.taboolaAccounts);

    let {campaigns:networkCampaigns} = await API.get('customAPI', '/keyword-tools/taboola/campaigns', {
      queryStringParameters: {
        account: 'intellagilelimited-network'
      }
    });
    networkCampaigns = networkCampaigns.map((c) => ({
      "id": c.id + "#" + c.advertiser_name,
      name: c.name
    }));
    setCampaignList([...networkCampaigns]);
    // let techadsCampaignList = campaignListResponse.data
    //   .map((row) => ({id:row.campaign_id, name: row.campaign_name, account:row.advertiser_name}))
    //   .filter((r) => r.name !== "n/a");

    // let {data:campaignListResponse} = await API.get('customAPI', '/report', {
    //   queryStringParameters: {
    //     startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    //     endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    //     page: 1,
    //     perPage: 1000,
    //     inputFilterSearchText: "",
    //     columns: "advertiser_name,campaign_id,campaign_name,cost,revenue",
    //     groupby: "advertiser_name,campaign_id,campaign_name",
    //     filters: "{}",
    //     rules: "{}",
    //     table: "yahoo_activity_taboola",
    //     orderBy: JSON.stringify({"selector":"campaign_name","sortDirection":"asc"}),
    //     overwriters: ""
    //   }
    // });
    // let adpcampaignList = campaignListResponse
    //   .map((row) => ({id:row.campaign_id, name: row.campaign_name, account:row.advertiser_name}))
    //   .filter((r) => r.name !== "n/a");
    // campaignListResponse = await API.get('customAPI', '/report', {
    //   queryStringParameters: {
    //     startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    //     endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    //     page: 1,
    //     perPage: 1000,
    //     inputFilterSearchText: "",
    //     columns: "advertiser_name,campaign_id,campaign_name,cost,revenue",
    //     groupby: "advertiser_name,campaign_id,campaign_name",
    //     filters: "{}",
    //     rules: "{}",
    //     table: "teachads_bing_taboola_activity",
    //     orderBy: JSON.stringify({"selector":"campaign_name","sortDirection":"asc"}),
    //     overwriters: ""
    //   }
    // });
    // let techadsCampaignList = campaignListResponse.data
    //   .map((row) => ({id:row.campaign_id, name: row.campaign_name, account:row.advertiser_name}))
    //   .filter((r) => r.name !== "n/a");

    // let campaignListResponse = await API.get('customAPI', '/report', {
    //   queryStringParameters: {
    //     startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    //     endDate: moment().subtract(1, "days").format("YYYY-MM-DD"),
    //     page: 1,
    //     perPage: 1000,
    //     inputFilterSearchText: "",
    //     columns: "advertiser_name,campaign_id,campaign_name,cost,revenue",
    //     groupby: "advertiser_name,campaign_id,campaign_name",
    //     filters: "{}",
    //     rules: "{}",
    //     table: "perion_taboola_activity",
    //     orderBy: JSON.stringify({"selector":"campaign_name","sortDirection":"asc"}),
    //     overwriters: ""
    //   }
    // });
    // let perionCampaignList = campaignListResponse.data
    //   .map((row) => ({id:row.campaign_id, name: row.campaign_name, account:row.advertiser_name}))
    //   .filter((r) => r.name !== "n/a");

    // setCampaignList([...adpcampaignList, ...techadsCampaignList, ...perionCampaignList]);
    // setCampaignList([...perionCampaignList]);
    // console.log(campaignListResponse);
    setIsLoading(false);
  }


  const loadCopyBlacklistFrom = async (account) => {

    let response = await API.get('customAPI', '/keyword-tools/taboola/campaigns', {
      queryStringParameters: {
        account
      }
    });
    setCampaignList([...response.campaigns]);

  }

  const toggleMarket = (newMarket) => {
    let index = marketList.indexOf(newMarket);
    if (index === -1) {
      setMarketList([...marketList, newMarket])
    } else {
      marketList.splice(index, 1);
      setMarketList([...marketList]);
    }
  }

  useEffect(() => {
    loadSettings();
  }, []);

  const [downloadUrl,setDownloadurl] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false)
  const [logs, setLogs] = useState([]);
  const makeMoneyBaby = async (values, { setSubmitting }) => {
    console.log(values, marketList);
    try {
      const {logs=[], downloadUrl=false} = await API.post('customAPI', '/keyword-tools/taboolaAutomation', {
        body: {
          marketList,
          ...values
        }
      });
      setLogs(logs);
      setDownloadurl(downloadUrl);
      setDownloadModal(true);
    } catch ( e ) {
      alert(e.message);
    }
    setSubmitting(false);
  }

  const [focusOn, setFocusOn] = useState("");

  let blankAccountSetup = {
    account: "",
    domain: '', 
    utm_source: '', 
    utm_campaign: '', 
    "site_id": '', 
    "domainUrl": "", 
    device: '', 
    isPerion: false,
    listPerionWallet: "",
    copy_blacklist_from: ''
  }

  const addAccountSetup = (values, setValues) => {
    // update dynamic form
    const accounts = [...values.accounts];
    accounts.push({...blankAccountSetup});
    setValues({ ...values, accounts });
  };

  const deleteAccountSetup = (idx, values, setValues) => {
    // update dynamic form
    const accounts = [...values.accounts];
    accounts.splice(idx, 1);
    setValues({ ...values, accounts }); 
  }

  return (
    <Container className="mt-2" fluid>
      <Row>
        <Col>
          <Card className="bg-secondary shadow">
            <CardHeader className="border-0 bg-green">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">TABOOLA UPLOAD</h3>
                </Col>
                <Col xs="3">
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Spinner isLoading={isLoading}>
              <Formik
                initialValues={{ 
                  accounts: [{...blankAccountSetup}],
                  daily_budget: 30, 
                  bid: 0.8, 
                  targetCPA: 1,
                  optimizationType: "",
                  number_of_creatives: 2
                }}
                onSubmit={makeMoneyBaby}
              >
                {({
                  values,
                  setValues,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue
                  /* and other goodies */
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <h6 className="heading-small text-muted mb-4">ACCOUNT SETUP <Button color="primary" type="button" size="sm" className="float-right" onClick={() => addAccountSetup(values, setValues)}>ADD ACCOUNT SETUP</Button></h6>
                    <FieldArray name="accounts">
                      {() => (values.accounts.map((account,idx) => {

                        const accountErrors = errors.accounts && errors.accounts.length && errors.accounts[idx] || {};
                        const accountTouched = touched.accounts && touched.accounts.length && touched.accounts[idx] || {};
                        // console.log(account);

                        return (<div key={`account-${idx}`}> 
                          {idx > 0 && <hr />}
                          <Row form>
                            <Col md={3}>
                              <FormGroup>
                                <Field
                                  label="Account"
                                  name={`accounts.${idx}.account`}
                                  component={ReactstrapSelect}
                                  inputprops={{
                                    name: `accounts.${idx}.account`,
                                    id: `accounts.${idx}.account`,
                                    options: Object.keys(accounts).map((a) => accounts[a]),
                                    defaultOption: "-- Account --"
                                  }}
                                  onChange={(e) => {
                                    const {domain} = accounts[e.target.value];
                                    setFieldValue(`accounts.${idx}.domain`, domain);
                                    // loadCopyBlacklistFrom(e.target.value);
                                    handleChange(e);
                                  }}
                                />
                                {accountErrors.account && accountTouched.account && accountErrors.account}
                              </FormGroup>
                            </Col>
                            <Col md={3}>
                              <FormGroup>
                                <Field
                                  label="Domain"
                                  name={`accounts.${idx}.domain`}
                                  component={ReactstrapSelect}
                                  inputprops={{
                                    name: `accounts.${idx}.domain`,
                                    id: `accounts.${idx}.domain`,
                                    options: Object.keys(settings),
                                    defaultOption: "-- Domain --"
                                  }}
                                  onChange={(e) => {

                                    if (["ShoppingTips","SearchTips","PriceSeeker","HealthTips"].indexOf(e.target.value) != -1) {
                                      setFieldValue(`accounts.${idx}.device`, "mobile");
                                    }
                                    
                                    const domain = e.target.value;
                                    let [account=false] = Object.values(accounts).filter(a => a.domain === domain);
                                    if ( account )
                                      setFieldValue(`accounts.${idx}.account`, account.id);

                                    handleChange(e);
                                  }}
                                />
                                {accountErrors.domain && accountTouched.domain && accountErrors.domain}
                              </FormGroup>
                            </Col>
                            <Col md={3}>
                              <FormGroup>
                                <Field
                                  label="UTM Source"
                                  name={`accounts.${idx}.utm_source`}
                                  component={ReactstrapSelect}
                                  inputprops={{
                                    name: `accounts.${idx}.utm_source`,
                                    id: `accounts.${idx}.utm_source`,
                                    options: Object.keys(settings[account.domain] || {}),
                                    defaultOption: "-- UTM Source --"
                                  }}
                                  onChange={(e) => {
                                    const {site_id=false, domainUrl=false, device="", isPerion=false, listPerionWallet=""} = settings[account.domain] 
                                      && settings[account.domain][e.target.value];
                                    
                                    setFieldValue(`accounts.${idx}.site_id`, site_id);
                                    setFieldValue(`accounts.${idx}.domainUrl`, domainUrl);
                                    setFieldValue(`accounts.${idx}.device`, device);
                                    setFieldValue(`accounts.${idx}.isPerion`, isPerion);
                                    setFieldValue(`accounts.${idx}.listPerionWallet`, listPerionWallet);
                                    handleChange(e)
                                  }}
                                />
                                {accountErrors.utm_source && accountTouched.utm_source && accountErrors.utm_source}
                              </FormGroup>
                            </Col>
                            <Col md={3}>
                              <FormGroup>
                                <Field
                                  label="UTM Campaign"
                                  name={`accounts.${idx}.utm_campaign`}
                                  component={ReactstrapSelect}
                                  inputprops={{
                                    name: `accounts.${idx}.utm_campaign`,
                                    id: `accounts.${idx}.utm_campaign`,
                                    options: UTM_CAMPAIGN,
                                    defaultOption: "-- UTM Campaign --"
                                  }}
                                />
                                {accountErrors.utm_campaign && accountTouched.utm_campaign && accountErrors.utm_campaign}
                              </FormGroup>
                            </Col>
                            <Col md={3}>
                              <FormGroup>
                                <Field
                                  label="DEVICE"
                                  name={`accounts.${idx}.device`}
                                  component={ReactstrapSelect}
                                  inputprops={{
                                    name: `accounts.${idx}.device`,
                                    id: `accounts.${idx}.device`,
                                    options: DEVICEs,
                                    defaultOption: "-- DEVICE --"
                                  }}
                                />
                                {accountErrors.device && accountTouched.device && accountErrors.device}
                              </FormGroup>
                            </Col>
                            <Col md={3}>
                              <FormGroup>
                                <Field
                                  label="Copy Blacklist from:"
                                  name={`accounts.${idx}.copy_blacklist_from`}
                                  component={ReactstrapSelect}
                                  inputprops={{
                                    name: `accounts.${idx}.copy_blacklist_from`,
                                    id: `accounts.${idx}.copy_blacklist_from`,
                                    options: campaignList,
                                    defaultOption: "-- Campaign --"
                                  }}
                                />
                                {accountErrors.copy_blacklist_from && touched.copy_blacklist_from && accountErrors.copy_blacklist_from}
                              </FormGroup>
                            </Col>
                            {idx > 0 && 
                              <Col xs={12}>
                                <h6 className="heading-small text-muted mb-4"><Button type="button" color="danger" size="sm" className="float-right" onClick={() => deleteAccountSetup(idx, values, setValues)}><i className="fas fa-arrow-up"/>&nbsp;DELETE</Button></h6>
                                <h6 className="heading-small text-muted mb-4"><Button type="button" color="primary" size="sm" className="float-right" onClick={() => addAccountSetup(values, setValues)}>ADD ACCOUNT SETUP</Button></h6>
                              </Col>
                            }
                          </Row>
                          </div>
                        );
                      }))}
                    </FieldArray>
                    <hr />
                    {/* Description */}
                    <h6 className="heading-small text-muted mb-4">CAMPAIGN SETUP</h6>
                    <Row form>
                      
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="Campaign Daily Budget"
                            name="daily_budget"
                            type="number"
                            component={ReactstrapInput}
                          />
                          {errors.daily_budget && touched.daily_budget && errors.daily_budget}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="BID"
                            name="bid"
                            type="number"
                            component={ReactstrapInput}
                          />
                          {errors.bid && touched.bid && errors.bid}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            label="Max number of creative per image"
                            name="number_of_creatives"
                            type="number"
                            component={ReactstrapInput}
                          />
                          {errors.number_of_creatives && touched.number_of_creatives && errors.number_of_creatives}
                        </FormGroup>
                      </Col>
                      {/*<Col md={6}>
                        <FormGroup>
                          <Field
                            label="CONVERSION BID STRATEGY"
                            name="optimizationType"
                            component={ReactstrapSelect}
                            inputprops={{
                              name: "optimizationType",
                              id: "optimizationType",
                              options: optimizationTypes,
                              defaultOption: "-- OPTIMISATION --"
                            }}
                          />
                          {errors.optimizationType && touched.optimizationType && errors.optimizationType}
                        </FormGroup>
                      </Col>
                      <Col md={3}>
                        <FormGroup>
                          <Field
                            disabled={values.optimizationType !== "TARGET_CPA_FULLY_AUTOMATED"}
                            label="Target CPA"
                            name="targetCPA"
                            type="number"
                            component={ReactstrapInput}
                          />
                          {errors.targetCPA && touched.targetCPA && errors.targetCPA}
                        </FormGroup>
                      </Col>*/}
                      
                    </Row>
                    <hr className="my-4" />
                    {/* Description */}
                    <h6 className="heading-small text-muted mb-4">KEYWORD LIST</h6>
                    <FormGroup>
                      <Label>MARKET SELECTION:</Label>
                      <FlagListGroup fill={true} markets={MARKETS} selectedMarkets={marketList} onClick={toggleMarket} />
                    </FormGroup>
                    {marketList.map((market) =>
                      <div key={market}>
                        <Row>
                          <Col md={6}>
                            <h3>
                              AD TEMPLATE <span className="text-primary" style={{ textTransform: "uppercase" }}>{market}</span> - 
                              use {`{Keyword}`} has a placeholder.
                            </h3>
                            <hr className="my-1" />
                            <ListGroup className="list-group-horizontal" style={{cursor: "pointer"}}>
                              <ListGroupItem className="p-1"><b>Macro:</b></ListGroupItem>
                              {/*eslint no-template-curly-in-string: 0*/}
                              {[
                                {name: "country", value:"${country:capitalized}$"},
                                // {name: "region", value:"$region$"},
                                {name: "city", value:"${city:capitalized}$"},
                                {name: "dayofweek", value:"${dayofweek:capitalized}$"}].map((opt, idn) => (
                              <ListGroupItem key={`macro-${idn}`} className="p-1" onClick={(e) => {
                                e.preventDefault();
                                const [fieldName, idx=false] = focusOn.split(".");
                                let currValue = values[fieldName];
                                if ( !!idx ) {
                                  currValue = values[fieldName][idx];
                                }
                                if ( !currValue ) {
                                  return;
                                }
                                setFieldValue(focusOn, currValue + " " + opt.value);
                                document.getElementById(focusOn).focus();
                              }} href="#" id={opt.name}>{opt.name}
                              </ListGroupItem>))}
                            </ListGroup>
                            <FieldArray
                               name={`${market}_adtitle`}
                               render={arrayHelpers => (
                                 <div>
                                 {[...Array(values.number_of_creatives)].map((x, index) =>
                                    <Field
                                      key={`Title ${index+1}`}
                                      label={`Title ${index+1}`}
                                      placeholder="Title ( max 150 characters )"
                                      name={`${market}_adtitle.${index}`}
                                      type="textarea"
                                      component={ReactstrapInput}
                                      onFocus={() => setFocusOn(`${market}_adtitle.${index}`)}
                                      id={`${market}_adtitle.${index}`}
                                    />
                                  )}
                                 </div>
                               )}
                             />
                            <hr className="my-1" />
                            <FieldArray
                               name={`${market}_description`}
                               render={arrayHelpers => (
                                 <div>
                                 {[...Array(values.number_of_creatives)].map((x, index) =>
                                    <Field
                                      key={`Description ${index+1}`}
                                      label={`Description ${index+1}`}
                                      placeholder="Description ( max 150 characters )"
                                      name={`${market}_description.${index}`}
                                      type="textarea"
                                      component={ReactstrapInput}
                                      onFocus={() => setFocusOn(`${market}_adtitle.${index}`)}
                                      id={`${market}_description.${index}`}
                                    />
                                  )}
                                 </div>
                               )}
                             />
                            <hr className="my-1" />
                            <Field
                              label="Call to action ( optional )"
                              name={`${market}_adcta`}
                              component={ReactstrapSelect}
                              inputprops={{
                                name: `${market}_adcta`,
                                id: `${market}_adcta`,
                                options: CTA,
                                defaultOption: "NONE"
                              }}
                            />
                          </Col>
                          <Col xs={6} md={6} key={`keyword-for-${market}`}>
                            <Field
                              label={`Keyword List FOR ${market.toUpperCase()}`}
                              placeholder="List of keyword, one per row!"
                              name={`${market}_keywordList`}
                              type="textarea"
                              rows="20"
                              component={ReactstrapInput}
                            />
                          </Col>
                        </Row>
                        <hr className="my-4" />
                      </div>
                    )}
                    <Row className="mt-4">
                      <Col className="text-center">
                        <Button color="primary" type="submit" disabled={isSubmitting}>MAKE MONEY BABE!!!</Button>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
              </Spinner>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={downloadModal} toggle={() => setDownloadModal(false)} size="lg">
        <ModalHeader toggle={() => setDownloadModal(false)}></ModalHeader>
        <ModalBody>
          <Row className="text-center">
            <Col>
              {logs.map((log) => {
                let message = log.message;
                if ( log.id_campaign ) {
                  message = <a rel="noopener noreferrer" href={`https://ads.taboola.com/campaigns/edit/account/${log.account}/campaign/${log.id_campaign}`} target="_blank">
                    {log.message}</a> 
                }
                return <p className={log.warning ? "text-warning" : "text-success"}>{message}</p>
              })}
            </Col>
          </Row>
          {downloadUrl && <Row className="text-center mt-4">
            <Col>
              <h6 className="mt-2 mb-2">Click DOWNLOAD ZIP to get your bulk zip!</h6>
              <a rel="noopener noreferrer" href={downloadUrl} target="_blank" className="btn btn-success">DOWNLOAD ZIP</a>
            </Col>
          </Row>}
        </ModalBody>
      </Modal>
    </Container>
  );
}


// const optimizationTypes = [
//   // {id: "TRAFFIC", name:  "Campaign optimized towards maximizing traffic (clicks)"},
//   {id: "CPC", name: "Semi Automatic"},
//   {id: "MAX_CONVERSION_FULLY_AUTOMATED", name: "Fully Automatic"},
//   {id: "TARGET_CPA_FULLY_AUTOMATED", name: "Target CPA"}
// ]


const DEVICEs = [
  { id: "mobile", name: "mobile" },
  { id: "tablet", name: "tablet" },
  { id: "desktop", name: "desktop" },
]



const MARKETS = [
    {value: "ar", flag: "ar", label: "Argentina"},
    {value: "at", flag: "at", label: "Austria"},
    {value: "br", flag: "br", label: "Brazil"},
    {value: "ca", flag: "ca", label: "Canada"},
    {value: "ch", flag: "ch", label: "Switzerland"},
    {value: "cl", flag: "cl", label: "Chile"},
    {value: "co", flag: "co", label: "Colombia"},
    {value: "de", flag: "de", label: "Germany"},
    {value: "dk", flag: "dk", label: "Danmark"},
    {value: "es", flag: "es", label: "Spain"},
    {value: "fi", flag: "fi", label: "Finland"},
    {value: "fr", flag: "fr", label: "France"},
    {value: "hk", flag: "hk", label: "Hong Kong"},
    {value: "in", flag: "in", label: "India"},
    {value: "id", flag: "id", label: "Indonesia"},
    {value: "it", flag: "it", label: "Italy"},
    {value: "mx", flag: "mx", label: "Mexico"},
    {value: "nl", flag: "nl", label: "Netherlands"},
    {value: "no", flag: "no", label: "Norway"},
    {value: "pe", flag: "pe", label: "Peru"},
    {value: "ph", flag: "ph", label: "Philippines"},
    {value: "se", flag: "se", label: "Sweden"},
    {value: "sg", flag: "sg", label: "Singapore"},
    {value: "tw", flag: "tw", label: "Taiwan"},
    {value: "th", flag: "th", label: "Thailand"},
    {value: "uk", flag: "gb", label: "United Kingdom"},
    {value: "us", flag: "us", label: "United States"},
    {value: "ve", flag: "ve", label: "Venezuela"},
    {value: "vn", flag: "vn", label: "Vietnam"},
  ];

  const UTM_CAMPAIGN = [
    { id: "automotive", name: "automotive" },
    { id: "education", name: "education" },
    { id: "fashion", name: "fashion" },
    { id: "finance", name: "finance" },
    { id: "flight", name: "flight" },
    { id: "gambling", name: "gambling" },
    { id: "health", name: "health" },
    { id: "household", name: "household" },
    { id: "services", name: "services" },
    { id: "shopping", name: "shopping" },
    { id: "tech", name: "tech" },
    { id: "travel", name: "travel" }
    // { value: "business", label: "business" },
    // { value: "internet", label: "internet" },
    // { value: "health", label: "health" },
    // { value: "astrology", label: "astrology" }
  ];




  const CTA = [
    { id: 'DOWNLOAD', name: 'Download' },
    { id: 'INSTALL_NOW', name: 'Install Now' },
    { id: 'LEARN_MORE', name: 'Learn More' },
    { id: 'SHOP_NOW', name: 'Shop Now' },
    { id: 'CLICK_HERE', name: 'Click Here' },
    { id: 'SIGN_UP', name: 'Sign Up' },
    { id: 'PLAY_NOW', name: 'Play Now' },
    { id: 'READ_MORE', name: 'Read More' },
    { id: 'GET_QUOTE', name: 'Get Quote' },
    { id: 'GET_OFFER', name: 'Get Offer' },
    { id: 'TRY_NOW', name: 'Try Now' },
    { id: 'NONE', name: 'None' }
  ];


export default TaboolaAutomation;
