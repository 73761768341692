import React, { useState, useEffect } from "react";
import cx from "classnames";
import { API } from 'aws-amplify';
import { Link } from "react-router-dom";

import DataTable from 'react-data-table-component';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  FormGroup,
  Col,
  Input,
  UncontrolledTooltip,
  ButtonGroup,
  Label
} from "reactstrap";



const FbRules = (props) => {


  const [isLoading, setIsLoading] = useState(false);
  const [customRules, setCustomRules] = useState([]);
  const [fbAccounts, setFbAccounts] = useState([]);
  const [fbAccountsMapping, setFbAccountsMapping] = useState({});

  const [fbByUtmCampaign, setFbByUtmCampaign] = useState({});
  const [fbByDomain, setFbByDomain] = useState({});
  const [fbByBuyingStrategy, setFbByBuyingStrategy] = useState({});
  const [fbAccountsByActivity, setFbByActivitiy] = useState({});
  const [filterByActivity, setFilterByActivity] = useState(false);
  const [filterByBuyingStrategy, setFilterByBuyingStrategy] = useState(false);
  const [filterByDomain, setFilterByDomain] = useState(false);
  const [filterByUTMCampaign, setFilterByUtmCampaign] = useState(false);

  const loadSettings = async () => {
    setIsLoading(true);
  
    let response = await API.get('customAPI', '/facebookAccountMapper/businessManager');
    const businessManagerMap = response.businessManagerList.reduce((acc, row) => {
      acc[row.id] = row;
      return acc;
    }, {})

    let {mapping} = await API.get('customAPI', '/facebookAccountMapper/mapping', {
      queryStringParameters: {
        groupby: "accountID",
        ignore_bulkupload: true
      }
    });
    setFbAccountsMapping(mapping);

    const fbAccounts = Object.keys(mapping).map((account_id) => {
      return {id: account_id, name: mapping[account_id].accountName, activity: mapping[account_id].activity, 
        bm: businessManagerMap[mapping[account_id].businessManagerID].name, buying_strategy: mapping[account_id].buying_strategy,
        utm_campaign: mapping[account_id].utm_campaign, domain: mapping[account_id].domain, raw: {...mapping[account_id]}};
    });
    setFbAccounts(fbAccounts);

    const fbByUtmCampaign = fbAccounts.reduce((acc, row) => {
      if ( row.utm_campaign ) {
        acc[row.utm_campaign] = acc[row.utm_campaign] || [];
        acc[row.utm_campaign].push(row);
      }
      return acc;
    }, {});
    setFbByUtmCampaign(fbByUtmCampaign);

    const fbByDomain = fbAccounts.reduce((acc, row) => {
      if ( row.domain ) {
        acc[row.domain] = acc[row.domain] || [];
        acc[row.domain].push(row);
      }
      return acc;
    }, {});
    setFbByDomain(fbByDomain);

    const fbByBuyingStrategy = fbAccounts.reduce((acc, row) => {
      if ( row.buying_strategy ) {
        acc[row.buying_strategy] = acc[row.buying_strategy] || [];
        acc[row.buying_strategy].push(row);
      }
      return acc;
    }, {});
    setFbByBuyingStrategy(fbByBuyingStrategy)

    const fbAccountsByActivity = fbAccounts.reduce((acc, row) => {
      if ( row.activity ) {
        acc[row.activity] = acc[row.activity] || [];
        acc[row.activity].push(row);
      }
      return acc;
    }, {});
    setFbByActivitiy(fbAccountsByActivity);

    const {rules} = await API.get('customAPI', '/fbrules');
    setCustomRules(rules);
    const ruleByName = rules.reduce((acc,r) => {
      acc[r.name] = acc[r.name] || {...r};
      acc[r.name].fbAccounts = acc[r.name].fbAccounts || [];
      acc[r.name].fbAccounts.push(r.fbAccount);
      return acc;
    }, {});

    const logsbyRule = 
    
    setRuleByName(ruleByName);
    setRuleNames(Object.keys(ruleByName).sort());
    setIsLoading(false);
  }

  const [ruleNameFilter, setRuleNameFilter] = useState("");
  const [ruleNames, setRuleNames] = useState([]);
  const [ruleByName, setRuleByName] = useState({});
  const [accountNameFilter, setAccountNameFilter] = useState(false);

  useEffect(() => {
    loadSettings();
  }, []);


  let data = customRules.filter((r) => ruleNameFilter && ruleNameFilter.length ? r.name === ruleNameFilter : true)
                      .filter((r) => accountNameFilter && accountNameFilter.length ? r.fbAccount == accountNameFilter : true)
                      .filter((r) => filterByActivity && filterByActivity.length ? r.activity === filterByActivity : true)
                      .filter((r) => filterByDomain && filterByDomain.length && fbAccountsMapping[r.fbAccount] ? fbAccountsMapping[r.fbAccount].domain === filterByDomain : true)
                      .filter((r) => filterByBuyingStrategy && filterByBuyingStrategy.length && fbAccountsMapping[r.fbAccount] ? fbAccountsMapping[r.fbAccount].buying_strategy === filterByBuyingStrategy : true)
                      .filter((r) => filterByUTMCampaign && filterByUTMCampaign.length && fbAccountsMapping[r.fbAccount] ? fbAccountsMapping[r.fbAccount].utm_campaign === filterByUTMCampaign : true)
  

  return (
    <Container className="mt-2" fluid>
      <Row>
        <Col>
          <Card className="shadow">
            <CardHeader className={cx("border-0")}>
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Facebook rules LOGs dashboard</h3>
                </Col>
                <Col className="text-right" xs="3">
                  <Link to='/admin/keyword-tools/automation/fb-rules' className="btn btn-icon btn-info" 
                    style={{paddingTop:0, paddingLeft: 5, paddingBottom:0, paddingRight:5}}>
                    GO BACK to rule dashboard
                  </Link>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <Label for="fbAccount">Activity: </Label>
                    <Input bsSize="sm" type="select" name="ruleActivity" id="ruleActivity" onChange={(e) => setFilterByActivity(e.target.value)} value={filterByActivity}>
                      <option key="empty-activity" value="">----</option>
                      {Object.keys(fbAccountsByActivity).map((activity,i) => <option key={`activity-${i}`} value={activity}>{activity}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="fbAccount">Buying Strategy: </Label>
                    <Input bsSize="sm" type="select" name="buying_strategy" id="buying_strategy" onChange={(e) => setFilterByBuyingStrategy(e.target.value)} value={filterByBuyingStrategy}>
                      <option key="empty-buying_strategy" value="">----</option>
                      {Object.keys(fbByBuyingStrategy).map((buying_strategy,i) => <option key={`buying_strategy-${i}`} value={buying_strategy}>{buying_strategy}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="fbAccount">Domain: </Label>
                    <Input bsSize="sm" type="select" name="domain" id="domain" onChange={(e) => setFilterByDomain(e.target.value)} value={filterByDomain}>
                      <option key="empty-domain" value="">----</option>
                      {Object.keys(fbByDomain).map((domain,i) => <option key={`domain-${i}`} value={domain}>{domain}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="fbAccount">UTM Campaign: </Label>
                    <Input bsSize="sm" type="select" name="utmCampaign" id="utmCampaign" onChange={(e) => setFilterByUtmCampaign(e.target.value)} value={filterByUTMCampaign}>
                      <option key="empty-utmCampaign" value="">----</option>
                      {Object.keys(fbByUtmCampaign).map((utmCampaign,i) => <option key={`utmCampaign-${i}`} value={utmCampaign}>{utmCampaign}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="fbAccount">Rule Name: </Label>
                    <Input type="select" name="ruleNameFilter" bsSize="sm" value={ruleNameFilter} onChange={(e) => setRuleNameFilter(e.target.value)}>
                      <option key="empty-page" value="">----</option>
                      {ruleNames.map((name) => <option key={name} value={name}>{name}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="fbAccount">FB Account: </Label>
                    <Input type="select" name="accountNameFilter" bsSize="sm" value={accountNameFilter} onChange={(e) => setAccountNameFilter(e.target.value)}>
                      <option key="empty-page" value="">----</option>
                      {fbAccounts.map((fb) => <option key={fb.name} value={fb.id}>{fb.name}</option>)}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <DataTable
                    columns={[
                      {
                        name: 'Name',
                        selector: 'name',
                        sortable: true,
                        grow: 1
                      },
                      {
                        name: 'fBAccountName',
                        selector: 'fBAccountName',
                        sortable: true,
                        grow: 1
                      },
                      {
                        name: 'Message',
                        selector: 'log',
                        sortable: true,
                        wrap:true,
                        grow: 2
                      },
                      {
                        name: "Last Execution Time",
                        selector: "last_execution_time",
                        sortable: true,
                        grow: 0.5
                      }
                    ]}
                    responsive={true}
                    title="Facebook Rules Logs"
                    progressPending={isLoading}
                    pagination
                    data={data.reduce((acc, r) => {
                      let outputLogs = JSON.parse(r.last_execution_output) || [];
                      r.fBAccountName = fbAccountsMapping[r.fbAccount] ? fbAccountsMapping[r.fbAccount].accountName : r.fbAccount;
                      // r.last_execution_time = r.last_execution_time;
                      outputLogs.map((log) => {
                        acc.push({...r, log: log.split("->").pop()})
                      });
                      return acc;
                    }, [])}
                    defaultSortField="name"
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}


export default FbRules;
